// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import {
  Grid,
  Input,
  InputLabel,
  InputAdornment,
  TextField,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
  Button,
  FormHelperText,
  Select,
  ListItemText
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'

// =============================================================================
// Style.
// =============================================================================

const useStyles = makeStyles((theme) => ({
  margin: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  checkBoxMargin: {
    marginTop: theme.spacing(0),
    marginLeft: -10
  },
  checkBoxHelperText: {
    marginTop: -8,
    marginLeft: 32,
    marginBottom: theme.spacing(1)
  },
  dateTextField: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
    width: '100%'
  }
}))

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const FormField = props => {
  const classes = useStyles()

  switch (props.type) {
    case 'date':
    case 'datetime':
      return (
        <Grid item xs={12} md={props.fullWidth ? 12 : props.width ? props.width : 6} style={{ paddingRight: 10 }}>
          <TextField
            id={`site-form-${props.name}`}
            label={props.label}
            type={props.type === 'datetime' ? 'datetime-local' : 'date'}
            autoFocus={props.autoFocus}
            required={props.required}
            className={classes.dateTextField}
            error={!!props.error}
            value={props.value || ''}
            onChange={props.handleChange}
            disabled={props.disabled}
            helperText={props.error ? props.error : props.helperText}
            InputLabelProps={{
              shrink: true
            }}
          />
        </Grid>
      )

    case 'checkbox':
      return (
        <FormControl>
          <FormGroup>
            <FormControlLabel
              control={(
                <Checkbox
                  name={`site-form-${props.name}`}
                  checked={!!+props.value}
                  onChange={props.handleChange}
                />
              )}
              required={props.required}
              error={props.error ? props.error : ''}
              label={props.label}
              className={props.noMargin ? null : classes.checkBoxMargin}
              disabled={props.disabled || props.readOnly}
            />
          </FormGroup>
          {props.helperText ? <FormHelperText className={classes.checkBoxHelperText}>{props.helperText}</FormHelperText> : null}
        </FormControl>
      )

    case 'file':
      return (
        <Grid item xs={12} md={props.fullWidth ? 12 : props.width ? props.width : 6}>
          <input
            accept={props.accept.join(',')}
            className={props.noMargin ? null : classes.margin}
            style={{ display: 'none' }}
            id={`site-form-${props.name}`}
            onChange={props.handleChange}
            type='file'
            ref={props.ref}
            disabled={props.disabled || props.readOnly}
          />
          <label htmlFor={`site-form-${props.name}`}>
            <Button
              variant='outlined'
              color='default'
              component='span'
              className={classes.button}
              style={props.fullWidth ? { width: '100%' } : null}
              disabled={props.disabled || props.readOnly}
            >
              {props.label || 'Seleccionar'}
            </Button>
          </label>
          <p className='FileInputName'>{props.value ? props.value.name : null}</p>
        </Grid>
      )

    case 'select':
      return (
        <Grid item xs={12} md={props.fullWidth ? 12 : props.width ? props.width : 6} style={{ paddingRight: 10 }}>
          <TextField
            fullWidth
            select
            autoFocus={props.autoFocus}
            required={props.required}
            id={`site-form-${props.name}`}
            error={!!props.error}
            label={props.label}
            className={props.noMargin ? null : classes.margin}
            value={props.value !== null && typeof props.value !== 'undefined' ? props.value : ''}
            onChange={props.handleChange}
            helperText={props.error ? props.error : props.helperText}
            disabled={props.disabled}
            variant={props.readOnly ? 'filled' : 'standard'}
            InputProps={{
              readOnly: props.readOnly,
              startAdornment: props.startAdornment ? <InputAdornment position='start'>{props.startAdornment}</InputAdornment> : null
            }}
          >
            {
              props.options.map(option => (
                <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                  {option.label}
                </MenuItem>
              ))
            }
          </TextField>
        </Grid>
      )

    case 'autocomplete':
      return (
        <Grid item xs={12} md={props.fullWidth ? 12 : props.width ? props.width : 6} style={{ paddingRight: 10 }}>
          <Autocomplete
            fullWidth
            className={props.noMargin ? null : classes.margin}
            id={`site-form-${props.name}`}
            options={props.options}
            autoHighlight
            autoFocus={props.autoFocus}
            required={props.required}
            disabled={props.disabled}
            onChange={(e, row) => props.handleChange({ target: { value: row && row.value ? +row.value : '' } })}
            getOptionLabel={option => option.label}
            getOptionDisabled={option => option.disabled}
            getOptionSelected={(option, value) => +option.value === +value.value}
            renderOption={option => option.label}
            renderInput={params => (
              <TextField
                {...params}
                label={props.label}
                variant={props.readOnly ? 'filled' : 'standard'}
              />
            )}
          />
        </Grid>
      )

    case 'multiselect':
      return (
        <Grid item xs={12} md={props.fullWidth ? 12 : props.width ? props.width : 6} style={{ paddingRight: 10 }}>
          <FormControl fullWidth className={props.noMargin ? null : classes.margin} error={props.error}>
            <InputLabel
              id={`site-form-${props.name}-label`}
              required={props.required}
              disabled={props.disabled}
            >
              {props.label}
            </InputLabel>
            <Select
              labelId={`site-form-${props.name}-label`}
              id={`site-form-${props.name}`}
              autoFocus={props.autoFocus}
              required={props.required}
              multiple
              disabled={props.disabled}
              error={!!props.error}
              value={props.value !== null && typeof props.value !== 'undefined' ? props.value : ''}
              onChange={props.handleChange}
              input={<Input />}
              renderValue={values => values.map(v => props.options.find(o => o.value === v).label).join(', ')}
            >
              {
                props.options.map(option => (
                  <MenuItem key={option.value} value={option.value} disabled={option.disabled}>
                    {option.disabled ? null : <Checkbox checked={props.value.indexOf(option.value) > -1} />}
                    <ListItemText primary={option.label} />
                  </MenuItem>
                ))
              }
            </Select>
            <FormHelperText>{props.error ? props.error : props.helperText}</FormHelperText>
          </FormControl>
        </Grid>
      )

    default:
      return (
        <Grid item xs={12} md={props.fullWidth ? 12 : props.width ? props.width : 6} style={{ paddingRight: 10 }}>
          <TextField
            type={props.type || 'text'}
            fullWidth
            autoFocus={props.autoFocus}
            required={props.required}
            id={`site-form-${props.name}`}
            error={!!props.error}
            label={props.label}
            className={props.noMargin ? null : classes.margin}
            value={props.value !== null && typeof props.value !== 'undefined' ? props.value : ''}
            onChange={props.handleChange}
            helperText={props.error ? props.error : props.helperText}
            disabled={props.disabled}
            variant={props.readOnly ? 'filled' : 'standard'}
            rows={props.rows || 1}
            margin={props.margin}
            multiline={props.rows > 1}
            InputProps={{
              readOnly: props.readOnly,
              startAdornment: props.startAdornment ? <InputAdornment position='start'>{props.startAdornment}</InputAdornment> : null
            }}
          />
        </Grid>
      )
  }
}

// Export.
export default FormField
