// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import * as _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  LinearProgress,
  Paper
} from '@material-ui/core'

// Components.
import Title from '../../../../UI/Brand/Title'
import FormField from '../../../../UI/Form/FormField'
import AlertSuccess from '../../../../UI/Alerts/AlertSuccess'
import AlertError from '../../../../UI/Alerts/AlertError'
import SaveButton from '../../../../UI/Buttons/SaveButton'
import UserImage from '../../../../UI/Image/UserImage'
import UserStats from './UserStats'
import GoBackButton from '../../../../UI/Buttons/GoBackButton'

// Redux actions.
import * as actions from '../../../../../store/actions'

// Shared.
import { validateEmail, userCan } from '../../../../../shared/functions'

// Styles.
import { useStyles } from '../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class UserForm extends Component {
  componentDidMount () {
    // Load the user.
    this.props.loadUser(this.props.id, this.props.token)
    // Load the profiles.
    this.props.loadProfiles(this.props.token)
    // Load the artists.
    this.props.loadArtists(this.props.token)
  }

  state = {
    fields: {
      email: {
        label: 'Email del usuario',
        error: null,
        type: 'text',
        required: true,
        touched: false,
        validation: validateEmail,
        errMsg: 'Por favor, escribe un email válido.',
        autoFocus: true
      },
      firstname: {
        label: 'Nombre del usuario',
        error: null,
        type: 'text',
        required: true,
        touched: false,
        validation: val => val.length >= 2,
        errMsg: 'Por favor, escribe un nombre de al menos dos caracteres.',
        autoFocus: false
      },
      profile_id: {
        label: 'Perfil',
        error: null,
        type: 'select',
        required: true,
        touched: false,
        errMsg: 'Por favor, selecciona un perfil de la lista.',
        autoFocus: false,
        options: []
      },
      artist_id: {
        label: 'Artista',
        helperText: 'El usuario solo podrá consultar datos del artista en cuestión.',
        error: null,
        type: 'select',
        required: false,
        touched: false,
        errMsg: 'Por favor, selecciona un artista de la lista.',
        autoFocus: false,
        options: []
      },
      active: {
        fullWidth: true,
        label: 'Usuario activo',
        helperText: 'Si desactivas al usuario, no podrá iniciar sesión hasta que vuelvas a activarlo.',
        error: null,
        type: 'checkbox',
        required: false,
        touched: false,
        errMsg: 'Por favor, proporciona un valor válido.',
        autoFocus: false
      },
      image: {
        fullWidth: true,
        label: 'Selecciona un archivo',
        error: null,
        type: 'file',
        value: '',
        accept: ['image/png', 'image/gif', 'image/jpeg'],
        required: false,
        touched: false,
        errMsg: 'Por favor, selecciona una imagen de tipo PNG, GIF o JPEG.',
        autoFocus: false
      },
      password: {
        label: 'Contraseña',
        error: null,
        type: 'password',
        required: true,
        touched: false,
        validation: val => val.length >= 2,
        errMsg: 'Por favor, escribe una contraseña de al menos dos caracteres.',
        autoFocus: false,
        fullWidth: true,
        helperText: 'Rellena este campo con la nueva contraseña del usuario sólo si deseas restablecerla.'
      }
    }
  }

  handleChange = (elemKey, newValue) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields[elemKey]
    }
    // Take care of the value change, if the field is a checkbox.
    if (this.state.fields[elemKey].type === 'checkbox') newValue = this.props.selectedUser ? !this.props.selectedUser[elemKey] : true
    // Modify the element value.
    this.props.updateSelectedUser(elemKey, newValue)
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.required && !(newValue + '').length && newElement.touched) {
      newElement.error = 'Campo obligatorio'
    } else if (newElement.validation && newValue.length && !newElement.validation(newValue)) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Special field options: profile ID.
    if (elemKey === 'profile_id') {
      if (newValue && this.profileRequiresArtist(newValue)) {
        newElement.helperText = 'Por favor, selecciona un artista de la lista.'
      } else {
        newElement.helperText = null
        this.props.updateSelectedUser('artist_id', '')
      }
    }
    // Update the state.
    this.setState({
      fields: {
        ...this.state.fields,
        [elemKey]: newElement
      }
    })
  }

  handleImageUpload = file => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields.image
    }
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.accept && newElement.accept.length && newElement.accept.indexOf(file.type) === -1) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Modify the element value.
    if (!newElement.error) {
      this.props.uploadUserImage(this.props.selectedUser.id, this.props.token, file)
    }
    // Update the state.
    this.setState({
      fields: {
        ...this.state.fields,
        image: newElement
      }
    })
  }

  shouldSaveButtonBeDisabled = () => {
    if (this.props.formLoading) return true
    if (!this.props.selectedUser) return true
    for (const fieldName in this.state.fields) {
      if (Object.prototype.hasOwnProperty.call(this.state.fields, fieldName)) {
        const field = this.state.fields[fieldName]
        if (field.required && !(this.props.selectedUser[fieldName] + '').length && field.touched) return true
        if (!this.props.id && field.required && !field.touched) return true
      }
    }
    return false
  }

  profileRequiresArtist = (profileId) => {
    if (!this.props.profiles || !profileId) return false
    const selectedProfile = this.props.profiles.find(p => +p.id === +profileId)
    if (!selectedProfile) return false
    return selectedProfile.is_artist
  }

  render () {
    let content = null

    // Handle loading state.
    if (this.props.loading || this.props.formLoading || this.props.artistsLoading) {
      // Show loader.
      content = <LinearProgress />
    } else {
      const currentUserCanUpdate = userCan(this.props.user, 'User', this.props.id ? 'update' : 'create')

      content = (
        <Grid container spacing={3}>
          <Grid item xs={12} md={3} lg={2}>
            <UserImage
              user={this.props.selectedUser}
              token={this.props.token}
              loading={this.props.imageLoading}
            />
            {
              currentUserCanUpdate
                ? (
                  <div style={{ marginTop: 15, textAlign: 'center' }}>
                    <FormField
                      fullWidth={this.state.fields.image.fullWidth}
                      width={this.state.fields.image.width}
                      key='image'
                      name='image'
                      type={this.state.fields.image.type}
                      autoFocus={this.state.fields.image.autoFocus}
                      required={this.state.fields.image.required}
                      error={this.state.fields.image.error}
                      label={this.state.fields.image.label}
                      value={this.props.selectedUser && this.props.selectedUser.image !== null && typeof this.props.selectedUser.image !== 'undefined' ? this.props.selectedUser.image : ''}
                      handleChange={e => this.handleImageUpload(e.target.files[0])}
                      options={this.state.fields.image.options}
                      startAdornment={this.state.fields.image.startAdornment}
                      helperText={this.state.fields.image.helperText}
                      readOnly={!currentUserCanUpdate}
                      accept={this.state.fields.image.accept}
                      disabled={this.props.imageLoading || !this.props.selectedUser || !this.props.selectedUser.id}
                    />
                  </div>
                  )
                : null
            }
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            <form className={this.props.classes.rootWrap} noValidate autoComplete='off'>
              {
                _.keys(this.state.fields).filter(k => k !== 'image').map(fieldName => {
                  const field = this.state.fields[fieldName]

                  // Get value.
                  const value = this.props.selectedUser && this.props.selectedUser[fieldName] !== null && typeof this.props.selectedUser[fieldName] !== 'undefined' ? this.props.selectedUser[fieldName] : ''
                  let disabled = field.disabled

                  // Special field options.
                  if (fieldName === 'profile_id') {
                    field.options = [
                      { label: '-', value: '' },
                      ...this.props.profiles.map(s => {
                        return { value: s.id, label: `${s.name} (${s.is_artist ? 'artista' : 'todo'})` }
                      })
                    ]
                  } else if (fieldName === 'artist_id') {
                    if (!this.props.selectedUser || !this.profileRequiresArtist(this.props.selectedUser.profile_id)) disabled = true
                    field.options = [
                      { label: '-', value: '' },
                      ...this.props.artists.map(a => {
                        return { value: a.id, label: a.name }
                      })
                    ]
                  } else if (fieldName === 'password' && this.props.id) {
                    field.label = 'Contraseña (solo si quieres restablecerla)'
                    field.required = false
                  }

                  if (!currentUserCanUpdate && fieldName === 'password') {
                    return null
                  }

                  return (
                    <FormField
                      fullWidth={field.fullWidth}
                      width={field.width}
                      key={fieldName}
                      name={fieldName}
                      type={field.type}
                      autoFocus={field.autoFocus}
                      required={field.required}
                      error={field.error}
                      label={field.label}
                      disabled={disabled}
                      value={value}
                      handleChange={e => this.handleChange(fieldName, e.target.value)}
                      options={field.options}
                      startAdornment={field.startAdornment}
                      helperText={field.helperText}
                      readOnly={!currentUserCanUpdate}
                      accept={field.accept}
                    />
                  )
                })
              }
            </form>
          </Grid>
        </Grid>
      )
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={this.props.classes.paper}>
            <div className='UserForm'>
              <Title>Propiedades del usuario</Title>
              <AlertError error={this.props.error} />
              <AlertSuccess message={this.props.success} />
              {content}
              <div className={this.props.classes.mt2}>
                <GoBackButton link='/users' />
                <SaveButton
                  model='User'
                  action={this.props.id ? 'update' : 'create'}
                  disabled={this.shouldSaveButtonBeDisabled()}
                  onClick={() => this.props.saveUser(this.props.id, this.props.token, this.props.selectedUser)}
                />
              </div>
            </div>
          </Paper>
        </Grid>
        <UserStats
          loading={this.props.loading || this.props.formLoading || this.props.artistsLoading}
          stats={this.props.selectedUser ? this.props.selectedUser.stats : null}
        />
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    user: state.auth.user,
    token: state.auth.token,
    loading: state.users.loading,
    formLoading: state.users.formLoading,
    error: state.users.error,
    users: state.users.users,
    profiles: state.users.profiles,
    artists: state.artists.artists,
    artistsLoading: state.artists.loading,
    success: state.users.success,
    selectedUser: state.users.selectedUser,
    imageLoading: state.users.imageLoading
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadUser: (id, token) => dispatch(actions.loadUser(id, token)),
    saveUser: (id, token, body) => dispatch(actions.saveUser(id, token, body)),
    loadProfiles: (token) => dispatch(actions.loadProfiles(token)),
    loadArtists: (token) => dispatch(actions.loadArtists(token)),
    updateSelectedUser: (key, value) => dispatch(actions.updateSelectedUser(key, value)),
    uploadUserImage: (it, token, file) => dispatch(actions.uploadUserImage(it, token, file))
  }
}

// Style component.
const UserFormStyled = props => {
  const classes = useStyles()
  return (
    <UserForm classes={classes} {...props} />
  )
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(UserFormStyled)
