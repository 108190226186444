// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  Grid,
  Paper
} from '@material-ui/core'
import { connect } from 'react-redux'
import Pagination from '@material-ui/lab/Pagination'

// Components.
import ProductScheduledDiscountsTable from './ProductScheduledDiscountsTable'
import Title from '../../../../../UI/Brand/Title'
import AlertSuccess from '../../../../../UI/Alerts/AlertSuccess'
import AlertError from '../../../../../UI/Alerts/AlertError'
import AlertInfo from '../../../../../UI/Alerts/AlertInfo'
import TableFilters from '../../../../../UI/Table/TableFilters'
import CreateButton from '../../../../../UI/Buttons/CreateButton'
import RefreshButton from '../../../../../UI/Buttons/RefreshButton'

// Shared.
import {
  applyFilter,
  calculateNumPages,
  getOptionsArray, prettyPrintDateTime,
  removeDuplicates
} from '../../../../../../shared/functions'
import { CRON_MESSAGES, DISCOUNT_TYPES, SCHEDULED_ACTION_STATUSES } from '../../../../../../shared/constants'

// Redux actions.
import * as actions from '../../../../../../store/actions'

// Styles.
import { useStyles } from '../../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class ProductScheduledDiscountList extends Component {
  state = {
    page: 1,
    orderBy: 'id',
    orderDirection: 'asc',
    filter: {
      productId: {
        touched: false,
        operand: 'equals',
        value: '',
        key: 'product_id',
        label: 'Producto',
        type: 'autocomplete',
        options: []
      },
      siteId: {
        touched: false,
        operand: 'equals',
        value: '',
        key: 'site_id',
        label: 'Sitio web',
        type: 'autocomplete',
        options: []
      },
      type: {
        touched: false,
        operand: 'equals',
        value: '',
        key: 'type',
        label: 'Tipo',
        type: 'select',
        options: getOptionsArray(DISCOUNT_TYPES)
      },
      status: {
        touched: false,
        operand: 'equals',
        value: '',
        key: 'status',
        label: 'Estado',
        type: 'select',
        options: getOptionsArray(SCHEDULED_ACTION_STATUSES)
      },
      dateFrom: {
        touched: false,
        operand: 'gte',
        value: null,
        key: 'date_from',
        label: 'Desde (UTC)',
        type: 'datetime'
      },
      dateTo: {
        touched: false,
        operand: 'lte',
        value: null,
        key: 'date_to',
        label: 'Hasta (UTC)',
        type: 'datetime'
      }
    }
  }

  handlePageChange = (e, value) => {
    this.setState({ page: +value })
  }

  onFilterChange = (elemKey, value, forceUntouched = false) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.filter[elemKey]
    }
    // Modify the filter value.
    newElement.value = value
    // Set as touched.
    newElement.touched = !forceUntouched
    // Update the state.
    this.setState({
      page: 1,
      filter: {
        ...this.state.filter,
        [elemKey]: newElement
      }
    })
  }

  handleSort = (fieldName) => event => {
    // Create a copy of the state object.
    const newState = { ...this.state }
    // Set the order field.
    newState.orderBy = fieldName
    // Toggle the order direction.
    newState.orderDirection = this.state.orderBy === fieldName ? (newState.orderDirection === 'asc' ? 'desc' : 'asc') : 'asc'
    // Update the state.
    this.setState(newState)
  }

  componentDidMount () {
    this.props.loadProductScheduledDiscounts(this.props.token)
  }

  render () {
    // Load content dynamically.
    let content = null

    if (this.props.productScheduledDiscounts) {
      // Filter results.
      const productScheduledDiscounts = applyFilter(this.props.productScheduledDiscounts, this.state.filter, this.state.orderBy, this.state.orderDirection)

      // Build the filter select options.
      const productFilterOptions = [
        { label: 'Todos', value: '' },
        ...this.props.productScheduledDiscounts.filter(d => !!d.product).map(d => {
          return { value: d.product.id, label: `${d.product.name} - ${d.product.category.site.url}` }
        })
      ]
      const siteFilterOptions = [
        { label: 'Todos', value: '' },
        ...this.props.productScheduledDiscounts.filter(d => !!d.product).map(d => {
          return { value: d.product.category.site.id, label: d.product.category.site.url }
        })
      ]

      content = (
        <>
          <AlertSuccess message={this.props.success} />
          <AlertError error={this.props.error} />
          {
            this.props.currentlyExecutingCron
              ? <AlertInfo title='El cron se está ejecutando'>La próxima ejecución del cron tendrá lugar cuando acabe la ejecución actual, a la hora correspondiente. {CRON_MESSAGES.productScheduledDiscounts}.</AlertInfo>
              : this.props.cronNextExecutionTime
                ? <AlertInfo title={`Próxima ejecución del cron: ${prettyPrintDateTime(this.props.cronNextExecutionTime)}`}>{CRON_MESSAGES.productScheduledDiscounts}.</AlertInfo>
                : null
          }
          <TableFilters
            filters={this.state.filter}
            deferredData={{ productId: removeDuplicates(productFilterOptions), siteId: removeDuplicates(siteFilterOptions) }}
            handleChange={this.onFilterChange}
          />
          <ProductScheduledDiscountsTable
            productScheduledDiscounts={productScheduledDiscounts}
            page={this.state.page}
            loading={this.props.loading}
            deleteProductScheduledDiscount={id => this.props.deleteProductScheduledDiscount(id, this.props.token)}
            saveProductScheduledDiscount={(id, body) => this.props.saveProductScheduledDiscount(id, this.props.token, body)}
            activateProductScheduledDiscount={id => this.props.activateProductScheduledDiscount(id, this.props.token)}
            expireProductScheduledDiscount={id => this.props.expireProductScheduledDiscount(id, this.props.token)}
            orderBy={this.state.orderBy}
            orderDirection={this.state.orderDirection}
            onSort={this.handleSort}
          />
          <Grid container spacing={3} className={this.props.classes.tableTools}>
            <Grid item xs={4}>
              <Pagination
                count={calculateNumPages(productScheduledDiscounts)}
                page={this.state.page}
                onChange={this.handlePageChange}
              />
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'center' }}>
              {productScheduledDiscounts.length} resultados
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <RefreshButton
                model='ProductScheduledDiscount'
                onClick={() => this.props.loadProductScheduledDiscounts(this.props.token)}
              />
              <CreateButton
                model='ProductScheduledDiscount'
                link='/product_scheduled_discount/create'
              />
            </Grid>
          </Grid>
        </>
      )
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={this.props.classes.paper}>
            <div className='ProductScheduledDiscountList'>
              <Title>Descuentos programados</Title>
              {content}
            </div>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    loading: state.productScheduledDiscounts.loading,
    error: state.productScheduledDiscounts.error,
    productScheduledDiscounts: state.productScheduledDiscounts.productScheduledDiscounts,
    cronNextExecutionTime: state.productScheduledDiscounts.cronNextExecutionTime,
    currentlyExecutingCron: state.productScheduledDiscounts.currentlyExecutingCron,
    success: state.productScheduledDiscounts.success,
    selectedProductScheduledDiscount: state.productScheduledDiscounts.selectedProductScheduledDiscount
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadProductScheduledDiscounts: token => dispatch(actions.loadProductScheduledDiscounts(token)),
    deleteProductScheduledDiscount: (id, token) => dispatch(actions.deleteProductScheduledDiscount(id, token)),
    activateProductScheduledDiscount: (id, token) => dispatch(actions.activateProductScheduledDiscount(id, token)),
    expireProductScheduledDiscount: (id, token) => dispatch(actions.expireProductScheduledDiscount(id, token)),
    saveProductScheduledDiscount: (id, token, body) => dispatch(actions.saveProductScheduledDiscount(id, token, body))
  }
}

// Style component.
const ProductScheduledDiscountListStyled = props => {
  const classes = useStyles()
  return (
    <ProductScheduledDiscountList classes={classes} {...props} />
  )
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(ProductScheduledDiscountListStyled)
