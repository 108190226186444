// ============================================================================
// Dependencies.
// ============================================================================

// Axios instance.
import axios from '../../axios'

// Action types.
import * as actions from './types'

// Functions.
import { getErrorMessage } from '../../shared/functions'

// ============================================================================
// Action creators.
// ============================================================================

// Load expense types start.
export const loadExpenseTypesStart = () => {
  return {
    type: actions.LOAD_EXPENSE_TYPES_START
  }
}

// Load expense types success.
export const loadExpenseTypesSuccess = expenseTypes => {
  return {
    type: actions.LOAD_EXPENSE_TYPES_SUCCESS,
    expenseTypes
  }
}

// Load expense types fail.
export const loadExpenseTypesFailed = error => {
  return {
    type: actions.LOAD_EXPENSE_TYPES_FAILED,
    error
  }
}

// Load expense types.
export const loadExpenseTypes = token => {
  return dispatch => {
    dispatch(loadExpenseTypesStart())
    // Perform request.
    axios(token).get('/expense_types')
      .then(response => {
        dispatch(loadExpenseTypesSuccess(response.data.expense_types))
      })
      .catch(error => {
        dispatch(loadExpenseTypesFailed(getErrorMessage(error)))
      })
  }
}

// Delete expense types start.
export const deleteExpenseTypeStart = () => {
  return {
    type: actions.DELETE_EXPENSE_TYPE_START
  }
}

// Delete expense types success.
export const deleteExpenseTypeSuccess = id => {
  return {
    type: actions.DELETE_EXPENSE_TYPE_SUCCESS,
    id
  }
}

// Delete expense types fail.
export const deleteExpenseTypeFailed = error => {
  return {
    type: actions.DELETE_EXPENSE_TYPE_FAILED,
    error
  }
}

// Delete expense types.
export const deleteExpenseType = (id, token) => {
  return dispatch => {
    dispatch(deleteExpenseTypeStart())
    // Perform request.
    axios(token).delete(`/expense_type/${id}`)
      .then(() => {
        dispatch(deleteExpenseTypeSuccess(id))
      })
      .catch(error => {
        dispatch(deleteExpenseTypeFailed(getErrorMessage(error)))
      })
  }
}

// Update the loaded expense type.
export const updateSelectedExpenseType = (key, value) => {
  return {
    type: actions.UPDATE_SELECTED_EXPENSE_TYPE,
    key,
    value
  }
}

// Start saving a expense type.
export const saveExpenseTypeStart = expenseType => {
  return {
    type: actions.SAVE_EXPENSE_TYPE_START,
    expenseType
  }
}

// Save expense type success.
export const saveExpenseTypeSuccess = (id, expenseType) => {
  return {
    type: actions.SAVE_EXPENSE_TYPE_SUCCESS,
    id,
    expenseType
  }
}

// Save expense type fail.
export const saveExpenseTypeFailed = (id, error) => {
  return {
    type: actions.SAVE_EXPENSE_TYPE_FAILED,
    id,
    error
  }
}

// Save an expense type.
export const saveExpenseType = (id, token, body = {}) => {
  return dispatch => {
    dispatch(saveExpenseTypeStart())
    // Perform request.
    body = body || {}
    const url = id ? `/expense_type/${id}` : '/expense_type'
    const method = id ? 'put' : 'post'
    const postData = {
      name: body.name
    }
    axios(token)[method](url, postData)
      .then(response => {
        dispatch(saveExpenseTypeSuccess(id, response.data))
      })
      .catch(error => {
        dispatch(saveExpenseTypeFailed(id, getErrorMessage(error)))
      })
  }
}
