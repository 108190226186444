// ============================================================================
// Dependencies.
// ============================================================================

// Axios instance.
import axios from '../../axios'

// Action types.
import * as actions from './types'

// Functions.
import { getErrorMessage } from '../../shared/functions'

// ============================================================================
// Action creators.
// ============================================================================

// Load stock movements start.
export const loadStockMovementsStart = () => {
  return {
    type: actions.LOAD_STOCK_MOVEMENTS_START
  }
}

// Load stock movements success.
export const loadStockMovementsSuccess = stockMovements => {
  return {
    type: actions.LOAD_STOCK_MOVEMENTS_SUCCESS,
    stockMovements
  }
}

// Load stock movements fail.
export const loadStockMovementsFailed = error => {
  return {
    type: actions.LOAD_STOCK_MOVEMENTS_FAILED,
    error
  }
}

// Load stock movements.
export const loadStockMovements = (token, type) => {
  return dispatch => {
    dispatch(loadStockMovementsStart())
    // Perform request.
    axios(token).get(`/stock_movements/${type}`)
      .then(response => {
        dispatch(loadStockMovementsSuccess(response.data.stock_movements))
      })
      .catch(error => {
        dispatch(loadStockMovementsFailed(getErrorMessage(error)))
      })
  }
}

// Delete stock movements start.
export const deleteStockMovementStart = () => {
  return {
    type: actions.DELETE_STOCK_MOVEMENT_START
  }
}

// Delete stock movements success.
export const deleteStockMovementSuccess = id => {
  return {
    type: actions.DELETE_STOCK_MOVEMENT_SUCCESS,
    id
  }
}

// Delete stock movements fail.
export const deleteStockMovementFailed = error => {
  return {
    type: actions.DELETE_STOCK_MOVEMENT_FAILED,
    error
  }
}

// Delete stock movements.
export const deleteStockMovement = (id, token) => {
  return dispatch => {
    dispatch(deleteStockMovementStart())
    // Perform request.
    axios(token).delete(`/stock_movement/${id}`)
      .then(() => {
        dispatch(deleteStockMovementSuccess(id))
      })
      .catch(error => {
        dispatch(deleteStockMovementFailed(getErrorMessage(error)))
      })
  }
}

// Update the loaded expense type.
export const updateSelectedStockMovement = (key, value) => {
  return {
    type: actions.UPDATE_SELECTED_STOCK_MOVEMENT,
    key,
    value
  }
}

// Start saving a expense type.
export const createStockMovementStart = () => {
  return {
    type: actions.SAVE_STOCK_MOVEMENT_START
  }
}

// Save expense type success.
export const createStockMovementSuccess = stockMovement => {
  return {
    type: actions.SAVE_STOCK_MOVEMENT_SUCCESS,
    stockMovement
  }
}

// Save expense type fail.
export const createStockMovementFailed = error => {
  return {
    type: actions.SAVE_STOCK_MOVEMENT_FAILED,
    error
  }
}

// Save a stock movement.
export const createStockMovement = (token, body = {}) => {
  return dispatch => {
    dispatch(createStockMovementStart())
    // Perform request.
    body = body || {}
    const postData = {
      artist_id: +body.artist_id,
      pos_terminal_id: +body.pos_terminal_id,
      type: body.type,
      products: []
    }
    if (body.location) postData.location = body.location
    if (body.total_earnings) postData.total_earnings = +body.total_earnings
    if (body.total_expenses) postData.total_expenses = +body.total_expenses
    for (let i = 0; i < body.products.length; i++) {
      postData.products.push({
        product_id: +body.products[i].product_id,
        product_variation_id: body.products[i].product_variation_id ? +body.products[i].product_variation_id : null,
        price_new: body.products[i].price_new ? +body.products[i].price_new : null,
        stock_quantity: +body.products[i].stock_quantity
      })
    }
    axios(token).post('/stock_movement', postData)
      .then(response => {
        dispatch(createStockMovementSuccess(response.data))
      })
      .catch(error => {
        dispatch(createStockMovementFailed(getErrorMessage(error)))
      })
  }
}

// Load stock movement start.
export const loadStockMovementStart = () => {
  return {
    type: actions.LOAD_STOCK_MOVEMENT_START
  }
}

// Load stock movement success.
export const loadStockMovementSuccess = stockMovement => {
  return {
    type: actions.LOAD_STOCK_MOVEMENT_SUCCESS,
    stockMovement
  }
}

// Load stock movement fail.
export const loadStockMovementFailed = error => {
  return {
    type: actions.LOAD_STOCK_MOVEMENT_FAILED,
    error
  }
}

// Load stock movement.
export const loadStockMovement = (id, token) => {
  return dispatch => {
    if (!id) {
      dispatch(loadStockMovementSuccess(null))
      return
    }
    dispatch(loadStockMovementStart())
    // Perform request.
    axios(token).get(`/stock_movement/${id}`)
      .then(response => {
        dispatch(loadStockMovementSuccess(response.data))
      })
      .catch(error => {
        dispatch(loadStockMovementFailed(getErrorMessage(error)))
      })
  }
}

// Add a data source.
export const addStockMovementDataSource = (artistId, categoryId) => {
  return {
    type: actions.ADD_STOCK_MOVEMENT_DATA_SOURCE,
    artistId,
    categoryId
  }
}

// Delete a data source.
export const deleteStockMovementDataSource = (artistId, categoryId) => {
  return {
    type: actions.DELETE_STOCK_MOVEMENT_DATA_SOURCE,
    artistId,
    categoryId
  }
}

// Add a product to the selected stock movement.
export const addStockMovementProducts = (products, siteId) => {
  return {
    type: actions.ADD_STOCK_MOVEMENT_PRODUCTS,
    products,
    siteId
  }
}

// Delete a product from the selected stock movement.
export const deleteStockMovementProducts = productIds => {
  return {
    type: actions.DELETE_STOCK_MOVEMENT_PRODUCTS,
    productIds
  }
}

// Update a product from the selected stock movement.
export const updateSelectedStockMovementProduct = (idx, key, value) => {
  return {
    type: actions.UPDATE_SELECTED_STOCK_MOVEMENT_PRODUCT,
    idx,
    key,
    value
  }
}

// Update a data source.
export const updateSelectedStockMovementDataSource = (idx, artistId, categoryId) => {
  return {
    type: actions.UPDATE_SELECTED_STOCK_MOVEMENT_DATA_SOURCE,
    idx,
    artistId,
    categoryId
  }
}

// Successfully create a stock entry from a stock withdrawal.
export const createStockEntryFromWithdrawalSuccess = stockMovement => {
  return {
    type: actions.CREATE_STOCK_ENTRY_FROM_WITHDRAWAL_SUCCESS,
    stockMovement
  }
}

// Create a stock entry from a stock withdrawal.
export const createStockEntryFromWithdrawal = (id, token) => {
  return dispatch => {
    if (!id) {
      dispatch(loadStockMovementSuccess(null))
      return
    }
    dispatch(loadStockMovementStart())
    // Perform request.
    axios(token).get(`/stock_movement/${id}`)
      .then(response => {
        dispatch(createStockEntryFromWithdrawalSuccess(response.data))
      })
      .catch(error => {
        dispatch(loadStockMovementFailed(getErrorMessage(error)))
      })
  }
}
