// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  TableCell,
  TableRow,
  LinearProgress,
  IconButton
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'

// Components.
import FormField from '../../../../../../UI/Form/FormField'
import RowActions from '../../../../../../UI/Table/RowActions'
import SaveButton from '../../../../../../UI/Buttons/SaveButton'
import ArrangementLink from '../../../../../../UI/Links/ArrangementLink'
import AttachmentDownloadLink from '../../../../../../UI/Links/AttachmentDownloadLink'

// Shared.
import { prettyPrintDateTime } from '../../../../../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class ArrangementDocumentsTableRow extends Component {
  state = {
    editing: false,
    fields: {
      arrangement_id: {
        label: 'Trato comercial',
        error: null,
        value: '',
        type: 'select',
        required: true,
        touched: false,
        errMsg: 'Por favor, selecciona un trato comercial.',
        autoFocus: false,
        options: []
      },
      name: {
        label: 'Nombre',
        value: '',
        error: null,
        type: 'text',
        required: true,
        touched: false,
        errMsg: 'Por favor, proporciona un nombre válido.',
        autoFocus: true
      },
      filename: {
        label: 'Seleccionar',
        error: null,
        type: 'file',
        value: '',
        accept: [
          'application/pdf' /* .pdf */,
          'application/vnd.ms-excel' /* .xls */,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' /* .xlsx */,
          'application/msword' /* .doc */,
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document' /* .docx */,
          'application/vnd.oasis.opendocument.text' /* .odt */,
          'application/vnd.oasis.opendocument.spreadsheet' /* .ods */
        ],
        required: true,
        touched: false,
        validation: val => +val > 0,
        errMsg: 'Selecciona un archivo.',
        autoFocus: false
      }
    }
  }

  handleChange = (elemKey, newValue) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields[elemKey]
    }
    // Modify the element value.
    newElement.value = newValue
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.required && !newValue.length && newElement.touched) {
      newElement.error = 'Campo obligatorio'
    } else if (newElement.validation && newValue.length && !newElement.validation(newValue)) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Build new state.
    const newState = {
      editing: true,
      fields: {
        ...this.state.fields,
        [elemKey]: newElement
      }
    }
    // Update the state.
    this.setState(newState)
  }

  onInlineUpdateStart = () => {
    // Build new state.
    const newState = {
      editing: true,
      fields: {
        ...this.state.fields
      }
    }
    // Update value of the fields.
    newState.fields.name.value = this.props.arrangementDocument.name
    newState.fields.filename.value = this.props.arrangementDocument.filename
    newState.fields.arrangement_id.value = this.props.arrangementDocument.file
    // Update the state.
    this.setState(newState)
  }

  handleStopInlineUpdate = () => {
    this.setState({ editing: false })
  }

  shouldSaveButtonBeDisabled = () => {
    return this.props.loading ||
      !this.state.fields.name.value ||
      this.state.fields.name.value === '' ||
      !this.state.fields.filename.value ||
      this.state.fields.filename.value === '' ||
      !this.state.fields.arrangement_id.value ||
      this.state.fields.arrangement_id.value === ''
  }

  handleSaveArrangementDocument = () => {
    // Save the arrangement document.
    this.props.handleSaveArrangementDocument({
      arrangement_id: this.state.fields.arrangement_id.value,
      name: this.state.fields.name.value,
      file: this.state.fields.filename.value
    })
    // Stop inline update.
    this.handleStopInlineUpdate()
  }

  render () {
    if (this.props.loading) {
      return <LinearProgress />
    }

    let arrangementOptions = []
    if (this.props.arrangements) {
      arrangementOptions = [
        { label: '-', value: '' },
        ...this.props.arrangements.map(a => {
          return { value: a.id, label: a.name }
        })
      ]
    }

    return (
      <TableRow key={this.props.id}>
        <TableCell>
          {
            this.state.editing
              ? (
                <IconButton color='default' aria-label='undo' size='small' onClick={this.handleStopInlineUpdate}>
                  <ClearIcon />
                </IconButton>
                )
              : this.props.id
          }
        </TableCell>
        <TableCell>
          {
            this.state.editing
              ? (
                <FormField
                  noMargin
                  fullWidth
                  name='name'
                  type={this.state.fields.name.type}
                  autoFocus={this.state.fields.name.autofocus}
                  required={this.state.fields.name.required}
                  label={this.state.fields.name.label}
                  value={this.state.fields.name.value}
                  handleChange={e => this.handleChange('name', e.target.value)}
                />
                )
              : this.props.arrangementDocument.name
          }
        </TableCell>
        <TableCell>
          {
            this.state.editing
              ? (
                <FormField
                  noMargin
                  fullWidth
                  name='arrangement_id'
                  type={this.state.fields.arrangement_id.type}
                  autoFocus={this.state.fields.arrangement_id.autofocus}
                  required={this.state.fields.arrangement_id.required}
                  label={this.state.fields.arrangement_id.label}
                  value={this.state.fields.arrangement_id.value}
                  options={arrangementOptions}
                  handleChange={e => this.handleChange('arrangement_id', e.target.value)}
                />
                )
              : <ArrangementLink artistId={this.props.artistId} arrangement={this.props.arrangementDocument.arrangement} />
          }
        </TableCell>
        <TableCell>
          {
            this.state.editing
              ? (
                <FormField
                  noMargin
                  fullWidth
                  name='filename'
                  type={this.state.fields.filename.type}
                  autoFocus={this.state.fields.filename.autofocus}
                  required={this.state.fields.filename.required}
                  label={this.state.fields.filename.label}
                  value={this.state.fields.filename.value}
                  accept={this.state.fields.filename.accept}
                  handleChange={e => this.handleChange('filename', e.target.files[0])}
                />
                )
              : (
                <AttachmentDownloadLink
                  artistId={this.props.artistId}
                  label={this.props.arrangementDocument.filename}
                  downloadLink={this.props.arrangementDocument.download_link}
                />
                )
          }
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(this.props.arrangementDocument.createdAt)}
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(this.props.arrangementDocument.updatedAt)}
        </TableCell>
        <TableCell>
          {
            this.state.editing
              ? (
                <SaveButton
                  model='ArrangementDocument'
                  action='update'
                  disabled={this.shouldSaveButtonBeDisabled()}
                  onClick={this.handleSaveArrangementDocument}
                />
                )
              : (
                <RowActions
                  model='ArrangementDocument'
                  id={this.props.id}
                  handleDelete={() => this.props.onDeleteArrangementDocument()}
                  handleInlineUpdate={this.onInlineUpdateStart}
                />
                )
          }
        </TableCell>
      </TableRow>
    )
  }
}

// ============================================================================
// Export.
// ============================================================================

// Export.
export default ArrangementDocumentsTableRow
