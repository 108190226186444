// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import * as _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  LinearProgress,
  Paper
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'

// Components.
import Title from '../../../../UI/Brand/Title'
import FormField from '../../../../UI/Form/FormField'
import AlertSuccess from '../../../../UI/Alerts/AlertSuccess'
import AlertError from '../../../../UI/Alerts/AlertError'
import SiteCategories from './SiteCategories'
import SyncButton from '../../../../UI/Buttons/SyncButton'
import SaveButton from '../../../../UI/Buttons/SaveButton'
import GoBackButton from '../../../../UI/Buttons/GoBackButton'
import TableFilters from '../../../../UI/Table/TableFilters'
import SiteApiKeyButton from './SiteApiKeyButton'

// Shared.
import {
  validateURL,
  validateShopType,
  validateSyncType,
  getOptionsArray,
  calculateNumPages,
  applyFilter,
  userCan
} from '../../../../../shared/functions'
import { SITE_SYNC_TYPES, SITE_TYPES } from '../../../../../shared/constants'

// Redux actions.
import * as actions from '../../../../../store/actions/index'

// Styles.
import { useStyles } from '../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class SiteForm extends Component {
  componentDidMount () {
    // Load the current site.
    this.props.loadSite(this.props.siteId, this.props.token)
    // Load the sites list.
    if (!this.props.sites || !this.props.sites.length) {
      this.props.loadSites(this.props.token)
    }
  }

  validateSiteId = id => {
    return this.props.sites.find(s => +s.id === +id)
  }

  state = {
    fields: {
      url: {
        label: 'URL del sitio web',
        error: null,
        type: 'text',
        required: true,
        touched: false,
        validation: validateURL,
        errMsg: 'Por favor, escribe una URL válida. Por ejemplo: https://misitio.com.',
        autoFocus: true
      },
      type: {
        label: 'Tipo',
        error: null,
        type: 'select',
        required: true,
        touched: false,
        validation: validateShopType,
        errMsg: 'Por favor, selecciona un tipo de sitio web de la lista.',
        autoFocus: false,
        options: getOptionsArray(SITE_TYPES)
      },
      synced_to: {
        label: 'Sincronizado con',
        error: null,
        type: 'select',
        required: false,
        touched: false,
        validation: this.validateSiteId,
        errMsg: 'Por favor, selecciona un sitio de la lista.',
        autofocus: false,
        options: []
      },
      sync_type: {
        label: 'Tipo de sincronización',
        error: null,
        type: 'select',
        required: false,
        touched: false,
        validation: validateSyncType,
        errMsg: 'Por favor, selecciona un tipo de sincronización de la lista',
        autofocus: false,
        options: getOptionsArray(SITE_SYNC_TYPES)
      },
      client_key: {
        label: 'Client key',
        error: null,
        type: 'text',
        required: true,
        helperText: 'Clave para conectar con la API REST (WooCommerce) o el webservice (PrestaShop) del sitio web.',
        touched: false,
        validation: val => val.length >= 3,
        errMsg: 'Por favor, introduce una clave de API válida',
        autofocus: false
      },
      client_secret: {
        label: 'Client secret',
        error: null,
        type: 'text',
        required: false,
        helperText: 'Clave secreta para conectar con la API REST del sitio web WooCommerce. En PrestaShop no se usa.',
        touched: false,
        validation: val => val.length >= 3,
        errMsg: 'Por favor, introduce una contraseña de API válida',
        autofocus: false
      }
    },
    page: 1,
    orderBy: 'id',
    orderDirection: 'asc',
    filter: {
      name: {
        touched: false,
        operand: 'match',
        value: null,
        key: 'name',
        label: 'Nombre',
        type: 'text'
      }
    }
  }

  handleSort = (fieldName) => event => {
    // Create a copy of the state object.
    const newState = { ...this.state }
    // Set the order field.
    newState.orderBy = fieldName
    // Toggle the order direction.
    newState.orderDirection = this.state.orderBy === fieldName ? (newState.orderDirection === 'asc' ? 'desc' : 'asc') : 'asc'
    // Update the state.
    this.setState(newState)
  }

  onFilterChange = (elemKey, value, forceUntouched = false) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.filter[elemKey]
    }
    // Modify the filter value.
    newElement.value = value
    // Set as touched.
    newElement.touched = !forceUntouched
    // Update the state.
    this.setState({
      page: 1,
      filter: {
        ...this.state.filter,
        [elemKey]: newElement
      }
    })
  }

  handleCategoriesPageChange = (e, value) => {
    this.setState({ page: +value })
  }

  handleChange = (elemKey, newValue) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields[elemKey]
    }
    // Modify the element value.
    this.props.updateSelectedSite(elemKey, newValue)
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.required && !newValue.length && newElement.touched) {
      newElement.error = 'Campo obligatorio'
    } else if (newElement.validation && newValue.length && !newElement.validation(newValue)) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Update the state.
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [elemKey]: newElement
      }
    })
  }

  shouldSaveButtonBeDisabled = () => {
    if (this.props.formLoading) return true
    for (const fieldName in this.state.fields) {
      if (Object.prototype.hasOwnProperty.call(this.state.fields, fieldName)) {
        const field = this.state.fields[fieldName]
        if (field.required && (!this.props.selectedSite || !this.props.selectedSite[fieldName])) return true
        if (field.error) return true
      }
    }
    return false
  }

  render () {
    let content = null
    let categories = []

    // Handle loading state.
    if (this.props.loading || this.props.formLoading) {
      // Show loader.
      content = <LinearProgress />
    } else {
      // Filter results.
      categories = this.props.selectedSite ? applyFilter(this.props.selectedSite.categories, this.state.filter, this.state.orderBy, this.state.orderDirection) : null

      let apiKey = null
      if (this.props.selectedSite) {
        apiKey = (
          <SiteApiKeyButton
            apiKey={this.props.selectedSite.api_key}
            handleGenerateSiteApiKey={() => this.props.generateSiteApiKey(this.props.selectedSite.id, this.props.token)}
          />
        )
      }

      content = (
        <form className={this.props.classes.rootWrap} noValidate autoComplete='off'>
          {
            _.keys(this.state.fields).map(fieldName => {
              const field = this.state.fields[fieldName]

              // Get value.
              const value = this.props.selectedSite && this.props.selectedSite[fieldName] ? this.props.selectedSite[fieldName] : ''

              // Special field options.
              if (fieldName === 'synced_to') {
                field.options = [
                  { label: '-', value: '' },
                  ...this.props.sites.map(s => {
                    return { value: s.id, label: s.url }
                  })
                ]
              }

              return (
                <FormField
                  key={fieldName}
                  name={fieldName}
                  type={field.type}
                  autoFocus={field.autoFocus}
                  required={field.required}
                  error={field.error}
                  label={field.label}
                  helperText={field.helperText}
                  value={value}
                  handleChange={e => this.handleChange(fieldName, e.target.value)}
                  options={field.options}
                  readOnly={!userCan(this.props.user, 'Site', this.props.selectedSite && this.props.selectedSite.id ? 'update' : 'create')}
                />
              )
            })
          }
          {apiKey}
        </form>
      )
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={this.props.classes.paper}>
            <div className='Site'>
              <div className='SiteForm'>
                <Title>{this.props.selectedSite && this.props.siteId ? 'Propiedades del sitio web' : 'Nuevo sitio web'}</Title>
                <AlertError error={this.props.error} />
                <AlertSuccess message={this.props.success} />
                {content}
                <div className={this.props.classes.mt2}>
                  <GoBackButton link='/sites' />
                  <SaveButton
                    model='Site'
                    action={this.props.siteId ? 'update' : 'create'}
                    disabled={this.shouldSaveButtonBeDisabled()}
                    onClick={() => this.props.saveSite(this.props.siteId, this.props.token, this.props.selectedSite)}
                  />
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
        {
          this.props.selectedSite && this.props.selectedSite.id
            ? (
              <>
                <Grid item xs={12}>
                  <Paper className={this.props.classes.paper}>
                    <div className='SiteCategories'>
                      <Title>Categorías</Title>
                      <TableFilters
                        filters={this.state.filter}
                        handleChange={this.onFilterChange}
                      />
                      <AlertError error={this.props.categorySyncError} />
                      <AlertSuccess message={this.props.categorySyncSuccess} />
                      <SiteCategories
                        categories={categories}
                        page={this.state.page}
                        loading={this.props.categorySyncLoading}
                        orderBy={this.state.orderBy}
                        orderDirection={this.state.orderDirection}
                        onSort={this.handleSort}
                      />
                      <Grid container spacing={3} className={this.props.classes.tableTools}>
                        <Grid item xs={4}>
                          {
                            this.props.selectedSite.categories && this.props.selectedSite.categories.length
                              ? <Pagination
                                  count={calculateNumPages(categories)}
                                  page={this.state.page}
                                  onChange={this.handleCategoriesPageChange}
                                />
                              : null
                          }
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: 'center' }}>
                          {categories ? categories.length : 0} resultados
                        </Grid>
                        <Grid item xs={4} style={{ textAlign: 'right' }}>
                          <SyncButton
                            model='Site'
                            action='syncCategories'
                            onClick={() => this.props.syncCategories(this.props.selectedSite.id, this.props.token)}
                            disabled={this.props.categorySyncLoading}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </Paper>
                </Grid>
              </>
              )
            : null
        }
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    user: state.auth.user,
    token: state.auth.token,
    loading: state.sites.loading,
    formLoading: state.sites.formLoading,
    error: state.sites.error,
    success: state.sites.success,
    categorySyncLoading: state.sites.categorySyncLoading,
    categorySyncError: state.sites.categorySyncError,
    categorySyncSuccess: state.sites.categorySyncSuccess,
    sites: state.sites.sites,
    selectedSite: state.sites.selectedSite
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadSite: (id, token) => dispatch(actions.loadSite(id, token)),
    saveSite: (id, token, body) => dispatch(actions.saveSite(id, token, body)),
    loadSites: (token) => dispatch(actions.loadSites(token)),
    updateSelectedSite: (key, value) => dispatch(actions.updateSelectedSite(key, value)),
    syncCategories: (id, token) => dispatch(actions.syncCategories(id, token)),
    generateSiteApiKey: (id, token) => dispatch(actions.generateSiteApiKey(id, token))
  }
}

// Style component.
const SiteFormStyled = props => {
  const classes = useStyles()
  return (
    <SiteForm classes={classes} {...props} />
  )
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(SiteFormStyled)
