// ============================================================================
// Dependencies.
// ============================================================================

// Actions.
import * as actions from '../actions/types'

// Shared.
import { updateObject } from '../../shared/functions'

// ============================================================================
// Initial state.
// ============================================================================

// Initial state.
const initialState = {
  loading: false,
  error: null,
  success: null,
  expenseTypes: [],
  selectedExpenseType: null
}

// ============================================================================
// Functionality.
// ============================================================================

// Start loading list of expense types.
const loadExpenseTypesStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    success: null,
    expenseTypes: []
  })
}

// Finish loading list of expense types.
const loadExpenseTypesSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    expenseTypes: action.expenseTypes,
    error: null,
    success: null
  })
}

// Finish loading list of expense types, with error.
const loadExpenseTypesFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    expenseTypes: [],
    error: action.error,
    success: null
  })
}

// Start deleting expense type.
const deleteExpenseTypeStart = state => {
  return updateObject(state, {
    loading: true,
    success: null,
    error: null
  })
}

// Delete an expense type successfully.
const deleteExpenseTypeSuccess = (state, action) => {
  const oldExpenseTypeIdx = state.expenseTypes.findIndex(e => +e.id === +action.id)
  const newExpenseTypes = [...state.expenseTypes]
  newExpenseTypes.splice(oldExpenseTypeIdx, 1)
  return updateObject(state, {
    loading: false,
    expenseTypes: newExpenseTypes,
    error: null,
    success: `Se ha eliminado el tipo de gasto #${action.id} correctamente.`
  })
}

// Delete expense type, with error.
const deleteExpenseTypeFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    success: null
  })
}

// Update the selected expense type.
const updateSelectedExpenseType = (state, action) => {
  const newSelectedExpenseType = {
    ...state.selectedExpenseType
  }
  newSelectedExpenseType[action.key] = action.value
  return updateObject(state, {
    selectedExpenseType: newSelectedExpenseType
  })
}

// Start saving an expense type.
const saveExpenseTypeStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    success: null
  })
}

// Save an expense type successfully.
const saveExpenseTypeSuccess = (state, action) => {
  const newExpenseTypes = [
    ...state.expenseTypes
  ]
  if (action.id) {
    const updatedExpenseTypeIdx = state.expenseTypes.findIndex(s => +s.id === +action.id)
    newExpenseTypes[updatedExpenseTypeIdx] = { ...action.expenseType }
  } else {
    newExpenseTypes.push(action.expenseType)
  }
  return updateObject(state, {
    loading: false,
    error: null,
    success: action.id ? 'Tipo de gasto actualizado correctamente.' : 'Tipo de gasto creado correctamente.',
    expenseTypes: newExpenseTypes,
    selectedExpenseType: action.expenseType
  })
}

// Save an expense type, with error.
const saveExpenseTypeFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    success: null
  })
}

// ============================================================================
// Reducer definition.
// ============================================================================

// Reducer definition.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_EXPENSE_TYPES_START:
      return loadExpenseTypesStart(state, action)
    case actions.LOAD_EXPENSE_TYPES_SUCCESS:
      return loadExpenseTypesSuccess(state, action)
    case actions.LOAD_EXPENSE_TYPES_FAILED:
      return loadExpenseTypesFailed(state, action)
    case actions.DELETE_EXPENSE_TYPE_START:
      return deleteExpenseTypeStart(state, action)
    case actions.DELETE_EXPENSE_TYPE_SUCCESS:
      return deleteExpenseTypeSuccess(state, action)
    case actions.DELETE_EXPENSE_TYPE_FAILED:
      return deleteExpenseTypeFailed(state, action)
    case actions.UPDATE_SELECTED_EXPENSE_TYPE:
      return updateSelectedExpenseType(state, action)
    case actions.SAVE_EXPENSE_TYPE_START:
      return saveExpenseTypeStart(state, action)
    case actions.SAVE_EXPENSE_TYPE_SUCCESS:
      return saveExpenseTypeSuccess(state, action)
    case actions.SAVE_EXPENSE_TYPE_FAILED:
      return saveExpenseTypeFailed(state, action)
    default:
      return state
  }
}

export default reducer
