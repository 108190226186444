// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { Typography } from '@material-ui/core'

// =============================================================================
// Components.
// =============================================================================

// Stateless component.
const Title = props => {
  return (
    <Typography component='h2' variant='h6' color='primary' gutterBottom style={props.noMargin ? { margin: 0 } : {}}>
      {props.children}
    </Typography>
  )
}

// Export.
export default Title
