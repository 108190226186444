// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
  Grid,
  Card,
  CardContent,
  CardActionArea,
  CardMedia,
  Typography,
  Link
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

// Style.
import { useStyles } from '../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component.
const CardTotals = props => {
  const classes = useStyles()
  const cardContent = (
    <Card className={classes.root}>
      <CardActionArea>
        <CardMedia
          component='img'
          alt={props.type}
          height='140'
          image={props.image}
          title={props.type}
        />
        <CardContent>
          <Typography gutterBottom variant='h5' component='h2'>
            {props.total} {props.type}
          </Typography>
          <Typography variant='body2' color='textSecondary' component='p'>
            Click para ver listado completo
          </Typography>
        </CardContent>
      </CardActionArea>
    </Card>
  )
  return (
    <Grid item xs={12} sm={6} md={3}>
      {props.link && props.link.length ? <Link to={props.link} component={RouterLink} className={classes.noUnderlineOnHover}>{cardContent}</Link> : cardContent}
    </Grid>
  )
}

// Export.
export default CardTotals
