// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { Chip } from '@material-ui/core'

// Shared.
import { DISCOUNT_TYPES } from '../../../shared/constants'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const DiscountType = props => {
  switch (props.children) {
    case 'percentage': return <Chip size='small' variant='outlined' color='primary' label={DISCOUNT_TYPES.percentage} />
    case 'quantity': return <Chip size='small' variant='outlined' color='default' label={DISCOUNT_TYPES.quantity} />
    default: return props.children
  }
}

// Export.
export default DiscountType
