// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { connect } from 'react-redux'

// Redux actions.
import * as actions from '../../../../../../store/actions'

// Components.
import AlertInfo from '../../../../../UI/Alerts/AlertInfo'
import FormField from '../../../../../UI/Form/FormField'
import PosTerminalsTableRow from './PosTerminalsTableRow'
import PosTerminalsStockMovementsDialog from './PosTerminalsStockMovementsDialog'
import SaveButton from '../../../../../UI/Buttons/SaveButton'
import TableColumnTitle from '../../../../../UI/Table/TableColumnTitle'
import TableLoader from '../../../../../UI/Loaders/TableLoader'

// Shared.
import { RESULTS_PER_PAGE } from '../../../../../../shared/constants'

// Style.
import { useStyles } from '../../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Table columns.
const columns = [
  { name: 'id', label: 'ID' },
  { name: 'name', label: 'Nº asignado' },
  { name: 'bank_serial_number', label: 'Nº de serie banco' },
  { name: 'terminal_serial_number', label: 'Nº de serie TPV' },
  { name: 'created_at', label: 'Fecha de creación (UTC)' },
  { name: 'updated_at', label: 'Fecha de modificación (UTC)' },
  { name: 'actions', label: '' }
]

// Stateful component declaration.
class PosTerminalsTable extends Component {
  state = {
    fields: {
      name: {
        label: 'Nº asignado',
        error: null,
        type: 'number',
        required: true,
        touched: false,
        errMsg: 'Por favor, proporciona un nombre válido.',
        autoFocus: true
      },
      bank_serial_number: {
        label: 'Nº de serie banco',
        error: null,
        type: 'text',
        required: true,
        touched: false,
        errMsg: 'Por favor, proporciona un número de serie válido.',
        autoFocus: false
      },
      terminal_serial_number: {
        label: 'Nº de serie TPV',
        error: null,
        type: 'text',
        required: true,
        touched: false,
        errMsg: 'Por favor, proporciona un número de serie válido.',
        autoFocus: false
      }
    },
    stockMovementsDialogPosTerminalId: null
  }

  handleOpenStockMovementsDialog = posTerminalId => {
    const newState = {
      ...this.state
    }
    newState.stockMovementsDialogPosTerminalId = posTerminalId
    this.setState(newState)
  }

  handleCloseStockMovementsDialog = () => {
    const newState = {
      ...this.state
    }
    newState.stockMovementsDialogPosTerminalId = null
    this.setState(newState)
  }

  handleChange = (elemKey, newValue) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields[elemKey]
    }
    // Modify the element value.
    newElement.value = newValue
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.required && !newValue.length && newElement.touched) {
      newElement.error = 'Campo obligatorio'
    } else if (newElement.validation && newValue.length && !newElement.validation(newValue)) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Build new state.
    const newState = {
      fields: {
        ...this.state.fields,
        [elemKey]: newElement
      }
    }
    // Update the state.
    this.setState(newState)
  }

  handleCreatePosTerminal = () => {
    // Save the POS terminal.
    this.props.savePosTerminal(null, this.props.token, {
      name: this.state.fields.name.value,
      bank_serial_number: this.state.fields.bank_serial_number.value,
      terminal_serial_number: this.state.fields.terminal_serial_number.value
    })
    // Reset the form.
    const newStateFields = {
      ...this.state.fields
    }
    newStateFields.name.value = ''
    newStateFields.bank_serial_number.value = ''
    newStateFields.terminal_serial_number.value = ''
    this.setState({
      fields: newStateFields
    })
    // Close the form.
    this.props.handleFormClose()
  }

  shouldSaveButtonBeDisabled = () => {
    return this.props.loading ||
      !this.state.fields.name.value ||
      this.state.fields.name.value === '' ||
      !this.state.fields.bank_serial_number.value ||
      this.state.fields.bank_serial_number.value === '' ||
      !this.state.fields.terminal_serial_number.value ||
      this.state.fields.terminal_serial_number.value === ''
  }

  render () {
    // Loading.
    if (this.props.loading) {
      return <TableLoader cols={columns} />
    }

    let stockMovementsDialog = null

    if ((!this.props.posTerminals || !this.props.posTerminals.length) && !this.props.creating) {
      return (
        <AlertInfo>
          Aún no se ha creado ningún TPV.
        </AlertInfo>
      )
    }

    const posTerminalsList = []
    const minLimit = (this.props.page - 1) * RESULTS_PER_PAGE
    const maxLimit = Math.min(this.props.posTerminals.length, this.props.page * RESULTS_PER_PAGE)

    for (let i = minLimit; i < maxLimit; i++) {
      const posTerminal = this.props.posTerminals[i]

      if (+posTerminal.id === this.state.stockMovementsDialogPosTerminalId) {
        stockMovementsDialog = (
          <PosTerminalsStockMovementsDialog
            open={!!this.state.stockMovementsDialogPosTerminalId}
            onDialogClose={this.handleCloseStockMovementsDialog}
            posTerminal={posTerminal}
          />
        )
      }

      posTerminalsList.push((
        <PosTerminalsTableRow
          key={i}
          id={posTerminal.id}
          posTerminal={posTerminal}
          loading={this.props.loading}
          onDeletePosTerminal={() => this.props.deletePosTerminal(posTerminal.id, this.props.token)}
          handleSavePosTerminal={body => this.props.savePosTerminal(posTerminal.id, this.props.token, body)}
          handleOpenStockMovementsDialog={() => this.handleOpenStockMovementsDialog(posTerminal.id)}
        />
      ))
    }

    if (this.props.creating) {
      posTerminalsList.push(
        <TableRow key='new'>
          <TableCell className={this.props.classes.tableCellForm}>
            <IconButton color='default' aria-label='undo' size='small' onClick={this.props.handleFormClose}>
              <ClearIcon />
            </IconButton>
          </TableCell>
          {
            Object.keys(this.state.fields).map((fieldName, key) => {
              const field = this.state.fields[fieldName]
              return (
                <TableCell className={this.props.classes.tableCellForm} key={key}>
                  <FormField
                    noMargin
                    fullWidth
                    name={fieldName}
                    type={field.type}
                    autoFocus={field.autofocus}
                    required={field.required}
                    label={field.label}
                    value={field.value}
                    handleChange={e => this.handleChange(fieldName, e.target.value)}
                  />
                </TableCell>
              )
            })
          }
          <TableCell className={this.props.classes.tableCellForm} />
          <TableCell className={this.props.classes.tableCellForm} />
          <TableCell className={this.props.classes.tableCellForm}>
            <SaveButton
              model='PosTerminal'
              action='create'
              disabled={this.shouldSaveButtonBeDisabled()}
              onClick={this.handleCreatePosTerminal}
            />
          </TableCell>
        </TableRow>
      )
    }

    return (
      <>
        <Table size='small'>
          <TableHead>
            <TableRow>
              {
                columns.map((col, key) => <TableColumnTitle key={key} {...col} />)
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {posTerminalsList}
          </TableBody>
        </Table>
        {stockMovementsDialog}
      </>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    deletePosTerminal: (id, token) => dispatch(actions.deletePosTerminal(id, token)),
    loadPosTerminals: token => dispatch(actions.loadPosTerminals(token)),
    savePosTerminal: (id, token, body) => dispatch(actions.savePosTerminal(id, token, body))
  }
}

// Style component.
const PosTerminalsTableStyled = props => {
  const classes = useStyles()
  return <PosTerminalsTable classes={classes} {...props} />
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(PosTerminalsTableStyled)
