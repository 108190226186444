// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import * as _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'

// Axios instance.
import axios from '../../../../../../../axios'

// Components.
import FormField from '../../../../../../UI/Form/FormField'

// Redux actions.
import * as actions from '../../../../../../../store/actions'

// Shared.
import { arrayDiff } from '../../../../../../../shared/functions'

// Styles.
import { useStyles } from '../../../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class StockMovementsFormDataSource extends Component {
  state = {
    fields: {
      artist_id: {
        label: 'Artista',
        error: null,
        type: 'select',
        required: true,
        touched: false,
        autoFocus: false,
        fullWidth: false,
        width: 4,
        options: []
      },
      category_id: {
        label: 'Categoría',
        error: null,
        type: 'select',
        required: true,
        touched: false,
        autoFocus: false,
        fullWidth: false,
        width: 4,
        options: []
      },
      product_ids: {
        label: 'Productos',
        error: null,
        type: 'multiselect',
        required: true,
        touched: false,
        autoFocus: false,
        fullWidth: false,
        width: 4,
        options: []
      }
    },
    selectedArtistDataSources: null,
    selectedCategory: null
  }

  loadCategory = id => {
    axios(this.props.token).get(`/category/${id}`)
      .then(response => {
        this.setState({
          ...this.state,
          selectedCategory: response.data
        })
      })
  }

  loadArtistDataSources = id => {
    axios(this.props.token).get(`/artist/data_sources/${id}`)
      .then(response => {
        this.setState({
          ...this.state,
          selectedArtistDataSources: response.data
        })
      })
  }

  componentDidMount () {
    // Load the artists list.
    if (!this.props.artists || !this.props.artists.length) {
      this.props.loadArtists(this.props.token)
    }
    // Load the artist.
    if (this.props.artistId && !this.state.selectedArtistDataSources) {
      this.loadArtistDataSources(this.props.artistId)
    }
    // Load the category.
    if (this.props.categoryId && !this.state.selectedCategory) {
      this.loadCategory(this.props.categoryId)
    }
  }

  handleChange = (elemKey, newValue) => {
    // Create a copy of the state object.
    const newFields = {
      ...this.state.fields
    }
    const newElement = {
      ...newFields[elemKey]
    }
    // Modify the element value.
    const selectedProductIds = this.getSelectedProductIds()
    if (elemKey === 'artist_id') {
      this.props.updateSelectedStockMovementDataSource(this.props.idx, newValue, '')
      this.props.deleteStockMovementProducts(selectedProductIds)
    } else if (elemKey === 'category_id') {
      this.props.updateSelectedStockMovementDataSource(this.props.idx, this.props.dataSources[this.props.idx].artist_id, newValue)
      this.props.deleteStockMovementProducts(selectedProductIds)
    } else {
      const addedProductIds = arrayDiff(newValue, selectedProductIds)
      if (addedProductIds.length) {
        const product = this.state.selectedCategory.products.find(p => +p.id === +addedProductIds)
        this.props.addStockMovementProducts([product], this.state.selectedCategory && this.state.selectedCategory.site ? this.state.selectedCategory.site.id : null)
      } else {
        const removedProductIds = arrayDiff(selectedProductIds, newValue)
        this.props.deleteStockMovementProducts(removedProductIds)
      }
    }
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.required && (newElement.type === 'select' ? !newValue : !newValue.length) && newElement.touched) {
      newElement.error = 'Campo obligatorio'
    } else {
      newElement.error = null
    }
    // Build new state.
    const newState = {
      ...this.state,
      fields: newFields
    }
    // Load the deferred data.
    if (elemKey === 'artist_id') {
      this.loadArtistDataSources(newValue, this.props.token)
    } else if (elemKey === 'category_id') {
      this.loadCategory(newValue, this.props.token)
    }
    // Update the state.
    this.setState(newState)
  }

  getSelectedProductIds = () => {
    if (this.props.selectedStockMovement && this.props.selectedStockMovement.products && this.props.selectedStockMovement.products.length) {
      const allProductIds = this.props.selectedStockMovement.products.map(p => +p.product_id)
      const allUniqueProductIds = _.uniq(allProductIds)
      const dsProductIds = this.state.selectedCategory && this.state.selectedCategory.products ? this.state.selectedCategory.products.map(p => +p.id) : []
      return _.intersection(allUniqueProductIds, dsProductIds)
    }
    return []
  }

  render () {
    return (
      <form className={this.props.classes.rootWrap} noValidate autoComplete='off'>
        {
          Object.keys(this.state.fields).map(fieldName => {
            const field = this.state.fields[fieldName]

            // Special field options.
            let value = ''
            let disabled = field.disabled
            const helperText = field.helperText

            switch (fieldName) {
              case 'artist_id':
                disabled = !this.props.artists.length
                if (!disabled) {
                  value = this.props.dataSources[this.props.idx] && this.props.dataSources[this.props.idx].artist_id ? this.props.dataSources[this.props.idx].artist_id : ''
                  field.options = [
                    { label: '-', value: '', disabled: true },
                    ...this.props.artists.map(a => {
                      return { value: a.id, label: a.name }
                    })
                  ]
                }
                break
              case 'category_id':
                disabled = !this.props.artists.length || !this.state.selectedArtistDataSources
                if (!disabled) {
                  value = this.props.dataSources[this.props.idx] && this.props.dataSources[this.props.idx].category_id ? this.props.dataSources[this.props.idx].category_id : ''
                  field.options = [
                    { label: '-', value: '', disabled: true },
                    ...this.state.selectedArtistDataSources.map(d => {
                      return { value: d.category.id, label: `${d.category.name} - ${d.category.site.url}`, disabled: !!this.props.dataSources.find(ds => +ds.category_id === +d.category.id) }
                    })
                  ]
                }
                break
              case 'product_ids':
                disabled = !this.props.artists.length || !this.state.selectedArtistDataSources || !this.state.selectedCategory
                if (!disabled) {
                  value = this.getSelectedProductIds()
                  field.options = [
                    { label: '-', value: '', disabled: true },
                    ...this.state.selectedCategory.products.filter(p => !p.is_pack).map(p => {
                      return { value: p.id, label: `${p.name}${p.variations && p.variations.length ? ` (${p.variations.length} variaciones)` : ''}` }
                    })
                  ]
                }
                break
              default:
            }

            return (
              <FormField
                key={fieldName}
                name={fieldName}
                disabled={disabled}
                type={field.type}
                autoFocus={field.autoFocus}
                required={field.required}
                error={field.error}
                label={field.label}
                value={value}
                fullWidth={field.fullWidth}
                width={field.width}
                handleChange={e => this.handleChange(fieldName, e.target.value)}
                options={field.options}
                helperText={helperText}
              />
            )
          })
        }
      </form>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    artistsLoading: state.artists.loading,
    error: state.stockMovements.error,
    artists: state.artists.artists,
    selectedStockMovement: state.stockMovements.selectedStockMovement,
    dataSources: state.stockMovements.dataSources
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadArtists: token => dispatch(actions.loadArtists(token)),
    addStockMovementDataSource: (artistId, categoryId) => dispatch(actions.addStockMovementDataSource(artistId, categoryId)),
    deleteStockMovementDataSource: (artistId, categoryId) => dispatch(actions.deleteStockMovementDataSource(artistId, categoryId)),
    updateSelectedStockMovementDataSource: (idx, artistId, categoryId) => dispatch(actions.updateSelectedStockMovementDataSource(idx, artistId, categoryId)),
    addStockMovementProducts: (products, siteId) => dispatch(actions.addStockMovementProducts(products, siteId)),
    deleteStockMovementProducts: productIds => dispatch(actions.deleteStockMovementProducts(productIds))
  }
}

// Style component.
const StockMovementsFormDataSourceStyled = props => {
  const classes = useStyles()
  return (
    <StockMovementsFormDataSource classes={classes} {...props} />
  )
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(StockMovementsFormDataSourceStyled)
