// ============================================================================
// Define actions as constants and export.
// ============================================================================

// AUTHENTICATION.
export const LOGOUT = 'LOGOUT'
export const LOGIN_START = 'LOGIN_START'
export const LOGIN_FAILED = 'LOGIN_FAILED'
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS'
export const SET_PASSWORD_RECOVERY_START = 'SET_PASSWORD_RECOVERY_START'
export const SET_PASSWORD_RECOVERY_FAILED = 'SET_PASSWORD_RECOVERY_FAILED'
export const SET_PASSWORD_RECOVERY_SUCCESS = 'SET_PASSWORD_RECOVERY_SUCCESS'
export const RECOVER_PASSWORD_START = 'RECOVER_PASSWORD_START'
export const RECOVER_PASSWORD_FAILED = 'RECOVER_PASSWORD_FAILED'
export const RECOVER_PASSWORD_SUCCESS = 'RECOVER_PASSWORD_SUCCESS'
export const RESET_LOGIN_ERROR = 'RESET_LOGIN_ERROR'
export const SET_LOGIN_REDIRECT_PATH = 'SET_LOGIN_REDIRECT_PATH'
export const RESET_PASSWORD_RECOVERY = 'RESET_PASSWORD_RECOVERY'
export const SET_RECOVERY_TOKEN = 'SET_RECOVERY_TOKEN'
export const SET_MAINTENANCE_MODE = 'SET_MAINTENANCE_MODE'
export const SET_OFFLINE_MODE = 'SET_OFFLINE_MODE'
export const UNSET_UNAVAILABLE_STATES = 'UNSET_UNAVAILABLE_STATES'
export const START_LOADING_GLOBAL = 'START_LOADING_GLOBAL'
export const TOGGLE_SIDE_MENU_STATE = 'TOGGLE_SIDE_MENU_STATE'
export const EXPAND_SIDE_MENU_SECTION = 'EXPAND_SIDE_MENU_SECTION'
export const COLLAPSE_ALL_SIDE_MENU_SECTIONS = 'COLLAPSE_ALL_SIDE_MENU_SECTIONS'
export const UPDATE_LOGGED_USER_FIELDS = 'UPDATE_LOGGED_USER_FIELDS'

// SITES.
export const LOAD_SITES_START = 'LOAD_SITES_START'
export const LOAD_SITES_SUCCESS = 'LOAD_SITES_SUCCESS'
export const LOAD_SITES_FAILED = 'LOAD_SITES_FAILED'
export const DELETE_SITE_START = 'DELETE_SITE_START'
export const DELETE_SITE_SUCCESS = 'DELETE_SITE_SUCCESS'
export const DELETE_SITE_FAILED = 'DELETE_SITE_FAILED'
export const LOAD_SITE_START = 'LOAD_SITE_START'
export const LOAD_SITE_SUCCESS = 'LOAD_SITE_SUCCESS'
export const LOAD_SITE_FAILED = 'LOAD_SITE_FAILED'
export const UPDATE_SELECTED_SITE = 'UPDATE_SELECTED_SITE'
export const SAVE_SITE_START = 'SAVE_SITE_START'
export const SAVE_SITE_SUCCESS = 'SAVE_SITE_SUCCESS'
export const SAVE_SITE_FAILED = 'SAVE_SITE_FAILED'
export const SYNC_CATEGORIES_START = 'SYNC_CATEGORIES_START'
export const SYNC_CATEGORIES_SUCCESS = 'SYNC_CATEGORIES_SUCCESS'
export const SYNC_CATEGORIES_FAILED = 'SYNC_CATEGORIES_FAILED'
export const ADD_SALE_START = 'ADD_SALE_START'
export const ADD_SALE_SUCCESS = 'ADD_SALE_SUCCESS'
export const ADD_SALE_FAILED = 'ADD_SALE_FAILED'
export const REMOVE_SALE_START = 'REMOVE_SALE_START'
export const REMOVE_SALE_SUCCESS = 'REMOVE_SALE_SUCCESS'
export const REMOVE_SALE_FAILED = 'REMOVE_SALE_FAILED'
export const GENERATE_SITE_API_KEY_START = 'GENERATE_SITE_API_KEY_START'
export const GENERATE_SITE_API_KEY_SUCCESS = 'GENERATE_SITE_API_KEY_SUCCESS'
export const GENERATE_SITE_API_KEY_FAILED = 'GENERATE_SITE_API_KEY_FAILED'

// ARTISTS.
export const LOAD_ARTISTS_START = 'LOAD_ARTISTS_START'
export const LOAD_ARTISTS_SUCCESS = 'LOAD_ARTISTS_SUCCESS'
export const LOAD_ARTISTS_FAILED = 'LOAD_ARTISTS_FAILED'
export const DELETE_ARTIST_START = 'DELETE_ARTIST_START'
export const DELETE_ARTIST_SUCCESS = 'DELETE_ARTIST_SUCCESS'
export const DELETE_ARTIST_FAILED = 'DELETE_ARTIST_FAILED'
export const LOAD_ARTIST_START = 'LOAD_ARTIST_START'
export const LOAD_ARTIST_SUCCESS = 'LOAD_ARTIST_SUCCESS'
export const LOAD_ARTIST_FAILED = 'LOAD_ARTIST_FAILED'
export const UPDATE_SELECTED_ARTIST = 'UPDATE_SELECTED_ARTIST'
export const SAVE_ARTIST_START = 'SAVE_ARTIST_START'
export const SAVE_ARTIST_SUCCESS = 'SAVE_ARTIST_SUCCESS'
export const SAVE_ARTIST_FAILED = 'SAVE_ARTIST_FAILED'
export const DELETE_DATA_SOURCE_START = 'DELETE_DATA_SOURCE_START'
export const DELETE_DATA_SOURCE_SUCCESS = 'DELETE_DATA_SOURCE_SUCCESS'
export const DELETE_DATA_SOURCE_FAILED = 'DELETE_DATA_SOURCE_FAILED'
export const SAVE_DATA_SOURCE_START = 'SAVE_DATA_SOURCE_START'
export const SAVE_DATA_SOURCE_SUCCESS = 'SAVE_DATA_SOURCE_SUCCESS'
export const SAVE_DATA_SOURCE_FAILED = 'SAVE_DATA_SOURCE_FAILED'
export const DELETE_ARRANGEMENT_START = 'DELETE_ARRANGEMENT_START'
export const DELETE_ARRANGEMENT_SUCCESS = 'DELETE_ARRANGEMENT_SUCCESS'
export const DELETE_ARRANGEMENT_FAILED = 'DELETE_ARRANGEMENT_FAILED'
export const LOAD_ARRANGEMENT_START = 'LOAD_ARRANGEMENT_START'
export const LOAD_ARRANGEMENT_SUCCESS = 'LOAD_ARRANGEMENT_SUCCESS'
export const LOAD_ARRANGEMENT_FAILED = 'LOAD_ARRANGEMENT_FAILED'
export const UPDATE_SELECTED_ARRANGEMENT = 'UPDATE_SELECTED_ARRANGEMENT'
export const SAVE_ARRANGEMENT_START = 'SAVE_ARRANGEMENT_START'
export const SAVE_ARRANGEMENT_SUCCESS = 'SAVE_ARRANGEMENT_SUCCESS'
export const SAVE_ARRANGEMENT_FAILED = 'SAVE_ARRANGEMENT_FAILED'
export const LOAD_ARTIST_CATEGORIES_START = 'LOAD_ARTIST_CATEGORIES_START'
export const LOAD_ARTIST_CATEGORIES_SUCCESS = 'LOAD_ARTIST_CATEGORIES_SUCCESS'
export const LOAD_ARTIST_CATEGORIES_FAILED = 'LOAD_ARTIST_CATEGORIES_FAILED'
export const DELETE_EXPENSE_START = 'DELETE_EXPENSE_START'
export const DELETE_EXPENSE_SUCCESS = 'DELETE_EXPENSE_SUCCESS'
export const DELETE_EXPENSE_FAILED = 'DELETE_EXPENSE_FAILED'
export const SAVE_EXPENSE_START = 'SAVE_EXPENSE_START'
export const SAVE_EXPENSE_SUCCESS = 'SAVE_EXPENSE_SUCCESS'
export const SAVE_EXPENSE_FAILED = 'SAVE_EXPENSE_FAILED'
export const LOAD_EXPENSES_START = 'LOAD_EXPENSES_START'
export const LOAD_EXPENSES_SUCCESS = 'LOAD_EXPENSES_SUCCESS'
export const LOAD_EXPENSES_FAILED = 'LOAD_EXPENSES_FAILED'
export const LOAD_ARTIST_STATS_START = 'LOAD_ARTIST_STATS_START'
export const LOAD_ARTIST_STATS_SUCCESS = 'LOAD_ARTIST_STATS_SUCCESS'
export const LOAD_ARTIST_STATS_FAILED = 'LOAD_ARTIST_STATS_FAILED'
export const LOAD_ARTIST_DATA_SOURCES_START = 'LOAD_ARTIST_DATA_SOURCES_START'
export const LOAD_ARTIST_DATA_SOURCES_SUCCESS = 'LOAD_ARTIST_DATA_SOURCES_SUCCESS'
export const LOAD_ARTIST_DATA_SOURCES_FAILED = 'LOAD_ARTIST_DATA_SOURCES_FAILED'
export const LOAD_ARTIST_ARRANGEMENTS_START = 'LOAD_ARTIST_ARRANGEMENTS_START'
export const LOAD_ARTIST_ARRANGEMENTS_SUCCESS = 'LOAD_ARTIST_ARRANGEMENTS_SUCCESS'
export const LOAD_ARTIST_ARRANGEMENTS_FAILED = 'LOAD_ARTIST_ARRANGEMENTS_FAILED'
export const DELETE_EARNING_START = 'DELETE_EARNING_START'
export const DELETE_EARNING_SUCCESS = 'DELETE_EARNING_SUCCESS'
export const DELETE_EARNING_FAILED = 'DELETE_EARNING_FAILED'
export const SAVE_EARNING_START = 'SAVE_EARNING_START'
export const SAVE_EARNING_SUCCESS = 'SAVE_EARNING_SUCCESS'
export const SAVE_EARNING_FAILED = 'SAVE_EARNING_FAILED'
export const LOAD_EARNINGS_START = 'LOAD_EARNINGS_START'
export const LOAD_EARNINGS_SUCCESS = 'LOAD_EARNINGS_SUCCESS'
export const LOAD_EARNINGS_FAILED = 'LOAD_EARNINGS_FAILED'

// CATEGORIES.
export const LOAD_CATEGORY_START = 'LOAD_CATEGORY_START'
export const LOAD_CATEGORY_SUCCESS = 'LOAD_CATEGORY_SUCCESS'
export const LOAD_CATEGORY_FAILED = 'LOAD_CATEGORY_FAILED'
export const SYNC_PRODUCTS_START = 'SYNC_PRODUCTS_START'
export const SYNC_PRODUCTS_SUCCESS = 'SYNC_PRODUCTS_SUCCESS'
export const SYNC_PRODUCTS_FAILED = 'SYNC_PRODUCTS_FAILED'

// PRODUCTS.
export const LOAD_PRODUCT_START = 'LOAD_PRODUCT_START'
export const LOAD_PRODUCT_SUCCESS = 'LOAD_PRODUCT_SUCCESS'
export const LOAD_PRODUCT_FAILED = 'LOAD_PRODUCT_FAILED'
export const SAVE_PRODUCT_START = 'SAVE_PRODUCT_START'
export const SAVE_PRODUCT_SUCCESS = 'SAVE_PRODUCT_SUCCESS'
export const SAVE_PRODUCT_FAILED = 'SAVE_PRODUCT_FAILED'
export const SEARCH_PRODUCTS_START = 'SEARCH_PRODUCTS_START'
export const SEARCH_PRODUCTS_SUCCESS = 'SEARCH_PRODUCTS_SUCCESS'
export const SEARCH_PRODUCTS_FAILED = 'SEARCH_PRODUCTS_FAILED'
export const UPDATE_SELECTED_PRODUCT = 'UPDATE_SELECTED_PRODUCT'
export const SYNC_PRODUCT_START = 'SYNC_PRODUCT_START'
export const SYNC_PRODUCT_SUCCESS = 'SYNC_PRODUCT_SUCCESS'
export const SYNC_PRODUCT_FAILED = 'SYNC_PRODUCT_FAILED'
export const UPDATE_PRODUCT_QUERY_STRING = 'UPDATE_PRODUCT_QUERY_STRING'

// SALES_REPORTS.
export const LOAD_SALES_REPORTS_START = 'LOAD_SALES_REPORTS_START'
export const LOAD_SALES_REPORTS_SUCCESS = 'LOAD_SALES_REPORTS_SUCCESS'
export const LOAD_SALES_REPORTS_FAILED = 'LOAD_SALES_REPORTS_FAILED'
export const LOAD_SALES_REPORT_START = 'LOAD_SALES_REPORT_START'
export const LOAD_SALES_REPORT_SUCCESS = 'LOAD_SALES_REPORT_SUCCESS'
export const LOAD_SALES_REPORT_FAILED = 'LOAD_SALES_REPORT_FAILED'
export const SAVE_SALES_REPORT_START = 'SAVE_SALES_REPORT_START'
export const SAVE_SALES_REPORT_SUCCESS = 'SAVE_SALES_REPORT_SUCCESS'
export const SAVE_SALES_REPORT_FAILED = 'SAVE_SALES_REPORT_FAILED'
export const DELETE_SALES_REPORT_START = 'DELETE_SALES_REPORT_START'
export const DELETE_SALES_REPORT_SUCCESS = 'DELETE_SALES_REPORT_SUCCESS'
export const DELETE_SALES_REPORT_FAILED = 'DELETE_SALES_REPORT_FAILED'
export const EDIT_NEW_SALES_REPORT = 'EDIT_NEW_SALES_REPORT'
export const RESET_LOADED_SALES_REPORT = 'RESET_LOADED_SALES_REPORT'

// DASHBOARD.
export const LOAD_DASHBOARD_STATS_START = 'LOAD_DASHBOARD_STATS_START'
export const LOAD_DASHBOARD_STATS_SUCCESS = 'LOAD_DASHBOARD_STATS_SUCCESS'
export const LOAD_DASHBOARD_STATS_FAILED = 'LOAD_DASHBOARD_STATS_FAILED'

// USERS.
export const LOAD_USERS_START = 'LOAD_USERS_START'
export const LOAD_USERS_SUCCESS = 'LOAD_USERS_SUCCESS'
export const LOAD_USERS_FAILED = 'LOAD_USERS_FAILED'
export const LOAD_USER_START = 'LOAD_USER_START'
export const LOAD_USER_SUCCESS = 'LOAD_USER_SUCCESS'
export const LOAD_USER_FAILED = 'LOAD_USER_FAILED'
export const DELETE_USER_START = 'DELETE_USER_START'
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS'
export const DELETE_USER_FAILED = 'DELETE_USER_FAILED'
export const UPDATE_SELECTED_USER = 'UPDATE_SELECTED_USER'
export const SAVE_USER_START = 'SAVE_USER_START'
export const SAVE_USER_SUCCESS = 'SAVE_USER_SUCCESS'
export const SAVE_USER_FAILED = 'SAVE_USER_FAILED'
export const LOAD_PROFILES_START = 'LOAD_PROFILES_START'
export const LOAD_PROFILES_SUCCESS = 'LOAD_PROFILES_SUCCESS'
export const LOAD_PROFILES_FAILED = 'LOAD_PROFILES_FAILED'
export const UPLOAD_USER_IMAGE_START = 'UPLOAD_USER_IMAGE_START'
export const UPLOAD_USER_IMAGE_SUCCESS = 'UPLOAD_USER_IMAGE_SUCCESS'
export const UPLOAD_USER_IMAGE_FAILED = 'UPLOAD_USER_IMAGE_FAILED'

// EXPENSE TYPES.
export const UPDATE_SELECTED_EXPENSE_TYPE = 'UPDATE_SELECTED_EXPENSE_TYPE'
export const LOAD_EXPENSE_TYPES_START = 'LOAD_EXPENSE_TYPES_START'
export const LOAD_EXPENSE_TYPES_SUCCESS = 'LOAD_EXPENSE_TYPES_SUCCESS'
export const LOAD_EXPENSE_TYPES_FAILED = 'LOAD_EXPENSE_TYPES_FAILED'
export const SAVE_EXPENSE_TYPE_START = 'SAVE_EXPENSE_TYPE_START'
export const SAVE_EXPENSE_TYPE_SUCCESS = 'SAVE_EXPENSE_TYPE_SUCCESS'
export const SAVE_EXPENSE_TYPE_FAILED = 'SAVE_EXPENSE_TYPE_FAILED'
export const DELETE_EXPENSE_TYPE_START = 'DELETE_EXPENSE_TYPE_START'
export const DELETE_EXPENSE_TYPE_SUCCESS = 'DELETE_EXPENSE_TYPE_SUCCESS'
export const DELETE_EXPENSE_TYPE_FAILED = 'DELETE_EXPENSE_TYPE_FAILED'

// SETTLEMENTS.
export const LOAD_SETTLEMENTS_START = 'LOAD_SETTLEMENTS_START'
export const LOAD_SETTLEMENTS_SUCCESS = 'LOAD_SETTLEMENTS_SUCCESS'
export const LOAD_SETTLEMENTS_FAILED = 'LOAD_SETTLEMENTS_FAILED'
export const SUBMIT_SETTLEMENT_START = 'SUBMIT_SETTLEMENT_START'
export const SUBMIT_SETTLEMENT_SUCCESS = 'SUBMIT_SETTLEMENT_SUCCESS'
export const SUBMIT_SETTLEMENT_FAILED = 'SUBMIT_SETTLEMENT_FAILED'
export const DELETE_SETTLEMENT_START = 'DELETE_SETTLEMENT_START'
export const DELETE_SETTLEMENT_SUCCESS = 'DELETE_SETTLEMENT_SUCCESS'
export const DELETE_SETTLEMENT_FAILED = 'DELETE_SETTLEMENT_FAILED'

// SETTINGS.
export const UPDATE_SELECTED_SETTING = 'UPDATE_SELECTED_SETTING'
export const LOAD_SETTINGS_START = 'LOAD_SETTINGS_START'
export const LOAD_SETTINGS_SUCCESS = 'LOAD_SETTINGS_SUCCESS'
export const LOAD_SETTINGS_FAILED = 'LOAD_SETTINGS_FAILED'
export const SAVE_SETTING_START = 'SAVE_SETTING_START'
export const SAVE_SETTING_SUCCESS = 'SAVE_SETTING_SUCCESS'
export const SAVE_SETTING_FAILED = 'SAVE_SETTING_FAILED'

// PRODUCT SCHEDULED DISCOUNTS.
export const UPDATE_SELECTED_PRODUCT_SCHEDULED_DISCOUNT = 'UPDATE_SELECTED_PRODUCT_SCHEDULED_DISCOUNT'
export const LOAD_PRODUCT_SCHEDULED_DISCOUNTS_START = 'LOAD_PRODUCT_SCHEDULED_DISCOUNTS_START'
export const LOAD_PRODUCT_SCHEDULED_DISCOUNTS_SUCCESS = 'LOAD_PRODUCT_SCHEDULED_DISCOUNTS_SUCCESS'
export const LOAD_PRODUCT_SCHEDULED_DISCOUNTS_FAILED = 'LOAD_PRODUCT_SCHEDULED_DISCOUNTS_FAILED'
export const LOAD_PRODUCT_SCHEDULED_DISCOUNT_START = 'LOAD_PRODUCT_SCHEDULED_DISCOUNT_START'
export const LOAD_PRODUCT_SCHEDULED_DISCOUNT_SUCCESS = 'LOAD_PRODUCT_SCHEDULED_DISCOUNT_SUCCESS'
export const LOAD_PRODUCT_SCHEDULED_DISCOUNT_FAILED = 'LOAD_PRODUCT_SCHEDULED_DISCOUNT_FAILED'
export const SAVE_PRODUCT_SCHEDULED_DISCOUNT_START = 'SAVE_PRODUCT_SCHEDULED_DISCOUNT_START'
export const SAVE_PRODUCT_SCHEDULED_DISCOUNT_SUCCESS = 'SAVE_PRODUCT_SCHEDULED_DISCOUNT_SUCCESS'
export const SAVE_PRODUCT_SCHEDULED_DISCOUNT_FAILED = 'SAVE_PRODUCT_SCHEDULED_DISCOUNT_FAILED'
export const DELETE_PRODUCT_SCHEDULED_DISCOUNT_START = 'DELETE_PRODUCT_SCHEDULED_DISCOUNT_START'
export const DELETE_PRODUCT_SCHEDULED_DISCOUNT_SUCCESS = 'DELETE_PRODUCT_SCHEDULED_DISCOUNT_SUCCESS'
export const DELETE_PRODUCT_SCHEDULED_DISCOUNT_FAILED = 'DELETE_PRODUCT_SCHEDULED_DISCOUNT_FAILED'
export const ACTIVATE_PRODUCT_SCHEDULED_DISCOUNT_SUCCESS = 'ACTIVATE_PRODUCT_SCHEDULED_DISCOUNT_SUCCESS'
export const EXPIRE_PRODUCT_SCHEDULED_DISCOUNT_SUCCESS = 'EXPIRE_PRODUCT_SCHEDULED_DISCOUNT_SUCCESS'

// SALES.
export const LOAD_SALES_START = 'LOAD_SALES_START'
export const LOAD_SALES_SUCCESS = 'LOAD_SALES_SUCCESS'
export const LOAD_SALES_FAILED = 'LOAD_SALES_FAILED'
export const SAVE_SALE_START = 'SAVE_SALE_START'
export const SAVE_SALE_SUCCESS = 'SAVE_SALE_SUCCESS'
export const SAVE_SALE_FAILED = 'SAVE_SALE_FAILED'
export const DELETE_SALE_START = 'DELETE_SALE_START'
export const DELETE_SALE_SUCCESS = 'DELETE_SALE_SUCCESS'
export const DELETE_SALE_FAILED = 'DELETE_SALE_FAILED'

// EARNING TYPES.
export const UPDATE_SELECTED_EARNING_TYPE = 'UPDATE_SELECTED_EARNING_TYPE'
export const LOAD_EARNING_TYPES_START = 'LOAD_EARNING_TYPES_START'
export const LOAD_EARNING_TYPES_SUCCESS = 'LOAD_EARNING_TYPES_SUCCESS'
export const LOAD_EARNING_TYPES_FAILED = 'LOAD_EARNING_TYPES_FAILED'
export const SAVE_EARNING_TYPE_START = 'SAVE_EARNING_TYPE_START'
export const SAVE_EARNING_TYPE_SUCCESS = 'SAVE_EARNING_TYPE_SUCCESS'
export const SAVE_EARNING_TYPE_FAILED = 'SAVE_EARNING_TYPE_FAILED'
export const DELETE_EARNING_TYPE_START = 'DELETE_EARNING_TYPE_START'
export const DELETE_EARNING_TYPE_SUCCESS = 'DELETE_EARNING_TYPE_SUCCESS'
export const DELETE_EARNING_TYPE_FAILED = 'DELETE_EARNING_TYPE_FAILED'

// POS TERMINALS.
export const UPDATE_SELECTED_POS_TERMINAL = 'UPDATE_SELECTED_POS_TERMINAL'
export const LOAD_POS_TERMINALS_START = 'LOAD_POS_TERMINALS_START'
export const LOAD_POS_TERMINALS_SUCCESS = 'LOAD_POS_TERMINALS_SUCCESS'
export const LOAD_POS_TERMINALS_FAILED = 'LOAD_POS_TERMINALS_FAILED'
export const SAVE_POS_TERMINAL_START = 'SAVE_POS_TERMINAL_START'
export const SAVE_POS_TERMINAL_SUCCESS = 'SAVE_POS_TERMINAL_SUCCESS'
export const SAVE_POS_TERMINAL_FAILED = 'SAVE_POS_TERMINAL_FAILED'
export const DELETE_POS_TERMINAL_START = 'DELETE_POS_TERMINAL_START'
export const DELETE_POS_TERMINAL_SUCCESS = 'DELETE_POS_TERMINAL_SUCCESS'
export const DELETE_POS_TERMINAL_FAILED = 'DELETE_POS_TERMINAL_FAILED'

// STOCK MOVEMENTS.
export const UPDATE_SELECTED_STOCK_MOVEMENT = 'UPDATE_SELECTED_STOCK_MOVEMENT'
export const UPDATE_SELECTED_STOCK_MOVEMENT_PRODUCT = 'UPDATE_SELECTED_STOCK_MOVEMENT_PRODUCT'
export const UPDATE_SELECTED_STOCK_MOVEMENT_DATA_SOURCE = 'UPDATE_SELECTED_STOCK_MOVEMENT_DATA_SOURCE'
export const ADD_STOCK_MOVEMENT_DATA_SOURCE = 'ADD_STOCK_MOVEMENT_DATA_SOURCE'
export const DELETE_STOCK_MOVEMENT_DATA_SOURCE = 'DELETE_STOCK_MOVEMENT_DATA_SOURCE'
export const ADD_STOCK_MOVEMENT_PRODUCTS = 'ADD_STOCK_MOVEMENT_PRODUCTS'
export const DELETE_STOCK_MOVEMENT_PRODUCTS = 'DELETE_STOCK_MOVEMENT_PRODUCTS'
export const LOAD_STOCK_MOVEMENTS_START = 'LOAD_STOCK_MOVEMENTS_START'
export const LOAD_STOCK_MOVEMENTS_SUCCESS = 'LOAD_STOCK_MOVEMENTS_SUCCESS'
export const LOAD_STOCK_MOVEMENTS_FAILED = 'LOAD_STOCK_MOVEMENTS_FAILED'
export const SAVE_STOCK_MOVEMENT_START = 'SAVE_STOCK_MOVEMENT_START'
export const SAVE_STOCK_MOVEMENT_SUCCESS = 'SAVE_STOCK_MOVEMENT_SUCCESS'
export const SAVE_STOCK_MOVEMENT_FAILED = 'SAVE_STOCK_MOVEMENT_FAILED'
export const DELETE_STOCK_MOVEMENT_START = 'DELETE_STOCK_MOVEMENT_START'
export const DELETE_STOCK_MOVEMENT_SUCCESS = 'DELETE_STOCK_MOVEMENT_SUCCESS'
export const DELETE_STOCK_MOVEMENT_FAILED = 'DELETE_STOCK_MOVEMENT_FAILED'
export const LOAD_STOCK_MOVEMENT_START = 'LOAD_STOCK_MOVEMENT_START'
export const LOAD_STOCK_MOVEMENT_SUCCESS = 'LOAD_STOCK_MOVEMENT_SUCCESS'
export const LOAD_STOCK_MOVEMENT_FAILED = 'LOAD_STOCK_MOVEMENT_FAILED'
export const CREATE_STOCK_ENTRY_FROM_WITHDRAWAL_SUCCESS = 'CREATE_STOCK_ENTRY_FROM_WITHDRAWAL'

// ARRANGEMENT DOCUMENTS.
export const LOAD_ARRANGEMENT_DOCUMENTS_START = 'LOAD_ARRANGEMENT_DOCUMENTS_START'
export const LOAD_ARRANGEMENT_DOCUMENTS_SUCCESS = 'LOAD_ARRANGEMENT_DOCUMENTS_SUCCESS'
export const LOAD_ARRANGEMENT_DOCUMENTS_FAILED = 'LOAD_ARRANGEMENT_DOCUMENTS_FAILED'
export const SAVE_ARRANGEMENT_DOCUMENT_START = 'SAVE_ARRANGEMENT_DOCUMENT_START'
export const SAVE_ARRANGEMENT_DOCUMENT_SUCCESS = 'SAVE_ARRANGEMENT_DOCUMENT_SUCCESS'
export const SAVE_ARRANGEMENT_DOCUMENT_FAILED = 'SAVE_ARRANGEMENT_DOCUMENT_FAILED'
export const DELETE_ARRANGEMENT_DOCUMENT_START = 'DELETE_ARRANGEMENT_DOCUMENT_START'
export const DELETE_ARRANGEMENT_DOCUMENT_SUCCESS = 'DELETE_ARRANGEMENT_DOCUMENT_SUCCESS'
export const DELETE_ARRANGEMENT_DOCUMENT_FAILED = 'DELETE_ARRANGEMENT_DOCUMENT_FAILED'
