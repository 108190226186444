// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  Grid,
  Paper
} from '@material-ui/core'
import { connect } from 'react-redux'
import Pagination from '@material-ui/lab/Pagination'

// Components.
import ArtistsTable from './ArtistsTable'
import Title from '../../../../UI/Brand/Title'
import AlertError from '../../../../UI/Alerts/AlertError'
import AlertSuccess from '../../../../UI/Alerts/AlertSuccess'
import CreateButton from '../../../../UI/Buttons/CreateButton'
import RefreshButton from '../../../../UI/Buttons/RefreshButton'
import TableFilters from '../../../../UI/Table/TableFilters'

// Shared.
import { applyFilter, calculateNumPages } from '../../../../../shared/functions'

// Redux actions.
import * as actions from '../../../../../store/actions'

// Styles.
import { useStyles } from '../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class ArtistList extends Component {
  state = {
    page: 1,
    orderBy: 'id',
    orderDirection: 'asc',
    filter: {
      name: {
        touched: false,
        operand: 'match',
        value: null,
        key: 'name',
        label: 'Nombre',
        type: 'text'
      }
    }
  }

  handlePageChange = (e, value) => {
    this.setState({ page: +value })
  }

  handleSort = (fieldName) => event => {
    // Create a copy of the state object.
    const newState = { ...this.state }
    // Set the order field.
    newState.orderBy = fieldName
    // Toggle the order direction.
    newState.orderDirection = this.state.orderBy === fieldName ? (newState.orderDirection === 'asc' ? 'desc' : 'asc') : 'asc'
    // Update the state.
    this.setState(newState)
  }

  onFilterChange = (elemKey, value, forceUntouched = false) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.filter[elemKey]
    }
    // Modify the filter value.
    newElement.value = value
    // Set as touched.
    newElement.touched = !forceUntouched
    // Update the state.
    this.setState({
      page: 1,
      filter: {
        ...this.state.filter,
        [elemKey]: newElement
      }
    })
  }

  componentDidMount () {
    this.props.loadArtists(this.props.token)
  }

  render () {
    // Load content dynamically.
    let content = null

    if (this.props.error) {
      // Handle error message.
      content = <AlertError error={this.props.error} />
    } else {
      // Filter results.
      const artists = applyFilter(this.props.artists, this.state.filter, this.state.orderBy, this.state.orderDirection)

      content = (
        <>
          <AlertSuccess message={this.props.success} />
          <TableFilters
            filters={this.state.filter}
            handleChange={this.onFilterChange}
          />
          <ArtistsTable
            loading={this.props.loading}
            artists={artists}
            deleteArtist={id => this.props.deleteArtist(id, this.props.token)}
            page={this.state.page}
            orderBy={this.state.orderBy}
            orderDirection={this.state.orderDirection}
            onSort={this.handleSort}
          />
          <Grid container spacing={3} className={this.props.classes.tableTools}>
            <Grid item xs={4}>
              <Pagination
                count={calculateNumPages(artists)}
                page={this.state.page}
                onChange={this.handlePageChange}
              />
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'center' }}>
              {artists.length} resultados
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <RefreshButton
                model='Artist'
                onClick={() => this.props.loadArtists(this.props.token)}
              />
              <CreateButton
                model='Artist'
                link='/artist/create'
              />
            </Grid>
          </Grid>
        </>
      )
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={this.props.classes.paper}>
            <div className='ArtistList'>
              <Title>Artistas</Title>
              {content}
            </div>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    loading: state.artists.loading,
    error: state.artists.error,
    artists: state.artists.artists,
    success: state.artists.success,
    selectedArtist: state.artists.selectedArtist
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadArtists: token => dispatch(actions.loadArtists(token)),
    deleteArtist: (id, token) => dispatch(actions.deleteArtist(id, token))
  }
}

// Style component.
const ArtistListStyled = props => {
  const classes = useStyles()
  return (
    <ArtistList classes={classes} {...props} />
  )
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(ArtistListStyled)
