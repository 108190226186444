// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import clsx from 'clsx'
import {
  AppBar,
  Avatar,
  Toolbar,
  Typography,
  IconButton,
  MenuItem,
  Menu,
  Link
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import { Link as RouterLink } from 'react-router-dom'
import { connect } from 'react-redux'

// Components.
import UserThumbnail from '../../../UI/Image/UserThumbnail'

// Styles.
import { useStyles } from '../../../styles'
import * as actions from '../../../../store/actions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class TopMenu extends Component {
  state = {
    myAccountAnchorEl: null
  }

  handleMyAccountMenuClick = (e) => {
    this.setState({
      myAccountAnchorEl: e.currentTarget
    })
  }

  handleMyAccountMenuClose = () => {
    this.setState({
      myAccountAnchorEl: null
    })
  }

  render () {
    return (
      <AppBar position='absolute' className={clsx(this.props.classes.appBar, this.props.sideMenuOpen && this.props.classes.appBarShift)}>
        <Toolbar className={this.props.classes.toolbar}>
          <IconButton
            edge='start'
            color='inherit'
            aria-label='open drawer'
            onClick={this.props.handleToggleSideMenuState}
            className={clsx(this.props.classes.menuButton, this.props.sideMenuOpen && this.props.classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Link component={RouterLink} to='/'>
            <Avatar alt='Tumerchan CRM' src='/logo192.png' className={this.props.classes.navAvatar} />
          </Link>
          <div className={this.props.classes.titleContainer}>
            <Link component={RouterLink} to='/' className={this.props.classes.titleLink}>
              <Typography component='h1' variant='h6' color='inherit' noWrap className={this.props.classes.title}>
                TuMerchan {this.props.user && this.props.user.artist_id ? 'for artists' : 'CRM'}
              </Typography>
            </Link>
          </div>
          <div>
            {this.props.user ? <Link component={RouterLink} to='/my-account' className={this.props.classes.topMenuLink}>{this.props.user.email}</Link> : null}
            <IconButton
              aria-label='My account'
              aria-controls='menu-appbar'
              aria-haspopup='true'
              onClick={this.handleMyAccountMenuClick}
              color='inherit'
            >
              <UserThumbnail user={this.props.user} token={this.props.token} />
            </IconButton>
            <Menu
              id='menu-appbar'
              anchorEl={this.state.myAccountAnchorEl}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right'
              }}
              open={!!this.state.myAccountAnchorEl}
              onClose={this.handleMyAccountMenuClose}
            >
              <MenuItem onClick={this.handleMyAccountMenuClose}>
                <Link component={RouterLink} to={this.props.user ? '/my-account' : null}>Mi cuenta</Link>
              </MenuItem>
              <MenuItem onClick={this.props.handleLogout}>Cerrar sesión</MenuItem>
            </Menu>
          </div>
        </Toolbar>
      </AppBar>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    sideMenuOpen: state.auth.sideMenuOpen
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    handleLogout: () => dispatch(actions.logout(true)),
    handleToggleSideMenuState: () => dispatch(actions.toggleSideMenuState())
  }
}

// Style component.
const TopMenuStyled = props => {
  const classes = useStyles()
  return <TopMenu classes={classes} {...props} />
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(TopMenuStyled)
