// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import * as _ from 'lodash'
import {
  Label,
  BarChart,
  AreaChart,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Legend,
  Brush,
  Area,
  Bar
} from 'recharts'
import {
  Grid,
  Button,
  Typography
} from '@material-ui/core'
import React, { Component } from 'react'

// Components.
import AlertInfo from '../Alerts/AlertInfo'
import { prettyPrintShortDate, tx } from '../../../shared/functions'

// =============================================================================
// Aux. functions.
// =============================================================================

// Bar colors.
const BAR_COLORS = [
  'rgb(120, 29, 66)',
  'rgb(244, 115, 64)',
  'rgb(239, 47, 136)',
  'rgb(136, 67, 242)'
]

// Default bar color.
const DEFAULT_BAR_COLOR = '#ccc'

// Get bar color.
const getBarColor = key => BAR_COLORS[+key] || DEFAULT_BAR_COLOR

// Lighten a color.
const lightenColor = color => color.replace(')', ', 0.6)')

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class TmChart extends Component {
  state = {
    type: 'bar'
  }

  setType = type => {
    this.setState({ type })
  }

  render () {
    if (!this.props.data) return null

    if (!this.props.data.length) {
      return <AlertInfo>No se han encontrado resultados.</AlertInfo>
    }

    const xAxisValues = _.keys(_.head(this.props.data)).filter(k => k !== 'date')

    // Format date.
    const data = []
    for (let i = 0; i < this.props.data.length; i++) {
      const date = prettyPrintShortDate(this.props.data[i].date)
      data.push({ ...this.props.data[i], date })
    }

    // Build chart.
    let chart = null
    switch (this.state.type) {
      case 'bar':
        chart = (
          <BarChart data={data} margin={{ left: 24 }}>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='date' />
            <YAxis>
              <Label position='left' angle={270} style={{ textAnchor: 'middle' }}>
                Total
              </Label>
            </YAxis>
            <Tooltip />
            <Brush dataKey='date' height={18} stroke={getBarColor(0)} />
            <Legend verticalAlign='top' wrapperStyle={{ lineHeight: '40px' }} />
            {
              xAxisValues.map((keyName, key) => <Bar key={key} dataKey={keyName} fill={getBarColor(key)} name={tx(keyName)} />)
            }
          </BarChart>
        )
        break
      case 'line':
        chart = (
          <AreaChart data={data} margin={{ left: 24 }}>
            <CartesianGrid strokeDasharray='3 3' />
            <XAxis dataKey='date' />
            <YAxis>
              <Label position='left' angle={270} style={{ textAnchor: 'middle' }}>
                Total
              </Label>
            </YAxis>
            <Tooltip />
            <Brush dataKey='date' height={18} stroke={getBarColor(0)} />
            <Legend verticalAlign='top' wrapperStyle={{ lineHeight: '40px' }} />
            {
              xAxisValues.map((keyName, key) => <Area key={key} type='monotone' dataKey={keyName} stroke={getBarColor(key)} fill={lightenColor(getBarColor(key))} name={tx(keyName)} />)
            }
          </AreaChart>
        )
        break
      default:
    }

    return (
      <Grid container style={{ marginBottom: 20 }}>
        <Grid item xs={12}>
          <Typography
            component='h3'
            style={{ textAlign: 'center', fontWeight: 'bold', marginBottom: 6 }}
          >
            {this.props.title}
          </Typography>
          <div className='ChartTypeButtons' style={{ textAlign: 'center', marginBottom: 15 }}>
            <Button color={this.state.type === 'bar' ? 'primary' : 'default'} variant='contained' size='small' onClick={() => this.setType('bar')} style={{ padding: 0, marginRight: 5 }}>Barras</Button>
            <Button color={this.state.type === 'line' ? 'primary' : 'default'} variant='contained' size='small' onClick={() => this.setType('line')} style={{ padding: 0 }}>Líneas</Button>
          </div>
          <ResponsiveContainer width='100%' height={this.props.height || 280}>
            {chart}
          </ResponsiveContainer>
        </Grid>
      </Grid>
    )
  }
}

// Export.
export default TmChart
