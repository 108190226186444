// ============================================================================
// Dependencies.
// ============================================================================

// Actions.
import * as actions from '../actions/types'

// Shared.
import { updateObject } from '../../shared/functions'

// ============================================================================
// Initial state.
// ============================================================================

// Initial state.
const initialState = {
  loading: true,
  error: null,
  success: null,
  posTerminals: [],
  selectedPosTerminal: null
}

// ============================================================================
// Functionality.
// ============================================================================

// Start loading list of POS terminals.
const loadPosTerminalsStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    success: null,
    posTerminals: []
  })
}

// Finish loading list of POS terminals.
const loadPosTerminalsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    posTerminals: action.posTerminals,
    error: null,
    success: null
  })
}

// Finish loading list of POS terminals, with error.
const loadPosTerminalsFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    posTerminals: [],
    error: action.error,
    success: null
  })
}

// Start deleting POS terminal.
const deletePosTerminalStart = state => {
  return updateObject(state, {
    loading: true,
    success: null,
    error: null
  })
}

// Delete a POS terminal successfully.
const deletePosTerminalSuccess = (state, action) => {
  const oldPosTerminalIdx = state.posTerminals.findIndex(e => +e.id === +action.id)
  const newPosTerminals = [...state.posTerminals]
  newPosTerminals.splice(oldPosTerminalIdx, 1)
  return updateObject(state, {
    loading: false,
    posTerminals: newPosTerminals,
    error: null,
    success: `Se ha eliminado el TPV #${action.id} correctamente.`
  })
}

// Delete POS terminal, with error.
const deletePosTerminalFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    success: null
  })
}

// Update the selected POS terminal.
const updateSelectedPosTerminal = (state, action) => {
  const newSelectedPosTerminal = {
    ...state.selectedPosTerminal
  }
  newSelectedPosTerminal[action.key] = action.value
  return updateObject(state, {
    selectedPosTerminal: newSelectedPosTerminal
  })
}

// Start saving a POS terminal.
const savePosTerminalStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    success: null
  })
}

// Save a POS terminal successfully.
const savePosTerminalSuccess = (state, action) => {
  const newPosTerminals = [
    ...state.posTerminals
  ]
  if (action.id) {
    const updatedPosTerminalIdx = state.posTerminals.findIndex(s => +s.id === +action.id)
    newPosTerminals[updatedPosTerminalIdx] = { ...action.posTerminal }
  } else {
    newPosTerminals.push(action.posTerminal)
  }
  return updateObject(state, {
    loading: false,
    error: null,
    success: action.id ? 'TPV actualizado correctamente.' : 'TPV creado correctamente.',
    posTerminals: newPosTerminals,
    selectedPosTerminal: action.posTerminal
  })
}

// Save a POS terminal, with error.
const savePosTerminalFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    success: null
  })
}

// ============================================================================
// Reducer definition.
// ============================================================================

// Reducer definition.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_POS_TERMINALS_START:
      return loadPosTerminalsStart(state, action)
    case actions.LOAD_POS_TERMINALS_SUCCESS:
      return loadPosTerminalsSuccess(state, action)
    case actions.LOAD_POS_TERMINALS_FAILED:
      return loadPosTerminalsFailed(state, action)
    case actions.DELETE_POS_TERMINAL_START:
      return deletePosTerminalStart(state, action)
    case actions.DELETE_POS_TERMINAL_SUCCESS:
      return deletePosTerminalSuccess(state, action)
    case actions.DELETE_POS_TERMINAL_FAILED:
      return deletePosTerminalFailed(state, action)
    case actions.UPDATE_SELECTED_POS_TERMINAL:
      return updateSelectedPosTerminal(state, action)
    case actions.SAVE_POS_TERMINAL_START:
      return savePosTerminalStart(state, action)
    case actions.SAVE_POS_TERMINAL_SUCCESS:
      return savePosTerminalSuccess(state, action)
    case actions.SAVE_POS_TERMINAL_FAILED:
      return savePosTerminalFailed(state, action)
    default:
      return state
  }
}

export default reducer
