// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import { React, Component } from 'react'
import {
  BrowserRouter as Router,
  Switch,
  Route
} from 'react-router-dom'
import { connect } from 'react-redux'
import { CircularProgress } from '@material-ui/core'

// CSS.
import './App.css'

// Components.
import AuthPage from './Auth/AuthPage'
import RecoverPass from './Auth/RecoverPass'
import RecoveryPage from './Auth/RecoveryPage'
import TumerchanCrm from './TumerchanCrm/TumerchanCrm'
import ErrorPage from './ErrorPage/ErrorPage'
import Offline from './UnavailablePage/Offline/Offline'
import Maintenance from './UnavailablePage/Maintenance/Maintenance'

// Redux actions.
import * as actions from '../store/actions/index'

// Shared.
import { userCan } from '../shared/functions'

// =============================================================================
// Definition of routes.
// =============================================================================

// Routes.
const routes = [
  {
    path: '/',
    exact: true,
    render: () => <TumerchanCrm section='/' />
  },
  {
    path: '/login',
    exact: true,
    component: AuthPage
  },
  {
    path: '/recovery',
    exact: true,
    component: RecoverPass
  },
  {
    path: '/recover',
    exact: true,
    component: RecoveryPage
  },
  {
    path: '/site/:id_site/category/:id_category/product/:id',
    exact: false,
    permissions: { model: 'Product', action: 'read' },
    render: () => <TumerchanCrm section='/sites' form subsection='/products' extraParam1='id_site' extraParam2='id_category' />
  },
  {
    path: '/site/:id_site/category/:id',
    exact: false,
    permissions: { model: 'Category', action: 'read' },
    render: () => <TumerchanCrm section='/sites' form subsection='/categories' extraParam1='id_site' />
  },
  {
    path: '/site/:id/sales',
    exact: false,
    permissions: { model: 'Site', action: 'read' },
    render: () => <TumerchanCrm section='/sites' subsection='/sales' form />
  },
  {
    path: '/site/create',
    exact: true,
    permissions: { model: 'Site', action: 'create' },
    render: () => <TumerchanCrm section='/sites' form />
  },
  {
    path: '/site/:id',
    exact: false,
    permissions: { model: 'Site', action: 'read' },
    render: () => <TumerchanCrm section='/sites' subsection='/form' form />
  },
  {
    path: '/sites',
    exact: true,
    permissions: { model: 'Site', action: 'list' },
    render: () => <TumerchanCrm section='/sites' list />
  },
  {
    path: '/artist/:id/expenses',
    exact: false,
    permissions: { model: 'Expense', action: 'list' },
    render: () => <TumerchanCrm section='/artists' subsection='/expenses' list />
  },
  {
    path: '/artist/:id/earnings',
    exact: false,
    permissions: { model: 'Earning', action: 'list' },
    render: () => <TumerchanCrm section='/artists' subsection='/earnings' list />
  },
  {
    path: '/artist/:id/settlements',
    exact: false,
    permissions: { model: 'Settlement', action: 'list' },
    render: () => <TumerchanCrm section='/artists' subsection='/settlements' list />
  },
  {
    path: '/artist/:id/data_sources',
    exact: false,
    permissions: { model: 'DataSource', action: 'list' },
    render: () => <TumerchanCrm section='/artists' subsection='/data_sources' list />
  },
  {
    path: '/artist/:id/arrangements',
    exact: false,
    permissions: { model: 'Arrangement', action: 'list' },
    render: () => <TumerchanCrm section='/artists' subsection='/arrangements' list />
  },
  {
    path: '/artist/:id/arrangement/create',
    exact: false,
    permissions: { model: 'Arrangement', action: 'create' },
    render: () => <TumerchanCrm section='/artists' subsection='/arrangements' form />
  },
  {
    path: '/artist/:id/arrangement/:id_arrangement',
    exact: false,
    permissions: { model: 'Arrangement', action: 'read' },
    render: () => <TumerchanCrm section='/artists' subsection='/arrangements' form extraParam1='id_arrangement' />
  },
  {
    path: '/artist/create',
    exact: true,
    permissions: { model: 'Artist', action: 'create' },
    render: () => <TumerchanCrm section='/artists' form />
  },
  {
    path: '/artist/:id',
    exact: false,
    permissions: { model: 'Artist', action: 'read' },
    render: () => <TumerchanCrm section='/artists' subsection='/form' form />
  },
  {
    path: '/artists',
    exact: true,
    permissions: { model: 'Artist', action: 'list' },
    render: () => <TumerchanCrm section='/artists' list />
  },
  {
    path: '/sales_reports',
    exact: true,
    permissions: { model: 'SalesReport', action: 'list' },
    render: () => <TumerchanCrm section='/sales_reports' list />
  },
  {
    path: '/sales_report/create',
    exact: true,
    permissions: { model: 'SalesReport', action: 'create' },
    render: () => <TumerchanCrm section='/sales_reports' form />
  },
  {
    path: '/warehouse/stock_withdrawals',
    exact: true,
    permissions: { model: 'StockMovement', action: 'list' },
    render: () => <TumerchanCrm section='/warehouse' subsection='/stock_withdrawals' list />
  },
  {
    path: '/warehouse/stock_withdrawals/create',
    exact: true,
    permissions: { model: 'StockMovement', action: 'create' },
    render: () => <TumerchanCrm section='/warehouse' subsection='/stock_withdrawals' form />
  },
  {
    path: '/warehouse/stock_entries',
    exact: true,
    permissions: { model: 'StockMovement', action: 'list' },
    render: () => <TumerchanCrm section='/warehouse' subsection='/stock_entries' list />
  },
  {
    path: '/warehouse/stock_entries/create',
    exact: true,
    permissions: { model: 'StockMovement', action: 'create' },
    render: () => <TumerchanCrm section='/warehouse' subsection='/stock_entries' form />
  },
  {
    path: '/warehouse/inventory',
    exact: true,
    permissions: { model: 'Product', action: 'search' },
    render: () => <TumerchanCrm section='/warehouse' subsection='/inventory' list />
  },
  {
    path: '/users',
    exact: true,
    permissions: { model: 'User', action: 'list' },
    render: () => <TumerchanCrm section='/users' list />
  },
  {
    path: '/user/create',
    exact: true,
    permissions: { model: 'User', action: 'create' },
    render: () => <TumerchanCrm section='/users' form />
  },
  {
    path: '/user/:id',
    exact: false,
    permissions: { model: 'User', action: 'read' },
    render: () => <TumerchanCrm section='/users' form />
  },
  {
    path: '/my-account',
    exact: false,
    render: () => <TumerchanCrm section='/my-account' form />
  },
  {
    path: '/settings/generic',
    exact: true,
    permissions: { model: 'Configuration', action: 'list' },
    render: () => <TumerchanCrm section='/settings' subsection='/generic_settings' list />
  },
  {
    path: '/settings/expense_types',
    exact: true,
    permissions: { model: 'ExpenseType', action: 'list' },
    render: () => <TumerchanCrm section='/settings' subsection='/expense_types' list />
  },
  {
    path: '/settings/earning_types',
    exact: true,
    permissions: { model: 'EarningType', action: 'list' },
    render: () => <TumerchanCrm section='/settings' subsection='/earning_types' list />
  },
  {
    path: '/settings/sales',
    exact: true,
    permissions: { model: 'Sale', action: 'list' },
    render: () => <TumerchanCrm section='/settings' subsection='/sales' list />
  },
  {
    path: '/settings/pos_terminals',
    exact: true,
    permissions: { model: 'PosTerminal', action: 'list' },
    render: () => <TumerchanCrm section='/settings' subsection='/pos_terminals' list />
  },
  {
    path: '/product_scheduled_discount/create',
    exact: true,
    permissions: { model: 'ProductScheduledDiscount', action: 'create' },
    render: () => <TumerchanCrm section='/product_scheduled_discounts' form />
  },
  {
    path: '/product_scheduled_discounts',
    exact: true,
    permissions: { model: 'ProductScheduledDiscount', action: 'list' },
    render: () => <TumerchanCrm section='/product_scheduled_discounts' list />
  },
  {
    path: '/docs',
    exact: true,
    render: () => <TumerchanCrm section='/docs' />
  }
]

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class App extends Component {
  componentDidMount () {
    // Try auto-login.
    this.props.checkInitialState()
  }

  render () {
    // Loading state.
    if (this.props.loading) {
      return <div className='GlobalLoader'><CircularProgress size={60} color='inherit' /></div>
    }

    // Check maintenance mode.
    if (this.props.maintenanceMode) {
      return <Maintenance />
    }

    // Check offline mode.
    if (this.props.offlineMode) {
      return <Offline />
    }

    return (
      <div className='App'>
        <Router>
          <Switch>
            {
              routes.map((route, key) => {
                if (route.permissions && this.props.user && !userCan(this.props.user, route.permissions.model, route.permissions.action)) {
                  return null
                }
                return (
                  <Route
                    key={key}
                    path={route.path}
                    exact={route.exact}
                    component={route.component}
                    render={route.render}
                  />
                )
              })
            }
            <Route render={() => <ErrorPage status='404' />} />
          </Switch>
        </Router>
      </div>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    isAuth: state.auth.user !== null,
    loading: state.auth.loading,
    maintenanceMode: state.auth.maintenanceMode,
    offlineMode: state.auth.offlineMode,
    user: state.auth.user
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    checkInitialState: () => dispatch(actions.checkInitialState())
  }
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(App)
