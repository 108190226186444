// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { connect } from 'react-redux'

// Redux actions.
import * as actions from '../../../../../../store/actions'

// Components.
import AlertInfo from '../../../../../UI/Alerts/AlertInfo'
import FormField from '../../../../../UI/Form/FormField'
import SaveButton from '../../../../../UI/Buttons/SaveButton'
import EarningTypesTableRow from './EarningTypesTableRow'
import TableColumnTitle from '../../../../../UI/Table/TableColumnTitle'
import TableLoader from '../../../../../UI/Loaders/TableLoader'

// Shared.
import { RESULTS_PER_PAGE } from '../../../../../../shared/constants'

// Style.
import { useStyles } from '../../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Table columns.
const columns = [
  { name: 'id', label: 'ID' },
  { name: 'name', label: 'Nombre' },
  { name: 'created_at', label: 'Fecha de creación (UTC)' },
  { name: 'updated_at', label: 'Fecha de modificación (UTC)' },
  { name: 'actions', label: '' }
]

// Stateful component declaration.
class EarningTypesTable extends Component {
  state = {
    fields: {
      name: {
        label: 'Nombre',
        error: null,
        type: 'text',
        required: true,
        touched: false,
        validation: val => val.length >= 2,
        errMsg: 'Por favor, proporciona un nombre válido.',
        autoFocus: false
      }
    }
  }

  handleChange = (elemKey, newValue) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields[elemKey]
    }
    // Modify the element value.
    newElement.value = newValue
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.required && !newValue.length && newElement.touched) {
      newElement.error = 'Campo obligatorio'
    } else if (newElement.validation && newValue.length && !newElement.validation(newValue)) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Build new state.
    const newState = {
      fields: {
        ...this.state.fields,
        [elemKey]: newElement
      }
    }
    // Update the state.
    this.setState(newState)
  }

  handleCreateEarningType = () => {
    // Save the earning type.
    this.props.saveEarningType(null, this.props.token, this.state.fields.name.value)
    // Reset the form.
    const newStateFields = {
      ...this.state.fields
    }
    newStateFields.name.value = ''
    this.setState({
      fields: newStateFields
    })
    // Close the form.
    this.props.handleFormClose()
  }

  shouldSaveButtonBeDisabled = () => {
    return this.props.loading ||
      !this.state.fields.name.value ||
      this.state.fields.name.value === ''
  }

  render () {
    // Loading.
    if (this.props.loading) {
      return <TableLoader cols={columns} />
    }

    if ((!this.props.earningTypes || !this.props.earningTypes.length) && !this.props.creating) {
      return (
        <AlertInfo>
          Aún no se han configurado categorías de ingreso.
        </AlertInfo>
      )
    }

    const earningTypesList = []
    const minLimit = (this.props.page - 1) * RESULTS_PER_PAGE
    const maxLimit = Math.min(this.props.earningTypes.length, this.props.page * RESULTS_PER_PAGE)

    for (let i = minLimit; i < maxLimit; i++) {
      const earningType = this.props.earningTypes[i]

      earningTypesList.push(<EarningTypesTableRow
        key={i}
        id={earningType.id}
        name={earningType.name}
        createdAt={earningType.created_at}
        updatedAt={earningType.updated_at}
        loading={this.props.loading}
        onDeleteEarningType={() => this.props.deleteEarningType(earningType.id, this.props.token)}
        handleSaveEarningType={name => this.props.saveEarningType(earningType.id, this.props.token, name)}
                            />)
    }

    if (this.props.creating) {
      earningTypesList.push(
        <TableRow key='new'>
          <TableCell className={this.props.classes.tableCellForm}>
            <IconButton color='default' aria-label='undo' size='small' onClick={this.props.handleFormClose}>
              <ClearIcon />
            </IconButton>
          </TableCell>
          <TableCell className={this.props.classes.tableCellForm} style={{ width: 240 }}>
            <FormField
              noMargin
              fullWidth
              name='name'
              type={this.state.fields.name.type}
              autoFocus={this.state.fields.name.autofocus}
              required={this.state.fields.name.required}
              label={this.state.fields.name.label}
              value={this.state.fields.name.value}
              handleChange={e => this.handleChange('name', e.target.value)}
            />
          </TableCell>
          <TableCell className={this.props.classes.tableCellForm} />
          <TableCell className={this.props.classes.tableCellForm} />
          <TableCell className={this.props.classes.tableCellForm}>
            <SaveButton
              action='create'
              model='EarningType'
              disabled={this.shouldSaveButtonBeDisabled()}
              onClick={this.handleCreateEarningType}
            />
          </TableCell>
        </TableRow>
      )
    }

    return (
      <Table size='small'>
        <TableHead>
          <TableRow>
            {
              columns.map((col, key) => <TableColumnTitle key={key} {...col} />)
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {earningTypesList}
        </TableBody>
      </Table>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    deleteEarningType: (id, token) => dispatch(actions.deleteEarningType(id, token)),
    loadEarningTypes: token => dispatch(actions.loadEarningTypes(token)),
    saveEarningType: (id, token, name) => dispatch(actions.saveEarningType(id, token, { name }))
  }
}

// Style component.
const EarningTypesTableStyled = props => {
  const classes = useStyles()
  return <EarningTypesTable classes={classes} {...props} />
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(EarningTypesTableStyled)
