// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'

// Components.
import TabSection from '../../../../UI/Custom/TabSection'
import SiteForm from './SiteForm'
import SiteSales from './Sales/SiteSalesList'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const Site = props => {
  const tabs = {
    form: { label: 'Información del sitio web', component: <SiteForm siteId={props.id} />, link: `/site/${props.id}` },
    sales: { label: 'Períodos de rebajas', component: <SiteSales siteId={props.id} />, link: `/site/${props.id}/sales`, disabled: !props.id }
  }

  return <TabSection tabs={tabs} {...props} />
}

// Export.
export default Site
