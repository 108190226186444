// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  Paper
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'

// Components.
import Title from '../../../../../UI/Brand/Title'
import AlertSuccess from '../../../../../UI/Alerts/AlertSuccess'
import AlertError from '../../../../../UI/Alerts/AlertError'
import AddButton from '../../../../../UI/Buttons/AddButton'
import RefreshButton from '../../../../../UI/Buttons/RefreshButton'
import ArrangementsTable from './ArrangementsTable'

// Redux actions.
import * as actions from '../../../../../../store/actions'

// Shared.
import { calculateNumPages } from '../../../../../../shared/functions'

// Styles.
import { useStyles } from '../../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class ArtistForm extends Component {
  state = {
    page: 1
  }

  componentDidMount () {
    // Load the artist data sources.
    this.props.loadArtistDataSources(this.props.artistId, this.props.token)
    // Load the artist arrangements.
    this.props.loadArtistArrangements(this.props.artistId, this.props.token)
  }

  handlePageChange = (e, value) => {
    this.setState({ page: +value })
  }

  render () {
    return (
      <Grid item xs={12}>
        <Paper className={this.props.classes.paper}>
          <div className='ArtistArrangements'>
            <Title>Tratos comerciales</Title>
            <AlertError error={this.props.arrangementsError} />
            <AlertSuccess message={this.props.arrangementsSuccess} />
            <ArrangementsTable
              dataSources={this.props.dataSources}
              artistId={this.props.artistId}
              arrangements={this.props.arrangements}
              loading={this.props.dataSourcesLoading || this.props.arrangementsLoading}
              page={this.state.page}
              deleteArrangement={id => this.props.deleteArrangement(id, this.props.token)}
            />
            <Grid container spacing={3} className={this.props.classes.tableTools}>
              <Grid item xs={4}>
                {
                  this.props.arrangements && this.props.arrangements.length
                    ? <Pagination
                        count={calculateNumPages(this.props.arrangements)}
                        page={this.state.page}
                        onChange={this.handlePageChange}
                      />
                    : null
                }
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'center' }}>
                {this.props.arrangements ? this.props.arrangements.length : 0} resultados
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                <RefreshButton
                  model='Arrangement'
                  onClick={() => this.props.loadArtistArrangements(this.props.artistId, this.props.token)}
                />
                <AddButton
                  model='Arrangement'
                  link={`/artist/${this.props.artistId}/arrangement/create`}
                  disabled={!this.props.dataSources || !this.props.dataSources.length}
                />
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    dataSources: state.artists.dataSources,
    arrangements: state.artists.arrangements,
    dataSourcesLoading: state.artists.dataSourcesLoading,
    arrangementsLoading: state.artists.arrangementsLoading,
    arrangementsError: state.artists.arrangementsError,
    arrangementsSuccess: state.artists.arrangementsSuccess
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadArtistDataSources: (id, token) => dispatch(actions.loadArtistDataSources(id, token)),
    loadArtistArrangements: (id, token) => dispatch(actions.loadArtistArrangements(id, token)),
    deleteArrangement: (id, token) => dispatch(actions.deleteArrangement(id, token))
  }
}

// Style component.
const ArtistFormStyled = props => {
  const classes = useStyles()
  return (
    <ArtistForm classes={classes} {...props} />
  )
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(ArtistFormStyled)
