// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
  Table,
  TableBody,
  TableHead,
  TableRow
} from '@material-ui/core'

// Components.
import AlertInfo from '../../../../../UI/Alerts/AlertInfo'
import ProductScheduledDiscountsTableRow from './ProductScheduledDiscountsTableRow'
import TableColumnTitle from '../../../../../UI/Table/TableColumnTitle'
import TableLoader from '../../../../../UI/Loaders/TableLoader'

// Shared.
import { RESULTS_PER_PAGE } from '../../../../../../shared/constants'

// =============================================================================
// Component declaration.
// =============================================================================

// Table columns.
const columns = [
  { name: 'id', label: 'ID', sortable: true },
  { name: 'product_id', label: 'Producto', sortable: true },
  { name: 'site_id', label: 'Sitio web', sortable: true },
  { name: 'type', label: 'Tipo', sortable: true },
  { name: 'discount', label: 'Descuento', sortable: true },
  { name: 'status', label: 'Estado', sortable: true },
  { name: 'date_from', label: 'Desde (UTC)', sortable: true },
  { name: 'date_to', label: 'Hasta (UTC)', sortable: true },
  { name: 'activated_at', label: 'Activo desde (UTC)', sortable: true },
  { name: 'expired_at', label: 'Inactivo desde (UTC)', sortable: true },
  { name: 'user_id', label: 'Creado por', sortable: true },
  { name: 'created_at', label: 'Fecha de creación (UTC)', sortable: true },
  { name: 'updated_at', label: 'Fecha de modificación (UTC)', sortable: true },
  { name: 'actions', label: '' }
]

// Stateless component declaration.
const ProductScheduledDiscountsTable = props => {
  // Loading.
  if (props.loading) {
    return <TableLoader cols={columns} />
  }

  // No results.
  if (!props.productScheduledDiscounts.length) {
    return <AlertInfo>No se han encontrado resultados.</AlertInfo>
  }

  const productScheduledDiscountsTable = []
  const minLimit = (props.page - 1) * RESULTS_PER_PAGE
  const maxLimit = Math.min(props.productScheduledDiscounts.length, props.page * RESULTS_PER_PAGE)

  for (let i = minLimit; i < maxLimit; i++) {
    const productScheduledDiscount = props.productScheduledDiscounts[i]
    productScheduledDiscountsTable.push(
      <ProductScheduledDiscountsTableRow
        key={i}
        productScheduledDiscount={productScheduledDiscount}
        deleteProductScheduledDiscount={props.deleteProductScheduledDiscount}
        saveProductScheduledDiscount={props.saveProductScheduledDiscount}
        activateProductScheduledDiscount={props.activateProductScheduledDiscount}
        expireProductScheduledDiscount={props.expireProductScheduledDiscount}
        loading={props.loading}
      />
    )
  }

  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          {
            columns.map((col, key) => <TableColumnTitle key={key} {...col} orderBy={props.orderBy} orderDirection={props.orderDirection} onSort={props.onSort} />)
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {productScheduledDiscountsTable}
      </TableBody>
    </Table>
  )
}

// Export.
export default ProductScheduledDiscountsTable
