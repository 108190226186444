// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import { Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { connect } from 'react-redux'

// Shared.
import { userCan } from '../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class ProductLink extends Component {
  render () {
    // Return null if wrong props.
    if (!this.props.product || !this.props.product.id || !this.props.site_id) return null
    // Return raw text if the user doesn't have permissions.
    if (!userCan(this.props.user, 'Product', 'read')) {
      return this.props.product.name
    }

    if (this.props.remote) {
      return (
        <Link component='a' href={`/site/${this.props.site_id}/category/${this.props.product.category_id}/product/${this.props.product.id}`}>{this.props.product.name}</Link>
      )
    } else {
      return (
        <Link component={RouterLink} to={`/site/${this.props.site_id}/category/${this.props.product.category_id}/product/${this.props.product.id}`}>{this.props.product.name}</Link>
      )
    }
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

// Export.
export default connect(mapStateToProps)(ProductLink)
