// ============================================================================
// Dependencies.
// ============================================================================

// Actions.
import * as actions from '../actions/types'

// Shared.
import { updateObject } from '../../shared/functions'

// ============================================================================
// Initial state.
// ============================================================================

// Initial state.
const initialState = {
  loading: true,
  error: null,
  success: null,
  sales: []
}

// ============================================================================
// Functionality.
// ============================================================================

// Start loading list of sales.
const loadSalesStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    success: null,
    sales: []
  })
}

// Finish loading list of sales.
const loadSalesSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    sales: action.sales,
    error: null,
    success: null
  })
}

// Finish loading list of sales, with error.
const loadSalesFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    sales: [],
    error: action.error,
    success: null
  })
}

// Start deleting sale.
const deleteSaleStart = state => {
  return updateObject(state, {
    loading: true,
    success: null,
    error: null
  })
}

// Delete an sale successfully.
const deleteSaleSuccess = (state, action) => {
  const oldSaleIdx = state.sales.findIndex(e => +e.id === +action.id)
  const newSales = [...state.sales]
  newSales.splice(oldSaleIdx, 1)
  return updateObject(state, {
    loading: false,
    sales: newSales,
    error: null,
    success: `Se ha eliminado el período de rebajas #${action.id} correctamente.`
  })
}

// Delete sale, with error.
const deleteSaleFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    success: null
  })
}

// Start saving an sale.
const saveSaleStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    success: null
  })
}

// Save an sale successfully.
const saveSaleSuccess = (state, action) => {
  const newSales = [
    ...state.sales
  ]
  if (action.id) {
    const updatedSaleIdx = state.sales.findIndex(s => +s.id === +action.id)
    newSales[updatedSaleIdx] = { ...action.sale }
  } else {
    newSales.push(action.sale)
  }
  return updateObject(state, {
    loading: false,
    error: null,
    success: action.id ? 'Período de rebajas actualizado correctamente.' : 'Período de rebajas creado correctamente.',
    sales: newSales
  })
}

// Save an sale, with error.
const saveSaleFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    success: null
  })
}

// ============================================================================
// Reducer definition.
// ============================================================================

// Reducer definition.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_SALES_START:
      return loadSalesStart(state, action)
    case actions.LOAD_SALES_SUCCESS:
      return loadSalesSuccess(state, action)
    case actions.LOAD_SALES_FAILED:
      return loadSalesFailed(state, action)
    case actions.DELETE_SALE_START:
      return deleteSaleStart(state, action)
    case actions.DELETE_SALE_SUCCESS:
      return deleteSaleSuccess(state, action)
    case actions.DELETE_SALE_FAILED:
      return deleteSaleFailed(state, action)
    case actions.SAVE_SALE_START:
      return saveSaleStart(state, action)
    case actions.SAVE_SALE_SUCCESS:
      return saveSaleSuccess(state, action)
    case actions.SAVE_SALE_FAILED:
      return saveSaleFailed(state, action)
    default:
      return state
  }
}

export default reducer
