// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import { Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { connect } from 'react-redux'

// Shared.
import { canSeeArtistContent, userCan } from '../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class ArtistLink extends Component {
  render () {
    // Return null if wrong props.
    if (!this.props.artist || !this.props.artist.name) return null
    // Return raw text if the user doesn't have permissions.
    if (!userCan(this.props.user, 'Artist', 'read') || !canSeeArtistContent(this.props.user, this.props.artist.id)) return this.props.artist.name

    return (
      <Link component={RouterLink} to={`/artist/${this.props.artist.id}`}>{this.props.artist.name}</Link>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

// Export.
export default connect(mapStateToProps)(ArtistLink)
