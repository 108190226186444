// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import { Grid, Paper } from '@material-ui/core'
import { connect } from 'react-redux'

// Components.
import Title from '../../../../UI/Brand/Title'
import UnderConstruction from '../../../../UI/Maintenance/UnderConstruction/UnderConstruction'

// Style.
import { useStyles } from '../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class Docs extends Component {
  render () {
    const docs = <UnderConstruction />

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={this.props.classes.paper}>
            <div className='UserList'>
              <Title>Documentación de usuario</Title>
              {docs}
            </div>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

// Style component.
const DocsStyled = props => {
  const classes = useStyles()
  return <Docs classes={classes} {...props} />
}

// Export.
export default connect(mapStateToProps)(DocsStyled)
