// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { Chip, Typography, Tooltip } from '@material-ui/core'

// Shared.
import { printPrice, prettyPrintDate } from '../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const Earning = props => {
  let settled = false
  let settlementDate = null
  let totalSettled = 0
  if (typeof props.settlements === 'object' && props.settlements.length) {
    const lastSettlement = props.settlements[props.settlements.length - 1]
    settlementDate = prettyPrintDate(lastSettlement.created_at)
    totalSettled = +lastSettlement.total_earning
    settled = true
  }

  let variant = 'outlined'
  let color = 'default'
  let text = null
  if (settled) {
    variant = 'default'
    color = 'primary'
    text = (
      <div>
        <Tooltip title={`Fecha: ${settlementDate}`} arrow placement='right'>
          <Typography variant='caption' color='textSecondary' align='center' style={{ fontSize: '0.8em' }}>100% liquidado{+totalSettled !== +props.total ? ` (${printPrice(+totalSettled)})` : null}</Typography>
        </Tooltip>
      </div>
    )
  }

  return (
    <>
      <Chip
        variant={variant}
        color={color}
        size='small'
        label={printPrice(+props.total)}
      />
      {text}
    </>
  )
}

// Export.
export default Earning
