// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import { Button } from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'
import { Link as RouterLink } from 'react-router-dom'
import { connect } from 'react-redux'

// Shared.
import { userCan } from '../../../shared/functions'

// Style.
import { useStyles } from '../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class AddButton extends Component {
  render () {
    const label = this.props.label ? this.props.label : 'Añadir'
    const action = this.props.action ? this.props.action : 'create'

    if (!userCan(this.props.user, this.props.model, action)) {
      return null
    }

    const commonProps = {
      className: this.props.classes.saveButton,
      variant: 'outlined',
      color: 'default',
      startIcon: <AddIcon />,
      style: this.props.style
    }

    if (this.props.link) {
      return (
        <Button {...commonProps} component={RouterLink} to={this.props.link}>
          {label}
        </Button>
      )
    } else if (this.props.onClick) {
      return (
        <Button {...commonProps} onClick={this.props.onClick}>
          {label}
        </Button>
      )
    }
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

// Style component.
const AddButtonStyled = props => {
  const classes = useStyles()
  return <AddButton classes={classes} {...props} />
}

// Export.
export default connect(mapStateToProps)(AddButtonStyled)
