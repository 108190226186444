// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  IconButton,
  TableCell,
  TableRow
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'

// Components.
import FormField from '../../../../../UI/Form/FormField'
import RowActions from '../../../../../UI/Table/RowActions'
import BooleanField from '../../../../../UI/Custom/BooleanField'
import SaveButton from '../../../../../UI/Buttons/SaveButton'

// Shared.
import {
  prettyPrintDateTime,
  formatDateTimePickerDate
} from '../../../../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class SalesTableRow extends Component {
  state = {
    editing: false,
    fields: {
      name: {
        label: 'Nombre',
        error: null,
        type: 'text',
        required: true,
        touched: false,
        validation: val => val.length >= 2,
        errMsg: 'Por favor, proporciona un nombre válido.',
        autoFocus: false
      },
      date_from: {
        label: 'Fecha de inicio',
        error: null,
        type: 'datetime',
        required: true,
        touched: false,
        errMsg: 'Por favor, selecciona una fecha válida.',
        autoFocus: false
      },
      date_to: {
        label: 'Fecha de finalización',
        error: null,
        type: 'datetime',
        required: true,
        touched: false,
        errMsg: 'Por favor, selecciona una fecha válida.',
        autoFocus: false
      }
    }
  }

  onInlineUpdateStart = () => {
    const newFields = {
      name: {
        ...this.state.fields.name,
        value: this.props.sale.name
      },
      date_from: {
        ...this.state.fields.date_from,
        value: formatDateTimePickerDate(this.props.sale.date_from)
      },
      date_to: {
        ...this.state.fields.date_to,
        value: formatDateTimePickerDate(this.props.sale.date_to)
      }
    }
    this.setState({ editing: true, fields: newFields })
  }

  handleStopInlineUpdate = () => {
    this.setState({ editing: false })
  }

  handleChange = (elemKey, newValue) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields[elemKey]
    }
    // Modify the element value.
    newElement.value = newValue
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.required && (newElement.type === 'select' ? !newValue : !newValue.length) && newElement.touched) {
      newElement.error = 'Campo obligatorio'
    } else if (newElement.validation && newValue.length && !newElement.validation(newValue)) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Build new state.
    const newState = {
      ...this.state,
      fields: {
        ...this.state.fields,
        [elemKey]: newElement
      }
    }
    // Update the state.
    this.setState(newState)
  }

  shouldSaveButtonBeDisabled = () => {
    return this.props.loading ||
      !this.state.fields.name.value ||
      this.state.fields.name.value === '' ||
      !this.state.fields.date_from.value ||
      this.state.fields.date_from.value === '' ||
      !this.state.fields.date_to.value ||
      this.state.fields.date_to.value === ''
  }

  handleSave = () => {
    this.props.saveSale(this.props.sale.id, { name: this.state.fields.name.value, date_from: this.state.fields.date_from.value, date_to: this.state.fields.date_to.value })
    this.handleStopInlineUpdate()
  }

  render () {
    return (
      <TableRow key={this.props.sale.id}>
        <TableCell>
          {
            this.state.editing
              ? (
                <IconButton color='default' aria-label='undo' size='small' onClick={this.handleStopInlineUpdate}>
                  <ClearIcon />
                </IconButton>
                )
              : this.props.sale.id
          }
        </TableCell>
        {
          ['name', 'date_from', 'date_to'].map((fieldName, key) => {
            const field = this.state.fields[fieldName]
            return (
              <TableCell key={key}>
                {
                  this.state.editing
                    ? <FormField
                        name={fieldName}
                        type={field.type}
                        autoFocus={field.autoFocus}
                        required={field.required}
                        error={field.error}
                        label={field.label}
                        value={field.value}
                        fullWidth
                        handleChange={e => this.handleChange(fieldName, e.target.value)}
                        options={field.options}
                        helperText={field.helperText}
                      />
                    : field.type === 'date' ? prettyPrintDateTime(this.props.sale[fieldName]) : this.props.sale[fieldName]
                }
              </TableCell>
            )
          })
        }
        <TableCell>
          {
            this.state.editing
              ? null
              : <BooleanField active={this.props.sale.active} activeLabel='Activo' inactiveLabel='Inactivo' />
          }
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(this.props.sale.created_at)}
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(this.props.sale.updated_at)}
        </TableCell>
        <TableCell>
          {
            this.state.editing
              ? (
                <SaveButton
                  model='Sale'
                  action='update'
                  disabled={this.shouldSaveButtonBeDisabled()}
                  onClick={this.handleSave}
                />
                )
              : <RowActions
                  model='Sale'
                  id={this.props.sale.id}
                  handleDelete={() => this.props.deleteSale(this.props.sale.id)}
                  handleInlineUpdate={this.onInlineUpdateStart}
                />
          }
        </TableCell>
      </TableRow>
    )
  }
}

// Export.
export default SalesTableRow
