// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
  Grid
} from '@material-ui/core'

// Components.
import ArtistStatsCardsCard from './ArtistStatsCardsCard'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const ArtistStatsCards = props => {
  if (!props.stats) {
    if (!props.loading) {
      return null
    }
    return (
      <Grid item xs={12}>
        <Grid container spacing={2}>
          <Grid item xs={12} md={6} lg={3}>
            <ArtistStatsCardsCard loading />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <ArtistStatsCardsCard loading />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <ArtistStatsCardsCard loading />
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <ArtistStatsCardsCard loading />
          </Grid>
        </Grid>
      </Grid>
    )
  }

  return (
    <Grid item xs={12}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={3} style={{ flexGrow: 1, flexDirection: 'column', alignItems: 'stretch' }}>
          <ArtistStatsCardsCard
            total={props.stats.products.total_sales.cur_year}
            title='productos vendidos'
            totalLastYear={props.stats.products.total_sales.last_year}
            footer='Nº total de productos vendidos en el período en todos los sitios web.'
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} style={{ flexGrow: 1, flexDirection: 'column', alignItems: 'stretch' }}>
          <ArtistStatsCardsCard
            total={props.stats.orders.total_n_orders.cur_year}
            title='pedidos únicos*'
            totalLastYear={props.stats.orders.total_n_orders.last_year}
            footer='*Nº total de pedidos que contienen al menos un producto del artista en todos los sitios web.'
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} style={{ flexGrow: 1, flexDirection: 'column', alignItems: 'stretch' }}>
          <ArtistStatsCardsCard
            total={props.stats.orders.total_paid.cur_year}
            title='en pedidos*'
            totalLastYear={props.stats.orders.total_paid.last_year}
            footer='*Total gastado en pedidos que contienen productos del artista (IVA incluido). Los pedidos pueden contener otros productos.'
            price
          />
        </Grid>
        <Grid item xs={12} md={6} lg={3} style={{ flexGrow: 1, flexDirection: 'column', alignItems: 'stretch' }}>
          <ArtistStatsCardsCard
            total={props.stats.orders.total_shipping.cur_year}
            title='en gastos de envío'
            totalLastYear={props.stats.orders.total_shipping.last_year}
            footer='Precio del envío según zona (IVA incluido): Península, 5€; Baleares, 7.50€; Canarias, Ceuta y Melilla, 15€; resto, variable.'
            price
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

// Export.
export default ArtistStatsCards
