// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import { Grid, Paper } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'

// Components.
import Title from '../../../../../UI/Brand/Title'
import AlertError from '../../../../../UI/Alerts/AlertError'
import AlertSuccess from '../../../../../UI/Alerts/AlertSuccess'
import AddButton from '../../../../../UI/Buttons/AddButton'
import RefreshButton from '../../../../../UI/Buttons/RefreshButton'
import ExpenseTypesTable from './ExpenseTypesTable'

// Styles.
import { useStyles } from '../../../../../styles'

// Shared.
import { calculateNumPages } from '../../../../../../shared/functions'

// Redux actions.
import * as actions from '../../../../../../store/actions'
import { connect } from 'react-redux'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class ExpenseTypesList extends Component {
  state = {
    page: 1,
    formOpen: false
  }

  handlePageChange = (e, value) => {
    this.setState({ page: +value })
  }

  handleFormOpen = () => {
    this.setState({ formOpen: true })
  }

  onFormClosed = () => {
    this.setState({ formOpen: false })
  }

  componentDidMount () {
    this.props.loadExpenseTypes(this.props.token)
  }

  render () {
    return (
      <Paper className={this.props.classes.paper}>
        <div className='ExpenseTypes'>
          <Title>Categorías de gasto</Title>
          <AlertError error={this.props.error} />
          <AlertSuccess message={this.props.success} />
          <ExpenseTypesTable
            expenseTypes={this.props.expenseTypes}
            loading={this.props.loading}
            page={this.state.page}
            creating={this.state.formOpen}
            handleFormClose={this.onFormClosed}
          />
          <Grid container spacing={3} className={this.props.classes.tableTools}>
            <Grid item xs={4}>
              {
                this.props.expenseTypes && this.props.expenseTypes.length
                  ? <Pagination
                      count={calculateNumPages(this.props.expenseTypes)}
                      page={this.state.page}
                      onChange={this.handlePageChange}
                    />
                  : null
              }
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'center' }}>
              {this.props.expenseTypes ? this.props.expenseTypes.length : 0} resultados
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <RefreshButton
                model='ExpenseType'
                onClick={() => this.props.loadExpenseTypes(this.props.token)}
              />
              <AddButton
                model='ExpenseType'
                onClick={this.handleFormOpen}
              />
            </Grid>
          </Grid>
        </div>
      </Paper>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    loading: state.expenseTypes.loading,
    expenseTypes: state.expenseTypes.expenseTypes,
    error: state.expenseTypes.error,
    success: state.expenseTypes.success
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadExpenseTypes: token => dispatch(actions.loadExpenseTypes(token))
  }
}

// Style component.
const ExpenseTypesListStyled = props => {
  const classes = useStyles()
  return (
    <ExpenseTypesList classes={classes} {...props} />
  )
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(ExpenseTypesListStyled)
