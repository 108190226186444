// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import MoreVertIcon from '@material-ui/icons/MoreVert'
import {
  IconButton,
  Link,
  Menu,
  MenuItem,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle, Button
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { connect } from 'react-redux'

// Shared.
import { userCan } from '../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class RowActions extends Component {
  state = {
    anchorEl: null,
    confirmationDialogOpen: false
  }

  handleClick = (e) => {
    this.setState({
      anchorEl: e.currentTarget
    })
  }

  handleClose = () => {
    this.setState({
      anchorEl: null
    })
  }

  handleInlineUpdate = () => {
    this.handleClose()
    this.props.handleInlineUpdate()
  }

  handleConfirmationDialogOpen = () => {
    this.setState({
      confirmationDialogOpen: true
    })
  }

  handleConfirmationDialogClose = () => {
    this.setState({
      confirmationDialogOpen: false,
      anchorEl: null
    })
  }

  handleCustomAction = action => {
    return () => {
      this.setState({
        anchorEl: null
      })
      action()
    }
  }

  render () {
    let downloadLink = null
    if (this.props.downloadLink && userCan(this.props.user, this.props.model, 'read')) {
      downloadLink = <MenuItem><Link component='a' href={this.props.downloadLink}>Descargar</Link></MenuItem>
    }

    let editLink = null
    if (this.props.editLink && userCan(this.props.user, this.props.model, this.props.editActionName || 'update')) {
      editLink = <MenuItem><Link component={RouterLink} to={this.props.editLink}>Editar</Link></MenuItem>
    }

    let inlineUpdateLink = null
    if (this.props.handleInlineUpdate && userCan(this.props.user, this.props.model, this.props.inlineUpdateActionName || 'update')) {
      inlineUpdateLink = <MenuItem onClick={this.handleInlineUpdate}>Editar</MenuItem>
    }

    let recalculateLink = null
    if (this.props.handleRecalculate && userCan(this.props.user, this.props.model, this.props.recalculateActionName || 'create')) {
      recalculateLink = <MenuItem onClick={this.props.handleRecalculate}>Recalcular</MenuItem>
    }

    const extraActions = []
    if (this.props.extraActions && this.props.extraActions.length) {
      for (let i = 0; i < this.props.extraActions.length; i++) {
        const action = this.props.extraActions[i]
        if (action.model && action.action && !userCan(this.props.user, this.props.model, action.actionName)) {
          continue
        }
        extraActions.push(<MenuItem key={i} onClick={this.handleCustomAction(action.handleAction)}>{action.label}</MenuItem>)
      }
    }

    let deleteLink = null
    if (this.props.handleDelete && userCan(this.props.user, this.props.model, this.props.deleteActionName || 'delete')) {
      deleteLink = <MenuItem onClick={this.handleConfirmationDialogOpen}>Eliminar</MenuItem>
    }

    // Return nothing, if no actions in menu.
    if (!downloadLink && !editLink && !inlineUpdateLink && !recalculateLink && !extraActions.legth && !deleteLink) {
      return null
    }

    return (
      <>
        <IconButton
          aria-label='more'
          aria-controls='long-menu'
          aria-haspopup='true'
          onClick={this.handleClick}
          size='small'
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id={this.props.id}
          anchorEl={this.state.anchorEl}
          keepMounted
          open={Boolean(this.state.anchorEl)}
          onClose={this.handleClose}
        >
          {downloadLink}
          {editLink}
          {inlineUpdateLink}
          {recalculateLink}
          {extraActions}
          {deleteLink}
        </Menu>
        {
          this.props.handleDelete
            ? (
              <Dialog
                open={this.state.confirmationDialogOpen}
                onClose={this.handleConfirmationDialogClose}
                aria-labelledby='alert-dialog-title'
                aria-describedby='alert-dialog-description'
              >
                <DialogTitle id='alert-dialog-title'>
                  ¿Seguro que deseas eliminar?
                </DialogTitle>
                <DialogContent>
                  <DialogContentText id='alert-dialog-description'>
                    Una vez eliminado, no podrás recuperar los datos.
                  </DialogContentText>
                </DialogContent>
                <DialogActions>
                  <Button color='default' variant='outlined' onClick={this.handleConfirmationDialogClose}>
                    Cancelar
                  </Button>
                  <Button color='primary' variant='contained' onClick={this.props.handleDelete} autoFocus>
                    Confirmar
                  </Button>
                </DialogActions>
              </Dialog>
              )
            : null
        }
      </>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

// Export.
export default connect(mapStateToProps)(RowActions)
