// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { Button } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

// Style.
import { useStyles } from '../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const GoBackButton = props => {
  const classes = useStyles()

  return (
    <Button
      className={classes.saveButton}
      variant='contained'
      component={RouterLink}
      to={props.link}
    >
      Volver
    </Button>
  )
}

// Export.
export default GoBackButton
