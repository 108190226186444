// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import * as _ from 'lodash'
import React, { Component } from 'react'
import {
  Paper
} from '@material-ui/core'

// Components.
import Title from '../../../../../UI/Brand/Title'
import AlertError from '../../../../../UI/Alerts/AlertError'
import AlertSuccess from '../../../../../UI/Alerts/AlertSuccess'
import FormField from '../../../../../UI/Form/FormField'
import SaveButton from '../../../../../UI/Buttons/SaveButton'

// Styles.
import { useStyles } from '../../../../../styles'

// Shared.
import { userCan } from '../../../../../../shared/functions'

// Redux actions.
import { connect } from 'react-redux'
import * as actions from '../../../../../../store/actions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class GenericSettingsList extends Component {
  componentDidMount () {
    this.props.loadSettings(this.props.token)
  }

  render () {
    return (
      <Paper className={this.props.classes.paper}>
        <div className='GenericSettingsList'>
          <Title>Ajustes generales</Title>
          <AlertError error={this.props.error} />
          <AlertSuccess message={this.props.success} />
          <form className={this.props.classes.rootWrap} noValidate autoComplete='off'>
            {
              _.keys(this.props.settings).map((id, key) => {
                const field = this.props.settings[id]
                const isCheckbox = /(_enabled)$/.test(field.key)
                return (
                  <FormField
                    key={key}
                    name={field.name}
                    type={isCheckbox ? 'checkbox' : 'text'}
                    width={isCheckbox ? 12 : 6}
                    required
                    label={field.description}
                    value={field.value}
                    handleChange={e => this.props.updateSetting(id, e.target.value, isCheckbox)}
                    options={field.options}
                    readOnly={!userCan(this.props.user, 'Configuration', 'update')}
                  />
                )
              })
            }
          </form>
          <div className={this.props.classes.margin}>
            <SaveButton
              model='Configuration'
              action='update'
              disabled={this.props.formLoading || !this.props.selectedSetting}
              onClick={() => this.props.selectedSetting ? this.props.saveSetting(this.props.selectedSetting.id, this.props.token, this.props.selectedSetting.key, this.props.selectedSetting.value) : null}
            />
          </div>
        </div>
      </Paper>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    user: state.auth.user,
    token: state.auth.token,
    loading: state.settings.loading,
    settings: state.settings.settings,
    error: state.settings.error,
    success: state.settings.success,
    selectedSetting: state.settings.selectedSetting
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadSettings: token => dispatch(actions.loadSettings(token)),
    updateSetting: (idx, value, isBoolean) => dispatch(actions.updateSetting(idx, value, isBoolean)),
    saveSetting: (id, token, key, value) => dispatch(actions.saveSetting(id, token, key, value))
  }
}

// Style component.
const GenericSettingsListStyled = props => {
  const classes = useStyles()
  return (
    <GenericSettingsList classes={classes} {...props} />
  )
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(GenericSettingsListStyled)
