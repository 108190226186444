// ============================================================================
// Dependencies.
// ============================================================================

// Actions.
import * as actions from '../actions/types'

// Shared.
import { updateObject } from '../../shared/functions'

// ============================================================================
// Initial state.
// ============================================================================

// Empty data source.
const emptyDataSource = { artist_id: null, category_id: null }

// Initial data sources.
const initialDataSources = [
  { ...emptyDataSource }
]

// Initial state.
const initialState = {
  loading: true,
  detailsLoading: false,
  error: null,
  success: null,
  stockMovements: [],
  selectedStockMovement: null,
  dataSources: initialDataSources,
  redirectTo: null
}

// ============================================================================
// Functionality.
// ============================================================================

// Start loading list of stock movements.
const loadStockMovementsStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    success: null,
    stockMovements: [],
    dataSources: [...initialDataSources],
    selectedStockMovement: null,
    redirectTo: null
  })
}

// Finish loading list of stock movements.
const loadStockMovementsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    stockMovements: action.stockMovements,
    error: null,
    success: null
  })
}

// Finish loading list of stock movements, with error.
const loadStockMovementsFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    stockMovements: [],
    error: action.error,
    success: null
  })
}

// Start deleting stock movement.
const deleteStockMovementStart = state => {
  return updateObject(state, {
    loading: true,
    success: null,
    error: null,
    redirectTo: null
  })
}

// Delete a stock movement successfully.
const deleteStockMovementSuccess = (state, action) => {
  const oldStockMovementIdx = state.stockMovements.findIndex(e => +e.id === +action.id)
  const newStockMovements = [...state.stockMovements]
  newStockMovements.splice(oldStockMovementIdx, 1)
  return updateObject(state, {
    loading: false,
    stockMovements: newStockMovements,
    error: null,
    success: `Se ha eliminado el movimiento de stock #${action.id} correctamente.`
  })
}

// Delete stock movement, with error.
const deleteStockMovementFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    success: null
  })
}

// Start saving a stock movement.
const createStockMovementStart = state => {
  return updateObject(state, {
    formLoading: true,
    error: null,
    success: null,
    redirectTo: null
  })
}

// Save a stock movement successfully.
const createStockMovementSuccess = (state, action) => {
  const newStockMovements = [
    ...state.stockMovements
  ]
  newStockMovements.push(action.stockMovement)
  return updateObject(state, {
    formLoading: false,
    error: null,
    success: 'Movimiento de stock creado correctamente. Se ha programado su ejecución, y se hará efectivo en los próximos minutos.',
    stockMovements: newStockMovements,
    selectedStockMovement: null,
    dataSources: [...initialDataSources]
  })
}

// Save a stock movement, with error.
const createStockMovementFailed = (state, action) => {
  return updateObject(state, {
    formLoading: false,
    error: action.error,
    success: null
  })
}

// Start loading stock movement.
const loadStockMovementStart = state => {
  return updateObject(state, {
    detailsLoading: true,
    error: null,
    success: null,
    selectedStockMovement: null,
    redirectTo: null
  })
}

// Load a stock movement successfully.
const loadStockMovementSuccess = (state, action) => {
  return updateObject(state, {
    detailsLoading: false,
    error: null,
    ...compatibilizeStockMovement(action.stockMovement)
  })
}

// Load stock movement, with error.
const loadStockMovementFailed = (state, action) => {
  return updateObject(state, {
    detailsLoading: false,
    error: action.error,
    selectedStockMovement: null
  })
}

// Update the selected stock movement.
const updateSelectedStockMovement = (state, action) => {
  const newSelectedStockMovement = {
    ...state.selectedStockMovement
  }
  newSelectedStockMovement[action.key] = action.value
  return updateObject(state, {
    selectedStockMovement: newSelectedStockMovement
  })
}

// Add a data source.
const addStockMovementDataSource = (state, action) => {
  const newDataSources = [...state.dataSources]
  const dataSourceIdx = newDataSources.findIndex(d => +d.artistId === +action.artistId && +d.categoryId === +action.categoryId)
  if (dataSourceIdx === -1) {
    newDataSources.push({ artist_id: action.artistId, category_id: action.categoryId })
  }
  return updateObject(state, {
    dataSources: newDataSources
  })
}

// Delete a data source.
const deleteStockMovementDataSource = (state, action) => {
  const newDataSources = [...state.dataSources]
  const dataSourceIdx = newDataSources.findIndex(d => +d.artistId === +action.artistId && +d.categoryId === +action.categoryId)
  if (dataSourceIdx !== -1) {
    newDataSources.splice(dataSourceIdx, 1)
  }
  return updateObject(state, {
    dataSources: newDataSources
  })
}

// Update a data source.
const updateSelectedStockMovementDataSource = (state, action) => {
  const newDataSources = [...state.dataSources]
  if (newDataSources[+action.idx]) {
    newDataSources[+action.idx].artist_id = action.artistId
    newDataSources[+action.idx].category_id = action.categoryId
  }
  return updateObject(state, {
    dataSources: newDataSources
  })
}

// Add a product to the selected stock movement.
const addStockMovementProducts = (state, action) => {
  const newSelectedStockMovement = {
    ...state.selectedStockMovement
  }
  const newProducts = newSelectedStockMovement && newSelectedStockMovement.products ? [...newSelectedStockMovement.products] : []
  for (let i = 0; i < action.products.length; i++) {
    const productIdx = newProducts.findIndex(d => +d.product_id === +action.products[i].id)
    if (productIdx === -1) {
      const product = {
        product_id: +action.products[i].id,
        name: action.products[i].name,
        category_id: +action.products[i].category_id,
        image: action.products[i].image,
        site_id: action.siteId,
        stock_quantity: 0
      }
      if (action.products[i].variations && action.products[i].variations.length) {
        for (let j = 0; j < action.products[i].variations.length; j++) {
          newProducts.push({
            ...product,
            product_variation_id: action.products[i].variations[j].id,
            product_variation_name: action.products[i].variations[j].name,
            price_old: +action.products[i].variations[j].sale_price ? +action.products[i].variations[j].sale_price : +action.products[i].variations[j].price,
            price_new: +action.products[i].variations[j].sale_price ? +action.products[i].variations[j].sale_price : +action.products[i].variations[j].price
          })
        }
      } else {
        newProducts.push({
          ...product,
          product_variation_id: null,
          product_variation_name: null,
          price_old: +action.products[i].sale_price ? +action.products[i].sale_price : +action.products[i].price,
          price_new: +action.products[i].sale_price ? +action.products[i].sale_price : +action.products[i].price
        })
      }
    }
  }
  newSelectedStockMovement.products = newProducts
  return updateObject(state, {
    selectedStockMovement: newSelectedStockMovement
  })
}

// Delete a product from the selected stock movement.
const deleteStockMovementProducts = (state, action) => {
  if (!state.selectedStockMovement || !state.selectedStockMovement.products || !state.selectedStockMovement.products.length || !action.productIds || !action.productIds.length) {
    return { ...state }
  }
  const newSelectedStockMovement = {
    ...state.selectedStockMovement
  }
  const newProducts = [...newSelectedStockMovement.products]
  for (let i = 0; i < action.productIds.length; i++) {
    const productIdToDelete = +action.productIds[i]
    for (let j = 0; j < state.selectedStockMovement.products.length; j++) {
      if (+state.selectedStockMovement.products[j].product_id === productIdToDelete) {
        newProducts[j] = null
      }
    }
  }
  newSelectedStockMovement.products = newProducts.filter(a => !!a)
  return updateObject(state, {
    selectedStockMovement: newSelectedStockMovement
  })
}

// Update a product from the selected stock movement.
const updateSelectedStockMovementProduct = (state, action) => {
  const newSelectedStockMovement = {
    ...state.selectedStockMovement
  }
  const newProducts = [
    ...newSelectedStockMovement.products
  ]
  newProducts[+action.idx][action.key] = action.value
  newSelectedStockMovement.products = newProducts
  return updateObject(state, {
    selectedStockMovement: newSelectedStockMovement
  })
}

// Successfully create a stock entry from a stock withdrawal.
const createStockEntryFromWithdrawalSuccess = (state, action) => {
  return updateObject(state, {
    detailsLoading: false,
    error: null,
    redirectTo: '/warehouse/stock_entries/create',
    ...compatibilizeStockMovement(action.stockMovement)
  })
}

// Convert a stock movement object from the API response to the compatible format.
const compatibilizeStockMovement = stockMovement => {
  const result = {
    selectedStockMovement: {
      ...stockMovement,
      products: []
    },
    dataSources: []
  }
  for (let i = 0; i < stockMovement.details.length; i++) {
    const detail = stockMovement.details[i]
    // Build the product object.
    const productObject = {
      stock_quantity: detail.stock_quantity,
      price_new: detail.price_new,
      product_id: detail.product.id,
      name: detail.product.name,
      image: detail.product.image,
      site_id: detail.product.category && detail.product.category.site ? detail.product.category.site.id : null,
      category_id: detail.product.category ? detail.product.category.id : null,
      price_old: detail.product.price,
      product_variation_id: null,
      product_variation_name: null
    }
    if (detail.product_variation) {
      productObject.product_variation_id = detail.product_variation.id
      productObject.product_variation_name = detail.product_variation.name
    }
    // Push the product object to the result.
    result.selectedStockMovement.products.push(productObject)
    // Push the data sources to the result.
    let dataSourceExists = false
    for (let j = 0; j < result.dataSources.length; j++) {
      const dataSource = result.dataSources[j]
      if (+dataSource.artist_id === +detail.product.artist_id && +dataSource.category_id === +productObject.category_id) {
        dataSourceExists = true
      }
    }
    if (!dataSourceExists) {
      result.dataSources.push({ artist_id: +detail.product.artist_id, category_id: +productObject.category_id })
    }
  }
  return result
}

// ============================================================================
// Reducer definition.
// ============================================================================

// Reducer definition.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_STOCK_MOVEMENTS_START:
      return loadStockMovementsStart(state, action)
    case actions.LOAD_STOCK_MOVEMENTS_SUCCESS:
      return loadStockMovementsSuccess(state, action)
    case actions.LOAD_STOCK_MOVEMENTS_FAILED:
      return loadStockMovementsFailed(state, action)
    case actions.DELETE_STOCK_MOVEMENT_START:
      return deleteStockMovementStart(state, action)
    case actions.DELETE_STOCK_MOVEMENT_SUCCESS:
      return deleteStockMovementSuccess(state, action)
    case actions.DELETE_STOCK_MOVEMENT_FAILED:
      return deleteStockMovementFailed(state, action)
    case actions.SAVE_STOCK_MOVEMENT_START:
      return createStockMovementStart(state, action)
    case actions.SAVE_STOCK_MOVEMENT_SUCCESS:
      return createStockMovementSuccess(state, action)
    case actions.SAVE_STOCK_MOVEMENT_FAILED:
      return createStockMovementFailed(state, action)
    case actions.LOAD_STOCK_MOVEMENT_START:
      return loadStockMovementStart(state, action)
    case actions.LOAD_STOCK_MOVEMENT_SUCCESS:
      return loadStockMovementSuccess(state, action)
    case actions.LOAD_STOCK_MOVEMENT_FAILED:
      return loadStockMovementFailed(state, action)
    case actions.ADD_STOCK_MOVEMENT_DATA_SOURCE:
      return addStockMovementDataSource(state, action)
    case actions.DELETE_STOCK_MOVEMENT_DATA_SOURCE:
      return deleteStockMovementDataSource(state, action)
    case actions.ADD_STOCK_MOVEMENT_PRODUCTS:
      return addStockMovementProducts(state, action)
    case actions.DELETE_STOCK_MOVEMENT_PRODUCTS:
      return deleteStockMovementProducts(state, action)
    case actions.UPDATE_SELECTED_STOCK_MOVEMENT:
      return updateSelectedStockMovement(state, action)
    case actions.UPDATE_SELECTED_STOCK_MOVEMENT_PRODUCT:
      return updateSelectedStockMovementProduct(state, action)
    case actions.UPDATE_SELECTED_STOCK_MOVEMENT_DATA_SOURCE:
      return updateSelectedStockMovementDataSource(state, action)
    case actions.CREATE_STOCK_ENTRY_FROM_WITHDRAWAL_SUCCESS:
      return createStockEntryFromWithdrawalSuccess(state, action)
    default:
      return state
  }
}

export default reducer
