// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import * as _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  LinearProgress,
  Paper
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'

// Components.
import Title from '../../../../../UI/Brand/Title'
import FormField from '../../../../../UI/Form/FormField'
import AlertSuccess from '../../../../../UI/Alerts/AlertSuccess'
import AlertInfo from '../../../../../UI/Alerts/AlertInfo'
import AlertError from '../../../../../UI/Alerts/AlertError'
import ProductsTable from '../../Warehouse/Inventory/ProductsTable'
import CategoryLink from '../../../../../UI/Links/CategoryLink'
import SyncButton from '../../../../../UI/Buttons/SyncButton'
import TableFilters from '../../../../../UI/Table/TableFilters'

// Shared.
import {
  applyFilter,
  calculateNumPages
} from '../../../../../../shared/functions'

// Redux actions.
import * as actions from '../../../../../../store/actions'

// Styles.
import { useStyles } from '../../../../../styles'

// =============================================================================
// Constant form fields.
// =============================================================================

// Form fields.
const fields = {
  name: {
    label: 'Nombre',
    type: 'text'
  },
  description: {
    label: 'Descripción',
    type: 'text'
  },
  url: {
    label: 'URL remota',
    type: 'text'
  },
  sync_mode: {
    label: 'Tipo de sincronización',
    type: 'text'
  }
}

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class Category extends Component {
  componentDidMount () {
    // Load the current category.
    this.props.loadCategory(this.props.id, this.props.token)
  }

  state = {
    page: 1,
    orderBy: 'id',
    orderDirection: 'asc',
    filter: {
      name: {
        touched: false,
        operand: 'match',
        value: null,
        key: 'name',
        label: 'Nombre',
        type: 'text'
      }
    }
  }

  handleSort = (fieldName) => event => {
    // Create a copy of the state object.
    const newState = { ...this.state }
    // Set the order field.
    newState.orderBy = fieldName
    // Toggle the order direction.
    newState.orderDirection = this.state.orderBy === fieldName ? (newState.orderDirection === 'asc' ? 'desc' : 'asc') : 'asc'
    // Update the state.
    this.setState(newState)
  }

  onFilterChange = (elemKey, value, forceUntouched = false) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.filter[elemKey]
    }
    // Modify the filter value.
    newElement.value = value
    // Set as touched.
    newElement.touched = !forceUntouched
    // Update the state.
    this.setState({
      page: 1,
      filter: {
        ...this.state.filter,
        [elemKey]: newElement
      }
    })
  }

  handlePageChange = (e, value) => {
    this.setState({ page: +value })
  }

  render () {
    let content = null
    let products = []

    // Handle loading state.
    if (this.props.loading) {
      // Show loader.
      content = <LinearProgress />
    } else {
      // Order results.
      products = applyFilter(this.props.selectedCategory.products, this.state.filter, this.state.orderBy, this.state.orderDirection)

      content = (
        <>
          <AlertInfo>No puedes editar los campos de la categoría. Las categorías se sincronizan de forma automática.</AlertInfo>
          <form className={this.props.classes.rootWrap} noValidate autoComplete='off'>
            {
              _.keys(fields).map(fieldName => {
                const field = fields[fieldName]
                return (
                  <FormField
                    readOnly
                    key={fieldName}
                    name={fieldName}
                    type={field.type}
                    label={field.label}
                    value={this.props.selectedCategory && this.props.selectedCategory[fieldName] ? this.props.selectedCategory[fieldName] : ''}
                  />
                )
              })
            }
            {
              this.props.selectedCategory && this.props.selectedCategory.parent
                ? (
                  <Grid item xs={12} className={this.props.classes.margin}>
                    Categoría padre: <CategoryLink category={this.props.selectedCategory.parent} remote />
                  </Grid>
                  )
                : null
            }
          </form>
        </>
      )
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={this.props.classes.paper}>
            <div className='Site'>
              <div className='Category'>
                <Title>Propiedades de la categoría</Title>
                <AlertError error={this.props.error} />
                {content}
              </div>
            </div>
          </Paper>
        </Grid>
        {
          this.props.selectedCategory
            ? (
              <Grid item xs={12}>
                <Paper className={this.props.classes.paper}>
                  <div className='CategoryProducts'>
                    <Title>Productos</Title>
                    <TableFilters
                      filters={this.state.filter}
                      handleChange={this.onFilterChange}
                    />
                    <AlertError error={this.props.productSyncError} />
                    <AlertSuccess message={this.props.productSyncSuccess} />
                    <ProductsTable
                      products={products}
                      site_id={this.props.selectedCategory.site_id}
                      page={this.state.page}
                      loading={this.props.productSyncLoading}
                      orderBy={this.state.orderBy}
                      orderDirection={this.state.orderDirection}
                      onSort={this.handleSort}
                    />
                    <Grid container spacing={3} className={this.props.classes.tableTools}>
                      <Grid item xs={4}>
                        <Pagination
                          count={calculateNumPages(products)}
                          page={this.state.page}
                          onChange={this.handlePageChange}
                        />
                      </Grid>
                      <Grid item xs={4} style={{ textAlign: 'center' }}>
                        {products.length} resultados
                      </Grid>
                      <Grid item xs={4} style={{ textAlign: 'right' }}>
                        <SyncButton
                          model='Category'
                          action='syncProducts'
                          onClick={() => this.props.syncProducts(this.props.selectedCategory.id, this.props.token)}
                          disabled={this.props.productSyncLoading}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </Paper>
              </Grid>
              )
            : null
        }
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    loading: state.categories.loading,
    error: state.categories.error,
    productSyncLoading: state.categories.productSyncLoading,
    productSyncError: state.categories.productSyncError,
    productSyncSuccess: state.categories.productSyncSuccess,
    selectedCategory: state.categories.selectedCategory
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadCategory: (id, token) => dispatch(actions.loadCategory(id, token)),
    syncProducts: (id, token) => dispatch(actions.syncProducts(id, token))
  }
}

// Style component.
const CategoryStyled = props => {
  const classes = useStyles()
  return (
    <Category classes={classes} {...props} />
  )
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(CategoryStyled)
