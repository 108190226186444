// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { useStyles } from '../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const ProductImage = props => {
  const classes = useStyles()
  if (!props.product) return null
  return (
    <div className={classes.productImageContainer}>
      <img className={classes.productImage} alt={props.product.name} src={props.product.image} />
    </div>
  )
}

// Export.
export default ProductImage
