// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { Box, Container, CssBaseline } from '@material-ui/core'

// Style.
import './Offline.css'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const Offline = (props) => {
  return (
    <Container component='main' maxWidth='xs' className='Offline'>
      <div className='OfflineBoxContainer'>
        <div className='OfflineBox'>
          <CssBaseline />
          <Box className='OfflinePageLogo'>
            <img src='/logo192.png' alt='Logo' />
          </Box>
          <Box className='OfflinePageDescription'>
            El sitio web no funciona correctamente
          </Box>
          <Box className='OfflinePageApology'>
            Nuestro equipo técnico está trabajando en el problema.<br />Por favor, sé paciente.
          </Box>
        </div>
      </div>
    </Container>
  )
}

// Export.
export default Offline
