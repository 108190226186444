// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'

// Components.
import RowActions from '../../../../UI/Table/RowActions'
import UserLink from '../../../../UI/Links/UserLink'
import AlertInfo from '../../../../UI/Alerts/AlertInfo'
import BooleanField from '../../../../UI/Custom/BooleanField'
import UserProfile from '../../../../UI/Custom/UserProfile'
import UserThumbnail from '../../../../UI/Image/UserThumbnail'
import ArtistLink from '../../../../UI/Links/ArtistLink'
import TableColumnTitle from '../../../../UI/Table/TableColumnTitle'
import TableLoader from '../../../../UI/Loaders/TableLoader'

// Shared.
import { prettyPrintDateTime } from '../../../../../shared/functions'
import { RESULTS_PER_PAGE } from '../../../../../shared/constants'

// =============================================================================
// Component declaration.
// =============================================================================

// Table columns.
const columns = [
  { name: 'id', label: 'ID', sortable: true },
  { name: 'image', label: '' },
  { name: 'email', label: 'Email', sortable: true },
  { name: 'name', label: 'Nombre', sortable: true },
  { name: 'status', label: 'Estado', sortable: true },
  { name: 'profile_id', label: 'Perfil', sortable: true },
  { name: 'artist_id', label: 'Artista', sortable: true },
  { name: 'created_at', label: 'Fecha de creación (UTC)', sortable: true },
  { name: 'updated_at', label: 'Fecha de modificación (UTC)', sortable: true },
  { name: 'actions', label: '' }
]

// Stateless component declaration.
const UsersTable = props => {
  // Loading.
  if (props.loading) {
    return <TableLoader cols={columns} />
  }

  // No results.
  if (!props.users.length) {
    return <AlertInfo>No se han encontrado resultados.</AlertInfo>
  }

  const usersTable = []
  const minLimit = (props.page - 1) * RESULTS_PER_PAGE
  const maxLimit = Math.min(props.users.length, props.page * RESULTS_PER_PAGE)

  for (let i = minLimit; i < maxLimit; i++) {
    const user = props.users[i]
    usersTable.push(
      <TableRow key={user.id}>
        <TableCell>
          {user.id}
        </TableCell>
        <TableCell>
          <UserThumbnail user={user} token={props.token} />
        </TableCell>
        <TableCell>
          <UserLink user={user} label='email' />
        </TableCell>
        <TableCell>
          {user.firstname}
        </TableCell>
        <TableCell>
          <BooleanField active={user.active} activeLabel='Activo' inactiveLabel='Bloqueado' />
        </TableCell>
        <TableCell>
          <UserProfile profile={user.profile.name} />
        </TableCell>
        <TableCell>
          <ArtistLink artist={user.artist} />
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(user.created_at)}
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(user.updated_at)}
        </TableCell>
        <TableCell>
          <RowActions
            model='User'
            id={user.id}
            editLink={`/user/${user.id}`}
            handleDelete={() => props.deleteUser(user.id)}
          />
        </TableCell>
      </TableRow>
    )
  }

  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          {
            columns.map((col, key) => <TableColumnTitle key={key} {...col} orderBy={props.orderBy} orderDirection={props.orderDirection} onSort={props.onSort} />)
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {usersTable}
      </TableBody>
    </Table>
  )
}

// Export.
export default UsersTable
