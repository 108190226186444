// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import * as _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  LinearProgress,
  Paper
} from '@material-ui/core'

// Components.
import Title from '../../../../../../UI/Brand/Title'
import FormField from '../../../../../../UI/Form/FormField'
import AlertSuccess from '../../../../../../UI/Alerts/AlertSuccess'
import AlertError from '../../../../../../UI/Alerts/AlertError'
import ArrangementCategories from './ArrangementCategories'
import SaveButton from '../../../../../../UI/Buttons/SaveButton'
import GoBackButton from '../../../../../../UI/Buttons/GoBackButton'

// Shared.
import {
  userCan,
  validatePercentage
} from '../../../../../../../shared/functions'

// Redux actions.
import * as actions from '../../../../../../../store/actions'

// Styles.
import { useStyles } from '../../../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class ArrangementForm extends Component {
  componentDidMount () {
    // Load the current artist.
    if (!this.props.selectedArtist) {
      this.props.loadArtist(this.props.artistId, this.props.token)
    }
    // Load the current arrangement.
    if (this.props.arrangementId && (!this.props.selectedArrangement || !this.props.selectedArrangement.id)) {
      this.props.loadArrangement(this.props.arrangementId, this.props.token)
    }
  }

  state = {
    fields: {
      name: {
        label: 'Nombre del trato comercial',
        error: null,
        type: 'text',
        required: true,
        touched: false,
        validation: val => val.length >= 2,
        errMsg: 'Por favor, escribe un nombre válido. Por ejemplo: "Productos nuevos 75%".',
        autoFocus: true
      },
      percentage: {
        label: 'Porcentaje del artista',
        error: null,
        type: 'text',
        required: true,
        touched: false,
        validation: validatePercentage,
        errMsg: 'Por favor, escribe un valor entre 0 y 100.',
        autoFocus: false,
        adornment: '%'
      },
      manufacturing_enabled: {
        label: 'El artista fabrica el merchandising con Tumerchan',
        helperText: 'Este campo se tendrá en cuenta para el cálculo de las liquidaciones. Si el artista fabrica las prendas con Tumerchan, se compartirán gastos.',
        error: null,
        type: 'checkbox',
        required: true,
        touched: false,
        errMsg: 'Por favor, proporciona un valor válido.',
        autoFocus: false
      }
    }
  }

  handleChange = (elemKey, newValue) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields[elemKey]
    }
    // Take care of the value change, if the field is a checkbox.
    if (this.state.fields[elemKey].type === 'checkbox') newValue = this.props.selectedArrangement ? !this.props.selectedArrangement[elemKey] : true
    // Modify the element value.
    this.props.updateSelectedArrangement(elemKey, newValue)
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.required && !newValue.length && newElement.touched) {
      newElement.error = 'Campo obligatorio'
    } else if (newElement.validation && newValue.length && !newElement.validation(newValue)) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Update the state.
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [elemKey]: newElement
      }
    })
  }

  shouldSubmitButtonBeDisabled = () => {
    return this.props.formLoading ||
      this.props.artistLoading ||
      this.props.arrangementLoading ||
      !this.props.selectedArrangement ||
      !this.props.selectedArrangement.name ||
      !!this.state.fields.name.error ||
      !this.props.selectedArrangement.percentage ||
      !!this.state.fields.percentage.error
  }

  render () {
    let formFields = null

    // Handle loading state.
    if (this.props.formLoading || this.props.artistLoading || this.props.arrangementLoading) {
      // Show loader.
      formFields = <LinearProgress />
    } else {
      formFields = (
        <form className={this.props.classes.rootWrap} noValidate autoComplete='off'>
          {
            _.keys(this.state.fields).map(fieldName => {
              const field = this.state.fields[fieldName]

              // Get value.
              const value = this.props.selectedArrangement && this.props.selectedArrangement[fieldName] ? this.props.selectedArrangement[fieldName] : ''

              // Special field options.
              let label = field.label
              if (fieldName === 'manufacturing_enabled' && !value) {
                label = 'El artista fabrica el merchandising por su cuenta'
              }

              return (
                <FormField
                  key={fieldName}
                  name={fieldName}
                  type={field.type}
                  autoFocus={field.autoFocus}
                  required={field.required}
                  error={field.error}
                  label={label}
                  value={value}
                  handleChange={e => this.handleChange(fieldName, e.target.value)}
                  options={field.options}
                  startAdornment={field.adornment}
                  fullWidth={field.fullWidth}
                  readOnly={!userCan(this.props.user, 'Arrangement', this.props.selectedArrangement && this.props.selectedArrangement.id ? 'update' : 'create')}
                />
              )
            })
          }
        </form>
      )
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={this.props.classes.paper}>
            <div className='Arrangement'>
              <div className='ArrangementForm'>
                <Title>{this.props.arrangementId ? 'Propiedades del trato comercial' : 'Nuevo trato comercial'}</Title>
                <AlertError error={this.props.arrangementError} />
                <AlertSuccess message={this.props.arrangementSuccess} />
                {formFields}
                <div className={this.props.classes.mtb}>
                  <Title>Productos</Title>
                  <ArrangementCategories />
                </div>
                <div className={this.props.classes.mt2}>
                  <GoBackButton link={`/artist/${this.props.artistId}`} />
                  <SaveButton
                    model='Arrangement'
                    action={this.props.arrangementId ? 'update' : 'create'}
                    disabled={this.shouldSubmitButtonBeDisabled()}
                    onClick={() => this.props.saveArrangement(this.props.arrangementId, this.props.artistId, this.props.token, this.props.selectedArrangement)}
                  />
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    arrangementLoading: state.artists.arrangementLoading,
    formLoading: state.artists.arrangementFormLoading,
    arrangementError: state.artists.arrangementError,
    arrangementSuccess: state.artists.arrangementSuccess,
    artistLoading: state.artists.loading,
    selectedArtist: state.artists.selectedArtist,
    selectedArrangement: state.artists.selectedArrangement,
    artistCategories: state.artists.artistCategories,
    artistCategoriesLoading: state.artists.artistCategoriesLoading,
    artistCategoriesError: state.artists.artistCategoriesError
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadArtist: (id, token) => dispatch(actions.loadArtist(id, token)),
    loadArrangement: (id, token) => dispatch(actions.loadArrangement(id, token)),
    saveArrangement: (id, artistId, token, body) => dispatch(actions.saveArrangement(id, token, { ...body, artist_id: artistId })),
    loadArtistCategories: (categoryIds, token) => dispatch(actions.loadArtistCategories(categoryIds, token)),
    updateSelectedArrangement: (key, value) => dispatch(actions.updateSelectedArrangement(key, value))
  }
}

// Style component.
const ArrangementFormStyled = props => {
  const classes = useStyles()
  return (
    <ArrangementForm classes={classes} {...props} />
  )
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(ArrangementFormStyled)
