// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { Link, Chip } from '@material-ui/core'

// Style.
import { useStyles } from '../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const RemoteProductAdminLink = props => {
  const classes = useStyles()
  if (!props.url) return null
  return (
    <Link className={classes.noUnderlineOnHover} target='_blank' component='a' href={props.url} rel='noreferrer'>
      <Chip className={classes.chipLink} variant='outlined' size='small' color='default' label='Editar' />
    </Link>
  )
}

// Export.
export default RemoteProductAdminLink
