// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { TableCell } from '@material-ui/core'

// =============================================================================
// Constants.
// =============================================================================

// Style of a disabled row.
const inactiveRowStyle = { opacity: 0.3, backgroundColor: '#e6e6e6' }

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const TableCellWithProgress = props => {
  if (typeof props.active === 'undefined') props.active = true
  return (
    <TableCell style={props.active ? null : inactiveRowStyle} {...props}>{props.children}</TableCell>
  )
}

// Export.
export default TableCellWithProgress
