// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton, Paper, Grid
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import Pagination from '@material-ui/lab/Pagination'
import { connect } from 'react-redux'

// Redux actions.
import * as actions from '../../../../../../store/actions'

// Components.
import Title from '../../../../../UI/Brand/Title'
import EarningType from '../../../../../UI/Custom/EarningType'
import AlertInfo from '../../../../../UI/Alerts/AlertInfo'
import Earning from '../../../../../UI/Custom/Earning'
import RowActions from '../../../../../UI/Table/RowActions'
import FormField from '../../../../../UI/Form/FormField'
import AlertSuccess from '../../../../../UI/Alerts/AlertSuccess'
import AlertError from '../../../../../UI/Alerts/AlertError'
import ArrangementLink from '../../../../../UI/Links/ArrangementLink'
import RefreshButton from '../../../../../UI/Buttons/RefreshButton'
import AddButton from '../../../../../UI/Buttons/AddButton'
import SaveButton from '../../../../../UI/Buttons/SaveButton'
import TableColumnTitle from '../../../../../UI/Table/TableColumnTitle'
import TableLoader from '../../../../../UI/Loaders/TableLoader'

// Shared.
import { calculateNumPages, prettyPrintDateTime, prettyPrintDate } from '../../../../../../shared/functions'
import { RESULTS_PER_PAGE } from '../../../../../../shared/constants'

// Style.
import { useStyles } from '../../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Table columns.
const columns = [
  { name: 'id', label: 'ID' },
  { name: 'earning_type_id', label: 'Tipo' },
  { name: 'arrangement_id', label: 'Trato comercial' },
  { name: 'description', label: 'Descripción' },
  { name: 'total_earning', label: 'Gasto' },
  { name: 'created_at', label: 'Fecha del evento' },
  { name: 'updated_at', label: 'Fecha de creación (UTC)' },
  { name: 'actions', label: '' }
]

// Stateful component declaration.
class ArtistEarningsList extends Component {
  state = {
    fields: {
      earning_type_id: {
        label: 'Tipo',
        error: null,
        value: '',
        type: 'select',
        required: true,
        touched: false,
        errMsg: 'Por favor, seleciona un tipo de ingreso.',
        autoFocus: true,
        options: []
      },
      description: {
        label: 'Descripción',
        error: null,
        type: 'text',
        value: '',
        required: true,
        touched: false,
        validation: val => val.length >= 2,
        errMsg: 'Por favor, proporciona una descripción.',
        autoFocus: false
      },
      date: {
        label: 'Fecha',
        error: null,
        type: 'date',
        value: '',
        required: true,
        touched: false,
        errMsg: 'Por favor, selecciona una fecha válida.',
        autoFocus: false
      },
      total_earning: {
        label: 'Cantidad',
        error: null,
        type: 'text',
        value: '',
        required: true,
        touched: false,
        validation: val => +val > 0,
        errMsg: 'Por favor, introduce una cantidad positiva.',
        autoFocus: false
      },
      arrangement_id: {
        label: 'Trato comercial',
        error: null,
        value: '',
        type: 'select',
        required: true,
        touched: false,
        errMsg: 'Por favor, selecciona un trato comercial.',
        autoFocus: false,
        options: []
      }
    },
    page: 1,
    creatingEarning: false,
    justOpened: false
  }

  componentDidMount () {
    if (this.props.artistId) {
      this.props.loadArtistEarnings(this.props.artistId, this.props.token)
    }
  }

  handleEarningsPageChange = (e, value) => {
    this.setState({ page: +value })
  }

  handleEarningFormOpen = () => {
    this.setState({ creatingEarning: true })
    this.props.loadArtistArrangements(this.props.artistId, this.props.token)
    this.props.loadEarningTypes(this.props.token)
  }

  handleEarningFormClose = () => {
    this.setState({ creatingEarning: false })
  }

  handleChange = (elemKey, newValue) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields[elemKey]
    }
    // Modify the element value.
    newElement.value = newValue
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.required && !newValue.length && newElement.touched) {
      newElement.error = 'Campo obligatorio'
    } else if (newElement.validation && newValue.length && !newElement.validation(newValue)) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Build new state.
    const newState = {
      fields: {
        ...this.state.fields,
        [elemKey]: newElement
      },
      justOpened: false
    }
    // Update the state.
    this.setState(newState)
  }

  handleSaveEarning = () => {
    // Save the data source.
    this.props.saveEarning(
      null,
      this.props.token,
      {
        arrangement_id: this.state.fields.arrangement_id.value,
        earning_type_id: this.state.fields.earning_type_id.value,
        description: this.state.fields.description.value,
        total_earning: this.state.fields.total_earning.value,
        date: this.state.fields.date.value
      }
    )
    // Reset the form.
    const newStateFields = {
      ...this.state.fields
    }
    newStateFields.arrangement_id.value = ''
    newStateFields.earning_type_id.value = ''
    newStateFields.description.value = ''
    newStateFields.total_earning.value = ''
    newStateFields.date.value = ''
    this.setState({
      fields: newStateFields
    })
    // Close the form.
    this.handleEarningFormClose()
  }

  shouldSaveButtonBeDisabled = () => {
    return this.props.earningTypesLoading ||
      !this.props.artistId ||
      !this.state.fields.earning_type_id.value ||
      !this.state.fields.arrangement_id.value ||
      !this.state.fields.description.value ||
      !this.state.fields.total_earning.value ||
      !this.state.fields.date.value ||
      this.state.fields.earning_type_id.value === '' ||
      this.state.fields.arrangement_id.value === '' ||
      this.state.fields.description.value === '' ||
      this.state.fields.total_earning.value === '' ||
      this.state.fields.date.value === ''
  }

  render () {
    let content
    if (!this.props.artistId) {
      return null
    } else if (this.props.earningsLoading) {
      content = <TableLoader cols={columns} />
    } else if ((!this.props.earnings || !this.props.earnings.length) && !this.state.creatingEarning) {
      content = (
        <AlertInfo>
          El artista aún no tiene ingresos. Haz click en <b>Añadir</b> para comenzar a añadir ingresos del asrtista y tenerlos en cuenta en las liquidaciones.
        </AlertInfo>
      )
    } else {
      const artistEarnings = []
      const minLimit = (this.state.page - 1) * RESULTS_PER_PAGE
      const maxLimit = Math.min(this.props.earnings.length, this.state.page * RESULTS_PER_PAGE)

      for (let i = minLimit; i < maxLimit; i++) {
        const earning = this.props.earnings[i]

        artistEarnings.push(
          <TableRow key={earning.id}>
            <TableCell>
              {earning.id}
            </TableCell>
            <TableCell>
              <EarningType>{earning.earning_type.name}</EarningType>
            </TableCell>
            <TableCell>
              <ArrangementLink arrangement={earning.arrangement} artistId={this.props.artistId} />
            </TableCell>
            <TableCell>
              {earning.description}
            </TableCell>
            <TableCell>
              <Earning total={earning.total_earning} settlements={earning.settlements} />
            </TableCell>
            <TableCell>
              {prettyPrintDate(earning.date)}
            </TableCell>
            <TableCell>
              {prettyPrintDateTime(earning.created_at)}
            </TableCell>
            <TableCell>
              <RowActions
                model='Earning'
                id={earning.id}
                handleDelete={() => this.props.deleteEarning(earning.id, this.props.token)}
              />
            </TableCell>
          </TableRow>
        )
      }

      if (this.state.creatingEarning && !this.props.arrangementsLoading && !this.props.earningTypesLoading) {
        const earningTypeOptions = [
          { label: '-', value: '' },
          ...this.props.earningTypes.map(s => {
            return { value: s.id, label: s.name }
          })
        ]

        const arrangementOptions = [
          { label: '-', value: '' },
          ...this.props.arrangements.map(a => {
            return { value: a.id, label: a.name }
          })
        ]

        artistEarnings.push(
          <TableRow key='new'>
            <TableCell className={this.props.classes.tableCellForm}>
              <IconButton color='default' aria-label='undo' size='small' onClick={this.handleEarningFormClose}>
                <ClearIcon />
              </IconButton>
            </TableCell>
            <TableCell className={this.props.classes.tableCellForm}>
              <FormField
                noMargin
                fullWidth
                name='earning_type_id'
                type={this.state.fields.earning_type_id.type}
                autoFocus={this.state.fields.earning_type_id.autofocus}
                required={this.state.fields.earning_type_id.required}
                label={this.state.fields.earning_type_id.label}
                value={this.state.fields.earning_type_id.value}
                handleChange={e => this.handleChange('earning_type_id', e.target.value)}
                options={earningTypeOptions}
              />
            </TableCell>
            <TableCell className={this.props.classes.tableCellForm}>
              <FormField
                noMargin
                fullWidth
                name='arrangement_id'
                type={this.state.fields.arrangement_id.type}
                autoFocus={this.state.fields.arrangement_id.autofocus}
                required={this.state.fields.arrangement_id.required}
                label={this.state.fields.arrangement_id.label}
                value={this.state.fields.arrangement_id.value}
                handleChange={e => this.handleChange('arrangement_id', e.target.value)}
                options={arrangementOptions}
              />
            </TableCell>
            {
              ['description', 'total_earning', 'date'].map((fieldName, key) => {
                const field = this.state.fields[fieldName]
                return (
                  <TableCell key={key} className={this.props.classes.tableCellForm}>
                    <FormField
                      noMargin
                      fullWidth
                      name={fieldName}
                      type={field.type}
                      autoFocus={field.autofocus}
                      required={field.required}
                      label={field.label}
                      value={field.value}
                      accept={field.accept}
                      handleChange={e => this.handleChange(fieldName, e.target.value)}
                    />
                  </TableCell>
                )
              })
            }
            <TableCell className={this.props.classes.tableCellForm} />
            <TableCell className={this.props.classes.tableCellForm}>
              <SaveButton
                model='Earning'
                action='create'
                disabled={this.shouldSaveButtonBeDisabled()}
                onClick={this.handleSaveEarning}
              />
            </TableCell>
          </TableRow>
        )
      }

      content = (
        <Table size='small'>
          <TableHead>
            <TableRow>
              {
                columns.map((col, key) => <TableColumnTitle key={key} {...col} />)
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {artistEarnings}
          </TableBody>
        </Table>
      )
    }

    return (
      <Grid item xs={12}>
        <Paper className={this.props.classes.paper}>
          <div className='ArtistEarnings'>
            <Title>Ingresos del artista</Title>
            <AlertError error={this.props.earningsError} />
            <AlertSuccess message={this.props.earningsSuccess} />
            {content}
            <Grid container spacing={3} className={this.props.classes.tableTools}>
              <Grid item xs={4}>
                {
                  this.props.earnings && this.props.earnings.length
                    ? <Pagination
                        count={calculateNumPages(this.props.earnings, 5)}
                        page={this.state.page}
                        onChange={this.handleEarningsPageChange}
                      />
                    : null
                }
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'center' }}>
                {this.props.earnings ? this.props.earnings.length : 0} resultados
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                <RefreshButton
                  model='Earning'
                  onClick={() => this.props.loadArtistEarnings(this.props.artistId, this.props.token)}
                />
                <AddButton
                  model='Earning'
                  onClick={this.handleEarningFormOpen}
                />
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    selectedArtist: state.artists.selectedArtist,
    earnings: state.artists.earnings,
    arrangementsLoading: state.artists.arrangementsLoading,
    arrangements: state.artists.arrangements,
    earningTypesLoading: state.earningTypes.loading,
    earningTypes: state.earningTypes.earningTypes,
    earningsLoading: state.artists.earningsLoading,
    earningsError: state.artists.earningsError,
    earningsSuccess: state.artists.earningsSuccess
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadEarningTypes: token => dispatch(actions.loadEarningTypes(token)),
    loadArtistArrangements: (artistId, token) => dispatch(actions.loadArtistArrangements(artistId, token)),
    loadArtistEarnings: (artistId, token) => dispatch(actions.loadArtistEarnings(artistId, token)),
    deleteEarning: (id, token) => dispatch(actions.deleteEarning(id, token)),
    saveEarning: (earningId, token, body) => dispatch(actions.saveEarning(earningId, token, body))
  }
}

// Style component.
const ArtistEarningsStyled = props => {
  const classes = useStyles()
  return <ArtistEarningsList classes={classes} {...props} />
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(ArtistEarningsStyled)
