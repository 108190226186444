// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  Grid,
  LinearProgress
} from '@material-ui/core'
import { connect } from 'react-redux'

// Components.
import ArrangementCategory from './ArrangementCategory'

// Redux actions.
import * as actions from '../../../../../../../store/actions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class ArrangementCategories extends Component {
  componentDidMount () {
    // Load the artist categories.
    if (!this.props.artistCategories.length && this.props.selectedArtist) {
      const categoryIds = this.props.dataSources.map(d => d.category.id)
      this.props.loadArtistCategories(categoryIds, this.props.token)
    }
  }

  handleSelectProduct = (product, selected) => {
    this.handleSelectProducts([product], selected, false)
  }

  handleSelectProducts = (products, selected, force = true) => {
    const newProducts = this.props.selectedArrangement && this.props.selectedArrangement.products ? [...this.props.selectedArrangement.products] : []
    for (let i = 0; i < products.length; i++) {
      const product = products[i]
      const productIdx = newProducts.findIndex(p => +p.id === +product.id)
      // Skip if the arrangement is another.
      if (product.arrangement_id && (!this.props.selectedArrangement || (this.props.selectedArrangement && +product.arrangement_id !== +this.props.selectedArrangement.id))) {
        continue
      }
      if (selected && productIdx < 0) {
        // Push it to the products array, as it is selected.
        newProducts.push(product)
      } else if (productIdx >= 0 && (!force || !selected)) {
        // Remove it from the products array, as it isn't selected.
        newProducts.splice(productIdx, 1)
      }
    }
    this.props.updateSelectedArrangement('products', newProducts)
  }

  render () {
    if (!this.props.artistCategories.length || !this.props.selectedArtist) {
      return <LinearProgress />
    }
    return (
      <Grid container spacing={3}>
        {
          this.props.artistCategories.map((c, key) => (
            <Grid item xs={12} key={key}>
              <ArrangementCategory
                key={key}
                category={c}
                arrangement={this.props.selectedArrangement}
                selectedProducts={this.props.selectedArrangement ? this.props.selectedArrangement.products || [] : []}
                onSelectProduct={this.handleSelectProduct}
                onSelectProducts={this.handleSelectProducts}
                user={this.props.user}
              />
            </Grid>
          ))
        }
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    selectedArtist: state.artists.selectedArtist,
    selectedArrangement: state.artists.selectedArrangement,
    artistLoading: state.artists.loading,
    arrangementLoading: state.artists.arrangementLoading,
    dataSources: state.artists.dataSources,
    artistCategories: state.artists.artistCategories,
    artistCategoriesLoading: state.artists.artistCategoriesLoading,
    artistCategoriesError: state.artists.artistCategoriesError
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadArtistCategories: (categoryIds, token) => dispatch(actions.loadArtistCategories(categoryIds, token)),
    updateSelectedArrangement: (key, value) => dispatch(actions.updateSelectedArrangement(key, value))
  }
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(ArrangementCategories)
