// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'

// Components.
import RowActions from '../../../../UI/Table/RowActions'
import NumberOfStuff from '../../../../UI/Custom/NumberOfStuff'
import BooleanField from '../../../../UI/Custom/BooleanField'
import SiteLink from '../../../../UI/Links/SiteLink'
import SiteType from '../../../../UI/Custom/SiteType'
import AlertInfo from '../../../../UI/Alerts/AlertInfo'
import TableColumnTitle from '../../../../UI/Table/TableColumnTitle'
import TableLoader from '../../../../UI/Loaders/TableLoader'

// Shared.
import { RESULTS_PER_PAGE, SITE_SYNC_TYPES } from '../../../../../shared/constants'
import { prettyPrintDateTime } from '../../../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Table columns.
const columns = [
  { name: 'id', label: 'ID', sortable: true },
  { name: 'url', label: 'URL', sortable: true },
  { name: 'type', label: 'Tipo', sortable: true },
  { name: 'n_categories', label: 'Nº categorías', sortable: true },
  { name: 'n_sales', label: 'Nº períodos de rebajas', sortable: true },
  { name: 'synced_to', label: 'Sincronizado con', sortable: true },
  { name: 'sync_type', label: 'Tipo de sincronización', sortable: true },
  { name: 'api_key', label: 'Clave de API', sortable: true },
  { name: 'created_at', label: 'Fecha de creación (UTC)', sortable: true },
  { name: 'updated_at', label: 'Fecha de modificación (UTC)', sortable: true },
  { name: 'actions', label: '' }
]

// Stateless component declaration.
const SitesTable = props => {
  // Loading.
  if (props.loading) {
    return <TableLoader cols={columns} />
  }

  // No results.
  if (!props.sites.length) {
    return <AlertInfo>No se han encontrado resultados.</AlertInfo>
  }

  const sitesTable = []
  const minLimit = (props.page - 1) * RESULTS_PER_PAGE
  const maxLimit = Math.min(props.sites.length, props.page * RESULTS_PER_PAGE)

  for (let i = minLimit; i < maxLimit; i++) {
    const site = props.sites[i]
    sitesTable.push(
      <TableRow key={site.id}>
        <TableCell>
          {site.id}
        </TableCell>
        <TableCell>
          <SiteLink site={site} />
        </TableCell>
        <TableCell>
          <SiteType type={site.type} />
        </TableCell>
        <TableCell>
          <NumberOfStuff>{site.n_categories}</NumberOfStuff>
        </TableCell>
        <TableCell>
          <NumberOfStuff>{site.n_sales}</NumberOfStuff>
        </TableCell>
        <TableCell>
          {site.synced_to ? <SiteLink site={props.sites.find(s => +s.id === site.synced_to)} /> : '-'}
        </TableCell>
        <TableCell>
          {site.sync_type ? <Chip size='small' variant='outlined' label={SITE_SYNC_TYPES[site.sync_type]} /> : '-'}
        </TableCell>
        <TableCell>
          <BooleanField active={!!site.api_key} activeLabel='Sí' inactiveLabel='No' />
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(site.created_at)}
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(site.updated_at)}
        </TableCell>
        <TableCell>
          <RowActions
            model='Site'
            id={site.id}
            editLink={`/site/${site.id}`}
            handleDelete={() => props.deleteSite(site.id)}
          />
        </TableCell>
      </TableRow>
    )
  }

  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          {
            columns.map((col, key) => <TableColumnTitle key={key} {...col} orderBy={props.orderBy} orderDirection={props.orderDirection} onSort={props.onSort} />)
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {sitesTable}
      </TableBody>
    </Table>
  )
}

// Export.
export default SitesTable
