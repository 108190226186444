// ============================================================================
// Dependencies.
// ============================================================================

// Axios instance.
import axios from '../../axios'

// Action types.
import * as actions from './types'

// Functions.
import { getErrorMessage } from '../../shared/functions'

// ============================================================================
// Action creators.
// ============================================================================

// Load product scheduled discounts start.
export const loadProductScheduledDiscountsStart = () => {
  return {
    type: actions.LOAD_PRODUCT_SCHEDULED_DISCOUNTS_START
  }
}

// Load product scheduled discounts success.
export const loadProductScheduledDiscountsSuccess = (productScheduledDiscounts, cronNextExecutionTime, currentlyExecuting) => {
  return {
    type: actions.LOAD_PRODUCT_SCHEDULED_DISCOUNTS_SUCCESS,
    productScheduledDiscounts,
    cronNextExecutionTime,
    currentlyExecuting
  }
}

// Load product scheduled discounts fail.
export const loadProductScheduledDiscountsFailed = error => {
  return {
    type: actions.LOAD_PRODUCT_SCHEDULED_DISCOUNTS_FAILED,
    error
  }
}

// Load product scheduled discounts.
export const loadProductScheduledDiscounts = token => {
  return dispatch => {
    dispatch(loadProductScheduledDiscountsStart())
    // Perform request.
    axios(token).get('/product_scheduled_discounts')
      .then(response => {
        dispatch(loadProductScheduledDiscountsSuccess(response.data.product_scheduled_discounts, response.data.cron_next_execution, response.data.currently_executing))
      })
      .catch(error => {
        dispatch(loadProductScheduledDiscountsFailed(getErrorMessage(error)))
      })
  }
}

// Load product scheduled discount start.
export const loadProductScheduledDiscountStart = () => {
  return {
    type: actions.LOAD_PRODUCT_SCHEDULED_DISCOUNT_START
  }
}

// Load product scheduled discount success.
export const loadProductScheduledDiscountSuccess = productScheduledDiscount => {
  return {
    type: actions.LOAD_PRODUCT_SCHEDULED_DISCOUNT_SUCCESS,
    productScheduledDiscount
  }
}

// Load product scheduled discount fail.
export const loadProductScheduledDiscountFailed = error => {
  return {
    type: actions.LOAD_PRODUCT_SCHEDULED_DISCOUNT_FAILED,
    error
  }
}

// Load product scheduled discount.
export const loadProductScheduledDiscount = (id, token) => {
  return dispatch => {
    if (!id) {
      dispatch(loadProductScheduledDiscountSuccess(null))
      return
    }
    dispatch(loadProductScheduledDiscountStart())
    // Perform request.
    axios(token).get(`/product_scheduled_discount/${id}`)
      .then(response => {
        dispatch(loadProductScheduledDiscountSuccess(response.data))
      })
      .catch(error => {
        dispatch(loadProductScheduledDiscountFailed(getErrorMessage(error)))
      })
  }
}

// Update a product scheduled discount from the list.
export const updateSelectedProductScheduledDiscount = (key, value, multiple) => {
  return {
    type: actions.UPDATE_SELECTED_PRODUCT_SCHEDULED_DISCOUNT,
    key,
    value,
    multiple
  }
}

// Start saving a product scheduled discount.
export const saveProductScheduledDiscountStart = productScheduledDiscount => {
  return {
    type: actions.SAVE_PRODUCT_SCHEDULED_DISCOUNT_START,
    productScheduledDiscount
  }
}

// Save product scheduled discount success.
export const saveProductScheduledDiscountSuccess = (id, productScheduledDiscount) => {
  return {
    type: actions.SAVE_PRODUCT_SCHEDULED_DISCOUNT_SUCCESS,
    id,
    productScheduledDiscount
  }
}

// Save product scheduled discount fail.
export const saveProductScheduledDiscountFailed = (id, error) => {
  return {
    type: actions.SAVE_PRODUCT_SCHEDULED_DISCOUNT_FAILED,
    id,
    error
  }
}

// Save a product scheduled discount.
export const saveProductScheduledDiscount = (id, token, body = {}) => {
  return dispatch => {
    dispatch(saveProductScheduledDiscountStart())
    // Perform request.
    body = body || {}
    const url = id ? `/product_scheduled_discount/${id}` : '/product_scheduled_discount'
    const method = id ? 'put' : 'post'
    axios(token)[method](url, {
      product_ids: body.product_ids && body.product_ids.length ? body.product_ids.map(id => +id) : [],
      type: body.type,
      discount: +body.discount,
      date_from: body.date_from ? body.date_from : null,
      date_to: body.date_to ? body.date_to : null
    })
      .then(response => {
        dispatch(saveProductScheduledDiscountSuccess(id, response.data))
      })
      .catch(error => {
        dispatch(saveProductScheduledDiscountFailed(id, getErrorMessage(error)))
      })
  }
}

// Delete product scheduled discount start.
export const deleteProductScheduledDiscountStart = () => {
  return {
    type: actions.DELETE_PRODUCT_SCHEDULED_DISCOUNT_START
  }
}

// Delete product scheduled discount success.
export const deleteProductScheduledDiscountSuccess = id => {
  return {
    type: actions.DELETE_PRODUCT_SCHEDULED_DISCOUNT_SUCCESS,
    id
  }
}

// Delete product scheduled discount fail.
export const deleteProductScheduledDiscountFailed = error => {
  return {
    type: actions.DELETE_PRODUCT_SCHEDULED_DISCOUNT_FAILED,
    error
  }
}

// Delete product scheduled discount.
export const deleteProductScheduledDiscount = (id, token) => {
  return dispatch => {
    dispatch(deleteProductScheduledDiscountStart())
    // Perform request.
    axios(token).delete(`/product_scheduled_discount/${id}`)
      .then(response => {
        dispatch(deleteProductScheduledDiscountSuccess(id))
      })
      .catch(error => {
        dispatch(deleteProductScheduledDiscountFailed(getErrorMessage(error)))
      })
  }
}

// Activate product scheduled discount success.
export const activateProductScheduledDiscountSuccess = (id, productScheduledDiscount) => {
  return {
    type: actions.ACTIVATE_PRODUCT_SCHEDULED_DISCOUNT_SUCCESS,
    id,
    productScheduledDiscount
  }
}

// Activate a product scheduled discount.
export const activateProductScheduledDiscount = (id, token) => {
  return dispatch => {
    dispatch(saveProductScheduledDiscountStart())
    // Perform request.
    axios(token).put(`/product_scheduled_discount/activate/${id}`, {})
      .then(response => {
        dispatch(activateProductScheduledDiscountSuccess(id, response.data))
      })
      .catch(error => {
        dispatch(saveProductScheduledDiscountFailed(id, getErrorMessage(error)))
      })
  }
}

// Expire product scheduled discount success.
export const expireProductScheduledDiscountSuccess = (id, productScheduledDiscount) => {
  return {
    type: actions.EXPIRE_PRODUCT_SCHEDULED_DISCOUNT_SUCCESS,
    id,
    productScheduledDiscount
  }
}

// Expire a product scheduled discount.
export const expireProductScheduledDiscount = (id, token) => {
  return dispatch => {
    dispatch(saveProductScheduledDiscountStart())
    // Perform request.
    axios(token).put(`/product_scheduled_discount/expire/${id}`, {})
      .then(response => {
        dispatch(expireProductScheduledDiscountSuccess(id, response.data))
      })
      .catch(error => {
        dispatch(saveProductScheduledDiscountFailed(id, getErrorMessage(error)))
      })
  }
}
