// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { Container } from '@material-ui/core'

// Style.
import './ErrorPage.css'

// Components.
import ErrorBox from '../UI/ErrorBox/ErrorBox'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const ErrorPage = (props) => {
  // Default status.
  const status = props.status || 404

  // Get the error description, depending on the status.
  let description = null
  switch (+props.status) {
    case 404:
      description = 'Página no encontrada'
      break
    default:
      description = 'Error desconocido'
  }

  return (
    <Container component='main' maxWidth='xs' className='ErrorPage'>
      <ErrorBox status={status} description={description} />
    </Container>
  )
}

// Export.
export default ErrorPage
