import { emphasize, makeStyles } from '@material-ui/core/styles'

// Default drawer width.
const drawerWidth = 280

// Styles definition function.
export const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  rootWrap: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  toolbar: {
    paddingRight: theme.spacing()
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  appBar: {
    backgroundColor: '#000000',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    })
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  navAvatar: {
    marginRight: 10,
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    }
  },
  menuButton: {
    marginRight: 36,
    [theme.breakpoints.only('xs')]: {
      marginRight: 16
    }
  },
  mb: {
    marginBottom: theme.spacing()
  },
  mr: {
    marginRight: theme.spacing()
  },
  ml: {
    marginLeft: theme.spacing()
  },
  mt: {
    marginTop: theme.spacing()
  },
  mrb: {
    marginRight: theme.spacing(),
    marginBottom: theme.spacing()
  },
  mtb: {
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1)
  },
  mb2: {
    marginBottom: theme.spacing(2)
  },
  mr2: {
    marginRight: theme.spacing(2)
  },
  mt2: {
    marginTop: theme.spacing(2)
  },
  mb3: {
    marginBottom: theme.spacing(3)
  },
  mr3: {
    marginRight: theme.spacing(3)
  },
  mt3: {
    marginTop: theme.spacing(3)
  },
  mb4: {
    marginBottom: theme.spacing(4)
  },
  mr4: {
    marginRight: theme.spacing(4)
  },
  mt4: {
    marginTop: theme.spacing(4)
  },
  menuButtonHidden: {
    display: 'none'
  },
  titleContainer: {
    flexGrow: 1
  },
  titleLink: {
    color: 'white',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'none'
    }
  },
  title: {
    [theme.breakpoints.only('xs')]: {
      fontSize: '1rem !important'
    }
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9)
    }
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  container: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(4)
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column'
  },
  paperInner: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
    marginTop: 30,
    marginBottom: 20,
    marginLeft: 10,
    marginRight: 10
  },
  tableFilter: {
    marginTop: 10,
    marginBottom: 25,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 25,
    paddingRight: 25,
    backgroundColor: '#f6f6f6',
    boxShadow: '2px 3px 3px #ccc'
  },
  selectionSummary: {
    marginTop: 20,
    marginBottom: 25,
    paddingTop: 20,
    paddingBottom: 20,
    paddingLeft: 25,
    paddingRight: 25,
    backgroundColor: '#f6f6f6',
    boxShadow: '2px 3px 3px #ccc',
    width: '100%'
  },
  selectionSummaryFooter: {
    marginTop: 30,
    textAlign: 'center',
    fontStyle: 'italic',
    color: '#999'
  },
  tabs: {
    marginBottom: 20
  },
  tableFilterTitle: {
    marginTop: 0,
    marginBottom: 10
  },
  inlineSubtitle: {
    margin: 0,
    marginLeft: 8,
    fontSize: 17
  },
  fixedHeight: {
    height: 240
  },
  avatar: {
    display: 'inline-block',
    margin: theme.spacing(1),
    height: 40,
    width: 40
  },
  avatarImage: {
    maxHeight: 40,
    maxWidth: 40
  },
  saveButton: {
    textAlign: 'right'
  },
  margin: {
    margin: theme.spacing(1)
  },
  alert: {
    marginTop: 10,
    marginBottom: 10
  },
  tableTools: {
    marginTop: 10,
    marginBottom: 10
  },
  cardGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
    paddingLeft: 0,
    paddingRight: 0
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  cardMedia: {
    paddingTop: '56.25%' // 16:9
  },
  cardContent: {
    flexGrow: 1
  },
  noUnderlineOnHover: {
    '&:hover': {
      textDecoration: 'none'
    }
  },
  tableCellForm: {
    padding: theme.spacing(1)
  },
  breadcrumbElement: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
      cursor: 'pointer',
      textDecoration: 'none'
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12)
    }
  },
  lastBreadcrumbElement: {
    backgroundColor: 'transparent',
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular
  },
  activeFilters: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    listStyle: 'none',
    padding: 0,
    margin: 0,
    marginTop: 8,
    marginBottom: 2
  },
  activeFilter: {
    margin: 0,
    marginRight: theme.spacing(0.5)
  },
  formSection: {
    width: '100%',
    marginTop: 20,
    marginBottom: 10
  },
  dateToolsTitle: {
    width: '100%',
    marginTop: 0,
    marginBottom: 10,
    paddingLeft: 10,
    fontSize: '1em'
  },
  formHelperBlock: {
    width: '100%',
    marginTop: 0,
    marginBottom: 20
  },
  noBorderBottom: {
    borderBottom: 'none'
  },
  productPageBlock: {
    width: '100%',
    marginTop: 0,
    marginBottom: 15
  },
  userImageContainer: {
    border: '1px solid #e6e6e6',
    boxShadow: '2px 2px 6px #ddd',
    padding: 15
  },
  userImage: {
    width: '100%'
  },
  productImageContainer: {
    border: '1px solid #e6e6e6',
    boxShadow: '2px 2px 6px #ddd',
    padding: 15
  },
  productImage: {
    maxWidth: '100%'
  },
  priceBlock: {
    border: 'none',
    cursor: 'default',
    height: '32px',
    display: 'inline-flex',
    outline: '0',
    padding: '0',
    boxSizing: 'border-box',
    alignItems: 'center',
    verticalAlign: 'middle',
    justifyContent: 'center',
    textDecoration: 'none'
  },
  oldPrice: {
    textDecoration: 'line-through',
    marginRight: 5,
    opacity: 0.5,
    fontSize: '0.9em'
  },
  newPrice: {
    fontWeight: 'bold'
  },
  artistStatsCardPlaceholder: {
    maxHeight: 150,
    opacity: 0.3
  },
  topMenuLink: {
    color: 'white',
    textDecoration: 'none',
    [theme.breakpoints.only('xs')]: {
      display: 'none'
    },
    '&:hover': {
      textDecoration: 'none'
    }
  },
  center: {
    textAlign: 'center'
  },
  helperChip: {
    marginTop: 3
  },
  fs09: {
    fontSize: '0.9em'
  },
  fs08: {
    fontSize: '0.8em'
  },
  fs07: {
    fontSize: '0.7em'
  },
  fs06: {
    fontSize: '0.6em'
  },
  chipLink: {
    cursor: 'pointer !important'
  }
}), { index: 999 })
