// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { useStyles } from '../../styles'

// Shared.
import { buildUrl } from '../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const UserImage = props => {
  const classes = useStyles()
  let imgAlt = ''
  let imgUri = '/placeholder.gif'
  if (!props.loading && props.user && props.token && props.user.image) {
    imgAlt = props.user.name
    imgUri = buildUrl(props.user.image, { _t: props.token, ck: Date.now() })
  }
  return (
    <div className={classes.userImageContainer}>
      <img className={classes.userImage} alt={imgAlt} src={imgUri} />
    </div>
  )
}

// Export.
export default UserImage
