// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { Chip, Typography, Tooltip } from '@material-ui/core'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'

// Shared.
import { printPrice, prettyPrintDate } from '../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const Expense = props => {
  let settlementStatus = null
  let settlementDate = null
  let totalSettled = 0
  if (typeof props.settlements === 'object' && props.settlements.length) {
    for (let i = 0; i < props.settlements.length; i++) {
      totalSettled += (+props.settlements[i].total_settled)
    }
    const lastSettlement = props.settlements[props.settlements.length - 1]
    settlementDate = prettyPrintDate(lastSettlement.created_at)
    settlementStatus = (totalSettled === +lastSettlement.total_expense ? 'full' : 'partial')
  }

  let variant = 'outlined'
  let color = 'default'
  let text = null
  switch (settlementStatus) {
    case 'partial':
      variant = 'outlined'
      color = 'primary'
      text = (
        <div style={{ marginTop: 5 }}>
          <Tooltip title={`Por liquidar: ${printPrice(+props.total - totalSettled)}`} arrow placement='right'>
            <Chip icon={<MoneyOffIcon />} variant='default' color='default' size='small' label={printPrice(totalSettled)} />
          </Tooltip>
        </div>
      )
      break
    case 'full':
      variant = 'default'
      color = 'primary'
      text = (
        <div>
          <Tooltip title={`Fecha: ${settlementDate}`} arrow placement='right'>
            <Typography variant='caption' color='textSecondary' align='center' style={{ fontSize: '0.8em' }}>100% liquidado{+totalSettled !== +props.total ? ` (${printPrice(+totalSettled)})` : null}</Typography>
          </Tooltip>
        </div>
      )
      break
    default:
  }

  return (
    <>
      <Chip
        variant={variant}
        color={color}
        size='small'
        label={printPrice(+props.total)}
      />
      {text}
    </>
  )
}

// Export.
export default Expense
