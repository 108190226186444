// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  TableCell,
  TableRow,
  LinearProgress,
  IconButton
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'

// Components.
import FormField from '../../../../../UI/Form/FormField'
import RowActions from '../../../../../UI/Table/RowActions'
import SaveButton from '../../../../../UI/Buttons/SaveButton'

// Shared.
import { prettyPrintDateTime } from '../../../../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class PosTerminalsTableRow extends Component {
  state = {
    editing: false,
    fields: {
      name: {
        label: 'Nº asignado',
        error: null,
        type: 'number',
        required: true,
        touched: false,
        errMsg: 'Por favor, proporciona un nombre válido.',
        autoFocus: true
      },
      bank_serial_number: {
        label: 'Nº de serie banco',
        error: null,
        type: 'text',
        required: true,
        touched: false,
        errMsg: 'Por favor, proporciona un número de serie válido.',
        autoFocus: false
      },
      terminal_serial_number: {
        label: 'Nº de serie TPV',
        error: null,
        type: 'text',
        required: true,
        touched: false,
        errMsg: 'Por favor, proporciona un número de serie válido.',
        autoFocus: false
      }
    }
  }

  handleChange = (elemKey, newValue) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields[elemKey]
    }
    // Modify the element value.
    newElement.value = newValue
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.required && !newValue.length && newElement.touched) {
      newElement.error = 'Campo obligatorio'
    } else if (newElement.validation && newValue.length && !newElement.validation(newValue)) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Build new state.
    const newState = {
      editing: true,
      fields: {
        ...this.state.fields,
        [elemKey]: newElement
      }
    }
    // Update the state.
    this.setState(newState)
  }

  onInlineUpdateStart = () => {
    // Build new state.
    const newState = {
      editing: true,
      fields: {
        ...this.state.fields
      }
    }
    // Update value of the fields.
    newState.fields.name.value = this.props.posTerminal.name
    newState.fields.bank_serial_number.value = this.props.posTerminal.bank_serial_number
    newState.fields.terminal_serial_number.value = this.props.posTerminal.terminal_serial_number
    // Update the state.
    this.setState(newState)
  }

  handleStopInlineUpdate = () => {
    this.setState({ editing: false })
  }

  shouldSaveButtonBeDisabled = () => {
    return this.props.loading ||
      !this.state.fields.name.value ||
      this.state.fields.name.value === '' ||
      !this.state.fields.bank_serial_number.value ||
      this.state.fields.bank_serial_number.value === '' ||
      !this.state.fields.terminal_serial_number.value ||
      this.state.fields.terminal_serial_number.value === ''
  }

  handleSavePosTerminal = () => {
    // Save the POS terminal.
    this.props.handleSavePosTerminal({
      name: this.state.fields.name.value,
      bank_serial_number: this.state.fields.bank_serial_number.value,
      terminal_serial_number: this.state.fields.terminal_serial_number.value
    })
    // Stop inline update.
    this.handleStopInlineUpdate()
  }

  render () {
    if (this.props.loading) {
      return <LinearProgress />
    }
    return (
      <TableRow key={this.props.id}>
        <TableCell>
          {
            this.state.editing
              ? (
                <IconButton color='default' aria-label='undo' size='small' onClick={this.handleStopInlineUpdate}>
                  <ClearIcon />
                </IconButton>
                )
              : this.props.id
          }
        </TableCell>
        {
          Object.keys(this.state.fields).map((fieldName, key) => {
            const field = this.state.fields[fieldName]
            return (
              <TableCell key={key}>
                {
                  this.state.editing
                    ? (
                      <FormField
                        noMargin
                        fullWidth
                        name={fieldName}
                        type={field.type}
                        autoFocus={field.autofocus}
                        required={field.required}
                        label={field.label}
                        value={field.value}
                        handleChange={e => this.handleChange(fieldName, e.target.value)}
                      />
                      )
                    : this.props.posTerminal[fieldName]
                }
              </TableCell>
            )
          })
        }
        <TableCell>
          {prettyPrintDateTime(this.props.posTerminal.createdAt)}
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(this.props.posTerminal.updatedAt)}
        </TableCell>
        <TableCell>
          {
            this.state.editing
              ? (
                <SaveButton
                  model='PosTerminal'
                  action='update'
                  disabled={this.shouldSaveButtonBeDisabled()}
                  onClick={this.handleSavePosTerminal}
                />
                )
              : (
                <RowActions
                  model='PosTerminal'
                  id={this.props.id}
                  handleDelete={() => this.props.onDeletePosTerminal()}
                  handleInlineUpdate={this.onInlineUpdateStart}
                  extraActions={[
                    { label: 'Ver movimientos', handleAction: this.props.handleOpenStockMovementsDialog, actionName: 'read' }
                  ]}
                />
                )
          }
        </TableCell>
      </TableRow>
    )
  }
}

// ============================================================================
// Export.
// ============================================================================

// Export.
export default PosTerminalsTableRow
