// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import * as _ from 'lodash'
import moment from 'moment'
import 'moment/locale/es'

// Constants.
import {
  SITE_SYNC_TYPES,
  SITE_TYPES,
  FORM_ERRORS,
  RESULTS_PER_PAGE,
  MONTHS,
  DISCOUNT_TYPES,
  NUMERIC_VALUE_NAMES
} from './constants'

// Translations.
import translations from './translations/es_ES'

// =============================================================================
// Functions.
// =============================================================================

// Set the locale of the dates.
moment.locale('es')

// Update an object with new one.
export const updateObject = (oldObject, updatedProps) => {
  return {
    ...oldObject,
    ...updatedProps
  }
}

// Get an error message with the API standarized syntax.
export const getErrorMessage = (error) => {
  if (error.response && error.response.data) {
    error = error.response.data
  }
  const r = { message: error.message, details: [] }
  if (error.details) {
    if (typeof error.details === 'object') {
      if (error.details.message) {
        r.details.push(error.details.message)
      } else {
        for (const key in error.details) {
          if (Object.prototype.hasOwnProperty.call(error.details, key)) {
            if (typeof error.details[key] === 'object') {
              r.details.push(getFormErrorMessage(error.details[key]))
            } else {
              r.details.push(error.details[key])
            }
          }
        }
      }
    } else {
      r.details.push(error.details)
    }
  }
  return r
}

// Pretty print form errors.
export const getFormErrorMessage = details => details.message && !/is not a valid/.test(details.message) ? details.message : FORM_ERRORS[details.error].replace('%s', details.field)

// Validate an e-mail.
export const validateEmail = (email) => {
  return /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(email)
}

// Validate a password.
export const validatePassword = password => password.length > 3

// Validate a URL.
export const validateURL = url => /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/.test(url)

// Validate a shop type.
export const validateShopType = shopType => _.includes(_.keys(SITE_TYPES), shopType)

// Validate a discount type.
export const validateDiscountType = discountType => _.includes(_.keys(DISCOUNT_TYPES), discountType)

// Validate a positive number.
export const validatePositive = val => +val > 0

// Validate a shop sync type.
export const validateSyncType = syncType => _.includes(_.keys(SITE_SYNC_TYPES), syncType)

// Get a key - value object as an options array for a SELECT element.
export const getOptionsArray = (obj, allowNull = true) => {
  const r = []
  if (allowNull) {
    r.push({ value: '', label: '-' })
  }
  for (const key in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, key)) {
      r.push({ value: key, label: obj[key] })
    }
  }
  return r
}

// Pretty-print a date.
export const prettyPrintDateTime = date => moment(date).format('YYYY-MM-DD HH:mm:ss')

// Format a date for the date time picker.
export const formatDateTimePickerDate = date => moment(date).format('YYYY-MM-DDTHH:mm')

// Pretty-print a date.
export const prettyPrintDate = date => moment(date).format('YYYY-MM-DD')

// Print human readable datetime.
export const printReadableDateTime = date => moment(date).format('LLL')

// Pretty print a short date.
export const prettyPrintShortDate = date => moment(date).format('D MMM')

// Calculate number of pages.
export const calculateNumPages = (results, rpp = RESULTS_PER_PAGE) => Math.ceil(results.length / rpp)

// Dangerously print HTML.
export const printHTML = html => {
  return <div dangerouslySetInnerHTML={{ __html: html }} />
}

// Print a price in €.
export const printPrice = price => {
  let priceStr = (+price).toFixed(2)
  priceStr = priceStr.replace('.', ',')
  return `${printNumber(priceStr)} €`
}

// Print a number, with dots.
export const printNumber = number => {
  if (typeof number === 'number') number = `${number}`
  return number.replace(/\B(?=(\d{3})+(?!\d))/g, '.')
}

// Validate a percentage.
export const validatePercentage = val => +val <= 100 && +val >= 0

// Build a URL with parameters.
export const buildUrl = (url, params = {}) => {
  const currentQueryParts = url.split('?')
  if (currentQueryParts.length > 1) {
    const currentQueryString = currentQueryParts[1]
      .split('&')
      .map(e => {
        const parts = e.split('=')
        return { name: parts[0], value: parts[1] }
      })
    const currentParams = {}
    for (let i = 0; i < currentQueryString.length; i++) {
      currentParams[currentQueryString[i].name] = currentQueryString[i].value
    }
    url = currentQueryParts[0]
    params = { ...currentParams, ...params }
  }
  const queryString = _.keys(params).map(k => `${k}=${params[k]}`).join('&')
  return `${url}?${queryString}`
}

// Convert a string to a specific type.
export const convertType = (str, type, key) => {
  switch (type) {
    case 'select': /* fall through */
    case 'autocomplete':
      if (/_id$/.test(key) || /Id$/.test(key) || NUMERIC_VALUE_NAMES.indexOf(key) !== -1) return +str
      return str
    case 'number':
      return +str
    case 'date':
      return new Date(str)
    default:
      return str
  }
}

// Apply a filter.
export const applyFilter = (objArray, filterObj, orderBy = null, orderDirection = 'asc') => {
  let filteredObjArray = objArray
  for (const key in filterObj) {
    if (Object.prototype.hasOwnProperty.call(filterObj, key)) {
      // Return, if the value of the filter is null.
      if (!filterObj[key].value) continue
      // Convert the value to the specified type.
      const value = convertType(filterObj[key].value, filterObj[key].type, key)
      const stringParts = `${value}`.replace(/[\s]+/g, ' ').split(' ')
      const regularExpressions = stringParts.map(s => new RegExp(includeSpecialCharsRegExp(s), 'gi'))
      // Perform actions depending on the filter operand.
      switch (filterObj[key].operand) {
        case 'equals':
          filteredObjArray = objArray.filter(el => convertType(el[filterObj[key].key], filterObj[key].type, key) === value)
          break
        case 'gte':
          filteredObjArray = objArray.filter(el => convertType(el[filterObj[key].key], filterObj[key].type, key) >= value)
          break
        case 'lte':
          filteredObjArray = objArray.filter(el => convertType(el[filterObj[key].key], filterObj[key].type, key) <= value)
          break
        case 'lt':
          filteredObjArray = objArray.filter(el => convertType(el[filterObj[key].key], filterObj[key].type, key) < value)
          break
        case 'gt':
          filteredObjArray = objArray.filter(el => convertType(el[filterObj[key].key], filterObj[key].type, key) > value)
          break
        case 'match': /* string search */
          filteredObjArray = []
          for (let i = 0; i < objArray.length; i++) {
            let allPartsMatch = true
            for (let j = 0; j < regularExpressions.length; j++) {
              if (!regularExpressions[j].test(objArray[i][filterObj[key].key])) {
                allPartsMatch = false
                break
              }
            }
            if (allPartsMatch) filteredObjArray.push(objArray[i])
          }
          break
        default:
      }
    }
  }
  // Apply the order.
  if (orderBy) {
    filteredObjArray = _.orderBy(filteredObjArray, orderBy, orderDirection)
  }
  return filteredObjArray
}

// Replace special characters in a string regular expression.
export const includeSpecialCharsRegExp = str => {
  return str
    .replace(/a/g, '[aáàäâ]{1}')
    .replace(/e/g, '[eéèëê]{1}')
    .replace(/i/g, '[iíìïî]{1}')
    .replace(/o/g, '[oóòöô]{1}')
    .replace(/u/g, '[uúùüû]{1}')
}

// Get the value of an URL parameter.
export const getUrlParam = (name, url) => {
  name = name.replace(/[[\]]/g, '\\$&')
  const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)')
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ''
  return decodeURIComponent(results[2].replace(/\+/g, ' '))
}

// Returns true if two arrays are equal.
export const areArraysEqual = (a, b) => {
  if (a === b) return true
  if (a == null || b == null) return false
  if (a.length !== b.length) return false
  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false
  }
  return true
}

// Pretty-print the name of a month.
export const printMonthName = month => {
  const monthIdx = MONTHS.findIndex(m => +m.value === +month)
  if (monthIdx) return MONTHS[monthIdx].label
  return month
}

// Remove duplicates from an array of objects.
export const removeDuplicates = arr => {
  const uniqueArray = []
  for (let i = 0; i < arr.length; i++) {
    const newElement = arr[i]
    let alreadyPresent = false
    for (let j = 0; j < uniqueArray.length; j++) {
      const alreadyPresentElement = uniqueArray[j]
      let allPropsEqual = true
      for (const key in alreadyPresentElement) {
        if (Object.prototype.hasOwnProperty.call(alreadyPresentElement, key)) {
          allPropsEqual &= newElement[key] === alreadyPresentElement[key]
        }
      }
      if (allPropsEqual) {
        alreadyPresent = true
        break
      }
    }
    if (!alreadyPresent) uniqueArray.push(newElement)
  }
  return uniqueArray
}

// Calculate the sale price.
export const calculateSalePrice = (price, discountType, discountValue) => {
  switch (discountType) {
    case 'percentage':
      return price * (1 - discountValue / 100)
    case 'quantity':
      return price - discountValue
    default:
      return price
  }
}

// Calculate the difference between two arrays.
export const arrayDiff = (arr1, arr2) => arr1.filter(x => !arr2.includes(x))

// Returns true if the logged user can perform a specific action.
export const userCan = (user, model, action) => {
  if (!model) return true
  return user &&
    user.profile &&
    user.profile.permissions &&
    user.profile.permissions[model] &&
    user.profile.permissions[model].length &&
    user.profile.permissions[model].indexOf(action) !== -1
}

// Translate content to the default language.
// For example: 'sales_report' will become 'Reporte de ventas'.
export const tx = content => {
  return translations[content] || content
}

// Returns true if a user is allowed to view artist-protected content.
export const canSeeArtistContent = (loggedUser, artistId) => {
  if (!loggedUser) return true
  if (!artistId) return true
  if (!loggedUser.artist_id) return true
  return +loggedUser.artist_id === +artistId
}

// Pretty-print the stats periods.
export const getStatsPeriodPrettyName = (period, uppercaseFirstLetter = false) => {
  let str = period
  switch (period) {
    case 'day': str = 'desde ayer a las 0:00'; break
    case 'week': str = 'en los últimos 7 días'; break
    case 'month': str = 'en los últimos 31 días'; break
    case 'year': str = 'en los últimos 365 días'; break
    default:
  }
  return uppercaseFirstLetter ? ucFirst(str) : str
}

// Uppercase first letter.
export const ucFirst = str => str.charAt(0).toUpperCase() + str.slice(1)

// Build an array of fixed length.
export const buildArray = (length, value = null) => {
  const r = []
  for (let i = 0; i < length; i++) {
    r.push(value)
  }
  return r
}

// Get min. date for a fixed period, such as 'week' or 'month'.
export const getMinDateForPeriod = statsPeriod => {
  const date = new Date()
  date.setHours(0)
  date.setMinutes(0)
  date.setSeconds(0)
  switch (statsPeriod) {
    case 'day': date.setDate(date.getDate() - 1); break
    case 'week': date.setDate(date.getDate() - 7); break
    case 'month': date.setMonth(date.getMonth() - 1); break
    case 'year': date.setFullYear(date.getFullYear() - 1); break
    default:
  }
  return date
}
