// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'

// Components.
import TabSection from '../../../../UI/Custom/TabSection'
import ArtistForm from './ArtistForm'
import ArtistEarnings from './Earnings/ArtistEarningsList'
import ArtistExpenses from './Expenses/ArtistExpensesList'
import Arrangements from './Arrangements/Arrangements'
import DataSourcesList from './DataSources/DataSourcesList'
import ArtistSettlements from './Settlements/SettlementsList'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const Artist = props => {
  const tabs = {
    form: {
      label: 'Información',
      component: <ArtistForm artistId={props.id} />,
      link: `/artist/${props.id}`,
      permissions: { model: 'Artist', action: 'read' }
    },
    data_sources: {
      label: 'Fuentes de datos',
      component: <DataSourcesList artistId={props.id} />,
      link: `/artist/${props.id}/data_sources`,
      disabled: !props.id,
      permissions: { model: 'DataSource', action: 'list' },
      hideForArtist: true
    },
    arrangements: {
      label: 'Tratos comerciales',
      component: <Arrangements artistId={props.id} />,
      link: `/artist/${props.id}/arrangements`,
      permissions: { model: 'Arrangement', action: 'list' },
      disabled: !props.id
    },
    expenses: {
      label: 'Gastos',
      component: <ArtistExpenses artistId={props.id} />,
      link: `/artist/${props.id}/expenses`,
      permissions: { model: 'Expense', action: 'list' },
      disabled: !props.id
    },
    earnings: {
      label: 'Ingresos',
      component: <ArtistEarnings artistId={props.id} />,
      link: `/artist/${props.id}/earnings`,
      permissions: { model: 'Earning', action: 'list' },
      disabled: !props.id
    },
    settlements: {
      label: 'Liquidaciones',
      component: <ArtistSettlements artistId={props.id} />,
      link: `/artist/${props.id}/settlements`,
      permissions: { model: 'Settlement', action: 'list' },
      disabled: !props.id
    }
  }
  return <TabSection tabs={tabs} {...props} />
}

// Export.
export default Artist
