// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import { React } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'

// Styles.
import { useStyles } from '../../../../../styles'

// Shared.
import { printPrice, calculateSalePrice } from '../../../../../../shared/functions'

// =============================================================================
// Components.
// =============================================================================

// Declare stateless component.
const ProductScheduledDiscountSummary = props => {
  const classes = useStyles()

  if (!props.products || !props.products.length || !props.selectedProductIds || !props.selectedProductIds.length || !props.discountType || !props.discountValue) {
    return null
  }

  const selectedProducts = props.products.filter(p => props.selectedProductIds.indexOf(p.id) !== -1)

  return (
    <div className={classes.selectionSummary}>
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>
              ID producto
            </TableCell>
            <TableCell>
              Nombre producto
            </TableCell>
            <TableCell>
              Precio antiguo
            </TableCell>
            <TableCell>
              Precio después del descuento
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            selectedProducts.map((p, key) => {
              return (
                <TableRow key={key}>
                  <TableCell>
                    {p.id}
                  </TableCell>
                  <TableCell>
                    {p.name}
                  </TableCell>
                  <TableCell>
                    {printPrice(p.price)}
                  </TableCell>
                  <TableCell>
                    {printPrice(calculateSalePrice(p.price, props.discountType, props.discountValue))}
                  </TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
      <p className={classes.selectionSummaryFooter}>Para añadir o quitar productos, edita el valor del campo 'Productos'.</p>
    </div>
  )
}

// Export.
export default ProductScheduledDiscountSummary
