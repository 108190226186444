// ============================================================================
// Dependencies.
// ============================================================================

// Axios instance.
import axios from '../../axios'
import * as _ from 'lodash'

// Action types.
import * as actions from './types'

// Functions.
import { buildUrl, getErrorMessage, prettyPrintDateTime } from '../../shared/functions'

// ============================================================================
// Action creators.
// ============================================================================

// Load artists start.
export const loadArtistsStart = () => {
  return {
    type: actions.LOAD_ARTISTS_START
  }
}

// Load artists success.
export const loadArtistsSuccess = artists => {
  return {
    type: actions.LOAD_ARTISTS_SUCCESS,
    artists
  }
}

// Load artists fail.
export const loadArtistsFailed = error => {
  return {
    type: actions.LOAD_ARTISTS_FAILED,
    error
  }
}

// Load artists.
export const loadArtists = token => {
  return dispatch => {
    dispatch(loadArtistsStart())
    // Perform request.
    axios(token).get('/artists')
      .then(response => {
        dispatch(loadArtistsSuccess(response.data.artists))
      })
      .catch(error => {
        dispatch(loadArtistsFailed(getErrorMessage(error)))
      })
  }
}

// Delete artist start.
export const deleteArtistStart = () => {
  return {
    type: actions.DELETE_ARTIST_START
  }
}

// Delete artist success.
export const deleteArtistSuccess = id => {
  return {
    type: actions.DELETE_ARTIST_SUCCESS,
    id
  }
}

// Delete artist fail.
export const deleteArtistFailed = error => {
  return {
    type: actions.DELETE_ARTIST_FAILED,
    error
  }
}

// Delete artist.
export const deleteArtist = (id, token) => {
  return dispatch => {
    dispatch(deleteArtistStart())
    // Perform request.
    axios(token).delete(`/artist/${id}`)
      .then(() => {
        dispatch(deleteArtistSuccess(id))
      })
      .catch(error => {
        dispatch(deleteArtistFailed(getErrorMessage(error)))
      })
  }
}

// Load artist start.
export const loadArtistStart = () => {
  return {
    type: actions.LOAD_ARTIST_START
  }
}

// Load artist success.
export const loadArtistSuccess = artist => {
  return {
    type: actions.LOAD_ARTIST_SUCCESS,
    artist
  }
}

// Load artist fail.
export const loadArtistFailed = error => {
  return {
    type: actions.LOAD_ARTIST_FAILED,
    error
  }
}

// Load artist.
export const loadArtist = (id, token) => {
  return dispatch => {
    if (!id) {
      dispatch(loadArtistSuccess(null))
      return
    }
    dispatch(loadArtistStart())
    // Perform request.
    axios(token).get(`/artist/${id}`)
      .then(response => {
        dispatch(loadArtistSuccess(response.data))
      })
      .catch(error => {
        dispatch(loadArtistFailed(getErrorMessage(error)))
      })
  }
}

// Update the loaded artist.
export const updateSelectedArtist = (key, value) => {
  return {
    type: actions.UPDATE_SELECTED_ARTIST,
    key,
    value
  }
}

// Start saving an artist.
export const saveArtistStart = artist => {
  return {
    type: actions.SAVE_ARTIST_START,
    artist
  }
}

// Save artist success.
export const saveArtistSuccess = (id, artist) => {
  return {
    type: actions.SAVE_ARTIST_SUCCESS,
    id,
    artist
  }
}

// Save artist fail.
export const saveArtistFailed = (id, error) => {
  return {
    type: actions.SAVE_ARTIST_FAILED,
    id,
    error
  }
}

// Save an artist.
export const saveArtist = (id, token, body = {}) => {
  return dispatch => {
    dispatch(saveArtistStart())
    // Perform request.
    body = body || {}
    const url = id ? `/artist/${id}` : '/artist'
    const method = id ? 'put' : 'post'
    axios(token)[method](url, {
      name: body.name
    })
      .then(response => {
        dispatch(saveArtistSuccess(id, response.data))
      })
      .catch(error => {
        dispatch(saveArtistFailed(id, getErrorMessage(error)))
      })
  }
}

// Delete data source start.
export const deleteDataSourceStart = () => {
  return {
    type: actions.DELETE_DATA_SOURCE_START
  }
}

// Delete data source success.
export const deleteDataSourceSuccess = id => {
  return {
    type: actions.DELETE_DATA_SOURCE_SUCCESS,
    id
  }
}

// Delete data source fail.
export const deleteDataSourceFailed = error => {
  return {
    type: actions.DELETE_DATA_SOURCE_FAILED,
    error
  }
}

// Delete data source.
export const deleteDataSource = (id, token) => {
  return dispatch => {
    dispatch(deleteDataSourceStart())
    // Perform request.
    axios(token).delete(`/data_source/${id}`)
      .then(() => {
        dispatch(deleteDataSourceSuccess(id))
      })
      .catch(error => {
        dispatch(deleteDataSourceFailed(getErrorMessage(error)))
      })
  }
}

// Start saving a data source.
export const saveDataSourceStart = artist => {
  return {
    type: actions.SAVE_DATA_SOURCE_START,
    artist
  }
}

// Save data source success.
export const saveDataSourceSuccess = dataSource => {
  return {
    type: actions.SAVE_DATA_SOURCE_SUCCESS,
    dataSource
  }
}

// Save data source fail.
export const saveDataSourceFailed = error => {
  return {
    type: actions.SAVE_DATA_SOURCE_FAILED,
    error
  }
}

// Save a data source.
export const saveDataSource = (token, artistId, categoryId) => {
  return dispatch => {
    dispatch(saveDataSourceStart())
    // Perform request.
    axios(token).post('/data_source', {
      artist_id: +artistId,
      category_id: +categoryId
    })
      .then(response => {
        dispatch(saveDataSourceSuccess(response.data))
      })
      .catch(error => {
        dispatch(saveDataSourceFailed(getErrorMessage(error)))
      })
  }
}

// Delete arrangement start.
export const deleteArrangementStart = () => {
  return {
    type: actions.DELETE_ARRANGEMENT_START
  }
}

// Delete arrangement success.
export const deleteArrangementSuccess = id => {
  return {
    type: actions.DELETE_ARRANGEMENT_SUCCESS,
    id
  }
}

// Delete arrangement fail.
export const deleteArrangementFailed = error => {
  return {
    type: actions.DELETE_ARRANGEMENT_FAILED,
    error
  }
}

// Delete arrangement.
export const deleteArrangement = (id, token) => {
  return dispatch => {
    dispatch(deleteArrangementStart())
    // Perform request.
    axios(token).delete(`/arrangement/${id}`)
      .then(() => {
        dispatch(deleteArrangementSuccess(id))
      })
      .catch(error => {
        dispatch(deleteArrangementFailed(getErrorMessage(error)))
      })
  }
}

// Load arrangement start.
export const loadArrangementStart = () => {
  return {
    type: actions.LOAD_ARRANGEMENT_START
  }
}

// Load arrangement success.
export const loadArrangementSuccess = arrangement => {
  return {
    type: actions.LOAD_ARRANGEMENT_SUCCESS,
    arrangement
  }
}

// Load arrangement fail.
export const loadArrangementFailed = error => {
  return {
    type: actions.LOAD_ARRANGEMENT_FAILED,
    error
  }
}

// Load arrangement.
export const loadArrangement = (id, token) => {
  return dispatch => {
    if (!id) {
      dispatch(loadArrangementSuccess(null))
      return
    }
    dispatch(loadArrangementStart())
    // Perform request.
    axios(token).get(`/arrangement/${id}`)
      .then(response => {
        dispatch(loadArrangementSuccess(response.data))
      })
      .catch(error => {
        dispatch(loadArrangementFailed(getErrorMessage(error)))
      })
  }
}

// Update the loaded arrangement.
export const updateSelectedArrangement = (key, value) => {
  return {
    type: actions.UPDATE_SELECTED_ARRANGEMENT,
    key,
    value
  }
}

// Save arrangement start.
export const saveArrangementStart = () => {
  return {
    type: actions.SAVE_ARRANGEMENT_START
  }
}

// Save arrangement success.
export const saveArrangementSuccess = (id, arrangement) => {
  return {
    type: actions.SAVE_ARRANGEMENT_SUCCESS,
    id,
    arrangement
  }
}

// Save arrangement fail.
export const saveArrangementFailed = error => {
  return {
    type: actions.SAVE_ARRANGEMENT_FAILED,
    error
  }
}

// Save arrangement.
export const saveArrangement = (id, token, body = {}) => {
  return dispatch => {
    dispatch(saveArrangementStart())
    // Perform request.
    body = body || {}
    const url = id ? `/arrangement/${id}` : '/arrangement'
    const method = id ? 'put' : 'post'
    axios(token)[method](url, {
      artist_id: +body.artist_id,
      name: body.name,
      percentage: +body.percentage,
      manufacturing_enabled: !!body.manufacturing_enabled,
      products: body.products && body.products.length ? body.products.map(p => p.id) : []
    })
      .then(response => {
        dispatch(saveArrangementSuccess(id, response.data))
      })
      .catch(error => {
        dispatch(saveArrangementFailed(getErrorMessage(error)))
      })
  }
}

// Load artist categories start.
export const loadArtistCategoriesStart = () => {
  return {
    type: actions.LOAD_ARTIST_CATEGORIES_START
  }
}

// Load artist categories success.
export const loadArtistCategoriesSuccess = categories => {
  return {
    type: actions.LOAD_ARTIST_CATEGORIES_SUCCESS,
    categories
  }
}

// Load artist categories fail.
export const loadArtistCategoriesFailed = error => {
  return {
    type: actions.LOAD_ARTIST_CATEGORIES_FAILED,
    error
  }
}

// Load artist categories.
export const loadArtistCategories = (categoryIds, token) => {
  return dispatch => {
    if (!categoryIds || !categoryIds.length) {
      dispatch(loadArtistCategoriesSuccess([]))
      return
    }
    dispatch(loadArtistCategoriesStart())
    // Init categories array.
    const categories = []
    // Load first category.
    // Done this way in order not to make the action async.
    axios(token).get(`/category/${_.head(categoryIds)}`)
      .then(async response => {
        categories.push(response.data)
        // Load others.
        for (let i = 1; i < categoryIds.length; i++) {
          const categoryId = categoryIds[i]
          try {
            const newResponse = await axios(token).get(`/category/${categoryId}`)
            categories.push(newResponse.data)
          } catch (error) {
            return dispatch(loadArtistCategoriesFailed(getErrorMessage(error)))
          }
        }
        return dispatch(loadArtistCategoriesSuccess(categories))
      })
      .catch(error => {
        dispatch(loadArtistCategoriesFailed(getErrorMessage(error)))
      })
  }
}

// Save expense start.
export const saveExpenseStart = () => {
  return {
    type: actions.SAVE_EXPENSE_START
  }
}

// Save expense success.
export const saveExpenseSuccess = (id, expense) => {
  return {
    type: actions.SAVE_EXPENSE_SUCCESS,
    id,
    expense
  }
}

// Save expense fail.
export const saveExpenseFailed = error => {
  return {
    type: actions.SAVE_EXPENSE_FAILED,
    error
  }
}

// Save expense.
export const saveExpense = (id, token, arrangementId, expenseTypeId, description, totalExpense, invoiceNumber, attachedFile) => {
  return dispatch => {
    dispatch(saveExpenseStart())
    // Init the form data.
    const formData = new FormData()
    if (arrangementId) formData.append('arrangement_id', arrangementId)
    if (expenseTypeId) formData.append('expense_type_id', expenseTypeId)
    if (description) formData.append('description', description)
    if (totalExpense) formData.append('total_expense', totalExpense)
    if (invoiceNumber) formData.append('invoice_number', invoiceNumber)
    if (attachedFile) formData.append('attached_file', attachedFile)
    // Perform request.
    axios(token).post('/expense', formData, { headers: { 'content-type': 'multipart/form-data' } })
      .then(response => {
        dispatch(saveExpenseSuccess(id, response.data))
      })
      .catch(error => {
        dispatch(saveExpenseFailed(getErrorMessage(error)))
      })
  }
}

// Delete expense start.
export const deleteExpenseStart = () => {
  return {
    type: actions.DELETE_EXPENSE_START
  }
}

// Delete expense success.
export const deleteExpenseSuccess = id => {
  return {
    type: actions.DELETE_EXPENSE_SUCCESS,
    id
  }
}

// Delete expense fail.
export const deleteExpenseFailed = error => {
  return {
    type: actions.DELETE_EXPENSE_FAILED,
    error
  }
}

// Delete expense.
export const deleteExpense = (id, token) => {
  return dispatch => {
    dispatch(deleteExpenseStart())
    // Perform request.
    axios(token).delete(`/expense/${id}`)
      .then(() => {
        dispatch(deleteExpenseSuccess(id))
      })
      .catch(error => {
        dispatch(deleteExpenseFailed(getErrorMessage(error)))
      })
  }
}

// Load artist expenses start.
export const loadExpensesStart = () => {
  return {
    type: actions.LOAD_EXPENSES_START
  }
}

// Load artist expenses success.
export const loadExpensesSuccess = expenses => {
  return {
    type: actions.LOAD_EXPENSES_SUCCESS,
    expenses
  }
}

// Load artist expenses fail.
export const loadExpensesFailed = error => {
  return {
    type: actions.LOAD_EXPENSES_FAILED,
    error
  }
}

// Load artist expenses.
export const loadArtistExpenses = (artistId, token) => {
  return dispatch => {
    dispatch(loadExpensesStart())
    // Perform request.
    axios(token).get(`/artist/expenses/${artistId}`)
      .then(response => {
        dispatch(loadExpensesSuccess(response.data))
      })
      .catch(error => {
        dispatch(loadExpensesFailed(getErrorMessage(error)))
      })
  }
}

// Load artist stats start.
export const loadStatsStart = () => {
  return {
    type: actions.LOAD_ARTIST_STATS_START
  }
}

// Load artist stats success.
export const loadStatsSuccess = stats => {
  return {
    type: actions.LOAD_ARTIST_STATS_SUCCESS,
    stats
  }
}

// Load artist stats fail.
export const loadStatsFailed = error => {
  return {
    type: actions.LOAD_ARTIST_STATS_FAILED,
    error
  }
}

// Load artist stats.
export const loadArtistStats = (artistId, token, minDate = null, maxDate = null) => {
  return dispatch => {
    dispatch(loadStatsStart())
    // Perform request.
    const params = {}
    if (minDate) params.stats_from = prettyPrintDateTime(minDate)
    if (maxDate) params.stats_to = prettyPrintDateTime(maxDate)
    const url = buildUrl(`/artist/stats/${artistId}`, params)
    axios(token).get(url)
      .then(response => {
        dispatch(loadStatsSuccess(response.data))
      })
      .catch(error => {
        dispatch(loadStatsFailed(getErrorMessage(error)))
      })
  }
}

// Load artist data sources start.
export const loadDataSourcesStart = () => {
  return {
    type: actions.LOAD_ARTIST_DATA_SOURCES_START
  }
}

// Load artist data sources success.
export const loadDataSourcesSuccess = dataSources => {
  return {
    type: actions.LOAD_ARTIST_DATA_SOURCES_SUCCESS,
    dataSources
  }
}

// Load artist data sources fail.
export const loadDataSourcesFailed = error => {
  return {
    type: actions.LOAD_ARTIST_DATA_SOURCES_FAILED,
    error
  }
}

// Load artist data sources.
export const loadArtistDataSources = (artistId, token) => {
  return dispatch => {
    dispatch(loadDataSourcesStart())
    // Perform request.
    axios(token).get(`/artist/data_sources/${artistId}`)
      .then(response => {
        dispatch(loadDataSourcesSuccess(response.data))
      })
      .catch(error => {
        dispatch(loadDataSourcesFailed(getErrorMessage(error)))
      })
  }
}

// Load artist arrangements start.
export const loadArrangementsStart = () => {
  return {
    type: actions.LOAD_ARTIST_ARRANGEMENTS_START
  }
}

// Load artist arrangements success.
export const loadArrangementsSuccess = arrangements => {
  return {
    type: actions.LOAD_ARTIST_ARRANGEMENTS_SUCCESS,
    arrangements
  }
}

// Load artist arrangements fail.
export const loadArrangementsFailed = error => {
  return {
    type: actions.LOAD_ARTIST_ARRANGEMENTS_FAILED,
    error
  }
}

// Load artist arrangements.
export const loadArtistArrangements = (artistId, token) => {
  return dispatch => {
    dispatch(loadArrangementsStart())
    // Perform request.
    axios(token).get(`/artist/arrangements/${artistId}`)
      .then(response => {
        dispatch(loadArrangementsSuccess(response.data))
      })
      .catch(error => {
        dispatch(loadArrangementsFailed(getErrorMessage(error)))
      })
  }
}

// Save earning start.
export const saveEarningStart = () => {
  return {
    type: actions.SAVE_EARNING_START
  }
}

// Save earning success.
export const saveEarningSuccess = (id, earning) => {
  return {
    type: actions.SAVE_EARNING_SUCCESS,
    id,
    earning
  }
}

// Save earning fail.
export const saveEarningFailed = error => {
  return {
    type: actions.SAVE_EARNING_FAILED,
    error
  }
}

// Save earning.
export const saveEarning = (id, token, body) => {
  return dispatch => {
    dispatch(saveEarningStart())
    // Perform request.
    body = body || {}
    axios(token).post('/earning', {
      arrangement_id: +body.arrangement_id,
      earning_type_id: +body.earning_type_id,
      description: body.description,
      date: body.date,
      total_earning: +body.total_earning
    })
      .then(response => {
        dispatch(saveEarningSuccess(id, response.data))
      })
      .catch(error => {
        dispatch(saveEarningFailed(getErrorMessage(error)))
      })
  }
}

// Delete earning start.
export const deleteEarningStart = () => {
  return {
    type: actions.DELETE_EARNING_START
  }
}

// Delete earning success.
export const deleteEarningSuccess = id => {
  return {
    type: actions.DELETE_EARNING_SUCCESS,
    id
  }
}

// Delete earning fail.
export const deleteEarningFailed = error => {
  return {
    type: actions.DELETE_EARNING_FAILED,
    error
  }
}

// Delete earning.
export const deleteEarning = (id, token) => {
  return dispatch => {
    dispatch(deleteEarningStart())
    // Perform request.
    axios(token).delete(`/earning/${id}`)
      .then(() => {
        dispatch(deleteEarningSuccess(id))
      })
      .catch(error => {
        dispatch(deleteEarningFailed(getErrorMessage(error)))
      })
  }
}

// Load artist earnings start.
export const loadEarningsStart = () => {
  return {
    type: actions.LOAD_EARNINGS_START
  }
}

// Load artist earnings success.
export const loadEarningsSuccess = earnings => {
  return {
    type: actions.LOAD_EARNINGS_SUCCESS,
    earnings
  }
}

// Load artist earnings fail.
export const loadEarningsFailed = error => {
  return {
    type: actions.LOAD_EARNINGS_FAILED,
    error
  }
}

// Load artist earnings.
export const loadArtistEarnings = (artistId, token) => {
  return dispatch => {
    dispatch(loadEarningsStart())
    // Perform request.
    axios(token).get(`/artist/earnings/${artistId}`)
      .then(response => {
        dispatch(loadEarningsSuccess(response.data))
      })
      .catch(error => {
        dispatch(loadEarningsFailed(getErrorMessage(error)))
      })
  }
}
