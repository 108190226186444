// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
  TableCell,
  TableSortLabel
} from '@material-ui/core'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const TableColumnTitle = props => {
  if (props.sortable && props.orderBy && props.orderDirection && props.onSort) {
    return (
      <TableCell>
        <TableSortLabel
          active={props.orderBy === props.name}
          direction={props.orderBy === props.name ? props.orderDirection : 'asc'}
          onClick={props.onSort(props.name)}
        >
          {props.label}
        </TableSortLabel>
      </TableCell>
    )
  }

  return (
    <TableCell>
      {props.label}
    </TableCell>
  )
}

// Export.
export default TableColumnTitle
