// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'

// Components.
import TabSection from '../../../../UI/Custom/TabSection'
import GenericSettingsList from './GenericSettings/GenericSettingsList'
import ExpenseTypesList from './ExpenseTypes/ExpenseTypesList'
import EarningTypesList from './EarningTypes/EarningTypesList'
import SalesList from './Sales/SalesList'
import PosTerminalsList from './PosTerminals/PosTerminalsList'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const Settings = props => {
  const tabs = {
    generic_settings: {
      label: 'Ajustes generales',
      component: <GenericSettingsList />,
      link: '/settings/generic',
      permissions: { model: 'Configuration', action: 'list' }
    },
    expense_types: {
      label: 'Tipos de gasto',
      component: <ExpenseTypesList />,
      link: '/settings/expense_types',
      permissions: { model: 'ExpenseType', action: 'list' }
    },
    earning_types: {
      label: 'Tipos de ingreso',
      component: <EarningTypesList />,
      link: '/settings/earning_types',
      permissions: { model: 'EarningType', action: 'list' }
    },
    sales: {
      label: 'Períodos de rebajas',
      component: <SalesList />,
      link: '/settings/sales',
      permissions: { model: 'Sale', action: 'list' }
    },
    pos_terminals: {
      label: 'TPVs',
      component: <PosTerminalsList />,
      link: '/settings/pos_terminals',
      permissions: { model: 'PosTerminal', action: 'list' }
    }
  }
  return <TabSection tabs={tabs} {...props} />
}

// Export.
export default Settings
