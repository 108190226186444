// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import * as _ from 'lodash'
import { React, Component } from 'react'
import { Link as RouterLink, Redirect, withRouter } from 'react-router-dom'

// Logo.
import logo from '../logo.png'

// Vendor components.
import {
  Avatar,
  Button,
  CssBaseline,
  TextField,
  Link,
  Grid,
  Box,
  Typography,
  Container,
  CircularProgress
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { connect } from 'react-redux'

// Components.
import AlertError from '../UI/Alerts/AlertError'
import Copyright from '../UI/Brand/Copyright'

// Redux actions.
import * as actions from '../../store/actions'

// Shared.
import {
  validateEmail,
  validatePassword
} from '../../shared/functions'

// =============================================================================
// Styles.
// =============================================================================

const useStyles = makeStyles((theme) => ({
  paper: {
    marginTop: theme.spacing(8),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  avatar: {
    margin: theme.spacing(1),
    height: 100,
    width: 100
  },
  avatarImage: {
    maxHeight: 100,
    maxWidth: 100
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1)
  },
  submit: {
    margin: theme.spacing(3, 0, 2)
  }
}))

// =============================================================================
// Components.
// =============================================================================

// Stateful component declaration.
class AuthPage extends Component {
  state = {
    fields: {
      email: {
        label: 'E-mail',
        type: 'email',
        value: '',
        error: null,
        required: true,
        touched: false,
        validation: validateEmail,
        errMsg: 'Por favor, escribe un e-mail válido',
        autofocus: true
      },
      password: {
        label: 'Contraseña',
        type: 'password',
        value: '',
        error: null,
        required: true,
        touched: false,
        validation: validatePassword,
        errMsg: 'Por favor, escribe una contraseña de al menos 3 caracteres',
        autofocus: false
      }
    }
  }

  shouldSubmitBeDisabled = () => {
    return this.state.fields.email.error !== null ||
      this.state.fields.password.error !== null ||
      !this.state.fields.email.touched ||
      !this.state.fields.password.touched ||
      this.props.loading
  }

  // What to do when the value of an input changes.
  valueChangedHandler = (elemKey, newValue) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields[elemKey]
    }
    // Modify the element value.
    newElement.value = newValue
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.required && !newValue.length && newElement.touched) {
      newElement.error = `El campo ${elemKey} es obligatorio`
    } else if (newElement.validation && !newElement.validation(newValue)) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Update the state.
    this.setState({
      fields: {
        ...this.state.fields,
        [elemKey]: newElement
      }
    })
    // Reset the login error.
    this.props.resetLoginError()
  }

  handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      if (!this.shouldSubmitBeDisabled()) {
        this.handleLogin()
      }
    }
  }

  handleLogin = () => {
    return this.props.login(this.state.fields.email.value, this.state.fields.password.value)
  }

  render () {
    // If user is authenticated, redirect to home.
    if (this.props.isAuth) {
      return <Redirect to={this.props.authRedirectPath} />
    }

    return (
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <div className={this.props.classes.paper}>
          <Avatar className={this.props.classes.avatar}>
            <img src={logo} alt='Tumerchan Logo' className={this.props.classes.avatarImage} />
          </Avatar>
          <Typography component='h1' variant='h5'>
            Iniciar sesión
          </Typography>
          <form autoComplete='off' className={this.props.classes.form} noValidate>
            {
              _.keys(this.state.fields).map((fieldName, idx) => {
                const field = this.state.fields[fieldName]
                return (
                  <TextField
                    onKeyPress={this.handleKeyPress}
                    key={idx}
                    variant='outlined'
                    margin='normal'
                    required
                    fullWidth
                    id={fieldName}
                    label={field.label}
                    placeholder={field.placeholder}
                    name={fieldName}
                    autoFocus={field.autofocus}
                    type={field.type}
                    onChange={e => this.valueChangedHandler(fieldName, e.target.value)}
                    error={!!field.error}
                    helperText={field.error ? field.error : field.helperText}
                  />
                )
              })
            }
            <Button
              type='button'
              fullWidth
              variant='contained'
              color='primary'
              className={this.props.classes.submit}
              onClick={this.handleLogin}
              disabled={this.shouldSubmitBeDisabled()}
            >
              {this.props.loading ? <CircularProgress /> : 'Iniciar sesión'}
            </Button>
            <Grid container>
              <Grid item xs>
                <Link to='/recovery' component={RouterLink} variant='body2'>
                  ¿Has olvidado la contraseña?
                </Link>
              </Grid>
              <Grid item />
            </Grid>
          </form>
        </div>
        <AlertError error={this.props.error} />
        <Box mt={8}>
          <Copyright />
        </Box>
      </Container>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    authRedirectPath: state.auth.authRedirectPath,
    error: state.auth.error,
    loading: state.auth.loading,
    isAuth: state.auth.user !== null
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    resetLoginError: () => dispatch(actions.resetLoginError()),
    login: (email, password) => dispatch(actions.signIn({ email: email, password: password }))
  }
}

// Map state to props.
const AuthComponent = withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthPage))

// Create new container component without state.
const AuthComponentStyled = () => {
  const classes = useStyles()
  return <AuthComponent classes={classes} />
}

export default AuthComponentStyled
