// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { Chip } from '@material-ui/core'
import BlockIcon from '@material-ui/icons/Block'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const BooleanField = props => (
  <Chip
    variant={props.active ? 'outlined' : 'default'}
    color={props.active ? 'primary' : 'default'}
    size='small'
    label={props.active ? props.activeLabel : props.inactiveLabel}
    icon={props.active ? null : <BlockIcon />}
  />
)

// Export.
export default BooleanField
