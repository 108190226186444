// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { Chip } from '@material-ui/core'

// Shared.
import { SCHEDULED_ACTION_STATUSES } from '../../../shared/constants'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const ScheduledActionStatus = props => {
  switch (props.children) {
    case 'scheduled': return (
      <Chip
        size='small'
        label={SCHEDULED_ACTION_STATUSES.scheduled}
        variant='default'
        color='primary'
      />
    )
    case 'scheduling': return (
      <Chip
        size='small'
        label={SCHEDULED_ACTION_STATUSES.scheduling}
        variant='default'
        color='primary'
        style={{ backgroundColor: '#afdbb6' }}
      />
    )
    case 'active': return (
      <Chip
        size='small'
        label={SCHEDULED_ACTION_STATUSES.active}
        variant='default'
        color='primary'
        style={{ backgroundColor: '#4caf50' }}
      />
    )
    case 'expired': return (
      <Chip
        size='small'
        label={SCHEDULED_ACTION_STATUSES.expired}
        variant='default'
        color='default'
      />
    )
    default: return props.children
  }
}

// Export.
export default ScheduledActionStatus
