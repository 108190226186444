// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'

// Components.
import StockMovementsList from '../../StockMovements/StockMovementsList'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const StockEntriesList = props => {
  return <StockMovementsList type='in' {...props} />
}

// Export.
export default StockEntriesList
