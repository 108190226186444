// ============================================================================
// Dependencies.
// ============================================================================

// Actions.
import * as actions from '../actions/types'

// Shared.
import { updateObject } from '../../shared/functions'

// ============================================================================
// Initial state.
// ============================================================================

// Initial state.
const initialState = {
  loading: true,
  error: null,
  success: null,
  formLoading: false,
  categorySyncLoading: false,
  categorySyncSuccess: null,
  categorySyncError: null,
  sites: [],
  selectedSite: null,
  siteSalesLoading: false,
  siteSalesError: null,
  siteSalesSuccess: null
}

// ============================================================================
// Functionality.
// ============================================================================

// Start loading list of sites.
const loadSitesStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    success: null,
    sites: []
  })
}

// Finish loading list of sites.
const loadSitesSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    sites: action.sites,
    error: null,
    success: null
  })
}

// Finish loading list of sites, with error.
const loadSitesFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    sites: [],
    error: action.error,
    success: null
  })
}

// Start deleting site.
const deleteSiteStart = state => {
  return updateObject(state, {
    loading: true,
    success: null,
    error: null
  })
}

// Delete a site successfully.
const deleteSiteSuccess = (state, action) => {
  const oldSiteIdx = state.sites.findIndex(e => +e.id === +action.id)
  const newSites = [...state.sites]
  newSites.splice(oldSiteIdx, 1)
  return updateObject(state, {
    loading: false,
    sites: newSites,
    error: null,
    success: `Se ha eliminado el sitio #${action.id} correctamente.`
  })
}

// Delete site, with error.
const deleteSiteFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    success: null
  })
}

// Start loading site.
const loadSiteStart = state => {
  return updateObject(state, {
    loading: true,
    success: null,
    error: null,
    selectedSite: null
  })
}

// Load a site successfully.
const loadSiteSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    categorySyncLoading: false,
    categorySyncError: null,
    categorySyncSuccess: null,
    error: null,
    success: null,
    selectedSite: action.site,
    siteSalesLoading: false,
    siteSalesError: null,
    siteSalesSuccess: null
  })
}

// Load site, with error.
const loadSiteFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    categorySyncLoading: false,
    categorySyncError: null,
    categorySyncSuccess: null,
    error: action.error,
    selectedSite: null
  })
}

// Update the selected site.
const updateSelectedSite = (state, action) => {
  const newSelectedSite = {
    ...state.selectedSite
  }
  newSelectedSite[action.key] = action.value
  return updateObject(state, {
    selectedSite: newSelectedSite
  })
}

// Start saving a site.
const saveSiteStart = (state, action) => {
  return updateObject(state, {
    formLoading: true,
    error: null,
    success: null
  })
}

// Save a site successfully.
const saveSiteSuccess = (state, action) => {
  const newSites = [
    ...state.sites
  ]
  if (action.id) {
    const updatedSiteIdx = state.sites.findIndex(s => +s.id === +action.id)
    newSites[updatedSiteIdx] = { ...action.site }
  } else {
    newSites.push(action.site)
  }
  return updateObject(state, {
    formLoading: false,
    error: null,
    success: action.id ? 'Sitio web actualizado correctamente.' : 'Sitio web creado correctamente.',
    sites: newSites,
    selectedSite: action.site
  })
}

// Save a site, with error.
const saveSiteFailed = (state, action) => {
  return updateObject(state, {
    formLoading: false,
    error: action.error,
    success: null
  })
}

// Start synchronizing the categories of a site.
const syncCategoriesStart = state => {
  return updateObject(state, {
    categorySyncLoading: true,
    categorySyncError: null,
    categorySyncSuccess: null
  })
}

// Finish loading list of sites.
const syncCategoriesSuccess = (state, action) => {
  return updateObject(state, {
    categorySyncLoading: false,
    selectedSite: {
      ...state.selectedSite,
      categories: action.categories
    },
    categorySyncError: null,
    categorySyncSuccess: 'Categorías sincronizadas satisfactoriamente.'
  })
}

// Finish loading list of sites, with error.
const syncCategoriesFailed = (state, action) => {
  return updateObject(state, {
    categorySyncLoading: false,
    categorySyncError: action.error,
    categorySyncSuccess: null
  })
}

// Start adding sale to site.
const addSaleStart = state => {
  return updateObject(state, {
    siteSalesLoading: true,
    siteSalesError: null,
    siteSalesSuccess: null
  })
}

// Finish adding sale to site.
const addSaleSuccess = (state, action) => {
  const newSelectedSite = {
    ...state.selectedSite,
    sales: action.sales
  }
  return updateObject(state, {
    siteSalesLoading: false,
    selectedSite: newSelectedSite,
    siteSalesError: null,
    siteSalesSuccess: 'Categorías sincronizadas satisfactoriamente.'
  })
}

// Finish adding sale to site, with error.
const addSaleFailed = (state, action) => {
  return updateObject(state, {
    siteSalesLoading: false,
    siteSalesError: action.error,
    siteSalesSuccess: null
  })
}

// Start deleting sale from site.
const removeSaleStart = state => {
  return updateObject(state, {
    siteSalesLoading: true,
    siteSalesError: null,
    siteSalesSuccess: null
  })
}

// Finish deleting sale from site.
const removeSaleSuccess = (state, action) => {
  const oldSiteSaleIdx = state.selectedSite.sales.findIndex(e => +e.sale_id === +action.id)
  const newSiteSales = [...state.selectedSite.sales]
  newSiteSales.splice(oldSiteSaleIdx, 1)
  return updateObject(state, {
    siteSalesLoading: false,
    selectedSite: {
      ...state.selectedSite,
      sales: newSiteSales
    },
    siteSalesError: null,
    siteSalesSuccess: 'Categorías sincronizadas satisfactoriamente.'
  })
}

// Finish deleting sale from site, with error.
const removeSaleFailed = (state, action) => {
  return updateObject(state, {
    siteSalesLoading: false,
    siteSalesError: action.error,
    siteSalesSuccess: null
  })
}

// Start generating API key.
const generateSiteApiKeyStart = state => {
  return updateObject(state, {
    formLoading: true,
    error: null,
    success: null
  })
}

// Finish generating API key.
const generateSiteApiKeySuccess = (state, action) => {
  return updateObject(state, {
    formLoading: false,
    error: null,
    selectedSite: {
      ...state.selectedSite,
      api_key: action.apiKey
    },
    success: 'Clave de API generada correctamente. Guárdala, ya que en la base de datos está cifrada y no podrás consultarla en el futuro.'
  })
}

// Finish generating API key, with error.
const generateSiteApiKeyFailed = (state, action) => {
  return updateObject(state, {
    formLoading: false,
    error: action.error,
    success: null
  })
}

// ============================================================================
// Reducer definition.
// ============================================================================

// Reducer definition.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_SITES_START:
      return loadSitesStart(state, action)
    case actions.LOAD_SITES_SUCCESS:
      return loadSitesSuccess(state, action)
    case actions.LOAD_SITES_FAILED:
      return loadSitesFailed(state, action)
    case actions.DELETE_SITE_START:
      return deleteSiteStart(state, action)
    case actions.DELETE_SITE_SUCCESS:
      return deleteSiteSuccess(state, action)
    case actions.DELETE_SITE_FAILED:
      return deleteSiteFailed(state, action)
    case actions.LOAD_SITE_START:
      return loadSiteStart(state, action)
    case actions.LOAD_SITE_SUCCESS:
      return loadSiteSuccess(state, action)
    case actions.LOAD_SITE_FAILED:
      return loadSiteFailed(state, action)
    case actions.UPDATE_SELECTED_SITE:
      return updateSelectedSite(state, action)
    case actions.SAVE_SITE_START:
      return saveSiteStart(state, action)
    case actions.SAVE_SITE_SUCCESS:
      return saveSiteSuccess(state, action)
    case actions.SAVE_SITE_FAILED:
      return saveSiteFailed(state, action)
    case actions.SYNC_CATEGORIES_START:
      return syncCategoriesStart(state, action)
    case actions.SYNC_CATEGORIES_SUCCESS:
      return syncCategoriesSuccess(state, action)
    case actions.SYNC_CATEGORIES_FAILED:
      return syncCategoriesFailed(state, action)
    case actions.ADD_SALE_START:
      return addSaleStart(state, action)
    case actions.ADD_SALE_SUCCESS:
      return addSaleSuccess(state, action)
    case actions.ADD_SALE_FAILED:
      return addSaleFailed(state, action)
    case actions.REMOVE_SALE_START:
      return removeSaleStart(state, action)
    case actions.REMOVE_SALE_SUCCESS:
      return removeSaleSuccess(state, action)
    case actions.REMOVE_SALE_FAILED:
      return removeSaleFailed(state, action)
    case actions.GENERATE_SITE_API_KEY_START:
      return generateSiteApiKeyStart(state, action)
    case actions.GENERATE_SITE_API_KEY_SUCCESS:
      return generateSiteApiKeySuccess(state, action)
    case actions.GENERATE_SITE_API_KEY_FAILED:
      return generateSiteApiKeyFailed(state, action)
    default:
      return state
  }
}

export default reducer
