// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import clsx from 'clsx'
import React from 'react'
import { Chip, Link } from '@material-ui/core'
import VisibilityIcon from '@material-ui/icons/Visibility'

// Style.
import { useStyles } from '../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const RemoteProductLink = props => {
  const classes = useStyles()
  return (
    <Link className={clsx(classes.noUnderlineOnHover, classes.ml)} target='_blank' component='a' href={props.url} rel='noreferrer'>
      <Chip className={classes.chipLink} variant='outlined' size='small' color='default' avatar={<VisibilityIcon style={{ fontSize: 18 }} />} label='Vista previa' />
    </Link>
  )
}

// Export.
export default RemoteProductLink
