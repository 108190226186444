// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { connect } from 'react-redux'

// Redux actions.
import * as actions from '../../../../../../store/actions'

// Components.
import AlertInfo from '../../../../../UI/Alerts/AlertInfo'
import FormField from '../../../../../UI/Form/FormField'
import ExpenseTypesTableRow from './ExpenseTypesTableRow'
import SaveButton from '../../../../../UI/Buttons/SaveButton'
import TableColumnTitle from '../../../../../UI/Table/TableColumnTitle'
import TableLoader from '../../../../../UI/Loaders/TableLoader'

// Shared.
import { RESULTS_PER_PAGE } from '../../../../../../shared/constants'

// Style.
import { useStyles } from '../../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Table columns.
const columns = [
  { name: 'id', label: 'ID' },
  { name: 'name', label: 'Nombre' },
  { name: 'created_at', label: 'Fecha de creación (UTC)' },
  { name: 'updated_at', label: 'Fecha de modificación (UTC)' },
  { name: 'actions', label: '' }
]

// Stateful component declaration.
class ExpenseTypesTable extends Component {
  state = {
    fields: {
      name: {
        label: 'Nombre',
        error: null,
        type: 'text',
        required: true,
        touched: false,
        validation: val => val.length >= 2,
        errMsg: 'Por favor, proporciona un nombre válido.',
        autoFocus: false
      }
    }
  }

  handleChange = (elemKey, newValue) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields[elemKey]
    }
    // Modify the element value.
    newElement.value = newValue
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.required && !newValue.length && newElement.touched) {
      newElement.error = 'Campo obligatorio'
    } else if (newElement.validation && newValue.length && !newElement.validation(newValue)) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Build new state.
    const newState = {
      fields: {
        ...this.state.fields,
        [elemKey]: newElement
      }
    }
    // Update the state.
    this.setState(newState)
  }

  handleCreateExpenseType = () => {
    // Save the expense type.
    this.props.saveExpenseType(null, this.props.token, this.state.fields.name.value)
    // Reset the form.
    const newStateFields = {
      ...this.state.fields
    }
    newStateFields.name.value = ''
    this.setState({
      fields: newStateFields
    })
    // Close the form.
    this.props.handleFormClose()
  }

  shouldSaveButtonBeDisabled = () => {
    return this.props.loading ||
      !this.state.fields.name.value ||
      this.state.fields.name.value === ''
  }

  render () {
    // Loading.
    if (this.props.loading) {
      return <TableLoader cols={columns} />
    }

    if ((!this.props.expenseTypes || !this.props.expenseTypes.length) && !this.props.creating) {
      return (
        <AlertInfo>
          Aún no se han configurado categorías de gasto.
        </AlertInfo>
      )
    }

    const expenseTypesList = []
    const minLimit = (this.props.page - 1) * RESULTS_PER_PAGE
    const maxLimit = Math.min(this.props.expenseTypes.length, this.props.page * RESULTS_PER_PAGE)

    for (let i = minLimit; i < maxLimit; i++) {
      const expenseType = this.props.expenseTypes[i]

      expenseTypesList.push(<ExpenseTypesTableRow
        key={i}
        id={expenseType.id}
        name={expenseType.name}
        createdAt={expenseType.created_at}
        updatedAt={expenseType.updated_at}
        loading={this.props.loading}
        onDeleteExpenseType={() => this.props.deleteExpenseType(expenseType.id, this.props.token)}
        handleSaveExpenseType={name => this.props.saveExpenseType(expenseType.id, this.props.token, name)}
                            />)
    }

    if (this.props.creating) {
      expenseTypesList.push(
        <TableRow key='new'>
          <TableCell className={this.props.classes.tableCellForm}>
            <IconButton color='default' aria-label='undo' size='small' onClick={this.props.handleFormClose}>
              <ClearIcon />
            </IconButton>
          </TableCell>
          <TableCell className={this.props.classes.tableCellForm} style={{ width: 240 }}>
            <FormField
              noMargin
              fullWidth
              name='name'
              type={this.state.fields.name.type}
              autoFocus={this.state.fields.name.autofocus}
              required={this.state.fields.name.required}
              label={this.state.fields.name.label}
              value={this.state.fields.name.value}
              handleChange={e => this.handleChange('name', e.target.value)}
            />
          </TableCell>
          <TableCell className={this.props.classes.tableCellForm} />
          <TableCell className={this.props.classes.tableCellForm} />
          <TableCell className={this.props.classes.tableCellForm}>
            <SaveButton
              model='ExpenseType'
              action='create'
              disabled={this.shouldSaveButtonBeDisabled()}
              onClick={this.handleSaveExpenseType}
            />
          </TableCell>
        </TableRow>
      )
    }

    return (
      <Table size='small'>
        <TableHead>
          <TableRow>
            {
              columns.map((col, key) => <TableColumnTitle key={key} {...col} />)
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {expenseTypesList}
        </TableBody>
      </Table>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    deleteExpenseType: (id, token) => dispatch(actions.deleteExpenseType(id, token)),
    loadExpenseTypes: token => dispatch(actions.loadExpenseTypes(token)),
    saveExpenseType: (id, token, name) => dispatch(actions.saveExpenseType(id, token, { name }))
  }
}

// Style component.
const ExpenseTypesTableStyled = props => {
  const classes = useStyles()
  return <ExpenseTypesTable classes={classes} {...props} />
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(ExpenseTypesTableStyled)
