// ============================================================================
// Dependencies.
// ============================================================================

// Actions.
import * as actions from '../actions/types'

// Shared.
import { formatDateTimePickerDate, updateObject } from '../../shared/functions'

// ============================================================================
// Initial state.
// ============================================================================

// Initial state.
const initialState = {
  loading: true,
  formLoading: false,
  error: null,
  success: null,
  salesReports: [],
  currentSalesReport: {},
  loadedSalesReport: null,
  loadingSalesReport: false
}

// ============================================================================
// Functionality.
// ============================================================================

// Start loading list of sales reports.
const loadSalesReportsStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    success: null,
    salesReports: [],
    loadingSalesReport: false,
    loadedSalesReport: null
  })
}

// Finish loading list of sales reports.
const loadSalesReportsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    salesReports: action.salesReports,
    error: null,
    success: null
  })
}

// Finish loading list of sales reports, with error.
const loadSalesReportsFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    salesReports: [],
    error: action.error,
    success: null
  })
}

// Start loading sales report.
const loadSalesReportStart = state => {
  return updateObject(state, {
    loadingSalesReport: true,
    loadedSalesReport: null,
    error: null,
    success: null
  })
}

// Finish loading sales report.
const loadSalesReportSuccess = (state, action) => {
  return updateObject(state, {
    loadingSalesReport: false,
    loadedSalesReport: action.salesReport,
    error: null
  })
}

// Finish loading sales report, with error.
const loadSalesReportFailed = (state, action) => {
  return updateObject(state, {
    loadingSalesReport: false,
    loadedSalesReport: null,
    error: action.error
  })
}

// Update the selected sales report.
const editNewSalesReport = (state, action) => {
  const currentSalesReport = {
    ...state.currentSalesReport
  }
  currentSalesReport[action.key] = action.value
  return updateObject(state, {
    currentSalesReport
  })
}

// Start saving a sales report.
const saveSalesReportStart = (state, action) => {
  return updateObject(state, {
    formLoading: true,
    error: null,
    success: null,
    loadingSalesReport: false,
    loadedSalesReport: null
  })
}

// Save a sales report successfully.
const saveSalesReportSuccess = (state, action) => {
  const currentSalesReports = [
    ...state.salesReports
  ]
  action.salesReport.date_from = formatDateTimePickerDate(action.salesReport.date_from)
  action.salesReport.date_to = formatDateTimePickerDate(action.salesReport.date_to)
  currentSalesReports.push(action.salesReport)
  return updateObject(state, {
    formLoading: false,
    error: null,
    success: 'Archivo CSV con la liquidación generado con éxito.',
    salesReports: currentSalesReports,
    currentSalesReport: action.salesReport
  })
}

// Save a sales report, with error.
const saveSalesReportFailed = (state, action) => {
  return updateObject(state, {
    formLoading: false,
    error: action.error,
    success: null
  })
}

// Start deleting sales report.
const deleteSalesReportStart = state => {
  return updateObject(state, {
    loading: true,
    success: null,
    error: null,
    loadingSalesReport: false,
    loadedSalesReport: null
  })
}

// Delete a sales report successfully.
const deleteSalesReportSuccess = (state, action) => {
  const oldSalesReportIdx = state.salesReports.findIndex(e => +e.id === +action.id)
  const newSalesReports = [...state.salesReports]
  newSalesReports.splice(oldSalesReportIdx, 1)
  return updateObject(state, {
    loading: false,
    salesReports: newSalesReports,
    error: null,
    success: `Se ha eliminado el reporte de ventas #${action.id} correctamente.`
  })
}

// Delete sales report, with error.
const deleteSalesReportFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    success: null
  })
}

// Reset the loaded sales report.
const resetLoadedSalesReport = state => {
  return updateObject(state, {
    loadingSalesReport: false,
    loadedSalesReport: null
  })
}

// ============================================================================
// Reducer definition.
// ============================================================================

// Reducer definition.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_SALES_REPORTS_START:
      return loadSalesReportsStart(state, action)
    case actions.LOAD_SALES_REPORTS_SUCCESS:
      return loadSalesReportsSuccess(state, action)
    case actions.LOAD_SALES_REPORTS_FAILED:
      return loadSalesReportsFailed(state, action)
    case actions.LOAD_SALES_REPORT_START:
      return loadSalesReportStart(state, action)
    case actions.LOAD_SALES_REPORT_SUCCESS:
      return loadSalesReportSuccess(state, action)
    case actions.LOAD_SALES_REPORT_FAILED:
      return loadSalesReportFailed(state, action)
    case actions.EDIT_NEW_SALES_REPORT:
      return editNewSalesReport(state, action)
    case actions.SAVE_SALES_REPORT_START:
      return saveSalesReportStart(state, action)
    case actions.SAVE_SALES_REPORT_SUCCESS:
      return saveSalesReportSuccess(state, action)
    case actions.SAVE_SALES_REPORT_FAILED:
      return saveSalesReportFailed(state, action)
    case actions.DELETE_SALES_REPORT_START:
      return deleteSalesReportStart(state, action)
    case actions.DELETE_SALES_REPORT_SUCCESS:
      return deleteSalesReportSuccess(state, action)
    case actions.DELETE_SALES_REPORT_FAILED:
      return deleteSalesReportFailed(state, action)
    case actions.RESET_LOADED_SALES_REPORT:
      return resetLoadedSalesReport(state, action)
    default:
      return state
  }
}

export default reducer
