// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
  Chip,
  Table,
  TableBody,
  TableHead,
  TableRow
} from '@material-ui/core'

// Components.
import NumberOfStuff from '../../../../../UI/Custom/NumberOfStuff'
import CalculationStatus from '../../../../../UI/Custom/CalculationStatus'
import AlertInfo from '../../../../../UI/Alerts/AlertInfo'
import RowActions from '../../../../../UI/Table/RowActions'
import TableCellWithProgress from '../../../../../UI/Table/TableCellWithProgress'
import UserLink from '../../../../../UI/Links/UserLink'
import TableColumnTitle from '../../../../../UI/Table/TableColumnTitle'
import TableLoader from '../../../../../UI/Loaders/TableLoader'

// Shared.
import {
  prettyPrintDateTime,
  printPrice,
  printMonthName,
  buildUrl
} from '../../../../../../shared/functions'
import { RESULTS_PER_PAGE } from '../../../../../../shared/constants'

// =============================================================================
// Custom chip components for the column values.
// =============================================================================

const SettlementResult = props => <Chip size='small' variant='default' color={props.children > 0 ? 'primary' : props.children < 0 ? 'secondary' : 'default'} label={printPrice(props.children)} />

const SettlementPrice = props => printPrice(props.children)

// =============================================================================
// Component declaration.
// =============================================================================

// Table columns.
const columns = [
  { name: 'id', label: 'ID', sortable: true },
  { name: 'month', label: 'Mes', sortable: true },
  { name: 'year', label: 'Año', sortable: true },
  { name: 'n_settlements', label: 'Nº tratos comerciales', sortable: true },
  { name: 'total_sales', label: 'Nº prod. vendidos', sortable: true },
  { name: 'total_paid', label: 'Ganancias (IVA incl.)', sortable: true },
  { name: 'total_commission', label: 'Comisión pagos', sortable: true },
  { name: 'total_expenses', label: 'Gastos', sortable: true },
  { name: 'total_earnings', label: 'Ingresos', sortable: true },
  { name: 'result', label: 'Resultado', sortable: true },
  { name: 'finished', label: 'Estado', sortable: true },
  { name: 'user_id', label: 'Creado por', sortable: true },
  { name: 'created_at', label: 'Fecha de creación (UTC)', sortable: true },
  { name: 'updated_at', label: 'Fecha de modificación (UTC)', sortable: true },
  { name: 'actions', label: '' }
]

// Stateless component declaration.
const SettlementsTable = props => {
  // Loading.
  if (props.loading) {
    return <TableLoader cols={columns} />
  }

  // No results.
  if (!props.settlements.length) {
    return <AlertInfo>Aún no se ha calculado ninguna liquidación.</AlertInfo>
  }

  const settlementsTable = []
  const minLimit = (props.page - 1) * RESULTS_PER_PAGE
  const maxLimit = Math.min(props.settlements.length, props.page * RESULTS_PER_PAGE)

  let lastSettlement = props.settlements[0]
  for (let j = 0; j < props.settlements.length; j++) {
    if (props.settlements[j].year > lastSettlement.year) {
      lastSettlement = props.settlements[j]
    } else if (props.settlements[j].year === lastSettlement.year && props.settlements[j].month > lastSettlement.month) {
      lastSettlement = props.settlements[j]
    }
  }

  for (let i = minLimit; i < maxLimit; i++) {
    const settlement = props.settlements[i]

    const isLastSettlement = (settlement.month === lastSettlement.month && settlement.year === lastSettlement.year)
    const canSettlementBeDownloaded = settlement.finished
    const canSettlementBeDeleted = settlement.finished && (!settlement.definitive || isLastSettlement)
    const canSettlementBeRecalculated = settlement.definitive && settlement.finished && isLastSettlement

    settlementsTable.push(
      <TableRow key={settlement.id}>
        <TableCellWithProgress active={settlement.definitive}>
          {settlement.id}
        </TableCellWithProgress>
        <TableCellWithProgress active={settlement.definitive}>
          {printMonthName(settlement.month)}
        </TableCellWithProgress>
        <TableCellWithProgress active={settlement.definitive}>
          {settlement.year}
        </TableCellWithProgress>
        <TableCellWithProgress active={settlement.definitive}>
          <NumberOfStuff>{settlement.n_arrangements}</NumberOfStuff>
        </TableCellWithProgress>
        <TableCellWithProgress active={settlement.definitive}>
          <NumberOfStuff>{settlement.total_sales}</NumberOfStuff>
        </TableCellWithProgress>
        <TableCellWithProgress active={settlement.definitive}>
          <SettlementPrice>{settlement.total_paid}</SettlementPrice>
        </TableCellWithProgress>
        <TableCellWithProgress active={settlement.definitive}>
          <SettlementPrice>{settlement.total_commission}</SettlementPrice>
        </TableCellWithProgress>
        <TableCellWithProgress active={settlement.definitive}>
          <SettlementPrice>{settlement.total_expenses}</SettlementPrice>
        </TableCellWithProgress>
        <TableCellWithProgress active={settlement.definitive}>
          <SettlementPrice>{settlement.total_earnings}</SettlementPrice>
        </TableCellWithProgress>
        <TableCellWithProgress active={settlement.definitive}>
          <SettlementResult>{settlement.result}</SettlementResult>
        </TableCellWithProgress>
        <TableCellWithProgress active={settlement.definitive}>
          <CalculationStatus>{settlement.finished}</CalculationStatus>
        </TableCellWithProgress>
        <TableCellWithProgress active={settlement.definitive}>
          {
            settlement.user
              ? <UserLink user={settlement.user} label='firstname' />
              : <span>-</span>
          }
        </TableCellWithProgress>
        <TableCellWithProgress active={settlement.definitive}>
          {prettyPrintDateTime(settlement.created_at)}
        </TableCellWithProgress>
        <TableCellWithProgress active={settlement.definitive}>
          {prettyPrintDateTime(settlement.updated_at)}
        </TableCellWithProgress>
        <TableCellWithProgress active={settlement.definitive}>
          {
            canSettlementBeDeleted || canSettlementBeRecalculated || canSettlementBeDownloaded
              ? <RowActions
                  model='Settlement'
                  id={settlement.id}
                  downloadLink={buildUrl(settlement.download_link, { _t: props.token })}
                  handleDelete={canSettlementBeDeleted ? () => props.deleteSettlement(settlement.id) : null}
                  handleRecalculate={canSettlementBeRecalculated ? () => props.submitSettlement(settlement.artist_id, settlement.month, settlement.year) : null}
                />
              : null
          }
        </TableCellWithProgress>
      </TableRow>
    )
  }

  return (
    <Table size='small' className='TableCondensed'>
      <TableHead>
        <TableRow>
          {
            columns.map((col, key) => <TableColumnTitle key={key} {...col} orderBy={props.orderBy} orderDirection={props.orderDirection} onSort={props.onSort} />)
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {settlementsTable}
      </TableBody>
    </Table>
  )
}

// Export.
export default SettlementsTable
