// ============================================================================
// Dependencies.
// ============================================================================

// Actions.
import * as actions from '../actions/types'

// Shared.
import { updateObject } from '../../shared/functions'

// ============================================================================
// Initial state.
// ============================================================================

// Initial state.
const initialState = {
  loading: true,
  error: null,
  success: null,
  settlements: []
}

// ============================================================================
// Functionality.
// ============================================================================

// Start loading list of settlements.
const loadSettlementsStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    success: null,
    settlements: []
  })
}

// Finish loading list of settlements.
const loadSettlementsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    settlements: action.settlements,
    error: null,
    success: null
  })
}

// Finish loading list of settlements, with error.
const loadSettlementsFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    settlements: [],
    error: action.error,
    success: null
  })
}

// Start deleting settlement.
const deleteSettlementStart = state => {
  return updateObject(state, {
    loading: true,
    success: null,
    error: null
  })
}

// Delete a settlement successfully.
const deleteSettlementSuccess = (state, action) => {
  const oldSettlementIdx = state.settlements.findIndex(e => +e.id === +action.id)
  const newSettlements = [...state.settlements]
  newSettlements.splice(oldSettlementIdx, 1)
  return updateObject(state, {
    loading: false,
    settlements: newSettlements,
    error: null,
    success: `Se ha eliminado la liquidación #${action.id} correctamente.`
  })
}

// Delete settlement, with error.
const deleteSettlementFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    success: null
  })
}

// Start submitting a settlement.
const submitSettlementStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    success: null
  })
}

// Submit a settlement successfully.
const submitSettlementSuccess = (state, action) => {
  const newSettlements = [
    ...state.settlements
  ]
  const updatedSettlementIdx = state.settlements.findIndex(s => +s.id === +action.settlement.id)
  if (updatedSettlementIdx && updatedSettlementIdx !== -1) {
    newSettlements[updatedSettlementIdx] = { ...action.settlement }
  } else {
    newSettlements.push(action.settlement)
  }
  // Set the other settlements as non-definitive.
  const similarSettlements = newSettlements.filter(s => s.month === action.settlement.month && s.year === action.settlement.year && +s.id !== +action.settlement.id && s.definitive)
  for (let i = 0; i < similarSettlements.length; i++) {
    const similarSettlementIdx = state.settlements.findIndex(s => +s.id === +similarSettlements[i].id)
    newSettlements[similarSettlementIdx].definitive = false
  }
  // Update the state.
  return updateObject(state, {
    loading: false,
    error: null,
    success: 'Tarea programada correctamente. La liquidación se volverá a calcular en unos minutos.',
    settlements: newSettlements
  })
}

// Submit a settlement, with error.
const submitSettlementFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    success: null
  })
}

// ============================================================================
// Reducer definition.
// ============================================================================

// Reducer definition.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_SETTLEMENTS_START:
      return loadSettlementsStart(state, action)
    case actions.LOAD_SETTLEMENTS_SUCCESS:
      return loadSettlementsSuccess(state, action)
    case actions.LOAD_SETTLEMENTS_FAILED:
      return loadSettlementsFailed(state, action)
    case actions.DELETE_SETTLEMENT_START:
      return deleteSettlementStart(state, action)
    case actions.DELETE_SETTLEMENT_SUCCESS:
      return deleteSettlementSuccess(state, action)
    case actions.DELETE_SETTLEMENT_FAILED:
      return deleteSettlementFailed(state, action)
    case actions.SUBMIT_SETTLEMENT_START:
      return submitSettlementStart(state, action)
    case actions.SUBMIT_SETTLEMENT_SUCCESS:
      return submitSettlementSuccess(state, action)
    case actions.SUBMIT_SETTLEMENT_FAILED:
      return submitSettlementFailed(state, action)
    default:
      return state
  }
}

export default reducer
