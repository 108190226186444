// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const SiteType = props => (
  <img
    src={`/shop-types/${props.type}.jpg`}
    alt={props.type}
    style={{ maxHeight: 20 }}
  />
)

// Export.
export default SiteType
