export const SITE_SYNC_TYPES = {
  multistore: 'Multistore',
  rest_api: 'API REST'
}

export const SITE_TYPES = {
  woocommerce: 'WooCommerce',
  prestashop: 'PrestaShop'
}

export const RESULTS_PER_PAGE = 8

export const FORM_ERRORS = {
  field_type: 'El campo %s no tiene un tipo correcto',
  field_required: 'El campo %s es obligatorio',
  field_too_high: 'El campo %s tiene un valor demasiado alto',
  field_too_low: 'El campo %s tiene un valor demasiado bajo',
  field_too_long: 'El campo %s es demasiado largo',
  field_too_short: 'El campo %s es demasiado corto',
  field_malformed: 'El campo %s no es válido',
  field_duplicate: 'El campo %s ya existe en la base de datos y no puede estar duplicado'
}

export const MONTHS = [
  { label: 'Todos', value: '' },
  { label: 'Enero', value: '1' },
  { label: 'Febrero', value: '2' },
  { label: 'Marzo', value: '3' },
  { label: 'Abril', value: '4' },
  { label: 'Mayo', value: '5' },
  { label: 'Junio', value: '6' },
  { label: 'Julio', value: '7' },
  { label: 'Agosto', value: '8' },
  { label: 'Septiembre', value: '9' },
  { label: 'Octubre', value: '10' },
  { label: 'Noviembre', value: '11' },
  { label: 'Diciembre', value: '12' }
]

export const YEARS = [
  { label: 'Todos', value: '' }
]
const currentYear = (new Date()).getFullYear()
for (let i = 2017; i <= currentYear; i++) {
  YEARS.push({ label: `${i}`, value: `${i}` })
}

export const SCHEDULED_ACTION_STATUSES = {
  scheduled: 'Programado',
  scheduling: 'Activando...',
  active: 'Activo',
  expired: 'Finalizado'
}

export const ASYNC_ACTION_STATUSES = {
  scheduled: 'Programado',
  inProgress: 'En ejecución',
  finished: 'Terminado',
  failed: 'Fallido'
}

export const DISCOUNT_TYPES = {
  percentage: 'Porcentaje',
  quantity: 'Cantidad'
}

export const CRON_MESSAGES = {
  productScheduledDiscounts: 'El proceso que actualiza los precios se ejecuta a las horas :00, :20, :30 y :50 (por ejemplo: a las 00:00, a las 00:20, a las 00:30, a las 00:50, a la 01:00, y en adelante)'
}

export const USER_STATUS_OPTIONS = [
  { label: 'Todos', value: '' },
  { label: 'Activo', value: 1 },
  { label: 'Bloqueado', value: 0 }
]

export const FINISHED_OPTIONS = [
  { label: 'Todos', value: '' },
  { label: 'Calculando', value: 0 },
  { label: 'Finalizado', value: 1 }
]

export const NUMERIC_VALUE_NAMES = ['finished', 'month', 'year', 'active']

export const API_POSSIBLE_ERRORS = [
  'El sitio web no está funcionando en este momento y no es posible conectar con él.',
  'Las credenciales de conexión del sitio web (API Key y API Secret) no son correctas.',
  'La URL del sitio web no es correcta.',
  'El protocolo especificado en la URL del sitio web no es correcto (por ejemplo, https:// en vez de http://).'
]

export const QUERY_STRING_MIN_LENGTH = 3

export const TEXT_SEARCH_DELAY = 400
