// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import * as _ from 'lodash'

// =============================================================================
// Translations.
// =============================================================================

// Underscore translations.
const translations = {
  sales_report: 'Reporte de ventas',
  sales_reports: 'Reportes de venta',
  settlement: 'Liquidación',
  settlements: 'Liquidaciones',
  stock_withdrawal: 'Retirada de stock',
  stock_withdrawals: 'Retiradas de stock',
  stock_entry: 'Entrada de stock',
  stock_entries: 'Entradas de stock',
  product_scheduled_discount: 'Descuento programado',
  product_scheduled_discounts: 'Descuentos programados',
  cur_year: 'Año actual',
  last_year: 'Año anterior'
}

// Add camelcase translations.
for (const key in translations) {
  if (Object.prototype.hasOwnProperty.call(translations, key)) {
    const keyCamelCase = _.camelCase(key)
    if (keyCamelCase !== key) translations[keyCamelCase] = translations[key]
  }
}

// Export.
export default translations
