// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog
} from '@material-ui/core'

// Shared.
import { prettyPrintDate, printHTML } from '../../../../../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const StockMovementLogsDialog = props => {
  return (
    <Dialog
      maxWidth='lg'
      open={!!(props.loading || props.stockMovement)}
      onClose={props.onDialogClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        {
          props.stockMovement
            ? (
              <>
                <span style={{ marginRight: 10 }}>ID #{props.stockMovement.id} - {props.stockMovement.location}, {prettyPrintDate(props.stockMovement.created_at)}</span>
              </>
              )
            : null
        }
      </DialogTitle>
      <DialogContent className='ActionLogs'>
        {printHTML(props.stockMovement.log)}
      </DialogContent>
      <DialogActions>
        <Button color='default' variant='outlined' onClick={props.onDialogClose}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

// Export.
export default StockMovementLogsDialog
