// ============================================================================
// Dependencies.
// ============================================================================

// Actions.
import * as actions from '../actions/types'

// Shared.
import { updateObject } from '../../shared/functions'

// ============================================================================
// Initial state.
// ============================================================================

// Initial state.
const initialState = {
  loading: false,
  error: null,
  success: null,
  earningTypes: [],
  selectedEarningType: null
}

// ============================================================================
// Functionality.
// ============================================================================

// Start loading list of earning types.
const loadEarningTypesStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    success: null,
    earningTypes: []
  })
}

// Finish loading list of earning types.
const loadEarningTypesSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    earningTypes: action.earningTypes,
    error: null,
    success: null
  })
}

// Finish loading list of earning types, with error.
const loadEarningTypesFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    earningTypes: [],
    error: action.error,
    success: null
  })
}

// Start deleting earning type.
const deleteEarningTypeStart = state => {
  return updateObject(state, {
    loading: true,
    success: null,
    error: null
  })
}

// Delete an earning type successfully.
const deleteEarningTypeSuccess = (state, action) => {
  const oldEarningTypeIdx = state.earningTypes.findIndex(e => +e.id === +action.id)
  const newEarningTypes = [...state.earningTypes]
  newEarningTypes.splice(oldEarningTypeIdx, 1)
  return updateObject(state, {
    loading: false,
    earningTypes: newEarningTypes,
    error: null,
    success: `Se ha eliminado el tipo de ingreso #${action.id} correctamente.`
  })
}

// Delete earning type, with error.
const deleteEarningTypeFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    success: null
  })
}

// Update the selected earning type.
const updateSelectedEarningType = (state, action) => {
  const newSelectedEarningType = {
    ...state.selectedEarningType
  }
  newSelectedEarningType[action.key] = action.value
  return updateObject(state, {
    selectedEarningType: newSelectedEarningType
  })
}

// Start saving an earning type.
const saveEarningTypeStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    success: null
  })
}

// Save an earning type successfully.
const saveEarningTypeSuccess = (state, action) => {
  const newEarningTypes = [
    ...state.earningTypes
  ]
  if (action.id) {
    const updatedEarningTypeIdx = state.earningTypes.findIndex(s => +s.id === +action.id)
    newEarningTypes[updatedEarningTypeIdx] = { ...action.earningType }
  } else {
    newEarningTypes.push(action.earningType)
  }
  return updateObject(state, {
    loading: false,
    error: null,
    success: action.id ? 'Tipo de ingreso actualizado correctamente.' : 'Tipo de ingreso creado correctamente.',
    earningTypes: newEarningTypes,
    selectedEarningType: action.earningType
  })
}

// Save an earning type, with error.
const saveEarningTypeFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    success: null
  })
}

// ============================================================================
// Reducer definition.
// ============================================================================

// Reducer definition.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_EARNING_TYPES_START:
      return loadEarningTypesStart(state, action)
    case actions.LOAD_EARNING_TYPES_SUCCESS:
      return loadEarningTypesSuccess(state, action)
    case actions.LOAD_EARNING_TYPES_FAILED:
      return loadEarningTypesFailed(state, action)
    case actions.DELETE_EARNING_TYPE_START:
      return deleteEarningTypeStart(state, action)
    case actions.DELETE_EARNING_TYPE_SUCCESS:
      return deleteEarningTypeSuccess(state, action)
    case actions.DELETE_EARNING_TYPE_FAILED:
      return deleteEarningTypeFailed(state, action)
    case actions.UPDATE_SELECTED_EARNING_TYPE:
      return updateSelectedEarningType(state, action)
    case actions.SAVE_EARNING_TYPE_START:
      return saveEarningTypeStart(state, action)
    case actions.SAVE_EARNING_TYPE_SUCCESS:
      return saveEarningTypeSuccess(state, action)
    case actions.SAVE_EARNING_TYPE_FAILED:
      return saveEarningTypeFailed(state, action)
    default:
      return state
  }
}

export default reducer
