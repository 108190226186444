// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { Chip } from '@material-ui/core'

// Shared.
import { printNumber } from '../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const NumberOfStuff = props => {
  const number = props.children || 0

  const style = {}
  if (props.marginLeft) style.marginLeft = 8
  if (props.condensed) style.padding = '0 !important'

  return (
    <Chip
      variant={+number > 0 ? 'default' : 'outlined'}
      color={+number > 0 ? 'primary' : 'default'}
      size='small'
      label={printNumber(+number)}
      style={style}
    />
  )
}

// Export.
export default NumberOfStuff
