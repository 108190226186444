// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { Chip } from '@material-ui/core'
import BlockIcon from '@material-ui/icons/Block'
import CheckCircleIcon from '@material-ui/icons/CheckCircle'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const ProductStatus = props => (
  <Chip
    variant='default'
    color={props.children ? 'primary' : 'default'}
    size='small'
    label={props.children ? 'Activo' : 'Inactivo'}
    icon={props.children ? <CheckCircleIcon /> : <BlockIcon />}
  />
)

// Export.
export default ProductStatus
