// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  LinearProgress,
  Paper,
  Chip
} from '@material-ui/core'

// Components.
import Title from '../../../../../../UI/Brand/Title'
import FormField from '../../../../../../UI/Form/FormField'
import AlertSuccess from '../../../../../../UI/Alerts/AlertSuccess'
import AlertError from '../../../../../../UI/Alerts/AlertError'
import PriceBlock from '../../../../../../UI/Custom/PriceBlock'
import ProductStatus from '../../../../../../UI/Custom/ProductStatus'
import NumberOfStuff from '../../../../../../UI/Custom/NumberOfStuff'
import RemoteProductLink from '../../../../../../UI/Links/RemoteProductLink'
import RemoteProductAdminLink from '../../../../../../UI/Links/RemoteProductAdminLink'
import GoBackButton from '../../../../../../UI/Buttons/GoBackButton'
import SyncButton from '../../../../../../UI/Buttons/SyncButton'
import ProductImage from '../../../../../../UI/Image/ProductImage'
import ProductVariationsTable from './ProductVariationsTable'
import ProductStockSync from './ProductStockSync/ProductStockSync'

// Redux actions.
import * as actions from '../../../../../../../store/actions'

// Styles.
import { useStyles } from '../../../../../../styles'

// Shared.
import { prettyPrintDateTime } from '../../../../../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class ProductForm extends Component {
  state = {
    variationsPage: 1,
    variationsOrderBy: 'id',
    variationsOrderDirection: 'asc',
    fields: {
      stock_synced_to: {
        label: 'Product maestro',
        error: null,
        type: 'select',
        required: false,
        touched: false,
        validation: this.validateProductId,
        errMsg: 'Por favor, selecciona un sitio de la lista.',
        autofocus: false,
        options: []
      }
    }
  }

  componentDidMount () {
    // Load the product.
    this.props.loadProduct(this.props.id, this.props.token)
  }

  handleSortVariations = (fieldName) => event => {
    // Create a copy of the state object.
    const newState = { ...this.state }
    // Set the order field.
    newState.variationsOrderBy = fieldName
    // Toggle the order direction.
    newState.variationsOrderDirection = this.state.variationsOrderBy === fieldName ? (newState.variationsOrderDirection === 'asc' ? 'desc' : 'asc') : 'asc'
    // Update the state.
    this.setState(newState)
  }

  onVariationsPageChange = (e, value) => {
    this.setState({ variationsPage: +value })
  }

  handleChange = (elemKey, newValue) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields[elemKey]
    }
    // Modify the element value.
    this.props.updateSelectedProduct(elemKey, newValue)
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.required && !newValue.length && newElement.touched) {
      newElement.error = 'Campo obligatorio'
    } else if (newElement.validation && newValue.length && !newElement.validation(newValue)) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Update the state.
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [elemKey]: newElement
      }
    })
  }

  render () {
    let content = null

    // Handle loading state.
    if (this.props.loading || this.props.formLoading) {
      // Show loader.
      content = <LinearProgress />
    } else {
      content = (
        <Grid container spacing={4}>
          <Grid item xs={12} md={4} lg={2}>
            <ProductImage product={this.props.selectedProduct} />
          </Grid>
          <Grid item xs={12} md={8} lg={10}>
            {
              !this.props.selectedProduct
                ? null
                : (
                  <>
                    <Title>{this.props.selectedProduct.name} <RemoteProductLink url={this.props.selectedProduct.url} /> <RemoteProductAdminLink url={this.props.selectedProduct.admin_url} /></Title>
                    <div className={this.props.classes.productPageBlock}>
                      Actualizado por última vez el {prettyPrintDateTime(this.props.selectedProduct.updated_at)}.
                    </div>
                    <div className={this.props.classes.productPageBlock}>
                      <ProductStatus>{this.props.selectedProduct.active}</ProductStatus>
                      {this.props.selectedProduct.is_pack ? <Chip style={{ marginLeft: 6 }} size='small' label='Pack de productos' variant='outlined' color='primary' /> : null}
                      <PriceBlock style={{ marginLeft: 6 }} original={this.props.selectedProduct.price} reduced={this.props.selectedProduct.sale_price} />
                    </div>
                    {
                      (this.props.selectedProduct.variations && this.props.selectedProduct.variations.length) || this.props.selectedProduct.is_pack
                        ? null
                        : (
                          <div className={this.props.classes.productPageBlock}>
                            Cantidad en stock: <NumberOfStuff>{this.props.selectedProduct.stock_quantity}</NumberOfStuff>
                          </div>
                          )
                    }
                    <FormField noMargin label='Descripción' type='text' readOnly value={this.props.selectedProduct.description} rows={6} fullWidth />
                  </>
                  )
            }
          </Grid>
        </Grid>
      )
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={this.props.classes.paper}>
            <div className='ProductForm'>
              <Title>Propiedades del producto</Title>
              <AlertError error={this.props.productSyncError} />
              <AlertSuccess message={this.props.productSyncSuccess} />
              {content}
              {this.props.productSyncLoading ? <div className={this.props.classes.mt3}><LinearProgress /></div> : null}
              <Grid container spacing={3} className={this.props.classes.tableTools}>
                <Grid item xs={12} sm={6} style={{ textAlign: 'left' }}>
                  <GoBackButton link={`/site/${this.props.siteId}/category/${this.props.categoryId}`} />
                </Grid>
                <Grid item xs={12} sm={6} style={{ textAlign: 'right' }}>
                  <SyncButton
                    model='Product'
                    action='sync'
                    onClick={() => this.props.syncProduct(this.props.selectedProduct.id, this.props.token)}
                    disabled={this.props.productSyncLoading}
                  />
                </Grid>
              </Grid>
            </div>
          </Paper>
        </Grid>
        {
          !this.props.selectedProduct
            ? null
            : (
              <ProductStockSync
                product={this.props.selectedProduct}
              />
              )
        }
        <ProductVariationsTable
          page={this.state.variationsPage}
          handlePageChange={this.onVariationsPageChange}
          orderBy={this.state.variationsOrderBy}
          orderDirection={this.state.variationsOrderDirection}
          onSort={this.handleSortVariations}
          variations={this.props.selectedProduct && this.props.selectedProduct.variations && this.props.selectedProduct.variations.length ? this.props.selectedProduct.variations : null}
        />
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    loading: state.products.loading,
    formLoading: state.products.formLoading,
    selectedProduct: state.products.selectedProduct,
    products: state.products.products,
    productSyncLoading: state.products.syncLoading,
    productSyncError: state.products.syncError,
    productSyncSuccess: state.products.syncSuccess
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadProduct: (id, token) => dispatch(actions.loadProduct(id, token)),
    syncProduct: (id, token) => dispatch(actions.syncProduct(id, token)),
    updateSelectedProduct: (key, value) => dispatch(actions.updateSelectedProduct(key, value))
  }
}

// Style component.
const ProductFormStyled = props => {
  const classes = useStyles()
  return (
    <ProductForm classes={classes} {...props} />
  )
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(ProductFormStyled)
