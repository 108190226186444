// ============================================================================
// Dependencies.
// ============================================================================

// Axios instance.
import axios from '../../axios'

// Action types.
import * as actions from './types'

// Functions.
import { getErrorMessage } from '../../shared/functions'

// ============================================================================
// Action creators.
// ============================================================================

// Load sales start.
export const loadSalesStart = () => {
  return {
    type: actions.LOAD_SALES_START
  }
}

// Load sales success.
export const loadSalesSuccess = sales => {
  return {
    type: actions.LOAD_SALES_SUCCESS,
    sales
  }
}

// Load sales fail.
export const loadSalesFailed = error => {
  return {
    type: actions.LOAD_SALES_FAILED,
    error
  }
}

// Load sales.
export const loadSales = token => {
  return dispatch => {
    dispatch(loadSalesStart())
    // Perform request.
    axios(token).get('/sales')
      .then(response => {
        dispatch(loadSalesSuccess(response.data.sales))
      })
      .catch(error => {
        dispatch(loadSalesFailed(getErrorMessage(error)))
      })
  }
}

// Delete sales start.
export const deleteSaleStart = () => {
  return {
    type: actions.DELETE_SALE_START
  }
}

// Delete sales success.
export const deleteSaleSuccess = id => {
  return {
    type: actions.DELETE_SALE_SUCCESS,
    id
  }
}

// Delete sales fail.
export const deleteSaleFailed = error => {
  return {
    type: actions.DELETE_SALE_FAILED,
    error
  }
}

// Delete sales.
export const deleteSale = (id, token) => {
  return dispatch => {
    dispatch(deleteSaleStart())
    // Perform request.
    axios(token).delete(`/sale/${id}`)
      .then(() => {
        dispatch(deleteSaleSuccess(id))
      })
      .catch(error => {
        dispatch(deleteSaleFailed(getErrorMessage(error)))
      })
  }
}

// Start saving a sale.
export const saveSaleStart = sale => {
  return {
    type: actions.SAVE_SALE_START,
    sale
  }
}

// Save sale success.
export const saveSaleSuccess = (id, sale) => {
  return {
    type: actions.SAVE_SALE_SUCCESS,
    id,
    sale
  }
}

// Save sale fail.
export const saveSaleFailed = (id, error) => {
  return {
    type: actions.SAVE_SALE_FAILED,
    id,
    error
  }
}

// Save an sale.
export const saveSale = (id, token, body = {}) => {
  return dispatch => {
    dispatch(saveSaleStart())
    // Perform request.
    body = body || {}
    const url = id ? `/sale/${id}` : '/sale'
    const method = id ? 'put' : 'post'
    const postData = {
      name: body.name,
      date_from: body.date_from,
      date_to: body.date_to
    }
    axios(token)[method](url, postData)
      .then(response => {
        dispatch(saveSaleSuccess(id, response.data))
      })
      .catch(error => {
        dispatch(saveSaleFailed(id, getErrorMessage(error)))
      })
  }
}
