// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import { Chip } from '@material-ui/core'
import React from 'react'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const ExpenseType = props => {
  return (
    <Chip
      size='small'
      variant='outlined'
      label={props.children}
      color='primary'
    />
  )
}

// Export.
export default ExpenseType
