// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import * as _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  LinearProgress,
  Paper
} from '@material-ui/core'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'

// Components.
import Title from '../../../../../../../UI/Brand/Title'
import FormField from '../../../../../../../UI/Form/FormField'
import AlertSuccess from '../../../../../../../UI/Alerts/AlertSuccess'
import AlertInfo from '../../../../../../../UI/Alerts/AlertInfo'
import ProductsTable from '../../../../Warehouse/Inventory/ProductsTable'
import SaveButton from '../../../../../../../UI/Buttons/SaveButton'

// Redux actions.
import * as actions from '../../../../../../../../store/actions'

// Styles.
import { useStyles } from '../../../../../../../styles'

// Shared.
import { userCan } from '../../../../../../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class ProductStockSync extends Component {
  validateProductId = id => {
    return this.props.products.find(s => +s.id === +id)
  }

  state = {
    fields: {
      stock_synced_to: {
        fullWidth: true,
        label: 'Product maestro',
        error: null,
        type: 'autocomplete',
        required: false,
        touched: false,
        validation: this.validateProductId,
        errMsg: 'Por favor, selecciona un sitio de la lista.',
        autofocus: false,
        options: []
      }
    }
  }

  componentDidMount () {
    // Load all products from the site.
    if (this.props.product && this.props.product.category && this.props.product.category.site && this.props.product.category.site.synced_to) {
      this.props.searchProducts(this.props.token, { site_id: +this.props.product.category.site.synced_to })
    }
  }

  handleChange = (elemKey, newValue) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields[elemKey]
    }
    // Modify the element value.
    this.props.updateSelectedProduct(elemKey, newValue)
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.required && !newValue.length && newElement.touched) {
      newElement.error = 'Campo obligatorio'
    } else if (newElement.validation && newValue.length && !newElement.validation(newValue)) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Update the state.
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [elemKey]: newElement
      }
    })
  }

  shouldSaveButtonBeDisabled = () => {
    return this.props.formLoading ||
      !this.props.product.stock_synced_to ||
      !this.validateProductId(this.props.product.stock_synced_to)
  }

  render () {
    if (!this.props.product) {
      return null
    }

    let content = null

    if (this.props.product.slave_products && this.props.product.slave_products.length) {
      // Product is a MASTER product.
      content = (
        <>
          <ProductsTable
            remoteLinks
            page={1}
            simplified
            site_id={this.props.product.category.site_id}
            products={this.props.product.slave_products.map(p => { return { ...p, master_product: this.props.product } })}
          />
          <Grid container spacing={3} className={this.props.classes.tableTools}>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              El producto tiene <b>{this.props.product.slave_products.length === 1 ? 'un producto esclavo' : `${this.props.product.slave_products.length} productos esclavos`}</b>.
            </Grid>
          </Grid>
        </>
      )
    } else if (this.props.product.category && this.props.product.category.site && this.props.product.category.site.synced_to) {
      if (this.props.product.master_product) {
        // Product is a SLAVE product.
        content = (
          <>
            <ProductsTable
              remoteLinks
              page={1}
              simplified
              site_id={this.props.product.master_product.category ? this.props.product.master_product.category.site_id : null}
              products={[this.props.product.master_product]}
            />
            <Grid container spacing={3} className={this.props.classes.tableTools}>
              <Grid item xs={12} style={{ textAlign: 'center' }}>
                El producto tiene <b>un producto maestro</b>.
                <SaveButton
                  model='Product'
                  action='update'
                  disabled={this.shouldSaveButtonBeDisabled()}
                  icon={<RemoveCircleIcon />}
                  label='Quitar'
                  onClick={() => this.props.saveProduct(this.props.product.id, this.props.token, { stock_synced_to: null })}
                />
              </Grid>
            </Grid>
          </>
        )
      } else {
        // Product might be a SLAVE product.
        content = (
          <>
            <AlertInfo>El producto no está sincronizado con ningún otro. Selecciona un producto de la lista para empezar a <b>sincronizar el stock</b> de ambos productos.</AlertInfo>
            <form className={this.props.classes.rootWrap} noValidate autoComplete='off'>
              {
                _.keys(this.state.fields).map(fieldName => {
                  const field = this.state.fields[fieldName]

                  // Get value.
                  const value = this.props.product && this.props.product[fieldName] ? this.props.product[fieldName] : ''

                  let label = field.label

                  // Special field options.
                  if (fieldName === 'stock_synced_to') {
                    field.options = [
                      { label: '-', value: '' },
                      ...this.props.products.map(p => {
                        return { value: p.id, label: p.name }
                      })
                    ]
                    if (this.props.products.length) {
                      label = `Producto maestro (${this.props.products[0].category.site.url})`
                    }
                  }

                  return (
                    <FormField
                      fullWidth={field.fullWidth}
                      key={fieldName}
                      name={fieldName}
                      type={field.type}
                      autoFocus={field.autoFocus}
                      required={field.required}
                      error={field.error}
                      label={label}
                      helperText={field.helperText}
                      value={value}
                      handleChange={e => this.handleChange(fieldName, e.target.value)}
                      options={field.options}
                      readOnly={!userCan(this.props.user, 'Product', 'update')}
                    />
                  )
                })
              }
            </form>
            {this.props.formLoading ? <div className={this.props.classes.mt3}><LinearProgress /></div> : null}
            <div className={this.props.classes.mt2}>
              <SaveButton
                model='Product'
                action='update'
                disabled={this.shouldSaveButtonBeDisabled()}
                onClick={() => this.props.saveProduct(this.props.product.id, this.props.token, { stock_synced_to: +this.props.product.stock_synced_to })}
              />
            </div>
          </>
        )
      }
    } else {
      return null
    }

    return (
      <Grid item xs={12}>
        <Paper className={this.props.classes.paper} elevation={2}>
          <Title>Sincronización de stock</Title>
          <AlertSuccess message={this.props.success} />
          {content}
        </Paper>
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    loading: state.products.loading,
    success: state.products.success,
    formLoading: state.products.formLoading,
    products: state.products.products
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    searchProducts: (token, params) => dispatch(actions.searchProducts(token, params)),
    updateSelectedProduct: (key, value) => dispatch(actions.updateSelectedProduct(key, value)),
    saveProduct: (id, token, body) => dispatch(actions.saveProduct(id, token, body))
  }
}

// Style component.
const ProductStockSyncStyled = props => {
  const classes = useStyles()
  return (
    <ProductStockSync classes={classes} {...props} />
  )
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(ProductStockSyncStyled)
