// ============================================================================
// Dependencies.
// ============================================================================

// Axios instance.
import axios from '../../axios'

// Action types.
import * as actions from './types'

// Functions.
import { getErrorMessage } from '../../shared/functions'

// ============================================================================
// Action creators.
// ============================================================================

// Load POS terminals start.
export const loadPosTerminalsStart = () => {
  return {
    type: actions.LOAD_POS_TERMINALS_START
  }
}

// Load POS terminals success.
export const loadPosTerminalsSuccess = posTerminals => {
  return {
    type: actions.LOAD_POS_TERMINALS_SUCCESS,
    posTerminals
  }
}

// Load POS terminals fail.
export const loadPosTerminalsFailed = error => {
  return {
    type: actions.LOAD_POS_TERMINALS_FAILED,
    error
  }
}

// Load POS terminals.
export const loadPosTerminals = token => {
  return dispatch => {
    dispatch(loadPosTerminalsStart())
    // Perform request.
    axios(token).get('/pos_terminals')
      .then(response => {
        dispatch(loadPosTerminalsSuccess(response.data.pos_terminals))
      })
      .catch(error => {
        dispatch(loadPosTerminalsFailed(getErrorMessage(error)))
      })
  }
}

// Delete POS terminals start.
export const deletePosTerminalStart = () => {
  return {
    type: actions.DELETE_POS_TERMINAL_START
  }
}

// Delete POS terminals success.
export const deletePosTerminalSuccess = id => {
  return {
    type: actions.DELETE_POS_TERMINAL_SUCCESS,
    id
  }
}

// Delete POS terminals fail.
export const deletePosTerminalFailed = error => {
  return {
    type: actions.DELETE_POS_TERMINAL_FAILED,
    error
  }
}

// Delete POS terminals.
export const deletePosTerminal = (id, token) => {
  return dispatch => {
    dispatch(deletePosTerminalStart())
    // Perform request.
    axios(token).delete(`/pos_terminal/${id}`)
      .then(() => {
        dispatch(deletePosTerminalSuccess(id))
      })
      .catch(error => {
        dispatch(deletePosTerminalFailed(getErrorMessage(error)))
      })
  }
}

// Update the loaded POS terminal.
export const updateSelectedPosTerminal = (key, value) => {
  return {
    type: actions.UPDATE_SELECTED_POS_TERMINAL,
    key,
    value
  }
}

// Start saving a POS terminal.
export const savePosTerminalStart = posTerminal => {
  return {
    type: actions.SAVE_POS_TERMINAL_START,
    posTerminal
  }
}

// Save POS terminal success.
export const savePosTerminalSuccess = (id, posTerminal) => {
  return {
    type: actions.SAVE_POS_TERMINAL_SUCCESS,
    id,
    posTerminal
  }
}

// Save POS terminal fail.
export const savePosTerminalFailed = (id, error) => {
  return {
    type: actions.SAVE_POS_TERMINAL_FAILED,
    id,
    error
  }
}

// Save a POS terminal.
export const savePosTerminal = (id, token, body = {}) => {
  return dispatch => {
    dispatch(savePosTerminalStart())
    // Perform request.
    body = body || {}
    const url = id ? `/pos_terminal/${id}` : '/pos_terminal'
    const method = id ? 'put' : 'post'
    const postData = {
      name: body.name,
      bank_serial_number: body.bank_serial_number,
      terminal_serial_number: body.terminal_serial_number
    }
    axios(token)[method](url, postData)
      .then(response => {
        dispatch(savePosTerminalSuccess(id, response.data))
      })
      .catch(error => {
        dispatch(savePosTerminalFailed(id, getErrorMessage(error)))
      })
  }
}
