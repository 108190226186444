// =============================================================================
// Dependencies.
// =============================================================================

// Components.
import AlertWarning from '../../Alerts/AlertWarning'

// Style.
import './UnderConstruction.css'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const UnderConstruction = () => (
  <div className='UnderConstruction'>
    <div className='UnderConstructionText'>
      <AlertWarning>
        Esta sección se encuentra en construcción. Sin embargo, si has podido acceder a ella, es que <b>muy pronto estará disponible</b>.
      </AlertWarning>
    </div>
    <div className='UnderConstructionImage'>
      <img src='/under_construction.gif' alt='En construcción' />
    </div>
  </div>
)

// Export.
export default UnderConstruction
