// ============================================================================
// Dependencies.
// ============================================================================

// Axios instance.
import axios from '../../axios'

// Action types.
import * as actions from './types'

// Functions.
import { getErrorMessage } from '../../shared/functions'

// ============================================================================
// Action creators.
// ============================================================================

// Load settlements start.
export const loadSettlementsStart = () => {
  return {
    type: actions.LOAD_SETTLEMENTS_START
  }
}

// Load settlements success.
export const loadSettlementsSuccess = settlements => {
  return {
    type: actions.LOAD_SETTLEMENTS_SUCCESS,
    settlements
  }
}

// Load settlements fail.
export const loadSettlementsFailed = error => {
  return {
    type: actions.LOAD_SETTLEMENTS_FAILED,
    error
  }
}

// Load settlements.
export const loadSettlements = (artistId, token) => {
  return dispatch => {
    dispatch(loadSettlementsStart())
    // Perform request.
    axios(token).get(`/settlements/${artistId}`)
      .then(response => {
        dispatch(loadSettlementsSuccess(response.data.settlements))
      })
      .catch(error => {
        dispatch(loadSettlementsFailed(getErrorMessage(error)))
      })
  }
}

// Delete settlements start.
export const deleteSettlementStart = () => {
  return {
    type: actions.DELETE_SETTLEMENT_START
  }
}

// Delete settlements success.
export const deleteSettlementSuccess = id => {
  return {
    type: actions.DELETE_SETTLEMENT_SUCCESS,
    id
  }
}

// Delete settlements fail.
export const deleteSettlementFailed = error => {
  return {
    type: actions.DELETE_SETTLEMENT_FAILED,
    error
  }
}

// Delete settlements.
export const deleteSettlement = (id, token) => {
  return dispatch => {
    dispatch(deleteSettlementStart())
    // Perform request.
    axios(token).delete(`/settlement/${id}`)
      .then(() => {
        dispatch(deleteSettlementSuccess(id))
      })
      .catch(error => {
        dispatch(deleteSettlementFailed(getErrorMessage(error)))
      })
  }
}

// Start submitting a settlement.
export const submitSettlementStart = () => {
  return {
    type: actions.SUBMIT_SETTLEMENT_START
  }
}

// Submit settlement success.
export const submitSettlementSuccess = settlement => {
  return {
    type: actions.SUBMIT_SETTLEMENT_SUCCESS,
    settlement
  }
}

// Submit settlement fail.
export const submitSettlementFailed = error => {
  return {
    type: actions.SUBMIT_SETTLEMENT_FAILED,
    error
  }
}

// Submit a settlement.
export const submitSettlement = (token, body = {}) => {
  return dispatch => {
    dispatch(submitSettlementStart())
    // Perform request.
    body = body || {}
    const postData = {
      artist_id: +body.artist_id,
      month: +body.month,
      year: +body.year
    }
    axios(token).post('/settlement', postData)
      .then(response => {
        dispatch(submitSettlementSuccess(response.data))
      })
      .catch(error => {
        dispatch(submitSettlementFailed(getErrorMessage(error)))
      })
  }
}

// Submit last settlement.
export const submitLastSettlement = (token, artistId) => {
  return dispatch => {
    dispatch(submitSettlementStart())
    // Calculate last settlement month and year.
    const currentDate = new Date()
    currentDate.setMonth(currentDate.getMonth() - 1)
    const month = currentDate.getMonth() + 1
    const year = currentDate.getFullYear()
    // Perform request.
    const postData = {
      artist_id: +artistId,
      month,
      year
    }
    axios(token).post('/settlement', postData)
      .then(response => {
        dispatch(submitSettlementSuccess(response.data))
      })
      .catch(error => {
        dispatch(submitSettlementFailed(getErrorMessage(error)))
      })
  }
}
