// ============================================================================
// Dependencies.
// ============================================================================

// Axios instance.
import axios from '../../axios'

// Action types.
import * as actions from './types'

// Functions.
import { getErrorMessage } from '../../shared/functions'

// ============================================================================
// Action creators.
// ============================================================================

// Load earning types start.
export const loadEarningTypesStart = () => {
  return {
    type: actions.LOAD_EARNING_TYPES_START
  }
}

// Load earning types success.
export const loadEarningTypesSuccess = earningTypes => {
  return {
    type: actions.LOAD_EARNING_TYPES_SUCCESS,
    earningTypes
  }
}

// Load earning types fail.
export const loadEarningTypesFailed = error => {
  return {
    type: actions.LOAD_EARNING_TYPES_FAILED,
    error
  }
}

// Load earning types.
export const loadEarningTypes = token => {
  return dispatch => {
    dispatch(loadEarningTypesStart())
    // Perform request.
    axios(token).get('/earning_types')
      .then(response => {
        dispatch(loadEarningTypesSuccess(response.data.earning_types))
      })
      .catch(error => {
        dispatch(loadEarningTypesFailed(getErrorMessage(error)))
      })
  }
}

// Delete earning types start.
export const deleteEarningTypeStart = () => {
  return {
    type: actions.DELETE_EARNING_TYPE_START
  }
}

// Delete earning types success.
export const deleteEarningTypeSuccess = id => {
  return {
    type: actions.DELETE_EARNING_TYPE_SUCCESS,
    id
  }
}

// Delete earning types fail.
export const deleteEarningTypeFailed = error => {
  return {
    type: actions.DELETE_EARNING_TYPE_FAILED,
    error
  }
}

// Delete earning types.
export const deleteEarningType = (id, token) => {
  return dispatch => {
    dispatch(deleteEarningTypeStart())
    // Perform request.
    axios(token).delete(`/earning_type/${id}`)
      .then(() => {
        dispatch(deleteEarningTypeSuccess(id))
      })
      .catch(error => {
        dispatch(deleteEarningTypeFailed(getErrorMessage(error)))
      })
  }
}

// Update the loaded earning type.
export const updateSelectedEarningType = (key, value) => {
  return {
    type: actions.UPDATE_SELECTED_EARNING_TYPE,
    key,
    value
  }
}

// Start saving a earning type.
export const saveEarningTypeStart = earningType => {
  return {
    type: actions.SAVE_EARNING_TYPE_START,
    earningType
  }
}

// Save earning type success.
export const saveEarningTypeSuccess = (id, earningType) => {
  return {
    type: actions.SAVE_EARNING_TYPE_SUCCESS,
    id,
    earningType
  }
}

// Save earning type fail.
export const saveEarningTypeFailed = (id, error) => {
  return {
    type: actions.SAVE_EARNING_TYPE_FAILED,
    id,
    error
  }
}

// Save an earning type.
export const saveEarningType = (id, token, body = {}) => {
  return dispatch => {
    dispatch(saveEarningTypeStart())
    // Perform request.
    body = body || {}
    const url = id ? `/earning_type/${id}` : '/earning_type'
    const method = id ? 'put' : 'post'
    const postData = {
      name: body.name
    }
    axios(token)[method](url, postData)
      .then(response => {
        dispatch(saveEarningTypeSuccess(id, response.data))
      })
      .catch(error => {
        dispatch(saveEarningTypeFailed(id, getErrorMessage(error)))
      })
  }
}
