// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'

// Components.
import CategoryLink from '../../../../UI/Links/CategoryLink'
import SyncMode from '../../../../UI/Custom/SyncMode'
import AlertWarning from '../../../../UI/Alerts/AlertWarning'
import NumberOfStuff from '../../../../UI/Custom/NumberOfStuff'
import TableColumnTitle from '../../../../UI/Table/TableColumnTitle'
import TableLoader from '../../../../UI/Loaders/TableLoader'

// Shared.
import { RESULTS_PER_PAGE } from '../../../../../shared/constants'
import { prettyPrintDateTime } from '../../../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Table columns.
const columns = [
  { name: 'id', label: 'ID', sortable: true },
  { name: 'remote_object_id', label: 'ID remoto', sortable: true },
  { name: 'name', label: 'Nombre', sortable: true },
  { name: 'n_products', label: 'Nº productos', sortable: true },
  { name: 'sync_mode', label: 'Tipo de sincronización', sortable: true },
  { name: 'created_at', label: 'Fecha de creación (UTC)', sortable: true },
  { name: 'updated_at', label: 'Fecha de modificación (UTC)', sortable: true }
]

// Stateless component declaration.
const SiteCategories = props => {
  if (props.loading) {
    return <TableLoader cols={columns} />
  } else if (!props.categories || !props.categories.length) {
    return <AlertWarning>No se han encontrado categorías para este sitio web. Por favor, sincroniza las categorías de forma manual haciendo click en "Sincronizar", o espera a que finalice la sincronización automática que se realiza cada noche.</AlertWarning>
  }

  const siteCategories = []
  const minLimit = (props.page - 1) * RESULTS_PER_PAGE
  const maxLimit = Math.min(props.categories.length, props.page * RESULTS_PER_PAGE)

  for (let i = minLimit; i < maxLimit; i++) {
    const category = props.categories[i]
    siteCategories.push(
      <TableRow key={category.id}>
        <TableCell>
          {category.id}
        </TableCell>
        <TableCell>
          {category.remote_object_id}
        </TableCell>
        <TableCell>
          <CategoryLink category={category} />
        </TableCell>
        <TableCell>
          <NumberOfStuff>{category.n_products}</NumberOfStuff>
        </TableCell>
        <TableCell>
          <SyncMode sync_mode={category.sync_mode} />
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(category.created_at)}
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(category.updated_at)}
        </TableCell>
      </TableRow>
    )
  }

  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          {
            columns.map((col, key) => <TableColumnTitle key={key} {...col} orderBy={props.orderBy} orderDirection={props.orderDirection} onSort={props.onSort} />)
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {siteCategories}
      </TableBody>
    </Table>
  )
}

// Export.
export default SiteCategories
