// ============================================================================
// Dependencies.
// ============================================================================

// Actions.
import * as actions from '../actions/types'

// Utility.
import { updateObject } from '../../shared/functions'

// ============================================================================
// Initial state.
// ============================================================================

// Initial state.
const initialState = {
  error: null,
  loading: false,
  stats: {}
}

// ============================================================================
// Functionality.
// ============================================================================

// Load stats start.
const loadStatsStart = state => {
  // Update the state.
  return updateObject(state, {
    error: null,
    loading: true,
    stats: []
  })
}

// Load stats success.
const loadStatsSuccess = (state, action) => {
  // Update the state.
  return updateObject(state, {
    error: null,
    loading: false,
    stats: action.stats
  })
}

// Load stats fail.
const loadStatsFailed = (state, action) => {
  // Update the state.
  return updateObject(state, {
    error: action.error,
    loading: false,
    stats: []
  })
}

// ============================================================================
// Reducer definition.
// ============================================================================

// Reducer definition.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_DASHBOARD_STATS_START:
      return loadStatsStart(state, action)
    case actions.LOAD_DASHBOARD_STATS_SUCCESS:
      return loadStatsSuccess(state, action)
    case actions.LOAD_DASHBOARD_STATS_FAILED:
      return loadStatsFailed(state, action)
    default:
      return state
  }
}

export default reducer
