// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'

// Components.
import ArtistLink from '../../../../UI/Links/ArtistLink'
import NumberOfStuff from '../../../../UI/Custom/NumberOfStuff'
import AsyncActionStatus from '../../../../UI/Custom/AsyncActionStatus'
import AlertInfo from '../../../../UI/Alerts/AlertInfo'
import RowActions from '../../../../UI/Table/RowActions'
import TableColumnTitle from '../../../../UI/Table/TableColumnTitle'
import TableLoader from '../../../../UI/Loaders/TableLoader'
import SalesReportDialog from './SalesReportDialog'

// Shared.
import { prettyPrintDateTime, printPrice, buildUrl } from '../../../../../shared/functions'
import { RESULTS_PER_PAGE } from '../../../../../shared/constants'

// =============================================================================
// Component declaration.
// =============================================================================

// Table columns.
const columns = [
  { name: 'id', label: 'ID', sortable: true },
  { name: 'artist_id', label: 'Artista', sortable: true },
  { name: 'date_from', label: 'Desde', sortable: true },
  { name: 'date_to', label: 'Hasta', sortable: true },
  { name: 'total_sales', label: 'Nº prod. vendidos', sortable: true },
  { name: 'total_sales', label: 'Nº pedidos', sortable: true },
  { name: 'total_paid', label: 'Total ventas', sortable: true },
  { name: 'total_commission', label: 'Comisiones medios de pago', sortable: true },
  { name: 'status', label: 'Estado', sortable: true },
  { name: 'created_at', label: 'Fecha de creación (UTC)', sortable: true },
  { name: 'updated_at', label: 'Fecha de modificación (UTC)', sortable: true },
  { name: 'actions', label: '' }
]

// Stateless component declaration.
const SalesReportsTable = props => {
  // Loading.
  if (props.loading) {
    return <TableLoader cols={columns} />
  }

  // No results.
  if (!props.salesReports.length) {
    return <AlertInfo>Anú no has calculado ningún reporte de ventas.</AlertInfo>
  }

  const salesReportsTable = []
  const minLimit = (props.page - 1) * RESULTS_PER_PAGE
  const maxLimit = Math.min(props.salesReports.length, props.page * RESULTS_PER_PAGE)

  for (let i = minLimit; i < maxLimit; i++) {
    const salesReport = props.salesReports[i]

    const extraActions = []
    if (salesReport.total_sales && salesReport.total_sales > 0) {
      extraActions.push({ label: 'Ver detalles', handleAction: () => props.loadSalesReport(salesReport.id), actionName: 'read' })
    }

    salesReportsTable.push(
      <TableRow key={salesReport.id}>
        <TableCell>
          {salesReport.id}
        </TableCell>
        <TableCell>
          <ArtistLink artist={salesReport.artist} />
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(salesReport.date_from)}
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(salesReport.date_to)}
        </TableCell>
        <TableCell>
          <NumberOfStuff>{salesReport.total_sales}</NumberOfStuff>
        </TableCell>
        <TableCell>
          <NumberOfStuff>{salesReport.n_orders}</NumberOfStuff>
        </TableCell>
        <TableCell>
          <Chip size='small' variant='outlined' color='default' label={printPrice(salesReport.total_paid)} />
        </TableCell>
        <TableCell>
          {printPrice(salesReport.total_commission)}
        </TableCell>
        <TableCell>
          <AsyncActionStatus>{salesReport.status}</AsyncActionStatus>
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(salesReport.created_at)}
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(salesReport.updated_at)}
        </TableCell>
        <TableCell>
          {
            salesReport.status === 'finished'
              ? <RowActions
                  model='SalesReport'
                  id={salesReport.id}
                  downloadLink={buildUrl(salesReport.download_link, { _t: props.token })}
                  handleDelete={() => props.deleteSalesReport(salesReport.id)}
                  extraActions={extraActions}
                />
              : null
          }
        </TableCell>
      </TableRow>
    )
  }

  const salesReportDetailsDialog = (
    <SalesReportDialog
      loadingSalesReport={props.loadingSalesReport}
      loadedSalesReport={props.loadedSalesReport}
      onDialogClose={props.handleResetSalesReport}
    />
  )

  return (
    <>
      <Table size='small'>
        <TableHead>
          <TableRow>
            {
              columns.map((col, key) => <TableColumnTitle key={key} {...col} orderBy={props.orderBy} orderDirection={props.orderDirection} onSort={props.onSort} />)
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {salesReportsTable}
        </TableBody>
      </Table>
      {salesReportDetailsDialog}
    </>
  )
}

// Export.
export default SalesReportsTable
