// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip
} from '@material-ui/core'

// Components.
import SyncMode from '../../../../../UI/Custom/SyncMode'
import NumberOfStuff from '../../../../../UI/Custom/NumberOfStuff'
import BooleanField from '../../../../../UI/Custom/BooleanField'
import ProductStatus from '../../../../../UI/Custom/ProductStatus'
import AlertWarning from '../../../../../UI/Alerts/AlertWarning'
import MasterProductLink from '../../../../../UI/Links/MasterProductLink'
import ProductLink from '../../../../../UI/Links/ProductLink'
import CategoryLink from '../../../../../UI/Links/CategoryLink'
import SiteLink from '../../../../../UI/Links/SiteLink'
import ProductThumbnail from '../../../../../UI/Image/ProductThumbnail'
import TableColumnTitle from '../../../../../UI/Table/TableColumnTitle'
import TableLoader from '../../../../../UI/Loaders/TableLoader'

// Shared.
import { RESULTS_PER_PAGE } from '../../../../../../shared/constants'
import { prettyPrintDateTime, printPrice } from '../../../../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Table columns.
const columns = {
  detailed: [
    { name: 'id', label: 'ID', sortable: true },
    { name: 'image', label: '' },
    { name: 'remote_object_id', label: 'ID remoto', sortable: true },
    { name: 'name', label: 'Nombre', sortable: true },
    { name: 'active', label: 'Estado', sortable: true },
    { name: 'is_pack', label: 'Paquete de prouctos', sortable: true },
    { name: 'stock_quantity', label: 'Stock', sortable: true },
    { name: 'n_variations', label: 'Nº variaciones' },
    { name: 'price', label: 'Precio (IVA incl.)', sortable: true },
    { name: 'sale_price', label: 'Precio rebajado (IVA incl.)', sortable: true },
    { name: 'sync_mode', label: 'Tipo de sincronización', sortable: true },
    { name: 'created_at', label: 'Fecha de creación (UTC)', sortable: true },
    { name: 'updated_at', label: 'Fecha de modificación (UTC)', sortable: true },
    { name: 'actions', label: '' }
  ],
  simplified: [
    { name: 'id', label: 'ID', sortable: true },
    { name: 'image', label: '' },
    { name: 'remote_object_id', label: 'ID remoto', sortable: true },
    { name: 'name', label: 'Nombre', sortable: true },
    { name: 'category_id', label: 'Categoría', sortable: true },
    { name: 'site', label: 'Sitio web' },
    { name: 'active', label: 'Estado', sortable: true },
    { name: 'is_pack', label: 'Paquete de prouctos', sortable: true },
    { name: 'stock_quantity', label: 'Stock', sortable: true },
    { name: 'n_variations', label: 'Nº variaciones' },
    { name: 'price', label: 'Precio (IVA incl.)', sortable: true },
    { name: 'created_at', label: 'Fecha de creación (UTC)', sortable: true },
    { name: 'updated_at', label: 'Fecha de modificación (UTC)', sortable: true },
    { name: 'actions', label: '' }
  ]
}

// Get columns depending on the type.
const getColumns = simplified => simplified ? columns.simplified : columns.detailed

// Function to get the stock of a product by summing the stock of the variations.
const getTotalStockFromVariations = variations => {
  // If none of the variations has stock, return empty string.
  let atLeastOneVariationHasStock = false
  for (let i = 0; i < variations.length; i++) {
    if (variations[i].stock_quantity !== null) {
      atLeastOneVariationHasStock = true
      break
    }
  }
  if (!atLeastOneVariationHasStock) return null
  // Sum the stock of all variations.
  let stock = 0
  for (let j = 0; j < variations.length; j++) {
    if (variations[j].stock_quantity !== null) stock += (+variations[j].stock_quantity)
  }
  return stock
}

// Stateless component declaration.
const ProductsTable = props => {
  // Loading.
  if (props.loading) {
    return <TableLoader cols={getColumns(props.simplified)} />
  }

  const products = []
  const minLimit = (props.page - 1) * RESULTS_PER_PAGE
  const maxLimit = Math.min(props.products.length, props.page * RESULTS_PER_PAGE)

  if (!props.products.length) {
    return <AlertWarning>Esta categoría no es la categoría principal de ningún producto, o aún no se han sincronizado sus productos.</AlertWarning>
  }

  for (let i = minLimit; i < maxLimit; i++) {
    const product = props.products[i]

    let productStock = '-'
    if (product.stock_quantity !== null) {
      productStock = <NumberOfStuff>{product.stock_quantity}</NumberOfStuff>
    } else if (product.variations && product.variations.length) {
      const tip = product.variations.map(v => `${v.name}: ${v.stock_quantity !== null ? +v.stock_quantity : '-'}`).join(', ')
      const totalStockFromVariations = getTotalStockFromVariations(product.variations)
      if (totalStockFromVariations !== null) {
        productStock = (
          <Tooltip arrow placement='top' title={tip}>
            <span>
              <NumberOfStuff>{totalStockFromVariations}</NumberOfStuff>
              <span> *</span>
            </span>
          </Tooltip>
        )
      }
    }

    let simplifiedColumns = null
    let detailedColumns = null

    if (props.simplified) {
      simplifiedColumns = (
        <>
          <TableCell>
            <CategoryLink category={product.category} />
          </TableCell>
          <TableCell>
            <SiteLink site={product.category.site} />
          </TableCell>
        </>
      )
    } else {
      detailedColumns = (
        <>
          <TableCell>
            {product.sale_price && product.sale_price > 0 ? printPrice(product.sale_price) : '-'}
          </TableCell>
          <TableCell>
            <SyncMode sync_mode={product.sync_mode} />
          </TableCell>
        </>
      )
    }

    let productMasterLink = null
    if (product.master_product) {
      productMasterLink = (
        <MasterProductLink product={product.master_product} site_id={product.master_product.category ? product.master_product.category.site_id : null} />
      )
    }

    products.push(
      <TableRow key={product.id}>
        <TableCell>
          {product.id}
        </TableCell>
        <TableCell>
          <ProductThumbnail url={product.image} alt={product.name} />
        </TableCell>
        <TableCell>
          {product.remote_object_id}
        </TableCell>
        <TableCell>
          <ProductLink remote={props.remoteLinks} product={product} site_id={product.category ? product.category.site_id : props.site_id} />
          {productMasterLink}
        </TableCell>
        {simplifiedColumns}
        <TableCell>
          <ProductStatus>{product.active}</ProductStatus>
        </TableCell>
        <TableCell>
          <BooleanField active={product.is_pack} activeLabel='Sí' inactiveLabel='No' />
        </TableCell>
        <TableCell>
          {productStock}
        </TableCell>
        <TableCell>
          <NumberOfStuff>{product.variations && product.variations.length ? product.variations.length : 0}</NumberOfStuff>
        </TableCell>
        <TableCell>
          {printPrice(product.price)}
        </TableCell>
        {detailedColumns}
        <TableCell>
          {prettyPrintDateTime(product.created_at)}
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(product.updated_at)}
        </TableCell>
      </TableRow>
    )
  }

  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          {
            getColumns(props.simplified).map((col, key) => <TableColumnTitle key={key} {...col} orderBy={props.orderBy} orderDirection={props.orderDirection} onSort={props.onSort} />)
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {products}
      </TableBody>
    </Table>
  )
}

// Export.
export default ProductsTable
