// ============================================================================
// Dependencies.
// ============================================================================

// Axios instance.
import axios from '../../axios'

// Action types.
import * as actions from './types'

// Functions.
import { getErrorMessage } from '../../shared/functions'

// ============================================================================
// Action creators.
// ============================================================================

// Load sales reports start.
export const loadSalesReportsStart = () => {
  return {
    type: actions.LOAD_SALES_REPORTS_START
  }
}

// Load sales reports success.
export const loadSalesReportsSuccess = salesReports => {
  return {
    type: actions.LOAD_SALES_REPORTS_SUCCESS,
    salesReports
  }
}

// Load sales reports fail.
export const loadSalesReportsFailed = error => {
  return {
    type: actions.LOAD_SALES_REPORTS_FAILED,
    error
  }
}

// Load sales reports.
export const loadSalesReports = token => {
  return dispatch => {
    dispatch(loadSalesReportsStart())
    // Perform request.
    axios(token).get('/sales_reports')
      .then(response => {
        dispatch(loadSalesReportsSuccess(response.data.sales_reports))
      })
      .catch(error => {
        dispatch(loadSalesReportsFailed(getErrorMessage(error)))
      })
  }
}

// Load sales report start.
export const loadSalesReportStart = () => {
  return {
    type: actions.LOAD_SALES_REPORT_START
  }
}

// Load sales report success.
export const loadSalesReportSuccess = salesReport => {
  return {
    type: actions.LOAD_SALES_REPORT_SUCCESS,
    salesReport
  }
}

// Load sales report fail.
export const loadSalesReportFailed = error => {
  return {
    type: actions.LOAD_SALES_REPORT_FAILED,
    error
  }
}

// Load sales report.
export const loadSalesReport = (id, token) => {
  return dispatch => {
    dispatch(loadSalesReportStart())
    // Perform request.
    axios(token).get(`/sales_report/${id}`)
      .then(response => {
        dispatch(loadSalesReportSuccess(response.data))
      })
      .catch(error => {
        dispatch(loadSalesReportFailed(getErrorMessage(error)))
      })
  }
}

// Update the loaded sales report.
export const editNewSalesReport = (key, value) => {
  return {
    type: actions.EDIT_NEW_SALES_REPORT,
    key,
    value
  }
}

// Start saving a sales report.
export const saveSalesReportStart = salesReport => {
  return {
    type: actions.SAVE_SALES_REPORT_START,
    salesReport
  }
}

// Save sales report success.
export const saveSalesReportSuccess = salesReport => {
  return {
    type: actions.SAVE_SALES_REPORT_SUCCESS,
    salesReport
  }
}

// Save sales report fail.
export const saveSalesReportFailed = error => {
  return {
    type: actions.SAVE_SALES_REPORT_FAILED,
    error
  }
}

// Save a sales report.
export const saveSalesReport = (token, body = {}) => {
  return dispatch => {
    dispatch(saveSalesReportStart())
    // Perform request.
    body = body || {}
    axios(token).post('/sales_report', {
      artist_id: +body.artist_id,
      date_from: body.date_from,
      date_to: body.date_to
    })
      .then(response => {
        dispatch(saveSalesReportSuccess(response.data))
      })
      .catch(error => {
        dispatch(saveSalesReportFailed(getErrorMessage(error)))
      })
  }
}

// Delete sales report start.
export const deleteSalesReportStart = () => {
  return {
    type: actions.DELETE_SALES_REPORT_START
  }
}

// Delete sales report success.
export const deleteSalesReportSuccess = id => {
  return {
    type: actions.DELETE_SALES_REPORT_SUCCESS,
    id
  }
}

// Delete sales report fail.
export const deleteSalesReportFailed = error => {
  return {
    type: actions.DELETE_SALES_REPORT_FAILED,
    error
  }
}

// Delete sales report.
export const deleteSalesReport = (id, token) => {
  return dispatch => {
    dispatch(deleteSalesReportStart())
    // Perform request.
    axios(token).delete(`/sales_report/${id}`)
      .then(() => {
        dispatch(deleteSalesReportSuccess(id))
      })
      .catch(error => {
        dispatch(deleteSalesReportFailed(getErrorMessage(error)))
      })
  }
}

// Reset the loaded sales report.
export const resetLoadedSalesReport = () => {
  return {
    type: actions.RESET_LOADED_SALES_REPORT
  }
}
