// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import { React } from 'react'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  Typography
} from '@material-ui/core'

// Components.
import StockMovementsFormSummaryProduct from './StockMovementsFormSummaryProduct'
import Title from '../../../../../../UI/Brand/Title'

// Style.
import { useStyles } from '../../../../../../styles'

// =============================================================================
// Components.
// =============================================================================

// Declare stateless component.
const StockMovementsFormSummary = props => {
  const classes = useStyles()

  if (!props.products || !props.products.length) {
    return null
  }
  return (
    <Grid item xs={12}>
      <Paper elevation={2} className={classes.paperInner}>
        <div className='StockMovementProducts'>
          <div className='StockMovementProductsTable'>
            <Title>Detalles del stock {props.type === 'in' ? 'entrante' : 'saliente'}</Title>
            <Table size='small' className='CondensedTable'>
              <TableHead>
                <TableRow>
                  <TableCell>
                    ID producto
                  </TableCell>
                  <TableCell />
                  <TableCell>
                    Nombre producto
                  </TableCell>
                  <TableCell>
                    ID variación
                  </TableCell>
                  <TableCell>
                    Nombre variación
                  </TableCell>
                  <TableCell>
                    Precio en web
                  </TableCell>
                  <TableCell>
                    {
                      props.type === 'in'
                        ? <span>Stock entrante</span>
                        : (
                          <Tooltip title='Se comprobará el stock disponible del producto en el momento de la actualización. Si el stock disponible es menor, se retirará todo el stock disponible.' arrow placement='top'>
                            <Typography variant='inherit' align='center'>Stock a retirar*</Typography>
                          </Tooltip>
                          )
                    }
                  </TableCell>
                  <TableCell>
                    {
                      props.type === 'in'
                        ? (
                          <Tooltip title='Precio que tuvo el producto en el evento. Si el precio es el mismo que en el sitio web, déjalo en blanco.' arrow placement='top'>
                            <Typography variant='inherit' align='center'>Precio en evento*</Typography>
                          </Tooltip>
                          )
                        : (
                          <Tooltip title='Precio que tendrá el producto en el evento. Si el precio es el mismo que en el sitio web, déjalo en blanco.' arrow placement='top'>
                            <Typography variant='inherit' align='center'>Precio en evento*</Typography>
                          </Tooltip>
                          )
                    }
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {
                  props.products.map((product, key) => (
                    <StockMovementsFormSummaryProduct
                      key={key}
                      idx={key}
                      lastElement={key === props.products.length - 1}
                      product={product}
                    />
                  ))
                }
              </TableBody>
            </Table>
            <p className={classes.selectionSummaryFooter}>
              Al quitar productos, se quitarán también sus variaciones. Si no deseas {props.type === 'in' ? 'añadir' : 'retirar'} stock de una variación, escribe el valor '0'.
            </p>
          </div>
        </div>
      </Paper>
    </Grid>
  )
}

// Export.
export default StockMovementsFormSummary
