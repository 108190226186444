// ============================================================================
// Dependencies.
// ============================================================================

// Actions.
import * as actions from '../actions/types'

// Shared.
import { updateObject } from '../../shared/functions'

// ============================================================================
// Initial state.
// ============================================================================

// Initial state.
const initialState = {
  loading: true,
  error: null,
  productSyncLoading: false,
  productSyncSuccess: null,
  productSyncError: null,
  selectedCategory: null
}

// ============================================================================
// Functionality.
// ============================================================================

// Start loading category.
const loadCategoryStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    selectedCategory: null
  })
}

// Load a category successfully.
const loadCategorySuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    productSyncLoading: false,
    productSyncError: null,
    productSyncSuccess: null,
    error: null,
    selectedCategory: action.category
  })
}

// Load category, with error.
const loadCategoryFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    productSyncLoading: false,
    productSyncError: null,
    productSyncSuccess: null,
    error: action.error,
    selectedCategory: null
  })
}

// Start synchronizing the products of a category.
const syncProductsStart = state => {
  return updateObject(state, {
    productSyncLoading: true,
    productSyncError: null,
    productSyncSuccess: null
  })
}

// Finish synchronizing list of products.
const syncProductsSuccess = (state, action) => {
  return updateObject(state, {
    productSyncLoading: false,
    selectedCategory: {
      ...state.selectedCategory,
      products: action.products
    },
    productSyncError: null,
    productSyncSuccess: 'Productos sincronizados correctamente.'
  })
}

// Finish synchronizing list of products, with error.
const syncProductsFailed = (state, action) => {
  return updateObject(state, {
    productSyncLoading: false,
    productSyncError: action.error,
    productSyncSuccess: null
  })
}

// ============================================================================
// Reducer definition.
// ============================================================================

// Reducer definition.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_CATEGORY_START:
      return loadCategoryStart(state, action)
    case actions.LOAD_CATEGORY_SUCCESS:
      return loadCategorySuccess(state, action)
    case actions.LOAD_CATEGORY_FAILED:
      return loadCategoryFailed(state, action)
    case actions.SYNC_PRODUCTS_START:
      return syncProductsStart(state, action)
    case actions.SYNC_PRODUCTS_SUCCESS:
      return syncProductsSuccess(state, action)
    case actions.SYNC_PRODUCTS_FAILED:
      return syncProductsFailed(state, action)
    default:
      return state
  }
}

export default reducer
