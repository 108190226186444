// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { Chip } from '@material-ui/core'
import SubdirectoryArrowRightIcon from '@material-ui/icons/SubdirectoryArrowRight'

// Components.
import ProductLink from './ProductLink'

// Style.
import { useStyles } from '../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const MasterProductLink = props => {
  const classes = useStyles()
  return (
    <div className={classes.helperChip}>
      <Chip
        size='small'
        variant='outlined'
        color='primary'
        icon={<SubdirectoryArrowRightIcon style={{ marginLeft: 6 }} />}
        label={
          <ProductLink product={props.product} site_id={props.site_id} />
        }
      />
    </div>
  )
}

// Export.
export default MasterProductLink
