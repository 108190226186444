// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  Grid,
  Paper,
  Container,
  Link
} from '@material-ui/core'
import { Link as RouterLink, Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

// Components.
import Title from '../../../../UI/Brand/Title'
import AlertInfo from '../../../../UI/Alerts/AlertInfo'
import AlertSuccess from '../../../../UI/Alerts/AlertSuccess'
import AlertWarning from '../../../../UI/Alerts/AlertWarning'
import AlertError from '../../../../UI/Alerts/AlertError'
import CardTotals from '../../../../UI/Custom/CardTotals'

// Redux.
import * as actions from '../../../../../store/actions'

// Style.
import { useStyles } from '../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class Home extends Component {
  componentDidMount () {
    if (this.props.user && this.props.user.artist_id) this.props.loadArtistStats(this.props.user.artist_id, this.props.token)
    else this.props.loadDashboardStats(this.props.token)
  }

  printTotals = () => {
    if (!this.props.dashboardStats || !this.props.dashboardStats.totals || !this.props.dashboardStats.totals.length) {
      return [
        <CardTotals type='' image='/dashboard-cards/placeholder.gif' total='' link='' key={0} />,
        <CardTotals type='' image='/dashboard-cards/placeholder.gif' total='' link='' key={1} />,
        <CardTotals type='' image='/dashboard-cards/placeholder.gif' total='' link='' key={2} />,
        <CardTotals type='' image='/dashboard-cards/placeholder.gif' total='' link='' key={3} />
      ]
    }
    const totals = []
    for (let i = 0; i < this.props.dashboardStats.totals.length; i++) {
      const total = this.props.dashboardStats.totals[i]
      const element = (
        <CardTotals
          key={i}
          type={total.type}
          image={total.image}
          total={total.total}
          link={total.link}
        />
      )
      totals.push(element)
    }
    return totals
  }

  printAlerts = () => {
    if (!this.props.dashboardStats || !this.props.dashboardStats.alerts || !this.props.dashboardStats.alerts.length) {
      return null
    }
    const alerts = []
    for (let i = 0; i < this.props.dashboardStats.alerts.length; i++) {
      const alert = this.props.dashboardStats.alerts[i]
      let element = null
      const link = alert.link ? <Link component={RouterLink} to={alert.link.link}>{alert.link.label}</Link> : null
      switch (alert.type) {
        case 'warning':
          element = (
            <AlertWarning key={i}>
              {alert.message} {link}
            </AlertWarning>
          )
          break
        case 'success':
          element = (
            <AlertSuccess key={i} message={`${alert.message} ${link}`} />
          )
          break
        case 'info':
          element = (
            <AlertInfo key={i}>
              {alert.message} {link}
            </AlertInfo>
          )
          break
        case 'error':
          element = (
            <AlertError key={i} message={`${alert.message} ${link}`} />
          )
          break
        default:
      }
      if (element) alerts.push(element)
    }
    return alerts
  }

  render () {
    let content = null
    if (this.props.user && this.props.user.artist_id) {
      // Redirect to the artist homepage.
      return <Redirect to={`/artist/${this.props.user.artist_id}`} />
    } else {
      content = (
        <Container maxWidth='xl' className={this.props.classes.cardGrid}>
          <Grid container spacing={2}>
            {this.printTotals()}
          </Grid>
        </Container>
      )
    }

    let alerts = null
    if (this.props.user && this.props.user.artist_id) {
      alerts = null
    } else {
      alerts = this.printAlerts()
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={this.props.classes.paper}>
            <div className='Homepage'>
              <Title>Página de inicio</Title>
              {content}
              {alerts}
            </div>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user,
    loading: state.dashboard.loading,
    error: state.dashboard.error,
    dashboardStats: state.dashboard.stats,
    artistStatsLoading: state.artists.statsLoading,
    artistStats: state.artists.stats
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadDashboardStats: token => dispatch(actions.loadDashboardStats(token)),
    loadArtistStats: (artistId, token) => dispatch(actions.loadArtistStats(artistId, token))
  }
}

// Style component.
const HomeStyled = props => {
  const classes = useStyles()
  return <Home classes={classes} {...props} />
}

// Export.
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HomeStyled))
