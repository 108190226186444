// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'

// Components.
import ArrangementLink from '../../../../../UI/Links/ArrangementLink'
import Percentage from '../../../../../UI/Custom/Percentage'
import NumberOfStuff from '../../../../../UI/Custom/NumberOfStuff'
import AlertWarning from '../../../../../UI/Alerts/AlertWarning'
import RowActions from '../../../../../UI/Table/RowActions'
import BooleanField from '../../../../../UI/Custom/BooleanField'
import TableColumnTitle from '../../../../../UI/Table/TableColumnTitle'
import TableLoader from '../../../../../UI/Loaders/TableLoader'

// Shared.
import { RESULTS_PER_PAGE } from '../../../../../../shared/constants'
import { prettyPrintDateTime } from '../../../../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Table columns.
const columns = [
  { name: 'id', label: 'ID' },
  { name: 'name', label: 'Nombre' },
  { name: 'manufacturing_enabled', label: 'Fabricación de prendas' },
  { name: 'percentage', label: 'Porcentaje artista' },
  { name: 'n_products', label: 'Nº productos' },
  { name: 'created_at', label: 'Fecha de creación (UTC)' },
  { name: 'updated_at', label: 'Fecha de modificación (UTC)' },
  { name: 'actions', label: '' }
]

// Stateless component declaration.
const ArrangementsTable = props => {
  if (props.loading) {
    return <TableLoader cols={columns} />
  } else if (!props.dataSources || !props.dataSources.length) {
    return <AlertWarning>Por favor, configura fuentes de datos para este artista antes de editar sus tratos comerciales.</AlertWarning>
  } else if (!props.arrangements || !props.arrangements.length) {
    return <AlertWarning>Aún no se han configurado tratos comerciales para este artista, por lo que <b>no podrán calcularse las liquidaciones</b> correctamente.</AlertWarning>
  }

  const artistArrangements = []
  const minLimit = (props.page - 1) * RESULTS_PER_PAGE
  const maxLimit = Math.min(props.arrangements.length, props.page * RESULTS_PER_PAGE)

  for (let i = minLimit; i < maxLimit; i++) {
    const arrangement = props.arrangements[i]
    artistArrangements.push(
      <TableRow key={arrangement.id}>
        <TableCell>
          {arrangement.id}
        </TableCell>
        <TableCell>
          <ArrangementLink artistId={props.artistId} arrangement={arrangement} />
        </TableCell>
        <TableCell>
          <BooleanField active={arrangement.manufacturing_enabled} activeLabel='A cargo de Tumerchan' inactiveLabel='Por cuenta del artista' />
        </TableCell>
        <TableCell>
          <Percentage>{arrangement.percentage}</Percentage>
        </TableCell>
        <TableCell>
          <NumberOfStuff>{+arrangement.n_products}</NumberOfStuff>
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(arrangement.created_at)}
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(arrangement.updated_at)}
        </TableCell>
        <TableCell>
          <RowActions
            model='Arrangement'
            id={arrangement.id}
            handleDelete={() => props.deleteArrangement(arrangement.id)}
            editLink={`/artist/${props.artistId}/arrangement/${arrangement.id}`}
          />
        </TableCell>
      </TableRow>
    )
  }

  return (
    <>
      <Table size='small'>
        <TableHead>
          <TableRow>
            {
              columns.map((col, key) => <TableColumnTitle key={key} {...col} />)
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {artistArrangements}
        </TableBody>
      </Table>
    </>
  )
}

// Export.
export default ArrangementsTable
