// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  TableCell,
  TableRow,
  LinearProgress,
  IconButton
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'

// Components.
import FormField from '../../../../../UI/Form/FormField'
import RowActions from '../../../../../UI/Table/RowActions'
import ExpenseType from '../../../../../UI/Custom/ExpenseType'
import SaveButton from '../../../../../UI/Buttons/SaveButton'

// Shared.
import { prettyPrintDateTime } from '../../../../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class ExpenseTypesTableRow extends Component {
  state = {
    editing: false,
    newName: null
  }

  onInlineUpdateStart = () => {
    this.setState({ editing: true, newName: this.props.name })
  }

  handleStopInlineUpdate = () => {
    this.setState({ editing: false })
  }

  handleNameUpdate = value => {
    this.setState({ newName: value })
  }

  shouldSaveButtonBeDisabled = () => {
    return this.props.loading ||
      !this.state.newName ||
      this.state.newName === ''
  }

  handleSaveExpenseType = () => {
    this.props.handleSaveExpenseType(this.state.newName)
    this.handleStopInlineUpdate()
  }

  render () {
    if (this.props.loading) {
      return <LinearProgress />
    }
    return (
      <TableRow key={this.props.id}>
        <TableCell>
          {
            this.state.editing
              ? (
                <IconButton color='default' aria-label='undo' size='small' onClick={this.handleStopInlineUpdate}>
                  <ClearIcon />
                </IconButton>
                )
              : this.props.id
          }
        </TableCell>
        <TableCell>
          {
            this.state.editing
              ? (
                <FormField
                  noMargin
                  fullWidth
                  name='name'
                  type='text'
                  autoFocus
                  required
                  label='Nombre'
                  value={this.state.newName}
                  handleChange={e => this.handleNameUpdate(e.target.value)}
                />
                )
              : (
                <ExpenseType>{this.props.name}</ExpenseType>
                )
          }
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(this.props.createdAt)}
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(this.props.updatedAt)}
        </TableCell>
        <TableCell>
          {
            this.state.editing
              ? (
                <SaveButton
                  model='ExpenseType'
                  action='update'
                  disabled={this.shouldSaveButtonBeDisabled()}
                  onClick={this.handleSaveExpenseType}
                />
                )
              : (
                <RowActions
                  model='ExpenseType'
                  id={this.props.id}
                  handleDelete={() => this.props.onDeleteExpenseType()}
                  handleInlineUpdate={this.onInlineUpdateStart}
                />
                )
          }
        </TableCell>
      </TableRow>
    )
  }
}

// ============================================================================
// Export.
// ============================================================================

// Export.
export default ExpenseTypesTableRow
