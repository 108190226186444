// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import { React } from 'react'
import HomeIcon from '@material-ui/icons/Home'
import {
  Typography,
  Breadcrumbs,
  Link,
  Chip
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

// Style.
import { useStyles } from '../../styles'

// =============================================================================
// Components.
// =============================================================================

// Declare stateless component.
const Breadcrumb = props => {
  const classes = useStyles()

  const elements = [
    { link: '/', label: 'Home', icon: <HomeIcon fontSize='small' /> },
    ...props.elements
  ]

  const lastElement = elements.pop()
  return (
    <Breadcrumbs aria-label='breadcrumb'>
      {
        elements.map((element, key) => {
          if (element.link) {
            return (
              <Link key={key} color='inherit' to={element.link} component={RouterLink} className={classes.noUnderlineOnHover}>
                <Chip className={classes.breadcrumbElement} label={element.label} icon={element.icon} />
              </Link>
            )
          } else {
            return (
              <Typography key={key} color='textPrimary' component='div'>
                <Chip className={classes.lastBreadcrumbElement} label={element.label} icon={element.icon} />
              </Typography>
            )
          }
        })
      }
      <Typography color='textPrimary' component='div'>
        <Chip className={classes.lastBreadcrumbElement} label={lastElement.label} icon={lastElement.icon} />
      </Typography>
    </Breadcrumbs>
  )
}

// Export.
export default Breadcrumb
