// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { Avatar } from '@material-ui/core'

// Shared.
import { buildUrl } from '../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const UserThumbnail = props => {
  if (!props.user) return null
  return (
    <Avatar alt={props.user.email || 'image'} src={buildUrl(props.user.image, { _t: props.token, ck: Date.now() })} />
  )
}

// Export.
export default UserThumbnail
