// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  LinearProgress,
  Paper
} from '@material-ui/core'

// Components.
import Title from '../../../../../../UI/Brand/Title'
import FormField from '../../../../../../UI/Form/FormField'
import AlertSuccess from '../../../../../../UI/Alerts/AlertSuccess'
import AlertError from '../../../../../../UI/Alerts/AlertError'
import StockMovementsFormSummary from '../../StockMovements/StockMovementsForm/StockMovementsFormSummary'
import StockMovementsFormDataSources from '../../StockMovements/StockMovementsForm/StockMovementsFormDataSources'
import SaveButton from '../../../../../../UI/Buttons/SaveButton'
import GoBackButton from '../../../../../../UI/Buttons/GoBackButton'

// Redux actions.
import * as actions from '../../../../../../../store/actions'

// Styles.
import { useStyles } from '../../../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class StockWithdrawalsForm extends Component {
  state = {
    fields: {
      location: {
        label: 'Lugar/ descripción',
        error: null,
        type: 'text',
        required: true,
        touched: false,
        errMsg: 'Por favor, introduce un valor.',
        fullWidth: false,
        width: 6,
        autoFocus: true,
        helperText: 'Lugar del evento, o en su defecto, una descripción. Este campo aparecerá en el Excel en la sección "Lugar".'
      },
      pos_terminal_id: {
        label: 'Terminal TPV',
        error: null,
        type: 'select',
        required: false,
        touched: false,
        errMsg: 'Por favor, selecciona un TPV de la lista.',
        helperText: 'Terminal TPV que se utilizará en el evento. Este campo aparecerá en el Excel en la sección correspondiente.',
        autoFocus: false,
        fullWidth: false,
        width: 6,
        options: []
      }
    }
  }

  componentDidMount () {
    // Load the list of POS terminals.
    if (!this.props.posTerminals || !this.props.posTerminals.length) {
      this.props.loadPosTerminals(this.props.token)
    }
  }

  handleChange = (elemKey, newValue) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields[elemKey]
    }
    // Modify the element value.
    this.props.updateSelectedStockMovement(elemKey, newValue)
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.required && (newElement.type === 'select' ? !newValue : !newValue.length) && newElement.touched) {
      newElement.error = 'Campo obligatorio'
    } else if (newElement.validation && newValue.length && !newElement.validation(newValue)) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Build new state.
    const newState = {
      ...this.state,
      fields: {
        ...this.state.fields,
        [elemKey]: newElement
      }
    }
    // Update the state.
    this.setState(newState)
  }

  shouldSaveButtonBeDisabled = () => {
    if (this.props.formLoading) return true
    for (const fieldName in this.state.fields) {
      if (Object.prototype.hasOwnProperty.call(this.state.fields, fieldName)) {
        const field = this.state.fields[fieldName]
        if (field.required && (!this.props.selectedStockMovement || !this.props.selectedStockMovement[fieldName])) return true
        if (field.error) return true
      }
    }
    if (this.props.selectedStockMovement.products && this.props.selectedStockMovement.products.length) {
      for (let i = 0; i < this.props.selectedStockMovement.products.length; i++) {
        const product = this.props.selectedStockMovement.products[i]
        if (product.stock_quantity === null || isNaN(product.stock_quantity) || product.stock_quantity < 0) return true
        if (product.price_new && (isNaN(product.price_new) || product.price_new < 0)) return true
      }
    }
    return false
  }

  handleCreateStockWithdrawal = () => {
    this.props.createStockMovement(this.props.token, {
      artist_id: this.props.selectedStockMovement.artist_id,
      pos_terminal_id: this.props.selectedStockMovement.pos_terminal_id,
      location: this.props.selectedStockMovement.location,
      products: this.props.selectedStockMovement.products
    })
  }

  render () {
    let content = null

    // Handle loading state.
    if (this.props.formLoading) {
      // Show loader.
      content = <LinearProgress />
    } else {
      content = (
        <>
          <form className={this.props.classes.rootWrap} noValidate autoComplete='off'>
            {
              Object.keys(this.state.fields).map(fieldName => {
                const field = this.state.fields[fieldName]

                // Special field options.
                let disabled = field.disabled
                const helperText = field.helperText
                if (fieldName === 'pos_terminal_id') {
                  disabled = !this.props.posTerminals || this.props.posTerminalsLoading
                  if (!disabled) {
                    field.options = [
                      { label: '-', value: '' },
                      ...this.props.posTerminals.map(t => {
                        return { value: t.id, label: `Nº ${t.name} (nº de serie ${t.terminal_serial_number})` }
                      })
                    ]
                  }
                }

                // Get value.
                let value = this.props.selectedStockMovement && this.props.selectedStockMovement[fieldName] ? this.props.selectedStockMovement[fieldName] : ''
                if (!value.length && field.type === 'multiselect') value = []

                return (
                  <FormField
                    key={fieldName}
                    name={fieldName}
                    disabled={disabled}
                    type={field.type}
                    autoFocus={field.autoFocus}
                    required={field.required}
                    error={field.error}
                    label={field.label}
                    value={value}
                    fullWidth={field.fullWidth}
                    width={field.width}
                    handleChange={e => this.handleChange(fieldName, e.target.value)}
                    options={field.options}
                    helperText={helperText}
                  />
                )
              })
            }
          </form>
          <StockMovementsFormDataSources
            dataSources={this.props.dataSources}
            addStockMovementDataSource={this.props.addStockMovementDataSource}
          />
          <StockMovementsFormSummary
            type='out'
            products={this.props.selectedStockMovement ? this.props.selectedStockMovement.products : null}
          />
        </>
      )
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={this.props.classes.paper}>
            <div className='StockWithdrawal'>
              <div className='StockWithdrawalsForm'>
                <Title>Nueva salida de stock</Title>
                <AlertError error={this.props.error} />
                <AlertSuccess message={this.props.success} />
                {content}
                <div className={this.props.classes.mt2}>
                  <GoBackButton link='/warehouse/stock_withdrawals' />
                  <SaveButton
                    model='StockMovement'
                    action='create'
                    disabled={this.shouldSaveButtonBeDisabled()}
                    onClick={this.handleCreateStockWithdrawal}
                  />
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    formLoading: state.stockMovements.formLoading,
    error: state.stockMovements.error,
    success: state.stockMovements.success,
    artists: state.artists.artists,
    posTerminalsLoading: state.posTerminals.loading,
    posTerminals: state.posTerminals.posTerminals,
    selectedArtist: state.artists.selectedArtist,
    selectedCategory: state.categories.selectedCategory,
    selectedStockMovement: state.stockMovements.selectedStockMovement,
    dataSources: state.stockMovements.dataSources
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadPosTerminals: token => dispatch(actions.loadPosTerminals(token)),
    loadArtists: token => dispatch(actions.loadArtists(token)),
    loadArtist: (id, token) => dispatch(actions.loadArtist(id, token)),
    loadCategory: (id, token) => dispatch(actions.loadCategory(id, token)),
    loadStockMovement: (id, token) => dispatch(actions.loadStockMovement(id, token)),
    createStockMovement: (token, body) => dispatch(actions.createStockMovement(token, { ...body, type: 'out' })),
    updateSelectedStockMovement: (key, value) => dispatch(actions.updateSelectedStockMovement(key, value)),
    addStockMovementDataSource: (categoryId, artistId) => dispatch(actions.addStockMovementDataSource(categoryId, artistId)),
    deleteStockMovementDataSource: (categoryId, artistId) => dispatch(actions.deleteStockMovementDataSource(categoryId, artistId))
  }
}

// Style component.
const StockWithdrawalsFormStyled = props => {
  const classes = useStyles()
  return (
    <StockWithdrawalsForm classes={classes} {...props} />
  )
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(StockWithdrawalsFormStyled)
