// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import { React } from 'react'
import {
  Tooltip
} from '@material-ui/core'

// Shared.
import { prettyPrintDate } from '../../../shared/functions'

// =============================================================================
// Components.
// =============================================================================

// Version.
const currentVersionNumber = '1.5.1'
const currentVersionDate = '2022-03-01'

// Stateless component.
const Version = () => (
  <Tooltip title={`Fecha de lanzamiento: ${prettyPrintDate(currentVersionDate)}`} arrow placement='top'>
    <span>Versión <b>{currentVersionNumber}</b></span>
  </Tooltip>
)

// Export.
export default Version
