// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import { Button } from '@material-ui/core'
import RefreshIcon from '@material-ui/icons/Refresh'
import { connect } from 'react-redux'

// Shared.
import { userCan } from '../../../shared/functions'

// Style.
import { useStyles } from '../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class RefreshButton extends Component {
  render () {
    const label = this.props.label ? this.props.label : 'Actualizar'
    const action = this.props.action ? this.props.action : 'list'

    if (!userCan(this.props.user, this.props.model, action)) {
      return null
    }

    return (
      <Button
        color='default'
        variant='outlined'
        startIcon={<RefreshIcon />}
        style={{ marginRight: 10 }}
        onClick={this.props.onClick}
      >
        {label}
      </Button>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

// Style component.
const RefreshButtonStyled = props => {
  const classes = useStyles()
  return <RefreshButton classes={classes} {...props} />
}

// Export.
export default connect(mapStateToProps)(RefreshButtonStyled)
