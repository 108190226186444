// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'

// Shared.
import { printPrice } from '../../../shared/functions'

// Style.
import { useStyles } from '../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const PriceBlock = props => {
  const classes = useStyles()
  return (
    <div className={classes.priceBlock} {...props}>
      <span className={classes.oldPrice}>{props.reduced ? printPrice(props.original) : null}</span>
      <span className={classes.newPrice}>{props.reduced ? printPrice(props.reduced) : printPrice(props.original)}</span>
    </div>
  )
}

// Export.
export default PriceBlock
