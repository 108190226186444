// ============================================================================
// Dependencies.
// ============================================================================

// Axios instance.
import axios from '../../axios'

// Action types.
import * as actions from './types'

// Functions.
import { getErrorMessage } from '../../shared/functions'

// ============================================================================
// Action creators.
// ============================================================================

// Load users start.
export const loadUsersStart = () => {
  return {
    type: actions.LOAD_USERS_START
  }
}

// Load users success.
export const loadUsersSuccess = users => {
  return {
    type: actions.LOAD_USERS_SUCCESS,
    users
  }
}

// Load users fail.
export const loadUsersFailed = error => {
  return {
    type: actions.LOAD_USERS_FAILED,
    error
  }
}

// Load users.
export const loadUsers = token => {
  return dispatch => {
    dispatch(loadUsersStart())
    // Perform request.
    axios(token).get('/users')
      .then(response => {
        dispatch(loadUsersSuccess(response.data.users))
      })
      .catch(error => {
        dispatch(loadUsersFailed(getErrorMessage(error)))
      })
  }
}

// Delete user start.
export const deleteUserStart = () => {
  return {
    type: actions.DELETE_USER_START
  }
}

// Delete user success.
export const deleteUserSuccess = id => {
  return {
    type: actions.DELETE_USER_SUCCESS,
    id
  }
}

// Delete user fail.
export const deleteUserFailed = error => {
  return {
    type: actions.DELETE_USER_FAILED,
    error
  }
}

// Delete user.
export const deleteUser = (id, token) => {
  return dispatch => {
    dispatch(deleteUserStart())
    // Perform request.
    axios(token).delete(`/user/${id}`)
      .then(() => {
        dispatch(deleteUserSuccess(id))
      })
      .catch(error => {
        dispatch(deleteUserFailed(getErrorMessage(error)))
      })
  }
}

// Load user start.
export const loadUserStart = () => {
  return {
    type: actions.LOAD_USER_START
  }
}

// Load user success.
export const loadUserSuccess = user => {
  return {
    type: actions.LOAD_USER_SUCCESS,
    user
  }
}

// Load user fail.
export const loadUserFailed = error => {
  return {
    type: actions.LOAD_USER_FAILED,
    error
  }
}

// Load user.
export const loadUser = (id, token) => {
  return dispatch => {
    if (!id) {
      dispatch(loadUserSuccess(null))
      return
    }
    dispatch(loadUserStart())
    // Perform request.
    axios(token).get(`/user/${id}`)
      .then(response => {
        dispatch(loadUserSuccess(response.data))
      })
      .catch(error => {
        dispatch(loadUserFailed(getErrorMessage(error)))
      })
  }
}

// Load the logged user.
export const loadLoggedUser = (token) => {
  return dispatch => {
    dispatch(loadUserStart())
    // Perform request.
    axios(token).get('/me')
      .then(response => {
        dispatch(loadUserSuccess(response.data))
      })
      .catch(error => {
        dispatch(loadUserFailed(getErrorMessage(error)))
      })
  }
}

// Update the loaded user.
export const updateSelectedUser = (key, value) => {
  return {
    type: actions.UPDATE_SELECTED_USER,
    key,
    value
  }
}

// Start saving a user.
export const saveUserStart = user => {
  return {
    type: actions.SAVE_USER_START,
    user
  }
}

// Save user success.
export const saveUserSuccess = (id, user, isLoggedUser) => {
  return {
    type: actions.SAVE_USER_SUCCESS,
    id,
    user,
    isLoggedUser
  }
}

// Save user fail.
export const saveUserFailed = (id, error) => {
  return {
    type: actions.SAVE_USER_FAILED,
    id,
    error
  }
}

// Save a user.
export const saveUser = (id, token, body = {}) => {
  return dispatch => {
    dispatch(saveUserStart())
    // Perform request.
    body = body || {}
    const url = id ? `/user/${id}` : '/user'
    const method = id ? 'put' : 'post'
    const postData = {
      email: body.email,
      firstname: body.firstname,
      profile_id: +body.profile_id,
      artist_id: +body.artist_id,
      active: +body.active
    }
    if (id) postData.new_password = body.password
    else postData.password = body.password
    axios(token)[method](url, postData)
      .then(response => {
        dispatch(saveUserSuccess(id, response.data))
      })
      .catch(error => {
        dispatch(saveUserFailed(id, getErrorMessage(error)))
      })
  }
}

// Save the logged user.
export const saveLoggedUser = (token, body = {}) => {
  return dispatch => {
    dispatch(saveUserStart())
    // Perform request.
    body = body || {}
    const postData = {
      email: body.email,
      firstname: body.firstname
    }
    if (postData.new_password) postData.new_password = body.password
    axios(token).post('/me', postData)
      .then(response => {
        dispatch(saveUserSuccess(null, response.data, true))
      })
      .catch(error => {
        dispatch(saveUserFailed(getErrorMessage(error)))
      })
  }
}

// Load profiles start.
export const loadProfilesStart = () => {
  return {
    type: actions.LOAD_PROFILES_START
  }
}

// Load profiles success.
export const loadProfilesSuccess = profiles => {
  return {
    type: actions.LOAD_PROFILES_SUCCESS,
    profiles
  }
}

// Load profiles fail.
export const loadProfilesFailed = error => {
  return {
    type: actions.LOAD_PROFILES_FAILED,
    error
  }
}

// Load profiles.
export const loadProfiles = token => {
  return dispatch => {
    dispatch(loadProfilesStart())
    // Perform request.
    axios(token).get('/profiles')
      .then(response => {
        dispatch(loadProfilesSuccess(response.data.profiles))
      })
      .catch(error => {
        dispatch(loadProfilesFailed(getErrorMessage(error)))
      })
  }
}

// Upload user image start.
export const uploadUserImageStart = () => {
  return {
    type: actions.UPLOAD_USER_IMAGE_START
  }
}

// Upload user image success.
export const uploadUserImageSuccess = image => {
  return {
    type: actions.UPLOAD_USER_IMAGE_SUCCESS,
    image
  }
}

// Upload user image fail.
export const uploadUserImageFailed = error => {
  return {
    type: actions.UPLOAD_USER_IMAGE_FAILED,
    error
  }
}

// Upload user image.
export const uploadUserImage = (id, token, file) => {
  return dispatch => {
    dispatch(uploadUserImageStart())
    // Perform request.
    const formData = new FormData()
    formData.append('file', file)
    axios(token).post(`/user/image/${id}`, formData)
      .then(response => {
        dispatch(uploadUserImageSuccess(response.data.image))
      })
      .catch(error => {
        dispatch(uploadUserImageFailed(getErrorMessage(error)))
      })
  }
}

// Upload the logged user image.
export const uploadLoggedUserImage = (token, file) => {
  return dispatch => {
    dispatch(uploadUserImageStart())
    // Perform request.
    const formData = new FormData()
    formData.append('file', file)
    axios(token).post('/me/image', formData)
      .then(response => {
        dispatch(uploadUserImageSuccess(response.data.image))
      })
      .catch(error => {
        dispatch(uploadUserImageFailed(getErrorMessage(error)))
      })
  }
}
