// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button
} from '@material-ui/core'

// Components.
import AlertInfo from '../../../../../../UI/Alerts/AlertInfo'
import UserLink from '../../../../../../UI/Links/UserLink'
import RowActions from '../../../../../../UI/Table/RowActions'
import AsyncActionStatus from '../../../../../../UI/Custom/AsyncActionStatus'
import PosTerminalLink from '../../../../../../UI/Links/PosTerminalLink'
import TableColumnTitle from '../../../../../../UI/Table/TableColumnTitle'
import TableLoader from '../../../../../../UI/Loaders/TableLoader'

// Shared.
import { buildUrl, prettyPrintDateTime } from '../../../../../../../shared/functions'
import { RESULTS_PER_PAGE } from '../../../../../../../shared/constants'

// =============================================================================
// Component declaration.
// =============================================================================

// Table columns.
const columns = [
  { name: 'id', label: 'ID', sortable: true },
  { name: 'pos_terminal_id', label: 'TPV', sortable: true },
  { name: 'user_id', label: 'Creado por', sortable: true },
  { name: 'location', label: 'Lugar', sortable: true },
  { name: 'status', label: 'Estado', sortable: true },
  { name: 'created_at', label: 'Fecha de creación (UTC)', sortable: true },
  { name: 'updated_at', label: 'Fecha de modificación (UTC)', sortable: true },
  { name: 'actions1', label: '' },
  { name: 'actions2', label: '' },
  { name: 'actions3', label: '' }
]

// Stateless component declaration.
const StockWithdrawalsTable = props => {
  // Loading.
  if (props.loading) {
    return <TableLoader cols={columns} />
  }

  // No results.
  if (!props.stockMovements.length) {
    return <AlertInfo>No se han encontrado resultados.</AlertInfo>
  }

  const stockMovementsTable = []
  const minLimit = (props.page - 1) * RESULTS_PER_PAGE
  const maxLimit = Math.min(props.stockMovements.length, props.page * RESULTS_PER_PAGE)

  for (let i = minLimit; i < maxLimit; i++) {
    const stockMovement = props.stockMovements[i]

    const extraActions = []
    if (stockMovement.status === 'finished') {
      extraActions.push({ label: 'Crear entrada de stock', handleAction: () => props.handleCreateStockEntryFromWithdrawal(stockMovement.id), actionName: 'create' })
    }

    stockMovementsTable.push(
      <TableRow key={i}>
        <TableCell>{stockMovement.id}</TableCell>
        <TableCell><PosTerminalLink posTerminal={stockMovement.pos_terminal} /></TableCell>
        <TableCell><UserLink user={stockMovement.user} label='firstname' /></TableCell>
        <TableCell>{stockMovement.location}</TableCell>
        <TableCell><AsyncActionStatus>{stockMovement.status}</AsyncActionStatus></TableCell>
        <TableCell>{prettyPrintDateTime(stockMovement.created_at)}</TableCell>
        <TableCell>{prettyPrintDateTime(stockMovement.updated_at)}</TableCell>
        <TableCell>
          {
            stockMovement.status === 'scheduled'
              ? null
              : (
                <Button
                  onClick={() => props.handleOpenStockMovementLogs(stockMovement.id)}
                  size='small'
                >
                  Logs
                </Button>
                )
          }
        </TableCell>
        <TableCell>
          <Button
            onClick={() => props.handleOpenStockMovementDetails(stockMovement.id)}
            size='small'
          >
            Detalles
          </Button>
        </TableCell>
        <TableCell>
          <RowActions
            model='StockMovement'
            handleDelete={() => props.deleteStockMovement(stockMovement.id)}
            downloadLink={stockMovement.status === 'finished' && stockMovement.filename ? buildUrl(stockMovement.download_link, { _t: props.token }) : null}
            extraActions={extraActions}
          />
        </TableCell>
      </TableRow>
    )
  }

  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          {
            columns.map((col, key) => <TableColumnTitle key={key} {...col} orderBy={props.orderBy} orderDirection={props.orderDirection} onSort={props.onSort} />)
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {stockMovementsTable}
      </TableBody>
    </Table>
  )
}

// Export.
export default StockWithdrawalsTable
