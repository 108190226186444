// ============================================================================
// Dependencies.
// ============================================================================

// Actions.
import * as actions from '../actions/types'

// Shared.
import { updateObject } from '../../shared/functions'

// ============================================================================
// Initial state.
// ============================================================================

// Initial state.
const initialState = {
  formLoading: false,
  loading: true,
  error: null,
  success: null,
  products: [],
  selectedProduct: null,
  syncLoading: false,
  syncSuccess: null,
  syncError: null,
  queryString: ''
}

// ============================================================================
// Functionality.
// ============================================================================

// Start loading product.
const loadProductStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    selectedProduct: null,
    success: null
  })
}

// Load a product successfully.
const loadProductSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    selectedProduct: action.product
  })
}

// Load product, with error.
const loadProductFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    selectedProduct: null
  })
}

// Start saving a product.
const saveProductStart = (state, action) => {
  return updateObject(state, {
    formLoading: true,
    error: null,
    success: null
  })
}

// Save a product successfully.
const saveProductSuccess = (state, action) => {
  return updateObject(state, {
    formLoading: false,
    error: null,
    success: 'Producto actualizado con éxito.',
    selectedProduct: action.product
  })
}

// Save a product, with error.
const saveProductFailed = (state, action) => {
  return updateObject(state, {
    formLoading: false,
    error: action.error,
    success: null
  })
}

// Update the selected product.
const updateSelectedProduct = (state, action) => {
  const newSelectedProduct = {
    ...state.selectedProduct
  }
  newSelectedProduct[action.key] = action.value
  return updateObject(state, {
    selectedProduct: newSelectedProduct
  })
}

// Start synchronizing a product.
const syncProductStart = state => {
  return updateObject(state, {
    syncLoading: true,
    syncError: null,
    syncSuccess: null
  })
}

// Finish synchronizing a product.
const syncProductSuccess = (state, action) => {
  return updateObject(state, {
    syncLoading: false,
    selectedProduct: action.product,
    syncError: null,
    syncSuccess: 'Producto sincronizado correctamente.'
  })
}

// Finish synchronizing a product, with error.
const syncProductFailed = (state, action) => {
  return updateObject(state, {
    syncLoading: false,
    syncError: action.error,
    syncSuccess: null
  })
}

// Start searching products.
const searchProductsStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    products: []
  })
}

// Finish searching products.
const searchProductsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    products: action.products,
    error: null
  })
}

// Finish searching products, qith error.
const searchProductsFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    products: []
  })
}

// Update the query string.
const updateProductQueryString = (state, action) => {
  return updateObject(state, {
    queryString: action.queryString
  })
}

// ============================================================================
// Reducer definition.
// ============================================================================

// Reducer definition.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_PRODUCT_START:
      return loadProductStart(state, action)
    case actions.LOAD_PRODUCT_SUCCESS:
      return loadProductSuccess(state, action)
    case actions.LOAD_PRODUCT_FAILED:
      return loadProductFailed(state, action)
    case actions.SAVE_PRODUCT_START:
      return saveProductStart(state, action)
    case actions.SAVE_PRODUCT_SUCCESS:
      return saveProductSuccess(state, action)
    case actions.SAVE_PRODUCT_FAILED:
      return saveProductFailed(state, action)
    case actions.UPDATE_SELECTED_PRODUCT:
      return updateSelectedProduct(state, action)
    case actions.SYNC_PRODUCT_START:
      return syncProductStart(state, action)
    case actions.SYNC_PRODUCT_SUCCESS:
      return syncProductSuccess(state, action)
    case actions.SYNC_PRODUCT_FAILED:
      return syncProductFailed(state, action)
    case actions.SEARCH_PRODUCTS_START:
      return searchProductsStart(state, action)
    case actions.SEARCH_PRODUCTS_SUCCESS:
      return searchProductsSuccess(state, action)
    case actions.SEARCH_PRODUCTS_FAILED:
      return searchProductsFailed(state, action)
    case actions.UPDATE_PRODUCT_QUERY_STRING:
      return updateProductQueryString(state, action)
    default:
      return state
  }
}

export default reducer
