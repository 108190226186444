// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
  Container,
  Box
} from '@material-ui/core'

// Components.
import Copyright from '../../../UI/Brand/Copyright'
import ErrorBox from '../../../UI/ErrorBox/ErrorBox'
import Breadcrumb from '../../../UI/Brand/Breadcrumb'

// Sections.
import Home from './Home/Home'
import Docs from './Docs/Docs'
import Site from './Sites/Site'
import SiteList from './Sites/SiteList'
import Category from './Sites/Category/Category'
import Product from './Sites/Category/Product/Product'
import ArtistList from './Artists/ArtistList'
import Artist from './Artists/Artist'
import ArrangementForm from './Artists/Arrangements/Arrangement/ArrangementForm'
import UserList from './Users/UserList'
import UserForm from './Users/UserForm'
import MyAccountForm from './MyAccount/MyAccountForm'
import Settings from './Settings/Settings'
import Warehouse from './Warehouse/Warehouse'
import SalesReportList from './SalesReports/SalesReportList'
import SalesReportForm from './SalesReports/SalesReportForm'
import StockWithdrawalsForm from './Warehouse/StockWithdrawals/StockWithdrawalsForm/StockWithdrawalsForm'
import StockEntriesForm from './Warehouse/StockEntries/StockEntriesForm/StockEntriesForm'
import ProductScheduledDiscountList from './ProductScheduledDiscounts/ProductScheduledDiscountList/ProductScheduledDiscountList'
import ProductScheduledDiscountForm from './ProductScheduledDiscounts/ProductScheduledDiscountForm/ProductScheduledDiscountForm'

// Style.
import { useStyles } from '../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const Content = props => {
  const classes = useStyles()

  // Find the section to load.
  let content = null
  let path = []
  switch (props.section) {
    case '/':
      content = <Home {...props} />
      break
    case '/sites':
      path = [{ link: '/sites', label: 'Sitios web' }]
      if (props.subsection === '/products') {
        content = <Product {...props} siteId={props.extraId1} categoryId={props.extraId2} productId={props.id} />
        path.push({ label: `Sitio web #${props.extraId1}`, link: `/site/${props.extraId1}` })
        path.push({ label: `Categoría #${props.extraId2}`, link: `/site/${props.extraId1}/category/${props.extraId2}` })
        path.push({ label: `Producto #${props.id}` })
      } else if (props.subsection === '/categories') {
        content = <Category {...props} siteId={props.extraId1} categoryId={props.id} />
        path.push({ label: `Sitio web #${props.extraId1}`, link: `/site/${props.extraId1}` })
        path.push({ label: (props.id ? `Categoría #${props.id}` : 'Nueva categoría') })
      } else if (props.subsection === '/sales') {
        content = <Site {...props} activeTab='sales' />
        path.push({ label: `Sitio web #${props.id}`, link: `/site/${props.id}` })
        path.push({ label: 'Períodos de rebajas' })
      } else if (props.form) {
        content = <Site {...props} activeTab='form' />
        path.push({ label: (props.id ? `Sitio web #${props.id}` : 'Nuevo sitio web') })
      } else if (props.list) {
        content = <SiteList {...props} />
      }
      break
    case '/artists':
      path = [{ label: 'Artistas', link: props.loggedArtist ? null : '/artists' }]
      if (props.subsection === '/arrangements') {
        path.push({ label: `Artista #${props.id}`, link: `/artist/${props.id}` })
        path.push({ label: 'Tratos comerciales', link: `/artist/${props.id}/arrangements` })
        if (props.list) {
          content = <Artist {...props} activeTab='arrangements' artistId={props.id} />
        } else if (props.form) {
          path.push({ label: (props.extraId1 ? `Trato comercial #${props.extraId1}` : 'Nuevo trato comercial') })
          content = <ArrangementForm {...props} arrangementId={props.extraId1} artistId={props.id} />
        }
      } else if (props.subsection === '/expenses') {
        content = <Artist {...props} activeTab='expenses' artistId={props.id} />
        path.push({ label: `Artista #${props.id}`, link: `/artist/${props.id}` })
        path.push({ label: 'Gastos' })
      } else if (props.subsection === '/earnings') {
        content = <Artist {...props} activeTab='earnings' artistId={props.id} />
        path.push({ label: `Artista #${props.id}`, link: `/artist/${props.id}` })
        path.push({ label: 'Ingresos' })
      } else if (props.subsection === '/settlements') {
        content = <Artist {...props} activeTab='settlements' artistId={props.id} />
        path.push({ label: `Artista #${props.id}`, link: `/artist/${props.id}` })
        path.push({ label: 'Liquidaciones' })
      } else if (props.subsection === '/stats') {
        content = <Artist {...props} activeTab='form' artistId={props.id} />
        path.push({ label: `Artista #${props.id}`, link: `/artist/${props.id}` })
        path.push({ label: 'Estadísticas' })
      } else if (props.subsection === '/data_sources') {
        content = <Artist {...props} activeTab='data_sources' artistId={props.id} />
        path.push({ label: `Artista #${props.id}`, link: `/artist/${props.id}` })
        path.push({ label: 'Fuentes de datos' })
      } else if (props.form) {
        content = <Artist {...props} activeTab='form' />
        path.push({ label: (props.id ? `Artista #${props.id}` : 'Nuevo artista') })
      } else if (props.list) {
        content = <ArtistList {...props} />
      }
      break
    case '/sales_reports':
      path = [{ label: 'Reportes de ventas', link: '/sales_reports' }]
      if (props.form) {
        content = <SalesReportForm />
        path.push({ label: 'Nuevo reporte de ventas' })
      } else if (props.list) {
        content = <SalesReportList />
      }
      break
    case '/users':
      path = [{ label: 'Usuarios', link: '/users' }]
      if (props.form) {
        content = <UserForm {...props} />
        path.push({ label: (props.id ? `Usuario #${props.id}` : 'Nuevo usuario') })
      } else if (props.list) {
        content = <UserList {...props} />
      }
      break
    case '/my-account':
      path = [{ label: 'Mi cuenta' }]
      content = <MyAccountForm {...props} />
      break
    case '/settings':
      path = [{ label: 'Ajustes' }]
      switch (props.subsection) {
        case '/expense_types':
          path.push({ label: 'Tipos de gasto' })
          content = <Settings activeTab='expense_types' />
          break
        case '/earning_types':
          path.push({ label: 'Tipos de ingreso' })
          content = <Settings activeTab='earning_types' />
          break
        case '/sales':
          path.push({ label: 'Períodos de rebajas' })
          content = <Settings activeTab='sales' />
          break
        case '/pos_terminals':
          path.push({ label: 'TPVs' })
          content = <Settings activeTab='pos_terminals' />
          break
        default: content = <Settings activeTab='generic_settings' />
      }
      break
    case '/warehouse':
      path = [{ label: 'Almacén' }]
      if (props.list) {
        switch (props.subsection) {
          case '/stock_withdrawals':
            path.push({ label: 'Salidas de stock' })
            content = <Warehouse activeTab='stock_withdrawals' />
            break
          case '/stock_entries':
            path.push({ label: 'Entradas de stock' })
            content = <Warehouse activeTab='stock_entries' />
            break
          case '/inventory':
            path.push({ label: 'Inventario' })
            content = <Warehouse activeTab='inventory' />
            break
          default: content = null
        }
      } else if (props.form) {
        switch (props.subsection) {
          case '/stock_withdrawals':
            path.push({ label: 'Salidas de stock', link: '/warehouse/stock_withdrawals' })
            path.push({ label: 'Nueva salida de stock' })
            content = <StockWithdrawalsForm />
            break
          case '/stock_entries':
            path.push({ label: 'Entradas de stock', link: '/warehouse/stock_entries' })
            path.push({ label: 'Nueva entrada de stock' })
            content = <StockEntriesForm />
            break
          default: content = null
        }
      }
      break
    case '/product_scheduled_discounts':
      path = [{ link: '/product_scheduled_discounts', label: 'Descuentos programados' }]
      if (props.form) {
        content = <ProductScheduledDiscountForm {...props} />
        path.push({ label: 'Nuevo descuento programado' })
      } else if (props.list) {
        content = <ProductScheduledDiscountList {...props} />
      }
      break
    case '/docs':
      content = <Docs />
      break
    default:
      content = <ErrorBox {...props} />
  }

  return (
    <main className={classes.content}>
      <div className={classes.appBarSpacer} />
      <Container maxWidth='xl' className={classes.container}>
        <Box pt={1} pb={2}>
          <Breadcrumb elements={path} />
        </Box>
        {content}
        <Box pt={4}>
          <Copyright />
        </Box>
      </Container>
    </main>
  )
}

// Export.
export default Content
