// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  TableCell,
  TableRow,
  IconButton
} from '@material-ui/core'
import { connect } from 'react-redux'
import ClearIcon from '@material-ui/icons/Clear'

// Components.
import ProductLink from '../../../../../../UI/Links/ProductLink'
import FormField from '../../../../../../UI/Form/FormField'
import ProductThumbnail from '../../../../../../UI/Image/ProductThumbnail'
import ProductVariationName from '../../../../../../UI/Custom/ProductVariationName'

// Shared.
import { printPrice } from '../../../../../../../shared/functions'

// Redux actions.
import * as actions from '../../../../../../../store/actions'

// Style.
import { useStyles } from '../../../../../../styles'

// =============================================================================
// Components.
// =============================================================================

// Declare stateful component.
class StockMovementsFormSummaryProduct extends Component {
  state = {
    fields: {
      stock_quantity: {
        label: 'Cantidad',
        type: 'number',
        error: null,
        touched: false,
        required: true,
        errMsg: 'Por favor, introduce un valor mayor o igual que 0.',
        validation: val => +val >= 0
      },
      price_new: {
        label: 'Precio en el evento (€)',
        type: 'text',
        error: null,
        touched: false,
        required: false,
        errMsg: 'Por favor, introduce un valor positivo.',
        validation: val => +val > 0
      }
    }
  }

  handleChange = (elemKey, newValue) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields[elemKey]
    }
    // Modify the element value.
    this.props.updateSelectedStockMovementProduct(this.props.idx, elemKey, newValue)
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.required && (newElement.type === 'select' ? !newValue : !newValue.length) && newElement.touched) {
      newElement.error = 'Campo obligatorio'
    } else if (newElement.validation && newValue.length && !newElement.validation(newValue)) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Build new state.
    const newState = {
      ...this.state,
      fields: {
        ...this.state.fields,
        [elemKey]: newElement
      }
    }
    // Update the state.
    this.setState(newState)
  }

  render () {
    return (
      <TableRow>
        <TableCell className={this.props.lastElement ? null : this.props.classes.noBorderBottom}>
          {this.props.product.product_id}
        </TableCell>
        <TableCell className={this.props.lastElement ? null : this.props.classes.noBorderBottom}>
          <ProductThumbnail alt={this.props.product.name} url={this.props.product.image} />
        </TableCell>
        <TableCell className={this.props.lastElement ? null : this.props.classes.noBorderBottom}>
          <ProductLink site_id={this.props.product.site_id} product={{ id: this.props.product.product_id, name: this.props.product.name, category_id: this.props.product.category_id }} />
        </TableCell>
        <TableCell className={this.props.lastElement ? null : this.props.classes.noBorderBottom}>
          {this.props.product.product_variation_id ? this.props.product.product_variation_id : '-'}
        </TableCell>
        <TableCell className={this.props.lastElement ? null : this.props.classes.noBorderBottom}>
          <ProductVariationName>{this.props.product.product_variation_name}</ProductVariationName>
        </TableCell>
        <TableCell className={this.props.lastElement ? null : this.props.classes.noBorderBottom}>
          {printPrice(this.props.product.price_old)}
        </TableCell>
        {
          Object.keys(this.state.fields).map((fieldName, key) => {
            const field = this.state.fields[fieldName]
            let value = this.props.product[fieldName]
            if (value === null) value = ''

            return (
              <TableCell key={key} className={this.props.lastElement ? null : this.props.classes.noBorderBottom}>
                <FormField
                  name={`${fieldName}_${this.props.product.product_variation_id ? this.props.product.product_variation_id : this.props.product.product_id}`}
                  disabled={false}
                  type={field.type}
                  autoFocus={false}
                  required={field.required}
                  error={field.error}
                  label={field.label}
                  value={value}
                  margin='dense'
                  fullWidth
                  handleChange={e => this.handleChange(fieldName, e.target.value)}
                />
              </TableCell>
            )
          })
        }
        <TableCell className={this.props.lastElement ? null : this.props.classes.noBorderBottom}>
          <IconButton color='default' aria-label='remove' size='small' onClick={() => this.props.deleteStockMovementProducts([+this.props.product.product_id])}>
            <ClearIcon />
          </IconButton>
        </TableCell>
      </TableRow>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    selectedStockMovement: state.stockMovements.selectedStockMovement
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    deleteStockMovementProducts: (productIds) => dispatch(actions.deleteStockMovementProducts(productIds)),
    updateSelectedStockMovementProduct: (idx, key, value) => dispatch(actions.updateSelectedStockMovementProduct(idx, key, value))
  }
}

// Style component.
const StockMovementsFormSummaryProductStyled = props => {
  const classes = useStyles()
  return (
    <StockMovementsFormSummaryProduct classes={classes} {...props} />
  )
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(StockMovementsFormSummaryProductStyled)
