// ============================================================================
// Dependencies.
// ============================================================================

// Actions.
import * as actions from '../actions/types'

// Utility.
import { updateObject } from '../../shared/functions'

// ============================================================================
// Initial state.
// ============================================================================

// Initial state.
const initialState = {
  token: null,
  user: null,
  error: null,
  loading: false,
  success: null,
  authRedirectPath: '/',
  recoveryToken: null,
  maintenanceMode: false,
  offlineMode: false,
  resetRedirectPath: false,
  sideMenuOpen: false,
  sideMenuExpandedSection: null
}

// ============================================================================
// Functionality.
// ============================================================================

// Auth start.
const loginStart = state => {
  // Update the state.
  return updateObject(state, {
    error: null,
    loading: true,
    user: null
  })
}

// Auth success.
const loginSuccess = (state, action) => {
  // Update the state.
  return updateObject(state, {
    token: action.token,
    user: action.user,
    error: null,
    loading: false,
    resetRedirectPath: false
  })
}

// Auth fail.
const loginFail = (state, action) => {
  // Update the state.
  return updateObject(state, {
    error: action.error,
    loading: false
  })
}

// Set password recovery fail.
const setPasswordRecoveryFail = (state, action) => {
  // Update the state.
  return updateObject(state, {
    error: action.error,
    loading: false,
    success: null
  })
}

// Set password recovery start.
const setPasswordRecoveryStart = state => {
  // Update the state.
  return updateObject(state, {
    error: null,
    loading: true,
    success: null
  })
}

// Set password recovery success.
const setPasswordRecoverySuccess = (state, action) => {
  // Update the state.
  return updateObject(state, {
    success: `Si el correo electrónico ${action.email} está registrado en la plataforma, recibirás un correo electrónico con instrucciones para restablecer tu contraseña.`,
    error: null,
    loading: false
  })
}

// Recover password fail.
const recoverPasswordFail = (state, action) => {
  // Update the state.
  return updateObject(state, {
    error: action.error,
    loading: false,
    success: null
  })
}

// Recover password start.
const recoverPasswordStart = state => {
  // Update the state.
  return updateObject(state, {
    error: null,
    loading: true,
    success: null
  })
}

// Recover password success.
const recoverPasswordSuccess = state => {
  // Update the state.
  return updateObject(state, {
    success: 'Tu contraseña ha sido restablecida correctamente.',
    error: null,
    loading: false
  })
}

// Auth logout.
const authLogout = (state, action) => {
  // Update the state.
  return updateObject(state, {
    token: null,
    user: null,
    loading: false,
    authRedirectPath: '/',
    resetRedirectPath: !!action.resetRedirectPath
  })
}

// Change the auth redirect path.
const setLoginRedirectPath = (state, action) => {
  // Update the state.
  return updateObject(state, {
    authRedirectPath: action.path
  })
}

// Reset the login error.
const resetLoginError = (state, action) => {
  // Update the state.
  return updateObject(state, {
    error: null,
    loading: false
  })
}

// Reset the password recovery page.
const resetPasswordRecovery = (state, action) => {
  // Update the state.
  return updateObject(state, {
    error: null,
    loading: false,
    success: null
  })
}

// Set the password recovery token.
const setRecoveryToken = (state, action) => {
  // Update the state.
  return updateObject(state, {
    error: null,
    loading: false,
    success: null,
    recoveryToken: action.token
  })
}

// Set maintenance mode.
const setMaintenanceMode = (state, action) => {
  // Update the state.
  return updateObject(state, {
    maintenanceMode: true,
    loading: false
  })
}

// Set offline mode.
const setOfflineMode = (state, action) => {
  // Update the state.
  return updateObject(state, {
    offlineMode: true,
    loading: false
  })
}

// Unset offline and maintenance mode.
const unsetUnavailableStates = (state, action) => {
  // Update the state.
  return updateObject(state, {
    offlineMode: false,
    maintenanceMode: false,
    loading: false
  })
}

// Start loading (global loading state).
const startLoadingGlobal = (state, action) => {
  // Update the state.
  return updateObject(state, {
    loading: true
  })
}

// Toggle collapse status of the side menu.
const toggleSideMenuState = (state, action) => {
  // Update the state.
  return updateObject(state, {
    sideMenuOpen: !state.sideMenuOpen
  })
}

// Expand section of the side menu.
export const expandSideMenuSection = (state, action) => {
  // Update the state.
  return updateObject(state, {
    sideMenuExpandedSection: action.section
  })
}

// Collapse section of the side menu.
export const collapseAllSideMenuSections = (state, action) => {
  // Update the state.
  return updateObject(state, {
    sideMenuExpandedSection: null
  })
}

// Update the logged user object.
export const updateLoggedUserFields = (state, action) => {
  let newLoggedUser = {
    ...state.user
  }
  newLoggedUser = {
    ...newLoggedUser,
    ...action.user
  }
  return updateObject(state, {
    user: newLoggedUser
  })
}

// ============================================================================
// Reducer definition.
// ============================================================================

// Reducer definition.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOGIN_START:
      return loginStart(state, action)
    case actions.LOGIN_SUCCESS:
      return loginSuccess(state, action)
    case actions.LOGIN_FAILED:
      return loginFail(state, action)
    case actions.SET_PASSWORD_RECOVERY_START:
      return setPasswordRecoveryStart(state, action)
    case actions.SET_PASSWORD_RECOVERY_SUCCESS:
      return setPasswordRecoverySuccess(state, action)
    case actions.SET_PASSWORD_RECOVERY_FAILED:
      return setPasswordRecoveryFail(state, action)
    case actions.RECOVER_PASSWORD_START:
      return recoverPasswordStart(state, action)
    case actions.RECOVER_PASSWORD_SUCCESS:
      return recoverPasswordSuccess(state, action)
    case actions.RECOVER_PASSWORD_FAILED:
      return recoverPasswordFail(state, action)
    case actions.LOGOUT:
      return authLogout(state, action)
    case actions.SET_LOGIN_REDIRECT_PATH:
      return setLoginRedirectPath(state, action)
    case actions.RESET_LOGIN_ERROR:
      return resetLoginError(state, action)
    case actions.RESET_PASSWORD_RECOVERY:
      return resetPasswordRecovery(state, action)
    case actions.SET_RECOVERY_TOKEN:
      return setRecoveryToken(state, action)
    case actions.SET_MAINTENANCE_MODE:
      return setMaintenanceMode(state, action)
    case actions.SET_OFFLINE_MODE:
      return setOfflineMode(state, action)
    case actions.UNSET_UNAVAILABLE_STATES:
      return unsetUnavailableStates(state, action)
    case actions.START_LOADING_GLOBAL:
      return startLoadingGlobal(state, action)
    case actions.TOGGLE_SIDE_MENU_STATE:
      return toggleSideMenuState(state, action)
    case actions.EXPAND_SIDE_MENU_SECTION:
      return expandSideMenuSection(state, action)
    case actions.COLLAPSE_ALL_SIDE_MENU_SECTIONS:
      return collapseAllSideMenuSections(state, action)
    case actions.UPDATE_LOGGED_USER_FIELDS:
      return updateLoggedUserFields(state, action)
    default:
      return state
  }
}

export default reducer
