// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import { Chip } from '@material-ui/core'
import React from 'react'

// =============================================================================
// User profile colors.
// =============================================================================

// Profile colors.
const profileColors = {
  superadmin: '#1E90FF',
  admin: '#00BFFF',
  warehouse: '#87CEFA',
  artist_admin: '#f29d35',
  artist: '#ffc682'
}

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const UserProfile = props => {
  return (
    <Chip
      size='small'
      variant='default'
      label={props.profile}
      color='primary'
      style={{ backgroundColor: profileColors[props.profile] || '#B0E0E6' }}
    />
  )
}

// Export.
export default UserProfile
