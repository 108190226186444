// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { Avatar } from '@material-ui/core'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const ProductThumbnail = props => {
  if (!props.url) return null
  return (
    <Avatar variant='square' alt={props.alt || 'image'} src={props.url} />
  )
}

// Export.
export default ProductThumbnail
