// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  Chip,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog
} from '@material-ui/core'
import DateRangeIcon from '@material-ui/icons/DateRange'

// Components.
import NumberOfStuff from '../../../../UI/Custom/NumberOfStuff'
import AlertInfo from '../../../../UI/Alerts/AlertInfo'
import TableLoader from '../../../../UI/Loaders/TableLoader'
import TableColumnTitle from '../../../../UI/Table/TableColumnTitle'

// Shared.
import { applyFilter, prettyPrintDateTime, printPrice } from '../../../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Table columns.
const columns = [
  { name: 'product_remote_id', label: 'ID remoto', sortable: true },
  { name: 'product_site_url', label: 'Sitio web', sortable: true },
  { name: 'product_name', label: 'Producto', sortable: true },
  { name: 'total_sales', label: 'Nº ventas', sortable: true },
  { name: 'n_orders', label: 'Nº pedidos únicos', sortable: true },
  { name: 'total_paid', label: 'Total ventas (IVA incl.)', sortable: true },
  { name: 'total_commission', label: 'Total comisiones medios de pago (IVA incl.)', sortable: true }
]

// Stateful component declaration.
class SalesReportDialog extends Component {
  state = {
    orderBy: 'total_sales',
    orderDirection: 'desc'
  }

  handleSort = (fieldName) => event => {
    // Create a copy of the state object.
    const newState = { ...this.state }
    // Set the order field.
    newState.orderBy = fieldName
    // Toggle the order direction.
    newState.orderDirection = this.state.orderBy === fieldName ? (newState.orderDirection === 'asc' ? 'desc' : 'asc') : 'asc'
    // Update the state.
    this.setState(newState)
  }

  render () {
    let dialogContent = null
    if (!this.props.loadingSalesReport && !this.props.loadedSalesReport) {
      dialogContent = null
    } else if (this.props.loadingSalesReport) {
      dialogContent = <TableLoader cols={columns} />
    } else if (this.props.loadedSalesReport.details && this.props.loadedSalesReport.details.length) {
      const salesReportDetails = applyFilter(this.props.loadedSalesReport.details, {}, this.state.orderBy, this.state.orderDirection)

      dialogContent = (
        <Table size='small'>
          <TableHead>
            <TableRow>
              {
                columns.map((col, key) => <TableColumnTitle key={key} {...col} orderBy={this.state.orderBy} orderDirection={this.state.orderDirection} onSort={this.handleSort} />)
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {
              salesReportDetails.map((d, key) => (
                <TableRow key={key}>
                  <TableCell>{d.product_remote_id}</TableCell>
                  <TableCell>{d.product_site_url}</TableCell>
                  <TableCell>{d.product_name}</TableCell>
                  <TableCell><NumberOfStuff>{d.total_sales}</NumberOfStuff></TableCell>
                  <TableCell><NumberOfStuff>{d.n_orders}</NumberOfStuff></TableCell>
                  <TableCell>{printPrice(d.total_paid)}</TableCell>
                  <TableCell>{printPrice(d.total_commission)}</TableCell>
                </TableRow>
              ))
            }
          </TableBody>
        </Table>
      )
    } else {
      dialogContent = <AlertInfo>No se han encontrado resultados. Esta característica está disponible solo para reportes de venta generados a partir de la versión 1.2.7 del CRM; si este reporte es anterior, los detalles no se pueden mostrar.</AlertInfo>
    }

    return (
      <Dialog
        maxWidth='lg'
        open={!!(this.props.loadingSalesReport || this.props.loadedSalesReport)}
        onClose={this.props.onDialogClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {
            this.props.loadedSalesReport
              ? (
                <>
                  <span style={{ marginRight: 10 }}>ID #{this.props.loadedSalesReport.id} - {this.props.loadedSalesReport.artist.name}</span>
                  <Chip size='small' color='default' variant='default' icon={<DateRangeIcon />} label={`Desde ${prettyPrintDateTime(this.props.loadedSalesReport.date_from)}`} />
                  <Chip size='small' color='default' variant='default' icon={<DateRangeIcon />} label={`Hasta ${prettyPrintDateTime(this.props.loadedSalesReport.date_to)}`} />
                </>
                )
              : null
          }
        </DialogTitle>
        <DialogContent>
          {dialogContent}
        </DialogContent>
        <DialogActions>
          <Button color='default' variant='outlined' onClick={this.props.onDialogClose}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

// Export.
export default SalesReportDialog
