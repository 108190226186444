// ============================================================================
// Dependencies.
// ============================================================================

// Axios instance.
import axios from '../../axios'

// Action types.
import * as actions from './types'

// Functions.
import { getErrorMessage } from '../../shared/functions'

// ============================================================================
// Action creators.
// ============================================================================

// Load category start.
export const loadCategoryStart = () => {
  return {
    type: actions.LOAD_CATEGORY_START
  }
}

// Load category success.
export const loadCategorySuccess = category => {
  return {
    type: actions.LOAD_CATEGORY_SUCCESS,
    category
  }
}

// Load category fail.
export const loadCategoryFailed = error => {
  return {
    type: actions.LOAD_CATEGORY_FAILED,
    error
  }
}

// Load category.
export const loadCategory = (id, token) => {
  return dispatch => {
    if (!id) {
      dispatch(loadCategorySuccess(null))
      return
    }
    dispatch(loadCategoryStart())
    // Perform request.
    axios(token).get(`/category/${id}`)
      .then(response => {
        dispatch(loadCategorySuccess(response.data))
      })
      .catch(error => {
        dispatch(loadCategoryFailed(getErrorMessage(error)))
      })
  }
}

// Start synchronizing the products of a category.
export const syncProductsStart = () => {
  return {
    type: actions.SYNC_PRODUCTS_START
  }
}

// Successfully synchronize the products of a category.
export const syncProductsSuccess = products => {
  return {
    type: actions.SYNC_PRODUCTS_SUCCESS,
    products
  }
}

// Synchronize the products of a category, with error.
export const syncProductsFailed = error => {
  return {
    type: actions.SYNC_PRODUCTS_FAILED,
    error
  }
}

// Sync the products of a category.
export const syncProducts = (id, token) => {
  return dispatch => {
    dispatch(syncProductsStart())
    // Perform request.
    axios(token).post(`/category/${id}/sync_products`)
      .then(response => {
        dispatch(syncProductsSuccess(response.data))
      })
      .catch(() => {
        dispatch(syncProductsFailed({
          message: 'No se han podido sincronizar los productos de la categoría. Esto puede deberse a varios motivos:',
          details: [
            'Las credenciales de conexión del sitio web (API Key y API Secret) no son correctas',
            'La URL del sitio web no es correcta.',
            'El protocolo especificado en la URL del sitio web no es correcto (por ejemplo, https:// en vez de http://).',
            'El sitio web no está funcionando en este momento y no es posible conectar con él.'
          ]
        }))
      })
  }
}
