// ============================================================================
// Dependencies.
// ============================================================================

// Axios instance.
import axios from '../../axios'

// Action types.
import * as actions from './types'

// Functions.
import { getErrorMessage } from '../../shared/functions'

// ============================================================================
// Action creators.
// ============================================================================

// Load arrangement documents start.
export const loadArrangementDocumentsStart = () => {
  return {
    type: actions.LOAD_ARRANGEMENT_DOCUMENTS_START
  }
}

// Load arrangement documents success.
export const loadArrangementDocumentsSuccess = arrangementDocuments => {
  return {
    type: actions.LOAD_ARRANGEMENT_DOCUMENTS_SUCCESS,
    arrangementDocuments
  }
}

// Load arrangement documents fail.
export const loadArrangementDocumentsFailed = error => {
  return {
    type: actions.LOAD_ARRANGEMENT_DOCUMENTS_FAILED,
    error
  }
}

// Load arrangement documents.
export const loadArrangementDocuments = (artistId, token) => {
  return dispatch => {
    dispatch(loadArrangementDocumentsStart())
    // Perform request.
    axios(token).get(`/artist/arrangement_documents/${artistId}`)
      .then(response => {
        dispatch(loadArrangementDocumentsSuccess(response.data.arrangement_documents))
      })
      .catch(error => {
        dispatch(loadArrangementDocumentsFailed(getErrorMessage(error)))
      })
  }
}

// Delete arrangement documents start.
export const deleteArrangementDocumentStart = () => {
  return {
    type: actions.DELETE_ARRANGEMENT_DOCUMENT_START
  }
}

// Delete arrangement documents success.
export const deleteArrangementDocumentSuccess = id => {
  return {
    type: actions.DELETE_ARRANGEMENT_DOCUMENT_SUCCESS,
    id
  }
}

// Delete arrangement documents fail.
export const deleteArrangementDocumentFailed = error => {
  return {
    type: actions.DELETE_ARRANGEMENT_DOCUMENT_FAILED,
    error
  }
}

// Delete arrangement documents.
export const deleteArrangementDocument = (id, token) => {
  return dispatch => {
    dispatch(deleteArrangementDocumentStart())
    // Perform request.
    axios(token).delete(`/arrangement_document/${id}`)
      .then(() => {
        dispatch(deleteArrangementDocumentSuccess(id))
      })
      .catch(error => {
        dispatch(deleteArrangementDocumentFailed(getErrorMessage(error)))
      })
  }
}

// Start saving a arrangement document.
export const saveArrangementDocumentStart = arrangementDocument => {
  return {
    type: actions.SAVE_ARRANGEMENT_DOCUMENT_START,
    arrangementDocument
  }
}

// Save arrangement document success.
export const saveArrangementDocumentSuccess = (id, arrangementDocument) => {
  return {
    type: actions.SAVE_ARRANGEMENT_DOCUMENT_SUCCESS,
    id,
    arrangementDocument
  }
}

// Save arrangement document fail.
export const saveArrangementDocumentFailed = (id, error) => {
  return {
    type: actions.SAVE_ARRANGEMENT_DOCUMENT_FAILED,
    id,
    error
  }
}

// Save an arrangement document.
export const saveArrangementDocument = (id, token, body = {}) => {
  return dispatch => {
    dispatch(saveArrangementDocumentStart())
    // Perform request.
    const formData = new FormData()
    formData.append('name', body.name)
    formData.append('file', body.file)
    formData.append('arrangement_id', +body.arrangement_id)
    const url = id ? `/arrangement_document/${id}` : '/arrangement_document'
    const method = id ? 'put' : 'post'
    axios(token)[method](url, formData, { headers: { 'content-type': 'multipart/form-data' } })
      .then(response => {
        dispatch(saveArrangementDocumentSuccess(id, response.data))
      })
      .catch(error => {
        dispatch(saveArrangementDocumentFailed(id, getErrorMessage(error)))
      })
  }
}
