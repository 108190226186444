// ============================================================================
// Dependencies.
// ============================================================================

// Actions.
import * as actions from '../actions/types'

// Shared.
import { updateObject } from '../../shared/functions'

// ============================================================================
// Initial state.
// ============================================================================

// Initial state.
const initialState = {
  loading: true,
  formLoading: false,
  error: null,
  success: null,
  artists: [],
  selectedArtist: null,
  dataSources: [],
  dataSourcesLoading: null,
  dataSourcesError: null,
  dataSourcesSuccess: null,
  arrangements: [],
  arrangementsLoading: null,
  arrangementsError: null,
  arrangementsSuccess: null,
  arrangementLoading: false,
  arrangementFormLoading: false,
  arrangementError: null,
  arrangementSuccess: null,
  selectedArrangement: null,
  artistCategories: [],
  artistCategoriesLoading: false,
  artistCategoriesError: null,
  expenses: [],
  expensesLoading: null,
  expensesError: null,
  expensesSuccess: null,
  stats: null,
  statsLoading: null,
  statsError: null,
  statsSuccess: null,
  earnings: [],
  earningsLoading: null,
  earningsError: null,
  earningsSuccess: null
}

// ============================================================================
// Functionality.
// ============================================================================

// Start loading list of artists.
const loadArtistsStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    success: null,
    artists: []
  })
}

// Finish loading list of artists.
const loadArtistsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    artists: action.artists,
    error: null,
    success: null
  })
}

// Finish loading list of artists, with error.
const loadArtistsFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    artists: [],
    error: action.error,
    success: null
  })
}

// Start deleting artist.
const deleteArtistStart = state => {
  return updateObject(state, {
    loading: true,
    success: null,
    error: null
  })
}

// Delete an artist successfully.
const deleteArtistSuccess = (state, action) => {
  const oldArtistIdx = state.artists.findIndex(e => +e.id === +action.id)
  const newArtists = [...state.artists]
  newArtists.splice(oldArtistIdx, 1)
  return updateObject(state, {
    loading: false,
    artists: newArtists,
    error: null,
    success: `Se ha eliminado el artista #${action.id} correctamente.`
  })
}

// Delete artist, with error.
const deleteArtistFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    success: null
  })
}

// Start loading artist.
const loadArtistStart = state => {
  return updateObject(state, {
    loading: true,
    success: null,
    error: null,
    selectedArtist: null,
    dataSourcesLoading: false,
    dataSourcesError: null,
    dataSourcesSuccess: null,
    arrangementsLoading: false,
    arrangementsError: null,
    arrangementsSuccess: null,
    arrangementLoading: false,
    arrangementFormLoading: false,
    arrangementSuccess: null,
    arrangementError: null,
    expensesLoading: false,
    expensesError: null,
    expensesSuccess: null,
    statsLoading: false,
    statsError: null,
    statsSuccess: null,
    selectedArrangement: null,
    artistCategories: []
  })
}

// Delete an artist successfully.
const loadArtistSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    success: null,
    selectedArtist: action.artist
  })
}

// Delete artist, with error.
const loadArtistFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    selectedArtist: null
  })
}

// Update the selected artist.
const updateSelectedArtist = (state, action) => {
  const newSelectedArtist = {
    ...state.selectedArtist
  }
  newSelectedArtist[action.key] = action.value
  return updateObject(state, {
    selectedArtist: newSelectedArtist
  })
}

// Start saving an artist.
const saveArtistStart = (state, action) => {
  return updateObject(state, {
    formLoading: true,
    error: null,
    success: null
  })
}

// Save an artist successfully.
const saveArtistSuccess = (state, action) => {
  const newArtists = [
    ...state.artists
  ]
  if (action.id) {
    const updatedArtistIdx = state.artists.findIndex(s => +s.id === +action.id)
    newArtists[updatedArtistIdx] = { ...action.artist }
  } else {
    newArtists.push(action.artist)
  }
  return updateObject(state, {
    formLoading: false,
    error: null,
    success: action.id ? 'Artista actualizado correctamente.' : 'Artista creado correctamente.',
    artists: newArtists,
    selectedArtist: action.artist
  })
}

// Save an artist, with error.
const saveArtistFailed = (state, action) => {
  return updateObject(state, {
    formLoading: false,
    error: action.error,
    success: null
  })
}

// Start deleting data source.
const deleteDataSourceStart = state => {
  return updateObject(state, {
    dataSourcesLoading: true,
    dataSourcesSuccess: null,
    dataSourcesError: null
  })
}

// Delete a data source successfully.
const deleteDataSourceSuccess = (state, action) => {
  const oldDataSourceIdx = state.dataSources.findIndex(e => +e.id === +action.id)
  const newDataSources = [...state.dataSources]
  newDataSources.splice(oldDataSourceIdx, 1)
  return updateObject(state, {
    dataSources: newDataSources,
    dataSourcesLoading: false,
    dataSourcesError: null,
    dataSourcesSuccess: 'Fuente de datos eliminada correctamente.'
  })
}

// Delete data source, with error.
const deleteDataSourceFailed = (state, action) => {
  return updateObject(state, {
    dataSourcesLoading: false,
    dataSourcesError: action.error,
    dataSourcesSuccess: null
  })
}

// Start saving a data source.
const saveDataSourceStart = (state, action) => {
  return updateObject(state, {
    dataSourcesLoading: true,
    dataSourcesError: null,
    dataSourcesSuccess: null
  })
}

// Save a data source successfully.
const saveDataSourceSuccess = (state, action) => {
  const newDataSources = [
    ...state.dataSources
  ]
  newDataSources.push(action.dataSource)
  return updateObject(state, {
    dataSources: newDataSources,
    dataSourcesLoading: false,
    dataSourcesError: null,
    dataSourcesSuccess: 'Fuente de datos creada con éxito.'
  })
}

// Save a data source, with error.
const saveDataSourceFailed = (state, action) => {
  return updateObject(state, {
    dataSourcesLoading: false,
    dataSourcesError: action.error,
    dataSourcesSuccess: null
  })
}

// Start deleting arrangement.
const deleteArrangementStart = state => {
  return updateObject(state, {
    arrangementsLoading: true,
    arrangementsSuccess: null,
    arrangementsError: null
  })
}

// Delete an arrangement successfully.
const deleteArrangementSuccess = (state, action) => {
  const oldArrangementIdx = state.arrangements.findIndex(e => +e.id === +action.id)
  const newArrangements = [...state.arrangements]
  newArrangements.splice(oldArrangementIdx, 1)
  return updateObject(state, {
    arrangements: newArrangements,
    arrangementsLoading: false,
    arrangementsError: null,
    arrangementsSuccess: 'Se ha eliminado el trato comercial y se ha desvinculado de los productos que contenía'
  })
}

// Delete arrangement, with error.
const deleteArrangementFailed = (state, action) => {
  return updateObject(state, {
    arrangementsLoading: false,
    arrangementsError: action.error,
    arrangementsSuccess: null
  })
}

// Start loading arrangement.
const loadArrangementStart = state => {
  return updateObject(state, {
    arrangementLoading: true,
    arrangementFormLoading: false,
    arrangementError: null,
    arrangementSuccess: null,
    artistCategoriesLoading: false,
    artistCategoriesError: null,
    artistCategoriesSuccess: null,
    selectedArrangement: null
  })
}

// Load a arrangement successfully.
const loadArrangementSuccess = (state, action) => {
  return updateObject(state, {
    arrangementLoading: false,
    arrangementFormLoading: false,
    arrangementError: null,
    arrangementSuccess: null,
    selectedArrangement: action.arrangement
  })
}

// Load arrangement, with error.
const loadArrangementFailed = (state, action) => {
  return updateObject(state, {
    arrangementLoading: false,
    arrangementFormLoading: false,
    arrangementError: action.error,
    selectedArrangement: null
  })
}

// Update the selected arrangement.
const updateSelectedArrangement = (state, action) => {
  const newSelectedArrangement = {
    ...state.selectedArrangement
  }
  newSelectedArrangement[action.key] = action.value
  return updateObject(state, {
    selectedArrangement: newSelectedArrangement
  })
}

// Start saving an arrangement.
const saveArrangementStart = (state, action) => {
  return updateObject(state, {
    arrangementFormLoading: true,
    arrangementError: null,
    arrangementSuccess: null
  })
}

// Save an arrangement successfully.
const saveArrangementSuccess = (state, action) => {
  const newArrangements = [
    ...state.arrangements
  ]
  if (action.id) {
    const updatedSiteIdx = newArrangements.findIndex(s => +s.id === +action.id)
    newArrangements[updatedSiteIdx] = { ...action.arrangement }
  } else {
    newArrangements.push(action.arrangement)
  }
  return updateObject(state, {
    arrangementFormLoading: false,
    arrangementError: null,
    arrangementSuccess: action.id ? 'Trato comercial actualizado' : 'Trato comercial creado',
    selectedArrangement: action.arrangement,
    arrangements: newArrangements
  })
}

// Save an arrangement, with error.
const saveArrangementFailed = (state, action) => {
  return updateObject(state, {
    arrangementFormLoading: false,
    arrangementError: action.error,
    arrangementSuccess: null
  })
}

// Start loading arrangement.
const loadArtistCategoriesStart = state => {
  return updateObject(state, {
    artistCategories: [],
    artistCategoriesLoading: true,
    artistCategoriesError: null
  })
}

// Load a arrangement successfully.
const loadArtistCategoriesSuccess = (state, action) => {
  return updateObject(state, {
    artistCategories: action.categories,
    artistCategoriesLoading: false,
    artistCategoriesError: null
  })
}

// Load arrangement, with error.
const loadArtistCategoriesFailed = (state, action) => {
  return updateObject(state, {
    artistCategories: [],
    artistCategoriesLoading: false,
    artistCategoriesError: action.error
  })
}

// Start deleting expense.
const deleteExpenseStart = state => {
  return updateObject(state, {
    expensesLoading: true,
    expensesSuccess: null,
    expensesError: null
  })
}

// Delete an expense successfully.
const deleteExpenseSuccess = (state, action) => {
  const oldExpenseIdx = state.expenses.findIndex(e => +e.id === +action.id)
  const newExpenses = [...state.expenses]
  newExpenses.splice(oldExpenseIdx, 1)
  return updateObject(state, {
    expenses: newExpenses,
    expensesLoading: false,
    expensesError: null,
    expensesSuccess: 'Gasto eliminado correctamente.'
  })
}

// Delete expense, with error.
const deleteExpenseFailed = (state, action) => {
  return updateObject(state, {
    expensesLoading: false,
    expensesError: action.error,
    expensesSuccess: null
  })
}

// Start saving an expense.
const saveExpenseStart = (state, action) => {
  return updateObject(state, {
    expensesLoading: true,
    expensesError: null,
    expensesSuccess: null
  })
}

// Save an expense successfully.
const saveExpenseSuccess = (state, action) => {
  const newExpenses = [
    ...state.expenses
  ]
  newExpenses.push(action.expense)
  return updateObject(state, {
    expensesLoading: false,
    expensesError: null,
    expensesSuccess: 'Gasto creado correctamente.',
    expenses: newExpenses
  })
}

// Save an expense, with error.
const saveExpenseFailed = (state, action) => {
  return updateObject(state, {
    expensesLoading: false,
    expensesError: action.error,
    expensesSuccess: null
  })
}

// Start loading expenses.
const loadExpensesStart = state => {
  return updateObject(state, {
    expenses: [],
    expensesLoading: true,
    expensesSuccess: null,
    expensesError: null
  })
}

// Load expenses successfully.
const loadExpensesSuccess = (state, action) => {
  return updateObject(state, {
    expenses: action.expenses,
    expensesLoading: false,
    expensesError: null
  })
}

// Load expenses, with error.
const loadExpensesFailed = (state, action) => {
  return updateObject(state, {
    expensesLoading: false,
    expensesError: action.error
  })
}

// Start loading stats.
const loadStatsStart = state => {
  return updateObject(state, {
    stats: null,
    statsLoading: true,
    statsSuccess: null,
    statsError: null
  })
}

// Load stats successfully.
const loadStatsSuccess = (state, action) => {
  return updateObject(state, {
    stats: action.stats,
    statsLoading: false,
    statsError: null
  })
}

// Load stats, with error.
const loadStatsFailed = (state, action) => {
  return updateObject(state, {
    statsLoading: false,
    statsError: action.error
  })
}

// Start deleting earning.
const deleteEarningStart = state => {
  return updateObject(state, {
    earningsLoading: true,
    earningsSuccess: null,
    earningsError: null
  })
}

// Delete an earning successfully.
const deleteEarningSuccess = (state, action) => {
  const oldEarningIdx = state.earnings.findIndex(e => +e.id === +action.id)
  const newEarnings = [...state.earnings]
  newEarnings.splice(oldEarningIdx, 1)
  return updateObject(state, {
    earnings: newEarnings,
    earningsLoading: false,
    earningsError: null,
    earningsSuccess: 'Ingreso eliminado correctamente.'
  })
}

// Delete earning, with error.
const deleteEarningFailed = (state, action) => {
  return updateObject(state, {
    earningsLoading: false,
    earningsError: action.error,
    earningsSuccess: null
  })
}

// Start saving an earning.
const saveEarningStart = (state, action) => {
  return updateObject(state, {
    earningsLoading: true,
    earningsError: null,
    earningsSuccess: null
  })
}

// Save an earning successfully.
const saveEarningSuccess = (state, action) => {
  const newEarnings = [
    ...state.earnings
  ]
  newEarnings.push(action.earning)
  return updateObject(state, {
    earningsLoading: false,
    earningsError: null,
    earningsSuccess: 'Ingreso creado correctamente.',
    earnings: newEarnings
  })
}

// Save an earning, with error.
const saveEarningFailed = (state, action) => {
  return updateObject(state, {
    earningsLoading: false,
    earningsError: action.error,
    earningsSuccess: null
  })
}

// Start loading earnings.
const loadEarningsStart = state => {
  return updateObject(state, {
    earnings: [],
    earningsLoading: true,
    earningsSuccess: null,
    earningsError: null
  })
}

// Load earnings successfully.
const loadEarningsSuccess = (state, action) => {
  return updateObject(state, {
    earnings: action.earnings,
    earningsLoading: false,
    earningsError: null
  })
}

// Load earnings, with error.
const loadEarningsFailed = (state, action) => {
  return updateObject(state, {
    earningsLoading: false,
    earningsError: action.error
  })
}

// Start loading artist data sources.
const loadArtistDataSourcesStart = (state, action) => {
  return updateObject(state, {
    dataSources: null,
    dataSourcesLoading: true,
    dataSourcesSuccess: null,
    dataSourcesError: null
  })
}

// Load artist data sources successfully.
const loadArtistDataSourcesSuccess = (state, action) => {
  return updateObject(state, {
    dataSources: action.dataSources,
    dataSourcesLoading: false,
    dataSourcesError: null
  })
}

// Load artist data sources with error.
const loadArtistDataSourcesFailed = (state, action) => {
  return updateObject(state, {
    dataSourcesLoading: false,
    dataSourcesError: action.error
  })
}

// Start loading artist arrangements.
const loadArtistArrangementsStart = (state, action) => {
  return updateObject(state, {
    arrangements: null,
    arrangementsLoading: true,
    arrangementsSuccess: null,
    arrangementsError: null
  })
}

// Load artist arrangements successfully.
const loadArtistArrangementsSuccess = (state, action) => {
  return updateObject(state, {
    arrangements: action.arrangements,
    arrangementsLoading: false,
    arrangementsError: null
  })
}

// Load artist arrangements with error.
const loadArtistArrangementsFailed = (state, action) => {
  return updateObject(state, {
    arrangementsLoading: false,
    arrangementsError: action.error
  })
}

// ============================================================================
// Reducer definition.
// ============================================================================

// Reducer definition.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_ARTISTS_START:
      return loadArtistsStart(state, action)
    case actions.LOAD_ARTISTS_SUCCESS:
      return loadArtistsSuccess(state, action)
    case actions.LOAD_ARTISTS_FAILED:
      return loadArtistsFailed(state, action)
    case actions.DELETE_ARTIST_START:
      return deleteArtistStart(state, action)
    case actions.DELETE_ARTIST_SUCCESS:
      return deleteArtistSuccess(state, action)
    case actions.DELETE_ARTIST_FAILED:
      return deleteArtistFailed(state, action)
    case actions.LOAD_ARTIST_START:
      return loadArtistStart(state, action)
    case actions.LOAD_ARTIST_SUCCESS:
      return loadArtistSuccess(state, action)
    case actions.LOAD_ARTIST_FAILED:
      return loadArtistFailed(state, action)
    case actions.UPDATE_SELECTED_ARTIST:
      return updateSelectedArtist(state, action)
    case actions.SAVE_ARTIST_START:
      return saveArtistStart(state, action)
    case actions.SAVE_ARTIST_SUCCESS:
      return saveArtistSuccess(state, action)
    case actions.SAVE_ARTIST_FAILED:
      return saveArtistFailed(state, action)
    case actions.DELETE_DATA_SOURCE_START:
      return deleteDataSourceStart(state, action)
    case actions.DELETE_DATA_SOURCE_SUCCESS:
      return deleteDataSourceSuccess(state, action)
    case actions.DELETE_DATA_SOURCE_FAILED:
      return deleteDataSourceFailed(state, action)
    case actions.SAVE_DATA_SOURCE_START:
      return saveDataSourceStart(state, action)
    case actions.SAVE_DATA_SOURCE_SUCCESS:
      return saveDataSourceSuccess(state, action)
    case actions.SAVE_DATA_SOURCE_FAILED:
      return saveDataSourceFailed(state, action)
    case actions.DELETE_ARRANGEMENT_START:
      return deleteArrangementStart(state, action)
    case actions.DELETE_ARRANGEMENT_SUCCESS:
      return deleteArrangementSuccess(state, action)
    case actions.DELETE_ARRANGEMENT_FAILED:
      return deleteArrangementFailed(state, action)
    case actions.LOAD_ARRANGEMENT_START:
      return loadArrangementStart(state, action)
    case actions.LOAD_ARRANGEMENT_SUCCESS:
      return loadArrangementSuccess(state, action)
    case actions.LOAD_ARRANGEMENT_FAILED:
      return loadArrangementFailed(state, action)
    case actions.UPDATE_SELECTED_ARRANGEMENT:
      return updateSelectedArrangement(state, action)
    case actions.SAVE_ARRANGEMENT_START:
      return saveArrangementStart(state, action)
    case actions.SAVE_ARRANGEMENT_SUCCESS:
      return saveArrangementSuccess(state, action)
    case actions.SAVE_ARRANGEMENT_FAILED:
      return saveArrangementFailed(state, action)
    case actions.LOAD_ARTIST_CATEGORIES_START:
      return loadArtistCategoriesStart(state, action)
    case actions.LOAD_ARTIST_CATEGORIES_SUCCESS:
      return loadArtistCategoriesSuccess(state, action)
    case actions.LOAD_ARTIST_CATEGORIES_FAILED:
      return loadArtistCategoriesFailed(state, action)
    case actions.DELETE_EXPENSE_START:
      return deleteExpenseStart(state, action)
    case actions.DELETE_EXPENSE_SUCCESS:
      return deleteExpenseSuccess(state, action)
    case actions.DELETE_EXPENSE_FAILED:
      return deleteExpenseFailed(state, action)
    case actions.SAVE_EXPENSE_START:
      return saveExpenseStart(state, action)
    case actions.SAVE_EXPENSE_SUCCESS:
      return saveExpenseSuccess(state, action)
    case actions.SAVE_EXPENSE_FAILED:
      return saveExpenseFailed(state, action)
    case actions.LOAD_EXPENSES_START:
      return loadExpensesStart(state, action)
    case actions.LOAD_EXPENSES_SUCCESS:
      return loadExpensesSuccess(state, action)
    case actions.LOAD_EXPENSES_FAILED:
      return loadExpensesFailed(state, action)
    case actions.DELETE_EARNING_START:
      return deleteEarningStart(state, action)
    case actions.DELETE_EARNING_SUCCESS:
      return deleteEarningSuccess(state, action)
    case actions.DELETE_EARNING_FAILED:
      return deleteEarningFailed(state, action)
    case actions.SAVE_EARNING_START:
      return saveEarningStart(state, action)
    case actions.SAVE_EARNING_SUCCESS:
      return saveEarningSuccess(state, action)
    case actions.SAVE_EARNING_FAILED:
      return saveEarningFailed(state, action)
    case actions.LOAD_EARNINGS_START:
      return loadEarningsStart(state, action)
    case actions.LOAD_EARNINGS_SUCCESS:
      return loadEarningsSuccess(state, action)
    case actions.LOAD_EARNINGS_FAILED:
      return loadEarningsFailed(state, action)
    case actions.LOAD_ARTIST_STATS_START:
      return loadStatsStart(state, action)
    case actions.LOAD_ARTIST_STATS_SUCCESS:
      return loadStatsSuccess(state, action)
    case actions.LOAD_ARTIST_STATS_FAILED:
      return loadStatsFailed(state, action)
    case actions.LOAD_ARTIST_DATA_SOURCES_START:
      return loadArtistDataSourcesStart(state, action)
    case actions.LOAD_ARTIST_DATA_SOURCES_SUCCESS:
      return loadArtistDataSourcesSuccess(state, action)
    case actions.LOAD_ARTIST_DATA_SOURCES_FAILED:
      return loadArtistDataSourcesFailed(state, action)
    case actions.LOAD_ARTIST_ARRANGEMENTS_START:
      return loadArtistArrangementsStart(state, action)
    case actions.LOAD_ARTIST_ARRANGEMENTS_SUCCESS:
      return loadArtistArrangementsSuccess(state, action)
    case actions.LOAD_ARTIST_ARRANGEMENTS_FAILED:
      return loadArtistArrangementsFailed(state, action)
    default:
      return state
  }
}

export default reducer
