// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
  Container,
  Grid,
  Paper
} from '@material-ui/core'
import LinearProgress from '@material-ui/core/LinearProgress'

// Components.
import Title from '../../../../UI/Brand/Title'
import TmChart from '../../../../UI/Chart/TmChart'

// Shared.
import { prettyPrintDate } from '../../../../../shared/functions'

// Style.
import { useStyles } from '../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const UserStats = props => {
  const classes = useStyles()

  if (!props.stats) return null

  let stats = []
  if (props.loading) {
    stats = <LinearProgress />
  } else {
    const aWeekAgo = new Date()
    aWeekAgo.setDate(aWeekAgo.getDate() - 7)
    const aWeekAgoFormatted = prettyPrintDate(aWeekAgo)
    stats = (
      <div className='UserStatsContainer'>
        <TmChart title='Última semana' data={props.stats.filter(s => s.date >= aWeekAgoFormatted)} />
        <TmChart title='Últimos 2 meses' data={props.stats} />
      </div>
    )
  }

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <div className='UserStats'>
          <Title>Estadísticas del usuario</Title>
          <Container maxWidth='xl' className={classes.cardGrid}>
            {stats}
          </Container>
        </div>
      </Paper>
    </Grid>
  )
}

// Export.
export default UserStats
