// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'

// Components.
import RowActions from '../../../../UI/Table/RowActions'
import ArtistLink from '../../../../UI/Links/ArtistLink'
import AlertInfo from '../../../../UI/Alerts/AlertInfo'
import NumberOfStuff from '../../../../UI/Custom/NumberOfStuff'
import TableColumnTitle from '../../../../UI/Table/TableColumnTitle'
import TableLoader from '../../../../UI/Loaders/TableLoader'

// Shared.
import { prettyPrintDateTime } from '../../../../../shared/functions'
import { RESULTS_PER_PAGE } from '../../../../../shared/constants'

// =============================================================================
// Component declaration.
// =============================================================================

// Table columns.
const columns = [
  { name: 'id', label: 'ID', sortable: true },
  { name: 'name', label: 'Nombre', sortable: true },
  { name: 'n_data_sources', label: 'Nº fuentes de datos' },
  { name: 'n_arrangements', label: 'Nº tratos comerciales' },
  { name: 'created_at', label: 'Fecha de creación (UTC)', sortable: true },
  { name: 'updated_at', label: 'Fecha de modificación (UTC)', sortable: true },
  { name: 'actions', label: '' }
]

// Stateless component declaration.
const ArtistsTable = props => {
  // Loading.
  if (props.loading) {
    return <TableLoader cols={columns} />
  }

  // No results.
  if (!props.artists.length) {
    return <AlertInfo>No se han encontrado resultados.</AlertInfo>
  }

  const artistsTable = []
  const minLimit = (props.page - 1) * RESULTS_PER_PAGE
  const maxLimit = Math.min(props.artists.length, props.page * RESULTS_PER_PAGE)

  for (let i = minLimit; i < maxLimit; i++) {
    const artist = props.artists[i]
    artistsTable.push(
      <TableRow key={artist.id}>
        <TableCell>
          {artist.id}
        </TableCell>
        <TableCell>
          <ArtistLink artist={artist} />
        </TableCell>
        <TableCell>
          <NumberOfStuff>{artist.n_data_sources}</NumberOfStuff>
        </TableCell>
        <TableCell>
          <NumberOfStuff>{artist.n_arrangements}</NumberOfStuff>
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(artist.created_at)}
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(artist.updated_at)}
        </TableCell>
        <TableCell>
          <RowActions
            model='Artist'
            id={artist.id}
            editLink={`/artist/${artist.id}`}
            handleDelete={() => props.deleteArtist(artist.id)}
          />
        </TableCell>
      </TableRow>
    )
  }

  return (
    <Table size='small'>
      <TableHead>
        <TableRow>
          {
            columns.map((col, key) => <TableColumnTitle key={key} {...col} orderBy={props.orderBy} orderDirection={props.orderDirection} onSort={props.onSort} />)
          }
        </TableRow>
      </TableHead>
      <TableBody>
        {artistsTable}
      </TableBody>
    </Table>
  )
}

// Export.
export default ArtistsTable
