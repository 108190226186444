// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  TableHead,
  TableRow,
  TableCell,
  Table,
  TableBody,
  Button,
  Paper,
  Grid
} from '@material-ui/core'

// Components.
import Title from '../../../../../UI/Brand/Title'
import AlertWarning from '../../../../../UI/Alerts/AlertWarning'
import NumberOfStuff from '../../../../../UI/Custom/NumberOfStuff'
import ProductLink from '../../../../../UI/Links/ProductLink'
import CategoryLink from '../../../../../UI/Links/CategoryLink'
import ProductThumbnail from '../../../../../UI/Image/ProductThumbnail'
import TableColumnTitle from '../../../../../UI/Table/TableColumnTitle'
import TableLoader from '../../../../../UI/Loaders/TableLoader'

// Style.
import { useStyles } from '../../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Table columns.
const columns = [
  { name: 'id', label: 'ID' },
  { name: 'image', label: '' },
  { name: 'remote_object_id', label: 'ID remoto' },
  { name: 'name', label: 'Producto' },
  { name: 'category_id', label: 'Categoría' },
  { name: 'total_sales', label: 'Nº ventas' }
]

// Max. number of products.
const MAX_PRODUCTS = 3

// Stateful component declaration.
class ArtistStatsProducts extends Component {
  state = {
    collapsed: true
  }

  handleToggleCollapse () {
    const newState = { ...this.state }
    this.setState({ collapsed: !newState.collapsed })
  }

  render () {
    let content = null
    let toggleCollapseButton = null

    if (this.props.loading || !this.props.stats) {
      content = <TableLoader cols={columns} />
    } else {
      const products = this.props.stats.products.most_sold[this.props.year]

      if (!products || !products.length) {
        content = <AlertWarning>No se han vendido productos en este periodo.</AlertWarning>
      } else {
        const productRows = []
        const maxProducts = this.state.collapsed ? Math.min(MAX_PRODUCTS, products.length) : products.length
        for (let i = 0; i < maxProducts; i++) {
          const product = products[i]
          productRows.push((
            <TableRow key={i}>
              <TableCell>
                {product.id}
              </TableCell>
              <TableCell>
                <ProductThumbnail url={product.image} alt={product.name} />
              </TableCell>
              <TableCell>
                {product.remote_object_id}
              </TableCell>
              <TableCell>
                <ProductLink product={product} site_id={product.category.site_id} />
              </TableCell>
              <TableCell>
                <CategoryLink category={product.category} />
              </TableCell>
              <TableCell><NumberOfStuff>{product.total_sales}</NumberOfStuff></TableCell>
            </TableRow>
          ))
        }

        if (products.length > MAX_PRODUCTS) {
          toggleCollapseButton = (
            <Button variant='text' color='default' onClick={() => this.handleToggleCollapse()}>
              {this.state.collapsed ? `Mostrar todo (${products.length})` : 'Mostrar menos'}
            </Button>
          )
        }

        content = (
          <Table size='small' className={this.props.classes.mt2}>
            <TableHead>
              <TableRow>
                {
                  columns.map((col, key) => <TableColumnTitle key={key} {...col} />)
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {productRows}
            </TableBody>
          </Table>
        )
      }
    }

    return (
      <Grid item xs={12} lg={6}>
        <Paper className={this.props.classes.paper}>
          <Title>Productos maś vendidos {this.props.periodTitle}</Title>
          {content}
          <Grid item xs={12} className={this.props.classes.mt2} style={{ textAlign: 'center' }}>
            {toggleCollapseButton}
          </Grid>
        </Paper>
      </Grid>
    )
  }
}

// Style component.
const ArtistStatsProductsStyled = props => {
  const classes = useStyles()
  return <ArtistStatsProducts {...props} classes={classes} />
}

// Export.
export default ArtistStatsProductsStyled
