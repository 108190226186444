// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Button,
  Grid,
  LinearProgress,
  Paper,
  Box
} from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'

// Components.
import Title from '../../../../UI/Brand/Title'
import FormField from '../../../../UI/Form/FormField'
import AlertError from '../../../../UI/Alerts/AlertError'
import AlertInfo from '../../../../UI/Alerts/AlertInfo'
import DateTools from '../../../../UI/Custom/DateTools'
import GoBackButton from '../../../../UI/Buttons/GoBackButton'

// Redux actions.
import * as actions from '../../../../../store/actions'

// Styles.
import { useStyles } from '../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class SalesReportForm extends Component {
  componentDidMount () {
    // Load the artists list.
    if (!this.props.artists || !this.props.artists.length) {
      this.props.loadArtists(this.props.token)
    }
    // Load the sales.
    if (!this.props.sales || !this.props.sales.length) {
      this.props.loadSales(this.props.token)
    }
  }

  state = {
    fields: {
      date_from: {
        label: 'Fecha de inicio',
        error: null,
        type: 'datetime',
        required: true,
        touched: false,
        errMsg: 'Por favor, selecciona una fecha válida.',
        autoFocus: false
      },
      date_to: {
        label: 'Fecha de finalización',
        error: null,
        type: 'datetime',
        required: true,
        touched: false,
        errMsg: 'Por favor, selecciona una fecha válida.',
        autoFocus: false
      },
      artist_id: {
        label: 'Artista',
        error: null,
        type: 'select',
        required: true,
        touched: false,
        errMsg: 'Por favor, selecciona un artista de la lista.',
        autofocus: true,
        options: [],
        helperText: 'Asegúrate de haber configurado fuentes de datos para este artista; en caso contrario, el reporte de ventas saldrá vacío.'
      }
    }
  }

  handleChange = (elemKey, newValue) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields[elemKey]
    }
    // Modify the element value.
    this.props.editNewSalesReport(elemKey, newValue)
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.required && !newValue.length && newElement.touched) {
      newElement.error = 'Campo obligatorio'
    } else if (newElement.validation && newValue.length && !newElement.validation(newValue)) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Update the state.
    this.setState({
      ...this.state,
      fields: {
        ...this.state.fields,
        [elemKey]: newElement
      }
    })
  }

  render () {
    let content = null

    // Handle loading state.
    if (this.props.artistsLoading) {
      // Show loader.
      content = <LinearProgress />
    } else {
      const dateFromValue = this.props.currentSalesReport && this.props.currentSalesReport.date_from ? this.props.currentSalesReport.date_from : ''
      const dateToValue = this.props.currentSalesReport && this.props.currentSalesReport.date_to ? this.props.currentSalesReport.date_to : ''
      const artistOptions = [
        { label: '-', value: '' },
        ...this.props.artists.map(s => {
          return { value: s.id, label: s.name }
        })
      ]

      content = (
        <Box pt={2}>
          <form className={this.props.classes.rootWrap} noValidate autoComplete='off'>
            <DateTools onPropChange={this.handleChange} saleDates quickDates />
            <FormField
              disabled={this.props.formLoading}
              key='date_from'
              name='date_from'
              type={this.state.fields.date_from.type}
              autoFocus={this.state.fields.date_from.autoFocus}
              required={this.state.fields.date_from.required}
              error={this.state.fields.date_from.error}
              label={this.state.fields.date_from.label}
              value={dateFromValue}
              handleChange={e => this.handleChange('date_from', e.target.value + '')}
              fullWidth={false}
              helperText={this.state.fields.date_from.helperText}
            />
            <FormField
              disabled={this.props.formLoading}
              key='date_to'
              name='date_to'
              type={this.state.fields.date_to.type}
              autoFocus={this.state.fields.date_to.autoFocus}
              required={this.state.fields.date_to.required}
              error={this.state.fields.date_to.error}
              label={this.state.fields.date_to.label}
              value={dateToValue}
              handleChange={e => this.handleChange('date_to', e.target.value + '')}
              fullWidth={false}
              helperText={this.state.fields.date_to.helperText}
            />
            <FormField
              disabled={this.props.formLoading}
              key='artist_id'
              name='artist_id'
              type={this.state.fields.artist_id.type}
              autoFocus={this.state.fields.artist_id.autoFocus}
              required={this.state.fields.artist_id.required}
              error={this.state.fields.artist_id.error}
              label={this.state.fields.artist_id.label}
              value={this.props.currentSalesReport && this.props.currentSalesReport.artist_id ? this.props.currentSalesReport.artist_id : ''}
              handleChange={e => this.handleChange('artist_id', e.target.value + '')}
              options={artistOptions}
              fullWidth
              helperText={this.state.fields.artist_id.helperText}
            />
          </form>
        </Box>
      )
    }

    const saveButtonDisabled = this.props.formLoading || !this.props.currentSalesReport.artist_id || !this.props.currentSalesReport.date_from || !this.props.currentSalesReport.date_to
    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={this.props.classes.paper}>
            <div className='SalesReport'>
              <div className='SalesReportForm'>
                <Title>Nuevo reporte de ventas</Title>
                <AlertError error={this.props.error} />
                {content}
                {this.props.formLoading ? <LinearProgress /> : null}
                {
                  this.props.success
                    ? (
                      <AlertInfo
                        title='Tarea programada con éxito'
                      >
                        El reporte de ventas se está calculando. Puedes consultar el estado del cálculo en el listado de reportes de venta, y descargarlo una vez finalice.
                      </AlertInfo>
                      )
                    : null
                }
                <div className={this.props.classes.mt2}>
                  <GoBackButton link='/sales_reports' />
                  <Button
                    className={this.props.classes.saveButton}
                    variant='contained'
                    color='primary'
                    style={{ float: 'right' }}
                    disabled={saveButtonDisabled}
                    onClick={() => this.props.saveSalesReport(this.props.token, this.props.currentSalesReport)}
                    startIcon={<SaveIcon />}
                  >
                    Generar
                  </Button>
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    artistsLoading: state.artists.loading,
    formLoading: state.salesReports.formLoading,
    error: state.salesReports.error,
    success: state.salesReports.success,
    salesReports: state.salesReports.salesReports,
    artists: state.artists.artists,
    currentSalesReport: state.salesReports.currentSalesReport,
    sales: state.sales.sales
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadArtists: token => dispatch(actions.loadArtists(token)),
    saveSalesReport: (token, body) => dispatch(actions.saveSalesReport(token, body)),
    editNewSalesReport: (key, value) => dispatch(actions.editNewSalesReport(key, value)),
    loadSales: token => dispatch(actions.loadSales(token))
  }
}

// Style component.
const SalesReportFormStyled = props => {
  const classes = useStyles()
  return (
    <SalesReportForm classes={classes} {...props} />
  )
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(SalesReportFormStyled)
