// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog
} from '@material-ui/core'

// Components.
import AlertInfo from '../../../../../UI/Alerts/AlertInfo'
import UserLink from '../../../../../UI/Links/UserLink'
import StockMovementType from '../../../../../UI/Custom/StockMovementType'
import AsyncActionStatus from '../../../../../UI/Custom/AsyncActionStatus'

// Shared.
import { prettyPrintDate } from '../../../../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const PosTerminalsStockMovementsDialog = props => {
  let content = null
  if (props.posTerminal.stock_movements && props.posTerminal.stock_movements.length) {
    content = (
      <Table size='small'>
        <TableHead>
          <TableRow>
            <TableCell>ID</TableCell>
            <TableCell>Tipo</TableCell>
            <TableCell>Estado</TableCell>
            <TableCell>Lugar</TableCell>
            <TableCell>Creado por</TableCell>
            <TableCell>Fecha creación (UTC)</TableCell>
            <TableCell>Fecha modificación (UTC)</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            props.posTerminal.stock_movements.map((stockMovement, key) => {
              return (
                <TableRow key={key}>
                  <TableCell>{stockMovement.id}</TableCell>
                  <TableCell><StockMovementType>{stockMovement.type}</StockMovementType></TableCell>
                  <TableCell><AsyncActionStatus>{stockMovement.status}</AsyncActionStatus></TableCell>
                  <TableCell>{stockMovement.location}</TableCell>
                  <TableCell><UserLink user={stockMovement.user} label='firstname' /></TableCell>
                  <TableCell>{prettyPrintDate(stockMovement.created_at)}</TableCell>
                  <TableCell>{prettyPrintDate(stockMovement.updated_at)}</TableCell>
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    )
  } else {
    content = <AlertInfo>No se han encontrado movimientos de stock con este TPV. Probablemente el terminal está en la oficina.</AlertInfo>
  }

  return (
    <Dialog
      maxWidth='lg'
      open={props.open}
      onClose={props.onDialogClose}
      aria-labelledby='alert-dialog-title'
      aria-describedby='alert-dialog-description'
    >
      <DialogTitle id='alert-dialog-title'>
        Movimientos del TPV nº {props.posTerminal.name}
      </DialogTitle>
      <DialogContent>
        {content}
      </DialogContent>
      <DialogActions>
        <Button color='default' variant='outlined' onClick={props.onDialogClose}>
          Cerrar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

// Export.
export default PosTerminalsStockMovementsDialog
