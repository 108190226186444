// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import { Button } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import { connect } from 'react-redux'

// Shared.
import { userCan } from '../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class CreateButton extends Component {
  render () {
    const label = this.props.label ? this.props.label : 'Crear'
    const action = this.props.action ? this.props.action : 'create'

    if (!userCan(this.props.user, this.props.model, action)) {
      return null
    }

    return (
      <Button
        color='primary'
        variant='contained'
        component={RouterLink}
        disabled={this.props.disabled}
        to={this.props.link}
        startIcon={<AddIcon />}
      >
        {label}
      </Button>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

// Export.
export default connect(mapStateToProps)(CreateButton)
