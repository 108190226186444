// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import { Link as RouterLink } from 'react-router-dom'
import { connect } from 'react-redux'
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  List
} from '@material-ui/core'
import ExpandLessIcon from '@material-ui/icons/ExpandLess'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

// Shared.
import { userCan } from '../../../../../shared/functions'
import * as actions from '../../../../../store/actions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class SideMenuElement extends Component {
  shouldBeHidden (section) {
    if (section.hideForNonArtist && this.props.user && !this.props.user.artist_id) return true
    if (section.hideForArtist && this.props.user && this.props.user.artist_id) return true
    return (section.permissions && !userCan(this.props.user, section.permissions.model, section.permissions.action))
  }

  render () {
    // With no subsections.
    if (!this.props.subsections || !this.props.subsections.length) {
      const link = this.props.link || this.props.section
      // Hide section if the user doesn't have permissions.
      if (this.shouldBeHidden(this.props)) return null
      return (
        <ListItem button onClick={this.props.handleCollapseAllSideMenuSections} component={RouterLink} to={link} selected={this.props.currentSection === this.props.section}>
          <ListItemIcon>
            {this.props.icon}
          </ListItemIcon>
          <ListItemText primary={this.props.label} />
        </ListItem>
      )
    }

    // Hide section if the user doesn't have permissions for any of the sub-sections.
    let shouldBeHidden = true
    for (let i = 0; i < this.props.subsections.length; i++) {
      const subsection = this.props.subsections[i]
      if (!this.shouldBeHidden(subsection)) {
        shouldBeHidden = false
        break
      }
    }
    if (shouldBeHidden) return null

    // With subsections.
    return (
      <>
        <ListItem button onClick={() => this.props.expandSideMenuSection(this.props.section)} selected={this.props.currentSection === this.props.section}>
          <ListItemIcon>
            {this.props.icon}
          </ListItemIcon>
          <ListItemText primary={this.props.label} />
          {this.props.sideMenuExpandedSection === this.props.section ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </ListItem>
        <Collapse in={this.props.sideMenuExpandedSection === this.props.section}>
          <List component='div' disablePadding timeout='auto'>
            {
              this.props.subsections.map((subsection, key) => {
                if (this.shouldBeHidden(subsection)) return null
                const link = subsection.link || subsection.section
                return (
                  <ListItem key={key} button component={RouterLink} to={link} selected={this.props.sideMenuExpandedSection === this.props.section && this.props.currentSubsection === subsection.subsection}>
                    <ListItemIcon style={{ paddingLeft: 8 }}>
                      {subsection.icon}
                    </ListItemIcon>
                    <ListItemText primary={subsection.label} disableTypography />
                  </ListItem>
                )
              })
            }
          </List>
        </Collapse>
      </>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    user: state.auth.user,
    sideMenuExpandedSection: state.auth.sideMenuExpandedSection
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    expandSideMenuSection: section => dispatch(actions.expandSideMenuSection(section)),
    handleCollapseAllSideMenuSections: () => dispatch(actions.collapseAllSideMenuSections())
  }
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(SideMenuElement)
