// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import { Button } from '@material-ui/core'
import SyncIcon from '@material-ui/icons/Sync'
import { connect } from 'react-redux'

// Shared.
import { userCan } from '../../../shared/functions'

// Style.
import { useStyles } from '../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class SyncButton extends Component {
  render () {
    const label = this.props.label ? this.props.label : 'Sincronizar'
    const action = this.props.action ? this.props.action : 'sync'

    if (!userCan(this.props.user, this.props.model, action)) {
      return null
    }

    return (
      <Button
        color='primary'
        variant='contained'
        startIcon={<SyncIcon />}
        onClick={this.props.onClick}
        disabled={this.props.disabled}
      >
        {label}
      </Button>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

// Style component.
const SyncButtonStyled = props => {
  const classes = useStyles()
  return <SyncButton classes={classes} {...props} />
}

// Export.
export default connect(mapStateToProps)(SyncButtonStyled)
