// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import { Grid, Chip } from '@material-ui/core'

// Components.
import FormField from '../../UI/Form/FormField'

// Styles.
import { useStyles } from '../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
class TableFilters extends Component {
  componentDidMount () {
    document.addEventListener('keydown', this.escFunction, false)
  }

  escFunction = e => {
    if (e.keyCode === 27) {
      for (const fieldName in this.props.filters) {
        if (Object.prototype.hasOwnProperty.call(this.props.filters, fieldName)) {
          if (this.props.filters[fieldName].type === 'text' && this.props.filters[fieldName].touched && this.props.filters[fieldName].value) {
            this.props.handleChange(fieldName, null, true)
          }
        }
      }
    }
  }

  calculateGridWidth = () => {
    return Math.floor(12 / Object.keys(this.props.filters).length)
  }

  render () {
    return (
      <div className={this.props.classes.tableFilter}>
        <h4 className={this.props.classes.tableFilterTitle}>Filtrar por:</h4>
        <Grid container spacing={3}>
          {
            Object.keys(this.props.filters).map((fieldName, key) => {
              const field = this.props.filters[fieldName]
              return (
                <Grid item xs={12} lg={this.calculateGridWidth()} key={key}>
                  <FormField
                    key={fieldName}
                    name={fieldName}
                    type={field.type}
                    label={field.label}
                    value={field.value}
                    handleChange={e => this.props.handleChange(fieldName, e.target.value + '')}
                    options={this.props.deferredData && this.props.deferredData[fieldName] ? this.props.deferredData[fieldName] : field.options}
                    fullWidth
                    noMargin
                  />
                </Grid>
              )
            })
          }
        </Grid>
        <ul className={this.props.classes.activeFilters}>
          {
            Object.keys(this.props.filters).map((fieldName, key) => {
              const field = this.props.filters[fieldName]
              if (!field.touched || !field.value) {
                return null
              }
              return (
                <li key={key}>
                  <Chip
                    label={`${field.label}: ${field.value}`}
                    onDelete={() => this.props.handleChange(fieldName, null, true)}
                    className={this.props.classes.activeFilter}
                  />
                </li>
              )
            })
          }
        </ul>
      </div>
    )
  }
}

// Style component.
const TableFiltersStyled = props => {
  const classes = useStyles()
  return <TableFilters {...props} classes={classes} />
}

// Export
export default TableFiltersStyled
