// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import { Button } from '@material-ui/core'
import SaveIcon from '@material-ui/icons/Save'
import { connect } from 'react-redux'

// Shared.
import { userCan } from '../../../shared/functions'

// Style.
import { useStyles } from '../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class SaveButton extends Component {
  render () {
    const label = this.props.label ? this.props.label : 'Guardar'
    const action = this.props.action ? this.props.action : 'update'
    const icon = this.props.icon ? this.props.icon : <SaveIcon />

    if (!userCan(this.props.user, this.props.model, action)) {
      return null
    }

    return (
      <Button
        variant='contained'
        color='primary'
        style={{ float: 'right' }}
        disabled={this.props.disabled}
        onClick={this.props.onClick}
        startIcon={icon}
      >
        {label}
      </Button>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

// Style component.
const UpdateButtonStyled = props => {
  const classes = useStyles()
  return <SaveButton classes={classes} {...props} />
}

// Export.
export default connect(mapStateToProps)(UpdateButtonStyled)
