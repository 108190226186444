// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  LinearProgress,
  Paper
} from '@material-ui/core'

// Components.
import Title from '../../../../../UI/Brand/Title'
import FormField from '../../../../../UI/Form/FormField'
import AlertSuccess from '../../../../../UI/Alerts/AlertSuccess'
import AlertError from '../../../../../UI/Alerts/AlertError'
import ProductScheduledDiscountSummary from './ProductScheduledDiscountSummary'
import DateTools from '../../../../../UI/Custom/DateTools'
import SaveButton from '../../../../../UI/Buttons/SaveButton'
import GoBackButton from '../../../../../UI/Buttons/GoBackButton'

// Shared.
import {
  getOptionsArray,
  validateDiscountType,
  validatePositive
} from '../../../../../../shared/functions'
import { CRON_MESSAGES, DISCOUNT_TYPES } from '../../../../../../shared/constants'

// Redux actions.
import * as actions from '../../../../../../store/actions'

// Styles.
import { useStyles } from '../../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class ProductScheduledDiscountForm extends Component {
  state = {
    fields: {
      type: {
        label: 'Tipo',
        error: null,
        type: 'select',
        required: true,
        touched: false,
        validation: validateDiscountType,
        errMsg: 'Por favor, selecciona un tipo de descuento de la lista.',
        autoFocus: true,
        fullWidth: false,
        options: getOptionsArray(DISCOUNT_TYPES)
      },
      discount: {
        label: 'Valor del descuento',
        error: null,
        type: 'text',
        required: true,
        touched: false,
        validation: validatePositive,
        errMsg: 'Por favor, introduce un valor positivo.',
        fullWidth: false,
        autoFocus: false
      },
      site_id: {
        label: 'Sitio web',
        error: null,
        type: 'autocomplete',
        required: true,
        touched: false,
        errMsg: 'Por favor, proporciona un sitio web.',
        autoFocus: false,
        fullWidth: false,
        width: 4,
        options: []
      },
      category_id: {
        label: 'Categoría',
        error: null,
        type: 'autocomplete',
        required: true,
        touched: false,
        errMsg: 'Por favor, proporciona una categoría.',
        autoFocus: false,
        fullWidth: false,
        width: 4,
        options: []
      },
      product_ids: {
        label: 'Productos',
        error: null,
        type: 'multiselect',
        required: true,
        touched: false,
        errMsg: 'Por favor, proporciona un producto.',
        autoFocus: false,
        fullWidth: false,
        width: 4,
        options: []
      },
      date_from: {
        label: 'Fecha de inicio',
        error: null,
        type: 'datetime',
        required: true,
        touched: false,
        errMsg: 'Por favor, selecciona una fecha válida.',
        fullWidth: false,
        autoFocus: false
      },
      date_to: {
        label: 'Fecha de finalización',
        error: null,
        type: 'datetime',
        required: true,
        touched: false,
        errMsg: 'Por favor, selecciona una fecha válida.',
        fullWidth: false,
        autoFocus: false
      }
    }
  }

  componentDidMount () {
    // Load the current product scheduled discount.
    if (!this.props.selectedProductScheduledDiscount) {
      this.props.loadProductScheduledDiscount(this.props.id, this.props.token)
    }
    // Load the sites list.
    if (!this.props.sites || !this.props.sites.length) {
      this.props.loadSites(this.props.token)
    }
  }

  handleChange = (elemKey, newValue) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields[elemKey]
    }
    // Modify the element value.
    this.props.updateSelectedProductScheduledDiscount(elemKey, newValue)
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.required && (newElement.type === 'autocomplete' ? !newValue : !newValue.length) && newElement.touched) {
      newElement.error = 'Campo obligatorio'
    } else if (newElement.validation && newValue.length && !newElement.validation(newValue)) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Build new state.
    const newState = {
      ...this.state,
      fields: {
        ...this.state.fields,
        [elemKey]: newElement
      }
    }
    // Load the site.
    if (elemKey === 'site_id') {
      this.props.updateSelectedProductScheduledDiscount('category_id', '')
      this.props.updateSelectedProductScheduledDiscount('product_ids', '')
      this.props.loadSite(newValue, this.props.token)
    } else if (elemKey === 'category_id') {
      this.props.updateSelectedProductScheduledDiscount('product_ids', '')
      this.props.loadCategory(newValue, this.props.token)
    }
    // Update the state.
    this.setState(newState)
  }

  shouldSaveButtonBeDisabled = () => {
    if (this.props.formLoading) return true
    for (const fieldName in this.state.fields) {
      if (Object.prototype.hasOwnProperty.call(this.state.fields, fieldName)) {
        const field = this.state.fields[fieldName]
        if (field.required && (!this.props.selectedProductScheduledDiscount || !this.props.selectedProductScheduledDiscount[fieldName])) return true
        if (field.error) return true
      }
    }
    return false
  }

  render () {
    let content = null

    // Handle loading state.
    if (this.props.loading) {
      // Show loader.
      content = <LinearProgress />
    } else {
      content = (
        <form className={this.props.classes.rootWrap} noValidate autoComplete='off'>
          <h3 className={this.props.classes.formSection}>Propiedades del descuento</h3>
          {
            ['type', 'discount', 'site_id', 'category_id', 'product_ids'].map(fieldName => {
              const field = this.state.fields[fieldName]

              // Special field options.
              let disabled = field.disabled
              let helperText = field.helperText
              if (fieldName === 'site_id') {
                disabled = !this.props.sites.length
                if (!disabled) {
                  field.options = [
                    { label: '-', value: '', disabled: true },
                    ...this.props.sites
                      .filter(s => s.type !== 'prestashop')
                      .map(s => {
                        return { value: s.id, label: s.url }
                      })
                  ]
                }
              } else if (fieldName === 'category_id') {
                disabled = !this.props.sites.length || !this.props.selectedSite
                if (!disabled) {
                  field.options = [
                    { label: '-', value: '', disabled: true },
                    ...this.props.selectedSite.categories.map(c => {
                      return { value: c.id, label: c.name }
                    })
                  ]
                }
              } else if (fieldName === 'product_ids') {
                disabled = !this.props.sites.length || !this.props.selectedSite || !this.props.selectedCategory
                if (!disabled) {
                  field.options = [
                    { label: '-', value: '', disabled: true },
                    ...this.props.selectedCategory.products.map(p => {
                      return { value: p.id, label: p.name }
                    })
                  ]
                }
              } else if (fieldName === 'discount') {
                if (this.props.selectedProductScheduledDiscount) {
                  switch (this.props.selectedProductScheduledDiscount.type) {
                    case 'percentage': helperText = 'Introduce un valor entre 0 y 100'; break
                    case 'quantity': helperText = 'Introduce un valor positivo, en euros (€)'; break
                    default:
                  }
                }
              }

              // Get value.
              let value = this.props.selectedProductScheduledDiscount && this.props.selectedProductScheduledDiscount[fieldName] ? this.props.selectedProductScheduledDiscount[fieldName] : ''
              if (!value.length && field.type === 'multiselect') value = []

              return (
                <FormField
                  key={fieldName}
                  name={fieldName}
                  disabled={disabled}
                  type={field.type}
                  autoFocus={field.autoFocus}
                  required={field.required}
                  error={field.error}
                  label={field.label}
                  value={value}
                  fullWidth={field.fullWidth}
                  width={field.width}
                  handleChange={e => this.handleChange(fieldName, e.target.value)}
                  options={field.options}
                  helperText={helperText}
                />
              )
            })
          }

          <ProductScheduledDiscountSummary
            products={this.props.selectedCategory ? this.props.selectedCategory.products : null}
            selectedProductIds={this.props.selectedProductScheduledDiscount ? this.props.selectedProductScheduledDiscount.product_ids : null}
            discountType={this.props.selectedProductScheduledDiscount ? this.props.selectedProductScheduledDiscount.type : null}
            discountValue={this.props.selectedProductScheduledDiscount ? this.props.selectedProductScheduledDiscount.discount : null}
          />

          <h3 className={this.props.classes.formSection}>Selecciona las fechas</h3>
          <p className={this.props.classes.formHelperBlock}>{CRON_MESSAGES.productScheduledDiscounts}.</p>

          <DateTools onPropChange={this.handleChange} saleDates omitPastSales />

          {
            ['date_from', 'date_to'].map(fieldName => {
              const field = this.state.fields[fieldName]

              // Get value.
              const value = this.props.selectedProductScheduledDiscount && this.props.selectedProductScheduledDiscount[fieldName] ? this.props.selectedProductScheduledDiscount[fieldName] : ''

              return (
                <FormField
                  key={fieldName}
                  name={fieldName}
                  type={field.type}
                  autoFocus={field.autoFocus}
                  required={field.required}
                  error={field.error}
                  label={field.label}
                  value={value}
                  fullWidth={field.fullWidth}
                  width={field.width}
                  handleChange={e => this.handleChange(fieldName, e.target.value)}
                  options={field.options}
                  helperText={field.helperText}
                />
              )
            })
          }
        </form>
      )
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={this.props.classes.paper}>
            <div className='ProductScheduledDiscount'>
              <div className='ProductScheduledDiscountForm'>
                <Title>{this.props.selectedProductScheduledDiscount && this.props.id ? 'Propiedades del descuento programado' : 'Nuevo descuento programado'}</Title>
                <AlertError error={this.props.error} />
                <AlertSuccess message={this.props.success} />
                {content}
                <div className={this.props.classes.mt2}>
                  <GoBackButton link='/product_scheduled_discounts' />
                  <SaveButton
                    model='ProductScheduledDiscount'
                    action='create'
                    disabled={this.shouldSaveButtonBeDisabled()}
                    onClick={() => this.props.saveProductScheduledDiscount(this.props.id, this.props.token, {
                      product_ids: this.props.selectedProductScheduledDiscount.product_ids,
                      type: this.props.selectedProductScheduledDiscount.type,
                      discount: this.props.selectedProductScheduledDiscount.discount,
                      date_from: this.props.selectedProductScheduledDiscount.date_from,
                      date_to: this.props.selectedProductScheduledDiscount.date_to
                    })}
                  />
                </div>
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    loading: state.productScheduledDiscounts.loading,
    formLoading: state.productScheduledDiscounts.formLoading,
    error: state.productScheduledDiscounts.error,
    success: state.productScheduledDiscounts.success,
    sites: state.sites.sites,
    selectedSite: state.sites.selectedSite,
    selectedCategory: state.categories.selectedCategory,
    selectedProductScheduledDiscount: state.productScheduledDiscounts.selectedProductScheduledDiscount
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadSites: token => dispatch(actions.loadSites(token)),
    loadSite: (id, token) => dispatch(actions.loadSite(id, token)),
    loadCategory: (id, token) => dispatch(actions.loadCategory(id, token)),
    loadProductScheduledDiscount: (id, token) => dispatch(actions.loadProductScheduledDiscount(id, token)),
    saveProductScheduledDiscount: (id, token, body) => dispatch(actions.saveProductScheduledDiscount(id, token, body)),
    updateSelectedProductScheduledDiscount: (key, value) => dispatch(actions.updateSelectedProductScheduledDiscount(key, value))
  }
}

// Style component.
const ProductScheduledDiscountFormStyled = props => {
  const classes = useStyles()
  return (
    <ProductScheduledDiscountForm classes={classes} {...props} />
  )
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(ProductScheduledDiscountFormStyled)
