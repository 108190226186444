// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  Grid,
  Tabs,
  Tab,
  AppBar
} from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'

// Style.
import { useStyles } from '../../styles'
import { connect } from 'react-redux'
import { userCan } from '../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class Settings extends Component {
  shouldBeHidden (section) {
    if (section.hideForNonArtist && this.props.user && !this.props.user.artist_id) return true
    if (section.hideForArtist && this.props.user && this.props.user.artist_id) return true
    return (section.permissions && !userCan(this.props.user, section.permissions.model, section.permissions.action))
  }

  render () {
    if (!this.props.tabs || typeof this.props.tabs !== 'object' || !Object.keys(this.props.tabs).length) return

    const tabs = {}
    let id = 0
    for (const key in this.props.tabs) {
      if (Object.prototype.hasOwnProperty.call(this.props.tabs, key)) {
        // Skip, if the user is not allowed to see the section..
        if (this.shouldBeHidden(this.props.tabs[key])) continue
        // Push to the tabs object.
        tabs[key] = {
          id: id++,
          ...this.props.tabs[key]
        }
      }
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <div className={this.props.classes.rootWrap}>
            <AppBar position='static' color='default' className={this.props.classes.tabs}>
              <Tabs
                value={tabs[this.props.activeTab].id}
                onChange={() => {}}
                indicatorColor='primary'
                textColor='primary'
                variant='scrollable'
                scrollButtons='auto'
                aria-label='setting tabs'
              >
                {
                  Object.keys(tabs).map((tabKey, key) => (
                    <Tab
                      key={key}
                      component={RouterLink}
                      label={tabs[tabKey].label}
                      disabled={tabs[tabKey].disabled}
                      to={tabs[tabKey].link}
                      id={`nav-tab-${tabKey}`}
                      aria-controls={`nav-tabpanel-${tabKey}`}
                    />
                  ))
                }
              </Tabs>
            </AppBar>
          </div>
          {
            Object.keys(tabs).map((tabKey, key) => (
              <div
                key={key}
                role='tabpanel'
                hidden={this.props.activeTab !== tabKey}
                id={`nav-tabpanel-${tabKey}`}
                aria-labelledby={`nav-tab-${tabKey}`}
              >
                {this.props.activeTab === tabKey && (
                  tabs[tabKey].component
                )}
              </div>
            ))
          }
        </Grid>
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

// Style component.
const SettingsStyled = props => {
  const classes = useStyles()
  return <Settings {...props} classes={classes} />
}

// Export.
export default connect(mapStateToProps)(SettingsStyled)
