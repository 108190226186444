// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import { Grid, Paper } from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'

// Components.
import Title from '../../../../../../UI/Brand/Title'
import AlertError from '../../../../../../UI/Alerts/AlertError'
import AlertSuccess from '../../../../../../UI/Alerts/AlertSuccess'
import AddButton from '../../../../../../UI/Buttons/AddButton'
import RefreshButton from '../../../../../../UI/Buttons/RefreshButton'
import ArrangementDocumentsTable from './ArrangementDocumentsTable'

// Styles.
import { useStyles } from '../../../../../../styles'

// Shared.
import { calculateNumPages } from '../../../../../../../shared/functions'

// Redux actions.
import * as actions from '../../../../../../../store/actions'
import { connect } from 'react-redux'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class ArrangementDocumentsList extends Component {
  state = {
    page: 1,
    formOpen: false
  }

  handlePageChange = (e, value) => {
    this.setState({ page: +value })
  }

  handleFormOpen = () => {
    this.setState({ formOpen: true })
    if (!this.props.arrangements || (this.props.arrangements.length && +this.props.arrangements[0].artist_id !== +this.props.artistId)) {
      this.props.loadArtistArrangements(this.props.artistId, this.props.token)
    }
  }

  onFormClosed = () => {
    this.setState({ formOpen: false })
  }

  componentDidMount () {
    this.props.loadArrangementDocuments(this.props.artistId, this.props.token)
  }

  render () {
    return (
      <Grid item xs={12}>
        <Paper className={this.props.classes.paper}>
          <div className='ArrangementDocuments'>
            <Title>Documentos</Title>
            <AlertError error={this.props.error} />
            <AlertSuccess message={this.props.success} />
            <ArrangementDocumentsTable
              artistId={this.props.artistId}
              arrangementDocuments={this.props.arrangementDocuments}
              loading={this.props.loading}
              page={this.state.page}
              creating={this.state.formOpen}
              handleFormClose={this.onFormClosed}
            />
            <Grid container spacing={3} className={this.props.classes.tableTools}>
              <Grid item xs={4}>
                {
                  this.props.arrangementDocuments && this.props.arrangementDocuments.length
                    ? <Pagination
                        count={calculateNumPages(this.props.arrangementDocuments)}
                        page={this.state.page}
                        onChange={this.handlePageChange}
                      />
                    : null
                }
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'center' }}>
                {this.props.arrangementDocuments ? this.props.arrangementDocuments.length : 0} resultados
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                <RefreshButton
                  model='ArrangementDocument'
                  onClick={() => this.props.loadArrangementDocuments(this.props.artistId, this.props.token)}
                />
                <AddButton
                  model='ArrangementDocument'
                  onClick={this.handleFormOpen}
                />
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    loading: state.arrangementDocuments.loading,
    arrangements: state.artists.arrangements,
    arrangementDocuments: state.arrangementDocuments.arrangementDocuments,
    error: state.arrangementDocuments.error,
    success: state.arrangementDocuments.success
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadArtistArrangements: (id, token) => dispatch(actions.loadArtistArrangements(id, token)),
    loadArrangementDocuments: (artistId, token) => dispatch(actions.loadArrangementDocuments(artistId, token))
  }
}

// Style component.
const ArrangementDocumentsListStyled = props => {
  const classes = useStyles()
  return (
    <ArrangementDocumentsList classes={classes} {...props} />
  )
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(ArrangementDocumentsListStyled)
