// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { Chip } from '@material-ui/core'

// Shared.
import { printPrice } from '../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const PriceLabel = props => (
  <Chip
    variant={+props.children > 0 ? 'default' : 'outlined'}
    color={+props.children > 0 ? 'primary' : 'default'}
    size='small'
    label={printPrice(+props.children)}
    style={props.marginLeft ? { marginLeft: 8 } : {}}
  />
)

// Export.
export default PriceLabel
