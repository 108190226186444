// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import * as _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  LinearProgress,
  Paper
} from '@material-ui/core'

// Components.
import Title from '../../../../UI/Brand/Title'
import FormField from '../../../../UI/Form/FormField'
import AlertSuccess from '../../../../UI/Alerts/AlertSuccess'
import AlertError from '../../../../UI/Alerts/AlertError'
import SaveButton from '../../../../UI/Buttons/SaveButton'
import GoBackButton from '../../../../UI/Buttons/GoBackButton'
import ArtistStats from './Stats/ArtistStats'

// Redux actions.
import * as actions from '../../../../../store/actions'

// Shared.
import { userCan } from '../../../../../shared/functions'

// Styles.
import { useStyles } from '../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class ArtistForm extends Component {
  componentDidMount () {
    // Load the artist.
    this.props.loadArtist(this.props.artistId, this.props.token)
  }

  state = {
    fields: {
      name: {
        label: 'Nombre del artista',
        error: null,
        type: 'text',
        required: true,
        touched: false,
        validation: val => val.length >= 2,
        errMsg: 'Por favor, escribe un nombre de al menos dos caracteres.',
        autoFocus: true
      }
    }
  }

  handleChange = (elemKey, newValue) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields[elemKey]
    }
    // Take care of the value change, if the field is a checkbox.
    if (this.state.fields[elemKey].type === 'checkbox') newValue = this.props.selectedArtist ? !this.props.selectedArtist[elemKey] : true
    // Modify the element value.
    this.props.updateSelectedArtist(elemKey, newValue)
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.required && !newValue.length && newElement.touched) {
      newElement.error = 'Campo obligatorio'
    } else if (newElement.validation && newValue.length && !newElement.validation(newValue)) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Update the state.
    this.setState({
      fields: {
        ...this.state.fields,
        [elemKey]: newElement
      }
    })
  }

  render () {
    let content = null

    // Handle loading state.
    if (this.props.loading) {
      // SHow loader.
      content = <LinearProgress />
    } else {
      content = (
        <form className={this.props.classes.rootWrap} noValidate autoComplete='off'>
          {
            _.keys(this.state.fields).map(fieldName => {
              const field = this.state.fields[fieldName]

              // Get value.
              const value = this.props.selectedArtist && this.props.selectedArtist[fieldName] ? this.props.selectedArtist[fieldName] : ''

              return (
                <FormField
                  fullWidth
                  key={fieldName}
                  name={fieldName}
                  type={field.type}
                  autoFocus={field.autoFocus}
                  required={field.required}
                  error={field.error}
                  label={field.label}
                  value={value}
                  handleChange={e => this.handleChange(fieldName, e.target.value)}
                  options={field.options}
                  helperText={field.helperText}
                  noMargin={field.noMargin}
                  readOnly={!userCan(this.props.user, 'User', this.props.selectedArtist && this.props.selectedArtist.id ? 'update' : 'create')}
                />
              )
            })
          }
        </form>
      )
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={this.props.classes.paper}>
            <div className='ArtistForm'>
              <Title>Propiedades del artista</Title>
              <AlertError error={this.props.error} />
              <AlertSuccess message={this.props.success} />
              {content}
              <div className={this.props.classes.mt2}>
                <GoBackButton link='/artists' />
                <SaveButton
                  model='Artist'
                  action={this.props.selectedArtist && this.props.selectedArtist.id ? 'update' : 'create'}
                  disabled={this.props.formLoading || this.props.loading || !this.props.selectedArtist || !this.props.selectedArtist.name}
                  onClick={() => this.props.saveArtist(this.props.selectedArtist ? this.props.selectedArtist.id : null, this.props.token, this.props.selectedArtist)}
                />
              </div>
            </div>
          </Paper>
        </Grid>
        {this.props.selectedArtist ? <ArtistStats artistId={this.props.selectedArtist.id} /> : null}
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    user: state.auth.user,
    token: state.auth.token,
    loading: state.artists.loading,
    formLoading: state.artists.formLoading,
    error: state.artists.error,
    artists: state.artists.artists,
    success: state.artists.success,
    selectedArtist: state.artists.selectedArtist,
    dataSourcesLoading: state.artists.dataSourcesLoading,
    dataSourcesError: state.artists.dataSourcesError,
    dataSourcesSuccess: state.artists.dataSourcesSuccess,
    arrangementsLoading: state.artists.arrangementsLoading,
    arrangementsError: state.artists.arrangementsError,
    arrangementsSuccess: state.artists.arrangementsSuccess
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadArtist: (id, token) => dispatch(actions.loadArtist(id, token)),
    saveArtist: (id, token, body) => dispatch(actions.saveArtist(id, token, body)),
    loadArtists: (token) => dispatch(actions.loadArtists(token)),
    updateSelectedArtist: (key, value) => dispatch(actions.updateSelectedArtist(key, value)),
    deleteDataSource: (id, token) => dispatch(actions.deleteDataSource(id, token)),
    deleteArrangement: (id, token) => dispatch(actions.deleteArrangement(id, token))
  }
}

// Style component.
const ArtistFormStyled = props => {
  const classes = useStyles()
  return (
    <ArtistForm classes={classes} {...props} />
  )
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(ArtistFormStyled)
