// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  LinearProgress,
  Grid,
  Button,
  Paper,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Dialog
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { connect } from 'react-redux'

// Components.
import AlertError from '../../../../../UI/Alerts/AlertError'
import AlertSuccess from '../../../../../UI/Alerts/AlertSuccess'
import AlertWarning from '../../../../../UI/Alerts/AlertWarning'
import BooleanField from '../../../../../UI/Custom/BooleanField'
import FormField from '../../../../../UI/Form/FormField'
import SaleLink from '../../../../../UI/Links/SaleLink'
import Title from '../../../../../UI/Brand/Title'
import RowActions from '../../../../../UI/Table/RowActions'
import AddButton from '../../../../../UI/Buttons/AddButton'
import TableColumnTitle from '../../../../../UI/Table/TableColumnTitle'
import TableLoader from '../../../../../UI/Loaders/TableLoader'

// Shared.
import { RESULTS_PER_PAGE } from '../../../../../../shared/constants'
import { calculateNumPages, prettyPrintDateTime } from '../../../../../../shared/functions'

// Redux actions.
import * as actions from '../../../../../../store/actions'

// Style.
import { useStyles } from '../../../../../styles'

// =============================================================================
// Constants.
// =============================================================================

// Style of an active sale.
const activeSaleStyle = { backgroundColor: '#deefe1' }

// =============================================================================
// Component declaration.
// =============================================================================

// Table columns.
const columns = [
  { name: 'id', label: 'ID' },
  { name: 'name', label: 'Nombre' },
  { name: 'date_from', label: 'Desde' },
  { name: 'date_to', label: 'Hasta' },
  { name: 'status', label: 'Estado' },
  { name: 'created_at', label: 'Fecha de creación (UTC)' },
  { name: 'updated_at', label: 'Fecha de modificación (UTC)' },
  { name: 'actions', label: '' }
]

// Stateless component declaration.
class SiteSalesList extends Component {
  state = {
    page: 1,
    creating: false,
    selectedSaleId: '',
    addSaleDialogOpen: false
  }

  componentDidMount () {
    this.props.loadSales(this.props.token)
    this.props.loadSite(this.props.siteId, this.props.token)
  }

  handleConfirmationDialogOpen = () => {
    this.setState({
      addSaleDialogOpen: true
    })
  }

  handleConfirmationDialogClose = () => {
    this.setState({
      addSaleDialogOpen: false
    })
  }

  handlePageChange = (e, value) => {
    this.setState({ page: +value })
  }

  handleConfirmSaleAddition = () => {
    this.handleConfirmationDialogClose()
    this.props.addSiteSale(this.props.siteId, this.state.selectedSaleId, this.props.token)
  }

  handleUpdateSaleId = id => {
    this.setState({
      selectedSaleId: `${+id}`
    })
  }

  render () {
    let content = null
    let salesDialog = null
    const siteSales = []

    if (!this.props.siteId) {
      return null
    } else if (this.props.loading || this.props.siteSalesLoading || !this.props.selectedSite || !this.props.selectedSite.sales) {
      content = <TableLoader cols={columns} />
    } else if (!this.props.selectedSite.sales.length) {
      content = <AlertWarning>No se han configurado períodos de rebajas para este sitio web.</AlertWarning>
    } else {
      const minLimit = (this.state.page - 1) * RESULTS_PER_PAGE
      const maxLimit = Math.min(this.props.selectedSite.sales.length, this.state.page * RESULTS_PER_PAGE)

      for (let i = minLimit; i < maxLimit; i++) {
        const sale = this.props.selectedSite.sales[i]
        siteSales.push(
          <TableRow key={sale.id}>
            <TableCell style={sale.active ? activeSaleStyle : null}>
              {sale.id}
            </TableCell>
            <TableCell style={sale.active ? activeSaleStyle : null}>
              <SaleLink sale={sale} />
            </TableCell>
            <TableCell style={sale.active ? activeSaleStyle : null}>
              {prettyPrintDateTime(sale.date_from)}
            </TableCell>
            <TableCell style={sale.active ? activeSaleStyle : null}>
              {prettyPrintDateTime(sale.date_to)}
            </TableCell>
            <TableCell style={sale.active ? activeSaleStyle : null}>
              <BooleanField active={sale.active} activeLabel='Activo' inactiveLabel='Inactivo' />
            </TableCell>
            <TableCell style={sale.active ? activeSaleStyle : null}>
              {prettyPrintDateTime(sale.created_at)}
            </TableCell>
            <TableCell style={sale.active ? activeSaleStyle : null}>
              {prettyPrintDateTime(sale.updated_at)}
            </TableCell>
            <TableCell style={sale.active ? activeSaleStyle : null}>
              <RowActions
                model='Site'
                deleteActionName='update'
                id={sale.id}
                handleDelete={() => this.props.removeSiteSale(this.props.siteId, sale.id, this.props.token)}
              />
            </TableCell>
          </TableRow>
        )
      }

      content = (
        <Table size='small'>
          <TableHead>
            <TableRow>
              {
                columns.map((col, key) => <TableColumnTitle key={key} {...col} />)
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {siteSales}
          </TableBody>
        </Table>
      )

      const currentSiteSaleIds = this.props.selectedSite.sales.map(s => +s.id)
      const saleOptions = [
        { label: '-', value: '', disabled: true },
        ...this.props.sales.map(c => {
          return { value: c.id, label: c.name, disabled: currentSiteSaleIds.indexOf(+c.id) !== -1 }
        })
      ]

      salesDialog = (
        <Dialog
          open={this.state.addSaleDialogOpen}
          onClose={this.handleConfirmationDialogClose}
          aria-labelledby='alert-dialog-title'
          aria-describedby='alert-dialog-description'
        >
          <DialogTitle id='alert-dialog-title'>
            Añadir período de rebajas
          </DialogTitle>
          <DialogContent>
            <DialogContentText id='alert-dialog-description'>
              Selecciona un período de rebajas para el sitio web.
            </DialogContentText>
            {
              this.props.salesLoading
                ? <LinearProgress />
                : <FormField
                    key='sale_id'
                    name='sale_id'
                    type='select'
                    autoFocus
                    required
                    label='Período de rebajas'
                    value={this.state.selectedSaleId}
                    fullWidth
                    handleChange={e => this.handleUpdateSaleId(e.target.value)}
                    options={saleOptions}
                  />
            }
          </DialogContent>
          <DialogActions>
            <Button color='default' variant='outlined' onClick={this.handleConfirmationDialogClose}>
              Cancelar
            </Button>
            <Button color='primary' variant='contained' onClick={this.handleConfirmSaleAddition} autoFocus disabled={!this.state.selectedSaleId}>
              Añadir
            </Button>
          </DialogActions>
        </Dialog>
      )
    }

    return (
      <Grid item xs={12}>
        <Paper className={this.props.classes.paper}>
          <div className='SiteSales'>
            <Title>Períodos de rebajas</Title>
            <AlertError error={this.props.error} />
            <AlertSuccess message={this.props.success} />
            {content}
            <Grid container spacing={3} className={this.props.classes.tableTools}>
              <Grid item xs={4}>
                {
                  siteSales && siteSales.length
                    ? <Pagination
                        count={calculateNumPages(siteSales)}
                        page={this.state.page}
                        onChange={this.handlePageChange}
                      />
                    : null
                }
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'center' }}>
                {siteSales ? siteSales.length : 0} resultados
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                <AddButton
                  model='Site'
                  action='update'
                  onClick={this.handleConfirmationDialogOpen}
                />
              </Grid>
            </Grid>
          </div>
        </Paper>
        {salesDialog}
      </Grid>
    )
  }
}

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    loading: state.sites.loading,
    siteSalesLoading: state.sites.siteSalesLoading,
    error: state.sites.siteSalesError,
    success: state.sites.siteSalesSuccess,
    selectedSite: state.sites.selectedSite,
    salesLoading: state.sales.loading,
    sales: state.sales.sales
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadSite: (id, token) => dispatch(actions.loadSite(id, token)),
    loadSales: token => dispatch(actions.loadSales(token)),
    addSiteSale: (siteId, saleId, token) => dispatch(actions.addSiteSale(siteId, saleId, token)),
    removeSiteSale: (siteId, saleId, token) => dispatch(actions.removeSiteSale(siteId, saleId, token))
  }
}

// Style component.
const SiteSalesStyled = props => {
  const classes = useStyles()
  return (
    <SiteSalesList classes={classes} {...props} />
  )
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(SiteSalesStyled)
