// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { Box, Container, CssBaseline } from '@material-ui/core'

// Style.
import './Maintenance.css'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const Maintenance = (props) => {
  return (
    <Container component='main' maxWidth='xs' className='Maintenance'>
      <div className='MaintenanceBoxContainer'>
        <div className='MaintenanceBox'>
          <CssBaseline />
          <Box className='MaintenancePageLogo'>
            <img src='/logo192.png' alt='Logo' />
          </Box>
          <Box className='MaintenancePageDescription'>
            Sitio web en mantenimiento
          </Box>
          <Box className='MaintenancePageApology'>
            El servicio volverá en unos minutos.<br />Disculpa las molestias.
          </Box>
        </div>
      </div>
    </Container>
  )
}

// Export.
export default Maintenance
