// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { connect } from 'react-redux'

// Redux actions.
import * as actions from '../../../../../../../store/actions'

// Components.
import AlertInfo from '../../../../../../UI/Alerts/AlertInfo'
import FormField from '../../../../../../UI/Form/FormField'
import ArrangementDocumentsTableRow from './ArrangementDocumentsTableRow'
import SaveButton from '../../../../../../UI/Buttons/SaveButton'
import TableColumnTitle from '../../../../../../UI/Table/TableColumnTitle'
import TableLoader from '../../../../../../UI/Loaders/TableLoader'

// Shared.
import { RESULTS_PER_PAGE } from '../../../../../../../shared/constants'

// Style.
import { useStyles } from '../../../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Table columns.
const columns = [
  { name: 'id', label: 'ID' },
  { name: 'name', label: 'Nombre' },
  { name: 'arrangement_id', label: 'Trato comercial' },
  { name: 'file', label: 'Archivo adjunto' },
  { name: 'created_at', label: 'Fecha de creación (UTC)' },
  { name: 'updated_at', label: 'Fecha de actualización (UTC)' },
  { name: 'actions', label: '' }
]

// Stateful component declaration.
class ArrangementDocumentsTable extends Component {
  state = {
    fields: {
      arrangement_id: {
        label: 'Trato comercial',
        error: null,
        value: '',
        type: 'select',
        required: true,
        touched: false,
        errMsg: 'Por favor, selecciona un trato comercial.',
        autoFocus: false,
        options: []
      },
      name: {
        label: 'Nombre',
        value: '',
        error: null,
        type: 'text',
        required: true,
        touched: false,
        errMsg: 'Por favor, proporciona un nombre válido.',
        autoFocus: true
      },
      filename: {
        label: 'Seleccionar',
        error: null,
        type: 'file',
        value: '',
        accept: [
          'application/pdf' /* .pdf */,
          'application/vnd.ms-excel' /* .xls */,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' /* .xlsx */,
          'application/msword' /* .doc */,
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document' /* .docx */,
          'application/vnd.oasis.opendocument.text' /* .odt */,
          'application/vnd.oasis.opendocument.spreadsheet' /* .ods */
        ],
        required: true,
        touched: false,
        validation: val => +val > 0,
        errMsg: 'Selecciona un archivo.',
        autoFocus: false
      }
    }
  }

  handleChange = (elemKey, newValue) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields[elemKey]
    }
    // Modify the element value.
    newElement.value = newValue
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.required && !newValue.length && newElement.touched) {
      newElement.error = 'Campo obligatorio'
    } else if (newElement.validation && newValue.length && !newElement.validation(newValue)) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Build new state.
    const newState = {
      fields: {
        ...this.state.fields,
        [elemKey]: newElement
      }
    }
    // Update the state.
    this.setState(newState)
  }

  handleCreateArrangementDocument = () => {
    // Save the arrangement document.
    this.props.saveArrangementDocument(null, this.props.token, {
      arrangement_id: this.state.fields.arrangement_id.value,
      name: this.state.fields.name.value,
      file: this.state.fields.filename.value
    })
    // Reset the form.
    const newStateFields = {
      ...this.state.fields
    }
    newStateFields.name.value = ''
    newStateFields.filename.value = ''
    newStateFields.arrangement_id.value = ''
    this.setState({
      fields: newStateFields
    })
    // Close the form.
    this.props.handleFormClose()
  }

  shouldSaveButtonBeDisabled = () => {
    return this.props.loading ||
      !this.state.fields.name.value ||
      this.state.fields.name.value === '' ||
      !this.state.fields.arrangement_id.value ||
      this.state.fields.arrangement_id.value === '' ||
      !this.state.fields.filename.value ||
      this.state.fields.filename.value === ''
  }

  render () {
    // Loading.
    if (this.props.loading) {
      return <TableLoader cols={columns} />
    }

    if ((!this.props.arrangementDocuments || !this.props.arrangementDocuments.length) && !this.props.creating) {
      return (
        <AlertInfo>
          No hay documentos adjuntos.
        </AlertInfo>
      )
    }

    const arrangementDocumentsList = []
    const minLimit = (this.props.page - 1) * RESULTS_PER_PAGE
    const maxLimit = Math.min(this.props.arrangementDocuments.length, this.props.page * RESULTS_PER_PAGE)

    for (let i = minLimit; i < maxLimit; i++) {
      const arrangementDocument = this.props.arrangementDocuments[i]

      arrangementDocumentsList.push((
        <ArrangementDocumentsTableRow
          key={i}
          artistId={this.props.artistId}
          id={arrangementDocument.id}
          arrangements={this.props.arrangements}
          arrangementDocument={arrangementDocument}
          loading={this.props.loading}
          onDeleteArrangementDocument={() => this.props.deleteArrangementDocument(arrangementDocument.id, this.props.token)}
          handleSaveArrangementDocument={body => this.props.saveArrangementDocument(arrangementDocument.id, this.props.token, body)}
        />
      ))
    }

    if (this.props.creating) {
      arrangementDocumentsList.push(
        <TableRow key='new'>
          <TableCell className={this.props.classes.tableCellForm}>
            <IconButton color='default' aria-label='undo' size='small' onClick={this.props.handleFormClose}>
              <ClearIcon />
            </IconButton>
          </TableCell>
          {
            ['arrangement_id', 'name'].map((fieldName, key) => {
              const field = this.state.fields[fieldName]
              let options = field.options

              if (fieldName === 'arrangement_id') {
                options = [
                  { label: '-', value: '' },
                  ...this.props.arrangements.map(a => {
                    return { value: a.id, label: a.name }
                  })
                ]
              }

              return (
                <TableCell key={key}>
                  <FormField
                    noMargin
                    fullWidth
                    name={fieldName}
                    type={field.type}
                    autoFocus={field.autofocus}
                    required={field.required}
                    label={field.label}
                    value={field.value}
                    options={options}
                    handleChange={e => this.handleChange(fieldName, e.target.value)}
                  />
                </TableCell>
              )
            })
          }
          <TableCell>
            <FormField
              noMargin
              fullWidth
              name='filename'
              type={this.state.fields.filename.type}
              autoFocus={this.state.fields.filename.autofocus}
              required={this.state.fields.filename.required}
              label={this.state.fields.filename.label}
              value={this.state.fields.filename.value}
              accept={this.state.fields.filename.accept}
              handleChange={e => this.handleChange('filename', e.target.files[0])}
            />
          </TableCell>
          <TableCell />
          <TableCell />
          <TableCell className={this.props.classes.tableCellForm}>
            <SaveButton
              model='ArrangementDocument'
              action='create'
              disabled={this.shouldSaveButtonBeDisabled()}
              onClick={this.handleCreateArrangementDocument}
            />
          </TableCell>
        </TableRow>
      )
    }

    return (
      <>
        <Table size='small'>
          <TableHead>
            <TableRow>
              {
                columns.map((col, key) => <TableColumnTitle key={key} {...col} />)
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {arrangementDocumentsList}
          </TableBody>
        </Table>
      </>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    arrangements: state.artists.arrangements
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    deleteArrangementDocument: (id, token) => dispatch(actions.deleteArrangementDocument(id, token)),
    loadArrangementDocuments: token => dispatch(actions.loadArrangementDocuments(token)),
    saveArrangementDocument: (id, token, body) => dispatch(actions.saveArrangementDocument(id, token, body))
  }
}

// Style component.
const ArrangementDocumentsTableStyled = props => {
  const classes = useStyles()
  return <ArrangementDocumentsTable classes={classes} {...props} />
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(ArrangementDocumentsTableStyled)
