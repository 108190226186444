// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { Chip } from '@material-ui/core'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const Percentage = props => (
  <Chip
    variant='outlined'
    size='small'
    label={`${props.children}%`}
  />
)

// Export.
export default Percentage
