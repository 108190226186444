// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Grid, Button } from '@material-ui/core'

// Shared.
import { formatDateTimePickerDate } from '../../../shared/functions'

// Redux actions.
import * as actions from '../../../store/actions'

// Styles.
import { useStyles } from '../../styles'

// =============================================================================
// Aux. components.
// =============================================================================

// Custom date button.
const DateToolsButton = props => (
  <Button
    color='primary'
    variant='outlined'
    style={{ marginRight: 5, borderRadius: 15, padding: '2px 10px' }}
    onClick={props.onClick}
  >
    {props.children}
  </Button>
)

// Custom date button for sales.
const DateToolsSalesButton = props => (
  <Button
    color='default'
    variant='contained'
    style={{ marginRight: 5, borderRadius: 15, padding: '2px 10px' }}
    onClick={props.onClick}
  >
    {props.children}
  </Button>
)

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class DateTools extends Component {
  componentDidMount () {
    if (this.props.saleDates) {
      this.props.loadSales(this.props.token)
    }
  }

  setDateLimits = (dateFrom, dateTo = null) => {
    if (!dateTo) dateTo = new Date()
    this.props.onPropChange('date_from', formatDateTimePickerDate(dateFrom))
    this.props.onPropChange('date_to', formatDateTimePickerDate(dateTo))
  }

  handleToday = () => {
    const dateFrom = new Date()
    dateFrom.setHours(0)
    dateFrom.setMinutes(0)
    dateFrom.setSeconds(0)
    this.setDateLimits(dateFrom)
  }

  handleYesterday = () => {
    const dateFrom = new Date()
    dateFrom.setDate(dateFrom.getDate() - 1)
    dateFrom.setHours(0)
    dateFrom.setMinutes(0)
    dateFrom.setSeconds(0)
    const dateTo = new Date()
    dateTo.setDate(dateTo.getDate() - 1)
    dateTo.setHours(23)
    dateTo.setMinutes(59)
    dateTo.setSeconds(59)
    this.setDateLimits(dateFrom, dateTo)
  }

  handleSinceYesterday = () => {
    const dateFrom = new Date()
    dateFrom.setHours(0)
    dateFrom.setMinutes(0)
    dateFrom.setSeconds(0)
    dateFrom.setDate(dateFrom.getDate() - 2)
    this.setDateLimits(dateFrom)
  }

  handleDBT = () => {
    const dateFrom = new Date()
    dateFrom.setDate(dateFrom.getDate() - 2)
    dateFrom.setHours(0)
    dateFrom.setMinutes(0)
    dateFrom.setSeconds(0)
    const dateTo = new Date()
    dateTo.setDate(dateTo.getDate() - 2)
    dateTo.setHours(23)
    dateTo.setMinutes(59)
    dateTo.setSeconds(59)
    this.setDateLimits(dateFrom, dateTo)
  }

  handleSinceDBT = () => {
    const dateFrom = new Date()
    dateFrom.setHours(0)
    dateFrom.setMinutes(0)
    dateFrom.setSeconds(0)
    dateFrom.setDate(dateFrom.getDate() - 3)
    this.setDateLimits(dateFrom)
  }

  handleLast7Days = () => {
    const dateFrom = new Date()
    dateFrom.setDate(dateFrom.getDate() - 7)
    dateFrom.setHours(0)
    dateFrom.setMinutes(0)
    dateFrom.setSeconds(0)
    const dateTo = new Date()
    dateTo.setDate(dateTo.getDate() - 1)
    dateTo.setHours(23)
    dateTo.setMinutes(59)
    dateTo.setSeconds(59)
    this.setDateLimits(dateFrom, dateTo)
  }

  handleLast15Days = () => {
    const dateFrom = new Date()
    dateFrom.setDate(dateFrom.getDate() - 15)
    dateFrom.setHours(0)
    dateFrom.setMinutes(0)
    dateFrom.setSeconds(0)
    const dateTo = new Date()
    dateTo.setDate(dateTo.getDate() - 1)
    dateTo.setHours(23)
    dateTo.setMinutes(59)
    dateTo.setSeconds(59)
    this.setDateLimits(dateFrom, dateTo)
  }

  handleLastMonth = () => {
    const dateFrom = new Date()
    dateFrom.setMonth(dateFrom.getMonth() - 1)
    dateFrom.setHours(0)
    dateFrom.setMinutes(0)
    dateFrom.setSeconds(0)
    const dateTo = new Date()
    dateTo.setDate(dateTo.getDate() - 1)
    dateTo.setHours(23)
    dateTo.setMinutes(59)
    dateTo.setSeconds(59)
    this.setDateLimits(dateFrom, dateTo)
  }

  handleSaleDates = sale => {
    return () => this.setDateLimits(sale.date_from, sale.date_to)
  }

  render () {
    let sales = this.props.saleDates && this.props.sales && this.props.sales.length ? this.props.sales : null
    if (sales && this.props.omitPastSales) sales = sales.filter(s => (new Date(s.date_from)) >= new Date())

    return (
      <div className='DateTools' style={{ width: '100%' }}>
        {
          this.props.quickDates
            ? (
              <>
                <h3 className={this.props.classes.dateToolsTitle}>Selección rápida de fechas</h3>
                <Grid item xs={12} className={this.props.classes.mb3}>
                  <DateToolsButton onClick={this.handleToday}>Hoy</DateToolsButton>
                  <DateToolsButton onClick={this.handleYesterday}>Ayer</DateToolsButton>
                  <DateToolsButton onClick={this.handleSinceYesterday}>Desde ayer</DateToolsButton>
                  <DateToolsButton onClick={this.handleDBT}>Anteayer</DateToolsButton>
                  <DateToolsButton onClick={this.handleSinceDBT}>Desde anteayer</DateToolsButton>
                  <DateToolsButton onClick={this.handleLast7Days}>Últimos 7 días</DateToolsButton>
                  <DateToolsButton onClick={this.handleLast15Days}>Últimos 15 días</DateToolsButton>
                  <DateToolsButton onClick={this.handleLastMonth}>Último mes</DateToolsButton>
                </Grid>
              </>
              )
            : null
        }
        {
          sales && sales.length
            ? (
              <>
                <h3 className={this.props.classes.dateToolsTitle}>Fechas de los períodos de rebajas</h3>
                <Grid item xs={12} className={this.props.classes.mb3}>
                  {
                    sales.map((sale, key) => (
                      <DateToolsSalesButton key={key} onClick={this.handleSaleDates(sale)}>{sale.name}</DateToolsSalesButton>
                    ))
                  }
                </Grid>
              </>
              )
            : null
        }
      </div>
    )
  }
}

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    salesLoading: state.sales.loading,
    sales: state.sales.sales
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadSales: token => dispatch(actions.loadSales(token))
  }
}

// Style component.
const DateToolsStyled = props => {
  const classes = useStyles()
  return (
    <DateTools classes={classes} {...props} />
  )
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(DateToolsStyled)
