// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  IconButton,
  TableCell,
  TableRow
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'

// Components.
import FormField from '../../../../../UI/Form/FormField'
import RowActions from '../../../../../UI/Table/RowActions'
import DiscountType from '../../../../../UI/Custom/DiscountType'
import DiscountValue from '../../../../../UI/Custom/DiscountValue'
import SheduledActionStatus from '../../../../../UI/Custom/SheduledActionStatus'
import ProductLink from '../../../../../UI/Links/ProductLink'
import UserLink from '../../../../../UI/Links/UserLink'
import SiteLink from '../../../../../UI/Links/SiteLink'
import SaveButton from '../../../../../UI/Buttons/SaveButton'

// Shared.
import {
  getOptionsArray,
  prettyPrintDateTime,
  validateDiscountType,
  validatePositive
} from '../../../../../../shared/functions'
import { DISCOUNT_TYPES } from '../../../../../../shared/constants'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class ProductScheduledDiscountsTableRow extends Component {
  state = {
    editing: false,
    fields: {
      type: {
        label: 'Tipo',
        error: null,
        type: 'select',
        required: true,
        touched: false,
        validation: validateDiscountType,
        errMsg: 'Por favor, selecciona un tipo de descuento de la lista.',
        autoFocus: true,
        options: getOptionsArray(DISCOUNT_TYPES),
        value: ''
      },
      discount: {
        label: 'Valor',
        error: null,
        type: 'text',
        required: true,
        touched: false,
        validation: validatePositive,
        errMsg: 'Por favor, introduce un valor positivo.',
        autoFocus: false,
        value: ''
      }
    }
  }

  onInlineUpdateStart = () => {
    const newFields = {
      type: {
        ...this.state.fields.type,
        value: this.props.productScheduledDiscount.type
      },
      discount: {
        ...this.state.fields.discount,
        value: this.props.productScheduledDiscount.discount
      }
    }
    this.setState({ editing: true, fields: newFields })
  }

  handleStopInlineUpdate = () => {
    this.setState({ editing: false })
  }

  handleChange = (elemKey, newValue) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields[elemKey]
    }
    // Modify the element value.
    newElement.value = newValue
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.required && (newElement.type === 'select' ? !newValue : !newValue.length) && newElement.touched) {
      newElement.error = 'Campo obligatorio'
    } else if (newElement.validation && newValue.length && !newElement.validation(newValue)) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Build new state.
    const newState = {
      ...this.state,
      fields: {
        ...this.state.fields,
        [elemKey]: newElement
      }
    }
    // Update the state.
    this.setState(newState)
  }

  shouldSaveButtonBeDisabled = () => {
    return this.props.loading ||
      !this.state.fields.type.value ||
      this.state.fields.type.value === '' ||
      !this.state.fields.discount.value ||
      isNaN(this.state.fields.discount.value) ||
      +this.state.fields.discount.value <= 0 ||
      this.state.fields.discount.value === ''
  }

  handleSave = () => {
    this.props.saveProductScheduledDiscount(this.props.productScheduledDiscount.id, { type: this.state.fields.type.value, discount: this.state.fields.discount.value })
    this.handleStopInlineUpdate()
  }

  getExtraRowActions = () => {
    const extraRowActions = []
    switch (this.props.productScheduledDiscount.status) {
      case 'scheduled': extraRowActions.push({ label: 'Activar ahora', handleAction: () => this.props.activateProductScheduledDiscount(this.props.productScheduledDiscount.id), actionName: 'update' }); break
      case 'active': extraRowActions.push({ label: 'Expirar ahora', handleAction: () => this.props.expireProductScheduledDiscount(this.props.productScheduledDiscount.id), actionName: 'update' }); break
      default:
    }
    return extraRowActions
  }

  render () {
    const extraRowActions = this.getExtraRowActions()

    return (
      <TableRow key={this.props.productScheduledDiscount.id}>
        <TableCell>
          {
            this.state.editing
              ? (
                <IconButton color='default' aria-label='undo' size='small' onClick={this.handleStopInlineUpdate}>
                  <ClearIcon />
                </IconButton>
                )
              : this.props.productScheduledDiscount.id
          }
        </TableCell>
        <TableCell>
          <ProductLink product={this.props.productScheduledDiscount.product} site_id={this.props.productScheduledDiscount.product.category.site_id} />
        </TableCell>
        <TableCell>
          <SiteLink site={this.props.productScheduledDiscount.product.category.site} />
        </TableCell>
        <TableCell>
          {
            this.state.editing
              ? <FormField
                  name='type'
                  disabled={this.state.fields.type.disabled}
                  type={this.state.fields.type.type}
                  autoFocus={this.state.fields.type.autoFocus}
                  required={this.state.fields.type.required}
                  error={this.state.fields.type.error}
                  label={this.state.fields.type.label}
                  value={this.state.fields.type.value}
                  fullWidth
                  handleChange={e => this.handleChange('type', e.target.value)}
                  options={this.state.fields.type.options}
                  helperText={this.state.fields.type.helperText}
                />
              : <DiscountType>{this.props.productScheduledDiscount.type}</DiscountType>
          }
        </TableCell>
        <TableCell>
          {
            this.state.editing
              ? <FormField
                  name='discount'
                  disabled={this.state.fields.discount.disabled}
                  type={this.state.fields.discount.type}
                  autoFocus={this.state.fields.discount.autoFocus}
                  required={this.state.fields.discount.required}
                  error={this.state.fields.discount.error}
                  label={this.state.fields.discount.label}
                  value={this.state.fields.discount.value}
                  fullWidth
                  handleChange={e => this.handleChange('discount', e.target.value)}
                  helperText={this.state.fields.discount.helperText}
                />
              : <DiscountValue type={this.props.productScheduledDiscount.type} value={this.props.productScheduledDiscount.discount} />
          }
        </TableCell>
        <TableCell>
          <SheduledActionStatus>{this.props.productScheduledDiscount.status}</SheduledActionStatus>
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(this.props.productScheduledDiscount.date_from)}
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(this.props.productScheduledDiscount.date_to)}
        </TableCell>
        <TableCell>
          {this.props.productScheduledDiscount.activated_at ? prettyPrintDateTime(this.props.productScheduledDiscount.activated_at) : '-'}
        </TableCell>
        <TableCell>
          {this.props.productScheduledDiscount.expired_at ? prettyPrintDateTime(this.props.productScheduledDiscount.expired_at) : '-'}
        </TableCell>
        <TableCell>
          <UserLink user={this.props.productScheduledDiscount.user} label='firstname' />
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(this.props.productScheduledDiscount.created_at)}
        </TableCell>
        <TableCell>
          {prettyPrintDateTime(this.props.productScheduledDiscount.updated_at)}
        </TableCell>
        <TableCell>
          {
            this.state.editing
              ? (
                <SaveButton
                  model='ProductScheduledDiscount'
                  action='update'
                  disabled={this.shouldSaveButtonBeDisabled()}
                  onClick={this.handleSave}
                />
                )
              : <RowActions
                  model='ProductScheduledDiscount'
                  id={this.props.productScheduledDiscount.id}
                  handleDelete={() => this.props.deleteProductScheduledDiscount(this.props.productScheduledDiscount.id)}
                  handleInlineUpdate={this.props.productScheduledDiscount.status === 'scheduled' ? this.onInlineUpdateStart : null}
                  extraActions={extraRowActions}
                />
          }
        </TableCell>
      </TableRow>
    )
  }
}

// Export.
export default ProductScheduledDiscountsTableRow
