// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
  Chip,
  CircularProgress,
  Grid,
  Typography
} from '@material-ui/core'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const CalculationStatus = props => {
  if (+props.children) {
    return (
      <Chip
        variant='outlined'
        color={+props.children ? 'primary' : 'default'}
        size='small'
        label={props.children ? 'Terminado' : 'En progreso...'}
      />
    )
  }
  return (
    <Grid container spacing={0} style={{ minWidth: 100 }}>
      <CircularProgress size={20} style={{ marginRight: 5 }} />
      <Typography variant='caption' color='textSecondary' align='center'>Calculando...</Typography>
    </Grid>
  )
}

// Export.
export default CalculationStatus
