// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  Paper,
  LinearProgress,
  Grid
} from '@material-ui/core'
import clsx from 'clsx'

// Components.
import Title from '../../../../../UI/Brand/Title'
import TmChart from '../../../../../UI/Chart/TmChart'
import FormField from '../../../../../UI/Form/FormField'

// Style.
import { useStyles } from '../../../../../styles'

// Shared
import { printReadableDateTime } from '../../../../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class ArtistStatsChart extends Component {
  state = {
    statsFromLastYear: false
  }

  toggleLastYearStatistics = () => {
    const newState = {}
    newState.statsFromLastYear = !this.state.statsFromLastYear
    this.setState(newState)
  }

  hasDataFromLastYear = data => {
    for (let i = 0; i < data.length; i++) {
      if (data[i].last_year) return true
    }
    return false
  }

  render () {
    let content = null
    let title = null

    if (this.props.loading) {
      title = <Title>Calculando estadísticas...</Title>
      content = <LinearProgress />
    } else if (!this.props.stats) {
      return null
    } else {
      let data = []
      const hasDataFromLastYear = this.hasDataFromLastYear(this.props.stats.orders.n_orders)
      if (this.state.statsFromLastYear) {
        // Print the full object.
        data = this.props.stats.orders.n_orders
      } else {
        // Delete the 'last_year' attribute.
        for (let i = 0; i < this.props.stats.orders.n_orders.length; i++) {
          const line = { ...this.props.stats.orders.n_orders[i] }
          delete line.last_year
          data.push(line)
        }
      }

      let lastYearCheckbox = null
      if (data.length && hasDataFromLastYear) {
        lastYearCheckbox = (
          <FormField
            label='Mostrar comparativa con año anterior'
            name='show_last_year'
            required={false}
            type='checkbox'
            noMargin
            handleChange={() => this.toggleLastYearStatistics()}
            value={this.state.statsFromLastYear}
          />
        )
      }

      title = <Title>Nº pedidos desde el {printReadableDateTime(this.props.stats.min_date)}</Title>
      content = (
        <div className={lastYearCheckbox ? clsx(this.props.classes.center, this.props.classes.mb1) : null}>
          <TmChart title='Nº pedidos' data={data} />
          {lastYearCheckbox}
        </div>
      )
    }

    return (
      <Grid item xs={12}>
        <Paper className={this.props.classes.paper}>
          {title}
          {content}
        </Paper>
      </Grid>
    )
  }
}

// Style component.
const ArtistStatsChartStyled = props => {
  const classes = useStyles()
  return <ArtistStatsChart {...props} classes={classes} />
}

// Export.
export default ArtistStatsChartStyled
