// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  Paper,
  Grid,
  Tabs,
  Tab
} from '@material-ui/core'
import { connect } from 'react-redux'

// Redux actions.
import * as actions from '../../../../../../store/actions'

// Components.
import Title from '../../../../../UI/Brand/Title'
import AlertSuccess from '../../../../../UI/Alerts/AlertSuccess'
import AlertError from '../../../../../UI/Alerts/AlertError'
import ArtistStatsProducts from './ArtistStatsProducts'
import ArtistStatsCards from './ArtistStatsCards'
import ArtistStatsChart from './ArtistStatsChart'

// Style.
import { useStyles } from '../../../../../styles'
import { prettyPrintDateTime, getStatsPeriodPrettyName, getMinDateForPeriod } from '../../../../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class ArtistStats extends Component {
  state = {
    statsPeriod: 'day'
  }

  componentDidMount () {
    if (!this.props.artistId) return
    this.props.loadArtistStats(this.props.artistId, this.props.token, getMinDateForPeriod(this.state.statsPeriod))
  }

  handleSetStatsPeriod = (e, statsPeriod) => {
    this.setState({
      statsPeriod
    })
    this.props.loadArtistStats(this.props.artistId, this.props.token, getMinDateForPeriod(statsPeriod))
  }

  render () {
    if (!this.props.artistId) {
      return null
    }

    let content = null
    if (this.props.statsLoading || this.props.stats) {
      content = (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Tabs
              value={this.state.statsPeriod}
              onChange={this.handleSetStatsPeriod}
              indicatorColor='primary'
              textColor='inherit'
            >
              <Tab value='day' label={getStatsPeriodPrettyName('day', true)} />
              <Tab value='week' label={getStatsPeriodPrettyName('week', true)} />
              <Tab value='month' label={getStatsPeriodPrettyName('month', true)} />
              <Tab value='year' label={getStatsPeriodPrettyName('year', true)} />
            </Tabs>
          </Grid>
          <Grid item xs={12} style={{ marginBottom: 10 }}>
            Las estadísticas se obtienen de los pedidos que contienen productos del artista en todos los sitios web. Los pedidos se sincronizan varias veces al día.
            {
              this.props.stats && this.props.stats.orders && this.props.stats.orders.last_order_date
                ? <span> La fecha del último pedido de estas estadísticas es <b>{prettyPrintDateTime(this.props.stats.orders.last_order_date)}</b>.</span>
                : null
            }
          </Grid>
          <ArtistStatsCards loading={this.props.statsLoading} stats={this.props.stats} />
          <ArtistStatsChart loading={this.props.statsLoading} stats={this.props.stats} />
          <ArtistStatsProducts loading={this.props.statsLoading} stats={this.props.stats} year='cur_year' periodTitle={getStatsPeriodPrettyName(this.state.statsPeriod)} />
          <ArtistStatsProducts loading={this.props.statsLoading} stats={this.props.stats} year='last_year' periodTitle='el mismo período del año anterior' />
        </Grid>
      )
    }

    return (
      <Grid item xs={12}>
        <Paper className={this.props.classes.paper}>
          <div className='ArtistStats'>
            <Title>Estadísticas del artista</Title>
            <AlertError error={this.props.statsError} />
            <AlertSuccess message={this.props.statsSuccess} />
            {content}
          </div>
        </Paper>
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    selectedArtist: state.artists.selectedArtist,
    stats: state.artists.stats,
    statsLoading: state.artists.statsLoading,
    statsError: state.artists.statsError,
    statsSuccess: state.artists.statsSuccess
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadArtistStats: (artistId, token, minDate, maxDate) => dispatch(actions.loadArtistStats(artistId, token, minDate, maxDate))
  }
}

// Style component.
const ArtistStatsStyled = props => {
  const classes = useStyles()
  return <ArtistStats classes={classes} {...props} />
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(ArtistStatsStyled)
