// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { Chip } from '@material-ui/core'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const ProductVariationName = props => {
  if (!props.children) return '-'
  return <Chip variant='outlined' color='primary' size='small' label={props.children} />
}

// Export.
export default ProductVariationName
