// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import * as _ from 'lodash'
import React, { Component } from 'react'
import clsx from 'clsx'
import { connect } from 'react-redux'
import {
  Drawer,
  List,
  ListSubheader,
  Divider,
  IconButton
} from '@material-ui/core'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import DashboardIcon from '@material-ui/icons/Dashboard'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import PeopleIcon from '@material-ui/icons/People'
import LanguageIcon from '@material-ui/icons/Language'
import InsertChartIcon from '@material-ui/icons/InsertChart'
import SettingsIcon from '@material-ui/icons/Settings'
import LocalOfferIcon from '@material-ui/icons/LocalOffer'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import ReceiptIcon from '@material-ui/icons/Receipt'
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn'
import MoneyOffIcon from '@material-ui/icons/MoneyOff'
import LocalShippingIcon from '@material-ui/icons/LocalShipping'
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle'
import AddCircleIcon from '@material-ui/icons/AddCircle'
import StoreIcon from '@material-ui/icons/Store'
// import DescriptionIcon from '@material-ui/icons/Description'

// Components.
import SideMenuElement from './SideMenuElement'

// Styles.
import { useStyles } from '../../../../styles'

// Redux actions.
import * as actions from '../../../../../store/actions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class SideMenu extends Component {
  render () {
    // Sections.
    const sections = {
      main: {
        home: {
          section: '/',
          label: 'Dashboard',
          icon: <DashboardIcon />,
          hideForArtist: true
        },
        artists: {
          section: '/artists',
          label: 'Artistas',
          icon: <SupervisedUserCircleIcon />,
          hideForArtist: true,
          permissions: { model: 'Artist', action: 'list' }
        },
        artist: {
          section: '/artists',
          link: this.props.user && this.props.user.artist_id ? `/artist/${this.props.user.artist_id}` : '/artists',
          label: 'Perfil del artista',
          icon: <SupervisedUserCircleIcon />,
          hideForNonArtist: true,
          permissions: { model: 'Artist', action: 'read' }
        },
        sites: {
          section: '/sites',
          label: 'Sitios web',
          icon: <LanguageIcon />,
          permissions: { model: 'Site', action: 'list' }
        },
        sales_reports: {
          section: '/sales_reports',
          label: 'Reportes de ventas',
          icon: <InsertChartIcon />,
          permissions: { model: 'SalesReport', action: 'list' }
        },
        product_scheduled_discounts: {
          section: '/product_scheduled_discounts',
          label: 'Descuentos programados',
          icon: <LocalOfferIcon />,
          permissions: { model: 'ProductScheduledDiscount', action: 'list' }
        },
        warehouse: {
          section: '/warehouse',
          label: 'Almacén',
          icon: <LocalShippingIcon />,
          subsections: [
            {
              link: '/warehouse/stock_withdrawals',
              label: 'Salidas de stock',
              subsection: '/stock_withdrawals',
              icon: <RemoveCircleIcon fontSize='small' />,
              permissions: { model: 'StockMovement', action: 'list' }
            },
            {
              link: '/warehouse/stock_entries',
              label: 'Entradas de stock',
              subsection: '/stock_entries',
              icon: <AddCircleIcon fontSize='small' />,
              permissions: { model: 'StockMovement', action: 'list' }
            },
            {
              link: '/warehouse/inventory',
              label: 'Inventario',
              subsection: '/inventory',
              icon: <StoreIcon />,
              permissions: { model: 'Product', action: 'search' }
            }
          ]
        }
      },
      secondary: {
        users: {
          section: '/users',
          label: 'Usuarios',
          icon: <PeopleIcon />,
          permissions: { model: 'User', action: 'list' }
        },
        settings: {
          section: '/settings',
          label: 'Ajustes',
          icon: <SettingsIcon />,
          subsections: [
            {
              link: '/settings/generic',
              label: 'Ajustes generales',
              subsection: '/generic_settings',
              icon: <SettingsIcon fontSize='small' />,
              permissions: { model: 'Configuration', action: 'list' }
            },
            {
              link: '/settings/expense_types',
              label: 'Tipos de gasto',
              subsection: '/expense_types',
              icon: <MoneyOffIcon fontSize='small' />,
              permissions: { model: 'ExpenseType', action: 'list' }
            },
            {
              link: '/settings/earning_types',
              label: 'Tipos de ingreso',
              subsection: '/earning_types',
              icon: <MonetizationOnIcon fontSize='small' />,
              permissions: { model: 'EarningType', action: 'list' }
            },
            {
              link: '/settings/sales',
              label: 'Períodos de rebajas',
              subsection: '/sales',
              icon: <ReceiptIcon fontSize='small' />,
              permissions: { model: 'Sale', action: 'list' }
            },
            {
              link: '/settings/pos_terminals',
              label: 'TPVs',
              subsection: '/pos_terminals',
              icon: <CreditCardIcon fontSize='small' />,
              permissions: { model: 'PosTerminal', action: 'list' }
            }
          ]
        }
        // docs: {
        //   section: '/docs',
        //   label: 'Documentación',
        //   icon: <DescriptionIcon />
        // }
      }
    }

    return (
      <Drawer
        variant='permanent'
        classes={{
          paper: clsx(this.props.classes.drawerPaper, !this.props.sideMenuOpen && this.props.classes.drawerPaperClose)
        }}
        open={this.props.sideMenuOpen}
      >
        <div className={this.props.classes.toolbarIcon}>
          <IconButton onClick={this.props.handleToggleSideMenuState}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>
          <div>
            {
              _.keys(sections.main).map((e, key) => {
                const element = sections.main[e]
                return (
                  <SideMenuElement
                    key={key}
                    section={element.section}
                    link={element.link}
                    currentSection={this.props.section}
                    currentSubsection={this.props.subsection}
                    icon={element.icon}
                    label={element.label}
                    subsections={element.subsections}
                    permissions={element.permissions}
                    hideForArtist={element.hideForArtist}
                    hideForNonArtist={element.hideForNonArtist}
                  />
                )
              })
            }
          </div>
        </List>
        <Divider />
        <List>
          <div>
            <ListSubheader inset>Administración</ListSubheader>
            {
              _.keys(sections.secondary).map((e, key) => {
                const element = sections.secondary[e]
                return (
                  <SideMenuElement
                    key={key}
                    section={element.section}
                    link={element.link}
                    currentSection={this.props.section}
                    currentSubsection={this.props.subsection}
                    icon={element.icon}
                    label={element.label}
                    subsections={element.subsections}
                    permissions={element.permissions}
                    hideForArtist={element.hideForArtist}
                    hideForNonArtist={element.hideForNonArtist}
                  />
                )
              })
            }
          </div>
        </List>
      </Drawer>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    user: state.auth.user,
    sideMenuOpen: state.auth.sideMenuOpen
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    handleToggleSideMenuState: () => dispatch(actions.toggleSideMenuState())
  }
}

// Style component.
const SideMenuStyled = props => {
  const classes = useStyles()
  return <SideMenu classes={classes} {...props} />
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(SideMenuStyled)
