// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'

// Components.
import PriceLabel from './PriceLabel'
import Percentage from './Percentage'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const DiscountType = props => {
  switch (props.type) {
    case 'percentage': return <Percentage>{props.value}</Percentage>
    case 'quantity': return <PriceLabel>{props.value}</PriceLabel>
    default: return null
  }
}

// Export.
export default DiscountType
