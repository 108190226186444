// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  FormGroup,
  FormControlLabel,
  Checkbox,
  FormHelperText,
  FormLabel,
  Link
} from '@material-ui/core'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

// Components.
import Title from '../../../../../../UI/Brand/Title'
import NumberOfStuff from '../../../../../../UI/Custom/NumberOfStuff'

// Shared.
import { areArraysEqual, userCan } from '../../../../../../../shared/functions'
import { useStyles } from '../../../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class ArrangementCategory extends Component {
  state = {
    expanded: false
  }

  shouldComponentUpdate (nextProps, nextState, nextContext) {
    return nextState.expanded !== this.state.expanded || !areArraysEqual(this.props.selectedProducts.map(p => +p.id), nextProps.selectedProducts.map(p => +p.id))
  }

  // Check if a product should be selected.
  shouldProductBeSelected = (id, selectedProductIds) => {
    return selectedProductIds.indexOf(id) !== -1
  }

  handleCollapse = () => {
    const oldState = { ...this.state }
    const newState = { expanded: !oldState.expanded }
    this.setState(newState)
  }

  render () {
    const selectedProductIds = this.props.selectedProducts.map(p => +p.id)
    const numProductsFromCategory = this.props.category.products.filter(p => selectedProductIds.indexOf(+p.id) !== -1).length
    return (
      <Accordion expanded={this.state.expanded} onChange={this.handleCollapse}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls='panel1bh-content'
        >
          <Title noMargin>{this.props.category.name}</Title>
          <h2 className={this.props.classes.inlineSubtitle}>{this.props.category.site.url}</h2>
          <NumberOfStuff marginLeft>{numProductsFromCategory}</NumberOfStuff>
        </AccordionSummary>
        <AccordionDetails>
          <FormControl component='fieldset' className={this.props.classes.formControl}>
            <FormLabel component='legend'>
              {
                userCan(userCan(this.props.user, 'Arrangement', this.props.arrangement && this.props.arrangement.id ? 'update' : 'create'))
                  ? (
                    <>
                      <Link
                        component='button'
                        variant='body2'
                        onClick={() => this.props.onSelectProducts(this.props.category.products, true)}
                      >
                        Seleccionar todos
                      </Link>
                      <Link
                        component='button'
                        variant='body2'
                        onClick={() => this.props.onSelectProducts(this.props.category.products, false)}
                        style={{ marginLeft: 8 }}
                      >
                        Deseleccionar todos
                      </Link>
                    </>
                    )
                  : null
              }
            </FormLabel>
            <FormGroup>
              {
                this.props.category.products.map((p, key) => {
                  const isProductSelected = this.shouldProductBeSelected(p.id, selectedProductIds)
                  const productBelongsToAnotherArrangement = p.arrangement_id && (!this.props.arrangement || +p.arrangement_id !== +this.props.arrangement.id)
                  return (
                    <FormControlLabel
                      key={key}
                      control={
                        <Checkbox
                          disabled={productBelongsToAnotherArrangement}
                          checked={isProductSelected}
                          onChange={() => this.props.onSelectProduct(p, !isProductSelected)}
                          name={`product-${p.id}`}
                        />
                      }
                      label={p.name}
                      disabled={productBelongsToAnotherArrangement || !userCan(this.props.user, 'Arrangement', this.props.arrangement && this.props.arrangement.id ? 'update' : 'create')}
                    />
                  )
                })
              }
            </FormGroup>
            <FormHelperText>
              Selecciona los productos que pertenecen a este trato comercial
            </FormHelperText>
          </FormControl>
        </AccordionDetails>
      </Accordion>
    )
  }
}

// Style component.
const ArrangementCategoryStyled = props => {
  const classes = useStyles()
  return (
    <ArrangementCategory classes={classes} {...props} />
  )
}

// Export.
export default ArrangementCategoryStyled
