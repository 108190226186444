// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  Paper
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'

// Components.
import SettlementsTable from './SettlementsTable'
import Title from '../../../../../UI/Brand/Title'
import AlertError from '../../../../../UI/Alerts/AlertError'
import AlertSuccess from '../../../../../UI/Alerts/AlertSuccess'
import TableFilters from '../../../../../UI/Table/TableFilters'
import AddButton from '../../../../../UI/Buttons/AddButton'
import RefreshButton from '../../../../../UI/Buttons/RefreshButton'

// Shared.
import { calculateNumPages, applyFilter } from '../../../../../../shared/functions'
import { MONTHS, YEARS, FINISHED_OPTIONS } from '../../../../../../shared/constants'

// Redux actions.
import * as actions from '../../../../../../store/actions'

// Styles.
import { useStyles } from '../../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class SettlementList extends Component {
  state = {
    page: 1,
    filter: {
      month: {
        touched: false,
        operand: 'equals',
        value: '',
        key: 'month',
        label: 'Mes',
        type: 'select',
        options: MONTHS
      },
      year: {
        touched: false,
        operand: 'equals',
        value: '',
        key: 'year',
        label: 'Año',
        type: 'select',
        options: YEARS
      },
      finished: {
        touched: false,
        operand: 'equals',
        value: '',
        key: 'finished',
        label: 'Estado',
        type: 'select',
        options: FINISHED_OPTIONS
      }
    },
    orderBy: 'id',
    orderDirection: 'desc'
  }

  handlePageChange = (e, value) => {
    this.setState({ page: +value })
  }

  componentDidMount () {
    this.props.loadSettlements(this.props.artistId, this.props.token)
  }

  onFilterChange = (elemKey, value, forceUntouched = false) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.filter[elemKey]
    }
    // Modify the filter value.
    newElement.value = value
    // Set as touched.
    newElement.touched = !forceUntouched
    // Update the state.
    this.setState({
      page: 1,
      filter: {
        ...this.state.filter,
        [elemKey]: newElement
      }
    })
  }

  handleSort = (fieldName) => event => {
    // Create a copy of the state object.
    const newState = { ...this.state }
    // Set the order field.
    newState.orderBy = fieldName
    // Toggle the order direction.
    newState.orderDirection = this.state.orderBy === fieldName ? (newState.orderDirection === 'asc' ? 'desc' : 'asc') : 'asc'
    // Update the state.
    this.setState(newState)
  }

  render () {
    // Load content dynamically.
    let content = null

    if (this.props.error) {
      // Handle error message.
      content = <AlertError error={this.props.error} />
    } else {
      // Filter results.
      const settlements = applyFilter(this.props.settlements, this.state.filter, this.state.orderBy, this.state.orderDirection)

      content = (
        <>
          <AlertSuccess message={this.props.success} />
          <TableFilters
            filters={this.state.filter}
            handleChange={this.onFilterChange}
          />
          <SettlementsTable
            loading={this.props.loading}
            settlements={settlements}
            page={this.state.page}
            token={this.props.token}
            orderBy={this.state.orderBy}
            orderDirection={this.state.orderDirection}
            onSort={this.handleSort}
            deleteSettlement={id => this.props.deleteSettlement(id, this.props.token)}
            submitSettlement={(artistId, month, year) => this.props.submitSettlement(this.props.token, artistId, month, year)}
          />
          <Grid container spacing={3} className={this.props.classes.tableTools}>
            <Grid item xs={4}>
              <Pagination
                count={calculateNumPages(settlements)}
                page={this.state.page}
                onChange={this.handlePageChange}
              />
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'center' }}>
              {settlements.length} resultados
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <RefreshButton
                model='Settlement'
                onClick={() => this.props.loadSettlements(this.props.artistId, this.props.token)}
              />
              {
                settlements && settlements.length
                  ? null
                  : (
                    <AddButton
                      model='Settlement'
                      label='Calcular último mes'
                      style={{ marginRight: 10 }}
                      onClick={() => this.props.submitLastSettlement(this.props.token, this.props.artistId)}
                    />
                    )
              }
            </Grid>
          </Grid>
        </>
      )
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={this.props.classes.paper}>
            <div className='SettlementList'>
              <Title>Liquidaciones del artista</Title>
              {content}
            </div>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    loading: state.settlements.loading,
    success: state.settlements.success,
    error: state.settlements.error,
    settlements: state.settlements.settlements
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadSettlements: (artistId, token) => dispatch(actions.loadSettlements(artistId, token)),
    submitSettlement: (token, artistId, month, year) => dispatch(actions.submitSettlement(token, { artist_id: artistId, month, year })),
    submitLastSettlement: (token, artistId) => dispatch(actions.submitLastSettlement(token, artistId)),
    deleteSettlement: (id, token) => dispatch(actions.deleteSettlement(id, token))
  }
}

// Style component.
const SettlementListStyled = props => {
  const classes = useStyles()
  return (
    <SettlementList classes={classes} {...props} />
  )
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(SettlementListStyled)
