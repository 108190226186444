// ============================================================================
// Dependencies.
// ============================================================================

// Actions.
import * as actions from '../actions/types'

// Shared.
import { updateObject } from '../../shared/functions'

// ============================================================================
// Initial state.
// ============================================================================

// Initial state.
const initialState = {
  loading: true,
  error: null,
  success: null,
  productScheduledDiscounts: [],
  cronNextExecutionTime: null,
  currentlyExecutingCron: false,
  selectedProductScheduledDiscount: null
}

// ============================================================================
// Functionality.
// ============================================================================

// Start loading list of product scheduled discounts.
const loadProductScheduledDiscountsStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    success: null,
    productScheduledDiscounts: [],
    cronNextExecutionTime: null,
    currentlyExecutingCron: false
  })
}

// Finish loading list of product scheduled discounts.
const loadProductScheduledDiscountsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    productScheduledDiscounts: action.productScheduledDiscounts,
    cronNextExecutionTime: action.cronNextExecutionTime,
    currentlyExecutingCron: action.currentlyExecuting,
    error: null,
    success: null
  })
}

// Finish loading list of product scheduled discounts, with error.
const loadProductScheduledDiscountsFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    productScheduledDiscounts: [],
    error: action.error,
    success: null
  })
}

// Start loading product scheduled discount.
const loadProductScheduledDiscountStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    success: null,
    selectedProductScheduledDiscount: null
  })
}

// Finish loading product scheduled discount.
const loadProductScheduledDiscountSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    selectedProductScheduledDiscount: action.productScheduledDiscount,
    error: null,
    success: null
  })
}

// Finish loading product scheduled discount, with error.
const loadProductScheduledDiscountFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    selectedProductScheduledDiscount: null,
    error: action.error,
    success: null
  })
}

// Update the selected product scheduled discount.
const updateSelectedProductScheduledDiscount = (state, action) => {
  const newSelectedProductScheduledDiscount = {
    ...state.selectedProductScheduledDiscount
  }
  newSelectedProductScheduledDiscount[action.key] = action.value
  return updateObject(state, {
    selectedProductScheduledDiscount: newSelectedProductScheduledDiscount
  })
}

// Start saving a product scheduled discount.
const saveProductScheduledDiscountStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    success: null
  })
}

// Save a product scheduled discount successfully.
const saveProductScheduledDiscountSuccess = (state, action) => {
  let newProductScheduledDiscounts = [
    ...state.productScheduledDiscounts
  ]
  let newProductScheduledDiscount
  if (action.id) {
    const updatedProductScheduledDiscountIdx = state.productScheduledDiscounts.findIndex(s => +s.id === +action.id)
    newProductScheduledDiscount = { ...action.productScheduledDiscount }
    newProductScheduledDiscounts[updatedProductScheduledDiscountIdx] = newProductScheduledDiscount
  } else {
    newProductScheduledDiscount = null
    newProductScheduledDiscounts = newProductScheduledDiscounts.concat(action.productScheduledDiscount)
  }
  return updateObject(state, {
    loading: false,
    error: null,
    success: action.id ? action.message ? action.message : 'Descuento actualizado con éxito.' : 'Descuentos programados con éxito. Se aplicará a partir de la fecha seleccionada, teniendo en cuenta las horas a las que se ejecuta el cron.',
    productScheduledDiscounts: newProductScheduledDiscounts,
    selectedProductScheduledDiscount: newProductScheduledDiscount
  })
}

// Save a product scheduled discount, with error.
const saveProductScheduledDiscountFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    success: null
  })
}

// Start deleting product scheduled discount.
const deleteProductScheduledDiscountStart = state => {
  return updateObject(state, {
    loading: true,
    success: null,
    error: null
  })
}

// Delete a product scheduled discount successfully.
const deleteProductScheduledDiscountSuccess = (state, action) => {
  const oldProductScheduledDiscountIdx = state.productScheduledDiscounts.findIndex(e => +e.id === +action.id)
  const newProductScheduledDiscounts = [...state.productScheduledDiscounts]
  newProductScheduledDiscounts.splice(oldProductScheduledDiscountIdx, 1)
  return updateObject(state, {
    loading: false,
    productScheduledDiscounts: newProductScheduledDiscounts,
    error: null,
    success: `Se ha eliminado el descuento #${action.id} correctamente.`
  })
}

// Delete product scheduled discount, with error.
const deleteProductScheduledDiscountFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    success: null
  })
}

// Activate a product scheduled discount successfully.
const activateProductScheduledDiscountSuccess = (state, action) => {
  action.message = 'Descuento activado con éxito.'
  return saveProductScheduledDiscountSuccess(state, action)
}

// Expire a product scheduled discount successfully.
const expireProductScheduledDiscountSuccess = (state, action) => {
  action.message = 'Descuento expirado con éxito.'
  return saveProductScheduledDiscountSuccess(state, action)
}

// ============================================================================
// Reducer definition.
// ============================================================================

// Reducer definition.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_PRODUCT_SCHEDULED_DISCOUNTS_START:
      return loadProductScheduledDiscountsStart(state, action)
    case actions.LOAD_PRODUCT_SCHEDULED_DISCOUNTS_SUCCESS:
      return loadProductScheduledDiscountsSuccess(state, action)
    case actions.LOAD_PRODUCT_SCHEDULED_DISCOUNTS_FAILED:
      return loadProductScheduledDiscountsFailed(state, action)
    case actions.LOAD_PRODUCT_SCHEDULED_DISCOUNT_START:
      return loadProductScheduledDiscountStart(state, action)
    case actions.LOAD_PRODUCT_SCHEDULED_DISCOUNT_SUCCESS:
      return loadProductScheduledDiscountSuccess(state, action)
    case actions.LOAD_PRODUCT_SCHEDULED_DISCOUNT_FAILED:
      return loadProductScheduledDiscountFailed(state, action)
    case actions.UPDATE_SELECTED_PRODUCT_SCHEDULED_DISCOUNT:
      return updateSelectedProductScheduledDiscount(state, action)
    case actions.SAVE_PRODUCT_SCHEDULED_DISCOUNT_START:
      return saveProductScheduledDiscountStart(state, action)
    case actions.SAVE_PRODUCT_SCHEDULED_DISCOUNT_SUCCESS:
      return saveProductScheduledDiscountSuccess(state, action)
    case actions.SAVE_PRODUCT_SCHEDULED_DISCOUNT_FAILED:
      return saveProductScheduledDiscountFailed(state, action)
    case actions.DELETE_PRODUCT_SCHEDULED_DISCOUNT_START:
      return deleteProductScheduledDiscountStart(state, action)
    case actions.DELETE_PRODUCT_SCHEDULED_DISCOUNT_SUCCESS:
      return deleteProductScheduledDiscountSuccess(state, action)
    case actions.DELETE_PRODUCT_SCHEDULED_DISCOUNT_FAILED:
      return deleteProductScheduledDiscountFailed(state, action)
    case actions.ACTIVATE_PRODUCT_SCHEDULED_DISCOUNT_SUCCESS:
      return activateProductScheduledDiscountSuccess(state, action)
    case actions.EXPIRE_PRODUCT_SCHEDULED_DISCOUNT_SUCCESS:
      return expireProductScheduledDiscountSuccess(state, action)
    default:
      return state
  }
}

export default reducer
