// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'

// Components.
import Title from '../../../../../../UI/Brand/Title'
import SyncMode from '../../../../../../UI/Custom/SyncMode'
import ProductStatus from '../../../../../../UI/Custom/ProductStatus'
import NumberOfStuff from '../../../../../../UI/Custom/NumberOfStuff'
import ProductVariationName from '../../../../../../UI/Custom/ProductVariationName'
import TableColumnTitle from '../../../../../../UI/Table/TableColumnTitle'

// Shared.
import { applyFilter, calculateNumPages, prettyPrintDateTime, printPrice } from '../../../../../../../shared/functions'

// Style.
import { useStyles } from '../../../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Table columns.
const columns = [
  { name: 'id', label: 'ID', sortable: true },
  { name: 'remote_object_id', label: 'ID remoto', sortable: true },
  { name: 'name', label: 'Nombre', sortable: true },
  { name: 'active', label: 'Estado', sortable: true },
  { name: 'stock_quantity', label: 'Stock', sortable: true },
  { name: 'price', label: 'Precio (IVA incl.)', sortable: true },
  { name: 'sale_price', label: 'Precio rebajado (IVA incl.)', sortable: true },
  { name: 'sync_mode', label: 'Tipo de sincronización', sortable: true },
  { name: 'created_at', label: 'Fecha de creación (UTC)', sortable: true },
  { name: 'updated_at', label: 'Fecha de modificación (UTC)', sortable: true }
]

// Stateless component declaration.
const ProductVariationsTable = props => {
  const classes = useStyles()

  if (!props.variations || !props.variations.length) {
    return null
  }

  // Order results.
  const variations = applyFilter(props.variations, {}, props.orderBy, props.orderDirection)

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper} elevation={2}>
        <Title>Variaciones del producto</Title>
        <div className='ProductVariations'>
          <Table size='small'>
            <TableHead>
              <TableRow>
                {
                  columns.map((col, key) => <TableColumnTitle key={key} {...col} orderBy={props.orderBy} orderDirection={props.orderDirection} onSort={props.onSort} />)
                }
              </TableRow>
            </TableHead>
            <TableBody>
              {
                variations.map((variation, key) => (
                  <TableRow key={key}>
                    <TableCell>
                      {variation.id}
                    </TableCell>
                    <TableCell>
                      {variation.remote_object_id}
                    </TableCell>
                    <TableCell>
                      <ProductVariationName>{variation.name}</ProductVariationName>
                    </TableCell>
                    <TableCell>
                      <ProductStatus>{variation.active}</ProductStatus>
                    </TableCell>
                    <TableCell>
                      {variation.stock_quantity !== null ? <NumberOfStuff>{variation.stock_quantity}</NumberOfStuff> : '-'}
                    </TableCell>
                    <TableCell>
                      {printPrice(variation.price)}
                    </TableCell>
                    <TableCell>
                      {variation.sale_price && variation.sale_price > 0 ? printPrice(variation.sale_price) : '-'}
                    </TableCell>
                    <TableCell>
                      <SyncMode sync_mode={variation.sync_mode} />
                    </TableCell>
                    <TableCell>
                      {prettyPrintDateTime(variation.created_at)}
                    </TableCell>
                    <TableCell>
                      {prettyPrintDateTime(variation.created_at)}
                    </TableCell>
                  </TableRow>
                ))
              }
            </TableBody>
          </Table>
        </div>
        <Grid container spacing={3} className={classes.tableTools}>
          <Grid item xs={4}>
            <Pagination
              count={calculateNumPages(variations)}
              page={props.page}
              onChange={props.handlePageChange}
            />
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'center' }}>
            {variations.length} resultados
          </Grid>
          <Grid item xs={4} style={{ textAlign: 'right' }} />
        </Grid>
      </Paper>
    </Grid>
  )
}

// Export.
export default ProductVariationsTable
