// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton, Paper, Grid
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import Pagination from '@material-ui/lab/Pagination'
import { connect } from 'react-redux'

// Redux actions.
import * as actions from '../../../../../../store/actions'

// Components.
import Title from '../../../../../UI/Brand/Title'
import ExpenseType from '../../../../../UI/Custom/ExpenseType'
import AlertInfo from '../../../../../UI/Alerts/AlertInfo'
import Expense from '../../../../../UI/Custom/Expense'
import RowActions from '../../../../../UI/Table/RowActions'
import FormField from '../../../../../UI/Form/FormField'
import AttachmentDownloadLink from '../../../../../UI/Links/AttachmentDownloadLink'
import AlertSuccess from '../../../../../UI/Alerts/AlertSuccess'
import AlertError from '../../../../../UI/Alerts/AlertError'
import ArrangementLink from '../../../../../UI/Links/ArrangementLink'
import AddButton from '../../../../../UI/Buttons/AddButton'
import RefreshButton from '../../../../../UI/Buttons/RefreshButton'
import SaveButton from '../../../../../UI/Buttons/SaveButton'
import TableColumnTitle from '../../../../../UI/Table/TableColumnTitle'
import TableLoader from '../../../../../UI/Loaders/TableLoader'

// Shared.
import { calculateNumPages, prettyPrintDateTime } from '../../../../../../shared/functions'
import { RESULTS_PER_PAGE } from '../../../../../../shared/constants'

// Style.
import { useStyles } from '../../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Table columns.
const columns = [
  { name: 'id', label: 'ID' },
  { name: 'expense_type_id', label: 'Tipo' },
  { name: 'arrangement_id', label: 'Trato comercial' },
  { name: 'description', label: 'Descripción' },
  { name: 'total_expense', label: 'Gasto' },
  { name: 'invoice_number', label: 'Factura' },
  { name: 'attached_file', label: 'Archivo adjunto' },
  { name: 'created_at', label: 'Fecha (UTC)' },
  { name: 'actions', label: '' }
]

// Stateful component declaration.
class ArtistExpensesList extends Component {
  state = {
    fields: {
      expense_type_id: {
        label: 'Tipo',
        error: null,
        value: '',
        type: 'select',
        required: true,
        touched: false,
        errMsg: 'Por favor, seleciona un tipo de gasto.',
        autoFocus: true,
        options: []
      },
      description: {
        label: 'Descripción',
        error: null,
        type: 'text',
        value: '',
        required: true,
        touched: false,
        validation: val => val.length >= 2,
        errMsg: 'Por favor, proporciona una descripción.',
        autoFocus: false
      },
      invoice_number: {
        label: 'Nº factura (opcional)',
        error: null,
        type: 'text',
        value: '',
        required: false,
        touched: false,
        validation: val => val.length >= 1,
        errMsg: 'Por favor, selecciona un número de factura.',
        autoFocus: false
      },
      total_expense: {
        label: 'Cantidad',
        error: null,
        type: 'text',
        value: '',
        required: true,
        touched: false,
        validation: val => +val > 0,
        errMsg: 'Por favor, introduce una cantidad positiva.',
        autoFocus: false
      },
      attached_file: {
        label: 'Archivo adjunto (opcional)',
        error: null,
        type: 'file',
        value: '',
        accept: [
          'application/pdf' /* .pdf */,
          'application/vnd.ms-excel' /* .xls */,
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' /* .xlsx */,
          'application/msword' /* .doc */,
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document' /* .docx */,
          'application/vnd.oasis.opendocument.text' /* .odt */,
          'application/vnd.oasis.opendocument.spreadsheet' /* .ods */
        ],
        required: false,
        touched: false,
        validation: val => +val > 0,
        errMsg: 'Selecciona un archivo.',
        autoFocus: false
      },
      arrangement_id: {
        label: 'Trato comercial',
        error: null,
        value: '',
        type: 'select',
        required: true,
        touched: false,
        errMsg: 'Por favor, selecciona un trato comercial.',
        autoFocus: false,
        options: []
      }
    },
    page: 1,
    creatingExpense: false,
    justOpened: false
  }

  componentDidMount () {
    if (this.props.artistId) {
      this.props.loadArtistExpenses(this.props.artistId, this.props.token)
    }
  }

  handleExpensesPageChange = (e, value) => {
    this.setState({ page: +value })
  }

  handleExpenseFormOpen = () => {
    this.setState({ creatingExpense: true })
    this.props.loadArtistArrangements(this.props.artistId, this.props.token)
    this.props.loadExpenseTypes(this.props.token)
  }

  handleExpenseFormClose = () => {
    this.setState({ creatingExpense: false })
  }

  handleChange = (elemKey, newValue) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields[elemKey]
    }
    // Modify the element value.
    newElement.value = newValue
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.required && !newValue.length && newElement.touched) {
      newElement.error = 'Campo obligatorio'
    } else if (newElement.validation && newValue.length && !newElement.validation(newValue)) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Build new state.
    const newState = {
      fields: {
        ...this.state.fields,
        [elemKey]: newElement
      },
      justOpened: false
    }
    // Update the state.
    this.setState(newState)
  }

  handleSaveExpense = () => {
    // Save the data source.
    this.props.saveExpense(
      null,
      this.props.token,
      this.state.fields.arrangement_id.value,
      this.state.fields.expense_type_id.value,
      this.state.fields.description.value,
      this.state.fields.total_expense.value,
      this.state.fields.invoice_number.value,
      this.state.fields.attached_file.value
    )
    // Reset the form.
    const newStateFields = {
      ...this.state.fields
    }
    newStateFields.arrangement_id.value = ''
    newStateFields.expense_type_id.value = ''
    newStateFields.description.value = ''
    newStateFields.total_expense.value = ''
    newStateFields.invoice_number.value = ''
    newStateFields.attached_file.value = ''
    this.setState({
      fields: newStateFields
    })
    // Close the form.
    this.handleExpenseFormClose()
  }

  shouldSaveButtonBeDisabled = () => {
    return this.props.expenseTypesLoading ||
      !this.props.artistId ||
      !this.state.fields.expense_type_id.value ||
      !this.state.fields.arrangement_id.value ||
      !this.state.fields.description.value ||
      !this.state.fields.total_expense.value ||
      this.state.fields.expense_type_id.value === '' ||
      this.state.fields.description.value === '' ||
      this.state.fields.total_expense.value === '' ||
      this.state.fields.arrangement_id.value === ''
  }

  render () {
    let content
    if (!this.props.artistId) {
      return null
    } else if (this.props.expensesLoading) {
      content = <TableLoader cols={columns} />
    } else if ((!this.props.expenses || !this.props.expenses.length) && !this.state.creatingExpense) {
      content = (
        <AlertInfo>
          El artista aún no tiene gastos. Haz click en <b>Añadir</b> para comenzar a añadir gastos del asrtista y tenerlos en cuenta en las liquidaciones.
        </AlertInfo>
      )
    } else {
      const artistExpenses = []
      const minLimit = (this.state.page - 1) * RESULTS_PER_PAGE
      const maxLimit = Math.min(this.props.expenses.length, this.state.page * RESULTS_PER_PAGE)

      for (let i = minLimit; i < maxLimit; i++) {
        const expense = this.props.expenses[i]

        artistExpenses.push(
          <TableRow key={expense.id}>
            <TableCell>
              {expense.id}
            </TableCell>
            <TableCell>
              <ExpenseType>{expense.expense_type.name}</ExpenseType>
            </TableCell>
            <TableCell>
              <ArrangementLink arrangement={expense.arrangement} artistId={this.props.artistId} />
            </TableCell>
            <TableCell>
              {expense.description}
            </TableCell>
            <TableCell>
              <Expense total={expense.total_expense} settlements={expense.settlements} />
            </TableCell>
            <TableCell>
              {expense.invoice_number}
            </TableCell>
            <TableCell>
              <AttachmentDownloadLink
                artistId={this.props.artistId}
                label={expense.attached_file}
                downloadLink={expense.attachment_download_link}
              />
            </TableCell>
            <TableCell>
              {prettyPrintDateTime(expense.created_at)}
            </TableCell>
            <TableCell>
              <RowActions
                model='Expense'
                id={expense.id}
                handleDelete={() => this.props.deleteExpense(expense.id, this.props.token)}
              />
            </TableCell>
          </TableRow>
        )
      }

      if (this.state.creatingExpense && !this.props.arrangementsLoading && !this.props.expenseTypesLoading) {
        const expenseTypeOptions = [
          { label: '-', value: '' },
          ...this.props.expenseTypes.map(s => {
            return { value: s.id, label: s.name }
          })
        ]

        const arrangementOptions = [
          { label: '-', value: '' },
          ...this.props.arrangements.map(a => {
            return { value: a.id, label: a.name }
          })
        ]

        artistExpenses.push(
          <TableRow key='new'>
            <TableCell className={this.props.classes.tableCellForm}>
              <IconButton color='default' aria-label='undo' size='small' onClick={this.handleExpenseFormClose}>
                <ClearIcon />
              </IconButton>
            </TableCell>
            <TableCell className={this.props.classes.tableCellForm}>
              <FormField
                noMargin
                fullWidth
                name='expense_type_id'
                type={this.state.fields.expense_type_id.type}
                autoFocus={this.state.fields.expense_type_id.autofocus}
                required={this.state.fields.expense_type_id.required}
                label={this.state.fields.expense_type_id.label}
                value={this.state.fields.expense_type_id.value}
                handleChange={e => this.handleChange('expense_type_id', e.target.value)}
                options={expenseTypeOptions}
              />
            </TableCell>
            <TableCell className={this.props.classes.tableCellForm}>
              <FormField
                noMargin
                fullWidth
                name='arrangement_id'
                type={this.state.fields.arrangement_id.type}
                autoFocus={this.state.fields.arrangement_id.autofocus}
                required={this.state.fields.arrangement_id.required}
                label={this.state.fields.arrangement_id.label}
                value={this.state.fields.arrangement_id.value}
                handleChange={e => this.handleChange('arrangement_id', e.target.value)}
                options={arrangementOptions}
              />
            </TableCell>
            {
              ['description', 'total_expense', 'invoice_number'].map((fieldName, key) => {
                const field = this.state.fields[fieldName]
                return (
                  <TableCell key={key} className={this.props.classes.tableCellForm}>
                    <FormField
                      noMargin
                      fullWidth
                      name={fieldName}
                      type={field.type}
                      autoFocus={field.autofocus}
                      required={field.required}
                      label={field.label}
                      value={field.value}
                      accept={field.accept}
                      handleChange={e => this.handleChange(fieldName, e.target.value)}
                    />
                  </TableCell>
                )
              })
            }
            <TableCell className={this.props.classes.tableCellForm}>
              <FormField
                noMargin
                fullWidth
                name='attached_file'
                type={this.state.fields.attached_file.type}
                autoFocus={this.state.fields.attached_file.autofocus}
                required={this.state.fields.attached_file.required}
                value={this.state.fields.attached_file.value}
                accept={this.state.fields.attached_file.accept}
                handleChange={e => this.handleChange('attached_file', e.target.files[0])}
              />
            </TableCell>
            <TableCell className={this.props.classes.tableCellForm} />
            <TableCell className={this.props.classes.tableCellForm}>
              <SaveButton
                model='Expense'
                action='create'
                disabled={this.shouldSaveButtonBeDisabled()}
                onClick={this.handleSaveExpense}
              />
            </TableCell>
          </TableRow>
        )
      }

      content = (
        <Table size='small'>
          <TableHead>
            <TableRow>
              {
                columns.map((col, key) => <TableColumnTitle key={key} {...col} />)
              }
            </TableRow>
          </TableHead>
          <TableBody>
            {artistExpenses}
          </TableBody>
        </Table>
      )
    }

    return (
      <Grid item xs={12}>
        <Paper className={this.props.classes.paper}>
          <div className='ArtistExpenses'>
            <Title>Gastos del artista</Title>
            <AlertError error={this.props.expensesError} />
            <AlertSuccess message={this.props.expensesSuccess} />
            {content}
            <Grid container spacing={3} className={this.props.classes.tableTools}>
              <Grid item xs={4}>
                {
                  this.props.expenses && this.props.expenses.length
                    ? <Pagination
                        count={calculateNumPages(this.props.expenses, 5)}
                        page={this.state.page}
                        onChange={this.handleExpensesPageChange}
                      />
                    : null
                }
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'center' }}>
                {this.props.expenses ? this.props.expenses.length : 0} resultados
              </Grid>
              <Grid item xs={4} style={{ textAlign: 'right' }}>
                <RefreshButton
                  model='Expense'
                  onClick={() => this.props.loadArtistExpenses(this.props.artistId, this.props.token)}
                />
                <AddButton
                  model='Expense'
                  onClick={this.handleExpenseFormOpen}
                />
              </Grid>
            </Grid>
          </div>
        </Paper>
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    selectedArtist: state.artists.selectedArtist,
    expenses: state.artists.expenses,
    arrangementsLoading: state.artists.arrangementsLoading,
    arrangements: state.artists.arrangements,
    expenseTypesLoading: state.expenseTypes.loading,
    expenseTypes: state.expenseTypes.expenseTypes,
    expensesLoading: state.artists.expensesLoading,
    expensesError: state.artists.expensesError,
    expensesSuccess: state.artists.expensesSuccess
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadExpenseTypes: token => dispatch(actions.loadExpenseTypes(token)),
    loadArtistArrangements: (artistId, token) => dispatch(actions.loadArtistArrangements(artistId, token)),
    loadArtistExpenses: (artistId, token) => dispatch(actions.loadArtistExpenses(artistId, token)),
    deleteExpense: (id, token) => dispatch(actions.deleteExpense(id, token)),
    saveExpense: (expenseId, token, arrangementId, expenseTypeId, description, totalExpense, invoiceNumber, attachedFile) => dispatch(actions.saveExpense(expenseId, token, arrangementId, expenseTypeId, description, totalExpense, invoiceNumber, attachedFile))
  }
}

// Style component.
const ArtistExpensesStyled = props => {
  const classes = useStyles()
  return <ArtistExpensesList classes={classes} {...props} />
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(ArtistExpensesStyled)
