// ============================================================================
// Dependencies.
// ============================================================================

// Axios instance.
import axios from '../../axios'

// Action types.
import * as actions from './types'

// Shared.
import { getErrorMessage } from '../../shared/functions'
import { API_POSSIBLE_ERRORS } from '../../shared/constants'

// ============================================================================
// Action creators.
// ============================================================================

// Load sites start.
export const loadSitesStart = () => {
  return {
    type: actions.LOAD_SITES_START
  }
}

// Load sites success.
export const loadSitesSuccess = sites => {
  return {
    type: actions.LOAD_SITES_SUCCESS,
    sites
  }
}

// Load sites fail.
export const loadSitesFailed = error => {
  return {
    type: actions.LOAD_SITES_FAILED,
    error
  }
}

// Load sites.
export const loadSites = token => {
  return dispatch => {
    dispatch(loadSitesStart())
    // Perform request.
    axios(token).get('/sites')
      .then(response => {
        dispatch(loadSitesSuccess(response.data.sites))
      })
      .catch(error => {
        dispatch(loadSitesFailed(getErrorMessage(error)))
      })
  }
}

// Delete site start.
export const deleteSiteStart = () => {
  return {
    type: actions.DELETE_SITE_START
  }
}

// Delete site success.
export const deleteSiteSuccess = id => {
  return {
    type: actions.DELETE_SITE_SUCCESS,
    id
  }
}

// Delete site fail.
export const deleteSiteFailed = error => {
  return {
    type: actions.DELETE_SITE_FAILED,
    error
  }
}

// Delete site.
export const deleteSite = (id, token) => {
  return dispatch => {
    dispatch(deleteSiteStart())
    // Perform request.
    axios(token).delete(`/site/${id}`)
      .then(() => {
        dispatch(deleteSiteSuccess(id))
      })
      .catch(error => {
        dispatch(deleteSiteFailed(getErrorMessage(error)))
      })
  }
}

// Load site start.
export const loadSiteStart = () => {
  return {
    type: actions.LOAD_SITE_START
  }
}

// Load site success.
export const loadSiteSuccess = site => {
  return {
    type: actions.LOAD_SITE_SUCCESS,
    site
  }
}

// Load site fail.
export const loadSiteFailed = error => {
  return {
    type: actions.LOAD_SITE_FAILED,
    error
  }
}

// Load site.
export const loadSite = (id, token) => {
  return dispatch => {
    if (!id) {
      dispatch(loadSiteSuccess(null))
      return
    }
    dispatch(loadSiteStart())
    // Perform request.
    axios(token).get(`/site/${id}`)
      .then(response => {
        dispatch(loadSiteSuccess(response.data))
      })
      .catch(error => {
        dispatch(loadSiteFailed(getErrorMessage(error)))
      })
  }
}

// Update the loaded site.
export const updateSelectedSite = (key, value) => {
  return {
    type: actions.UPDATE_SELECTED_SITE,
    key,
    value
  }
}

// Start saving a site.
export const saveSiteStart = site => {
  return {
    type: actions.SAVE_SITE_START,
    site
  }
}

// Save site success.
export const saveSiteSuccess = (id, site) => {
  return {
    type: actions.SAVE_SITE_SUCCESS,
    id,
    site
  }
}

// Save site fail.
export const saveSiteFailed = (id, error) => {
  return {
    type: actions.SAVE_SITE_FAILED,
    id,
    error
  }
}

// Save a site.
export const saveSite = (id, token, body = {}) => {
  return dispatch => {
    dispatch(saveSiteStart())
    // Perform request.
    body = body || {}
    const url = id ? `/site/${id}` : '/site'
    const method = id ? 'put' : 'post'
    axios(token)[method](url, {
      url: body.url,
      type: body.type,
      client_key: body.client_key,
      client_secret: body.client_secret && body.client_secret.length ? body.client_secret : null,
      synced_to: body.synced_to ? +body.synced_to : null,
      sync_type: body.sync_type && body.sync_type.length ? body.sync_type : null
    })
      .then(response => {
        dispatch(saveSiteSuccess(id, response.data))
      })
      .catch(error => {
        dispatch(saveSiteFailed(id, getErrorMessage(error)))
      })
  }
}

// Start synchronizing the categories of a site.
export const syncCategoriesStart = () => {
  return {
    type: actions.SYNC_CATEGORIES_START
  }
}

// Successfully synchronize the categories of a site.
export const syncCategoriesSuccess = categories => {
  return {
    type: actions.SYNC_CATEGORIES_SUCCESS,
    categories
  }
}

// Synchronize the categories of a site, with error.
export const syncCategoriesFailed = error => {
  return {
    type: actions.SYNC_CATEGORIES_FAILED,
    error
  }
}

// Sync the categories of a site.
export const syncCategories = (id, token) => {
  return dispatch => {
    dispatch(syncCategoriesStart())
    // Perform request.
    axios(token).post(`/site/${id}/sync_categories`)
      .then(response => {
        dispatch(syncCategoriesSuccess(response.data))
      })
      .catch(() => {
        dispatch(syncCategoriesFailed({
          message: 'No se han podido sincronizar las categorías del sitio web. Esto puede deberse a varios motivos:',
          details: API_POSSIBLE_ERRORS
        }))
      })
  }
}

// Start adding sale to site.
export const addSiteSaleStart = () => {
  return {
    type: actions.ADD_SALE_START
  }
}

// Successfully add sale to site.
export const addSiteSaleSuccess = sales => {
  return {
    type: actions.ADD_SALE_SUCCESS,
    sales
  }
}

// Add sale to site, with error.
export const addSiteSaleFailed = error => {
  return {
    type: actions.ADD_SALE_FAILED,
    error
  }
}

// Add sale to site.
export const addSiteSale = (siteId, saleId, token) => {
  return dispatch => {
    dispatch(addSiteSaleStart())
    // Perform request.
    axios(token).post(`/site/${+siteId}/sale`, { sale_id: +saleId })
      .then(response => {
        dispatch(addSiteSaleSuccess(response.data))
      })
      .catch(error => {
        dispatch(addSiteSaleFailed(getErrorMessage(error)))
      })
  }
}

// Start removing sale from site.
export const removeSiteSaleStart = () => {
  return {
    type: actions.REMOVE_SALE_START
  }
}

// Successfully remove sale from site.
export const removeSiteSaleSuccess = id => {
  return {
    type: actions.REMOVE_SALE_SUCCESS,
    id
  }
}

// Remove sale from site, with error.
export const removeSiteSaleFailed = error => {
  return {
    type: actions.REMOVE_SALE_FAILED,
    error
  }
}

// Remove sale from site.
export const removeSiteSale = (siteId, saleId, token) => {
  return dispatch => {
    dispatch(removeSiteSaleStart())
    // Perform request.
    axios(token).delete(`/site/${+siteId}/sale/${saleId}`)
      .then(() => {
        dispatch(removeSiteSaleSuccess(saleId))
      })
      .catch(error => {
        dispatch(removeSiteSaleFailed(getErrorMessage(error)))
      })
  }
}

// Start generating API key for a site.
export const generateSiteApiKeyStart = () => {
  return {
    type: actions.GENERATE_SITE_API_KEY_START
  }
}

// Successfully generate API key for site.
export const generateSiteApiKeySuccess = apiKey => {
  return {
    type: actions.GENERATE_SITE_API_KEY_SUCCESS,
    apiKey
  }
}

// Generate API key for site, with error.
export const generateSiteApiKeyFailed = error => {
  return {
    type: actions.GENERATE_SITE_API_KEY_FAILED,
    error
  }
}

// Generate API key for site.
export const generateSiteApiKey = (siteId, token) => {
  return dispatch => {
    dispatch(generateSiteApiKeyStart())
    // Perform request.
    axios(token).post(`/site/${+siteId}/api_key`)
      .then(response => {
        dispatch(generateSiteApiKeySuccess(response.data.api_key))
      })
      .catch(error => {
        dispatch(generateSiteApiKeyFailed(getErrorMessage(error)))
      })
  }
}
