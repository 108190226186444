// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import { Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { connect } from 'react-redux'

// Shared.
import { userCan } from '../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class SiteLink extends Component {
  render () {
    // Return null if wrong props.
    if (!this.props.site || !this.props.site.url) return null
    if (!userCan(this.props.user, 'Site', 'read')) {
      return this.props.site.url
    }
    return (
      <Link component={RouterLink} to={`/site/${this.props.site.id}`}>{this.props.site.url}</Link>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

// Export.
export default connect(mapStateToProps)(SiteLink)
