// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { Chip } from '@material-ui/core'

// Shared.
import { ASYNC_ACTION_STATUSES } from '../../../shared/constants'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const AsyncActionStatus = props => {
  switch (props.children) {
    case 'scheduled': return (
      <Chip
        size='small'
        label={ASYNC_ACTION_STATUSES.scheduled}
        variant='default'
        color='primary'
      />
    )
    case 'in_progress': return (
      <Chip
        size='small'
        label={ASYNC_ACTION_STATUSES.inProgress}
        variant='default'
        color='primary'
        style={{ backgroundColor: '#afdbb6' }}
      />
    )
    case 'finished': return (
      <Chip
        size='small'
        label={ASYNC_ACTION_STATUSES.finished}
        variant='default'
        color='primary'
        style={{ backgroundColor: '#4caf50' }}
      />
    )
    case 'failed': return (
      <Chip
        size='small'
        label={ASYNC_ACTION_STATUSES.failed}
        variant='default'
        color='secondary'
      />
    )
    default: return props.children
  }
}

// Export.
export default AsyncActionStatus
