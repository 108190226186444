// ============================================================================
// Dependencies.
// ============================================================================

// Axios instance.
import axios from '../../axios'

// Action types.
import * as actions from './types'

// Shared.
import { buildUrl, getErrorMessage } from '../../shared/functions'
import { API_POSSIBLE_ERRORS } from '../../shared/constants'

// ============================================================================
// Action creators.
// ============================================================================

// Load product start.
export const loadProductStart = () => {
  return {
    type: actions.LOAD_PRODUCT_START
  }
}

// Load product success.
export const loadProductSuccess = product => {
  return {
    type: actions.LOAD_PRODUCT_SUCCESS,
    product
  }
}

// Load product fail.
export const loadProductFailed = error => {
  return {
    type: actions.LOAD_PRODUCT_FAILED,
    error
  }
}

// Load product.
export const loadProduct = (id, token) => {
  return dispatch => {
    dispatch(loadProductStart())
    // Perform request.
    axios(token).get(`/product/${id}`)
      .then(response => {
        dispatch(loadProductSuccess(response.data))
      })
      .catch(error => {
        dispatch(loadProductFailed(getErrorMessage(error)))
      })
  }
}

// Update the loaded product.
export const updateSelectedProduct = (key, value) => {
  return {
    type: actions.UPDATE_SELECTED_PRODUCT,
    key,
    value
  }
}

// Start synchronizing the product.
export const syncProductStart = () => {
  return {
    type: actions.SYNC_PRODUCT_START
  }
}

// Successfully synchronize the product.
export const syncProductSuccess = product => {
  return {
    type: actions.SYNC_PRODUCT_SUCCESS,
    product
  }
}

// Synchronize the product, with error.
export const syncProductFailed = error => {
  return {
    type: actions.SYNC_PRODUCT_FAILED,
    error
  }
}

// Sync the product.
export const syncProduct = (id, token) => {
  return dispatch => {
    dispatch(syncProductStart())
    // Perform request.
    axios(token).post(`/product/${id}/sync`)
      .then(response => {
        dispatch(syncProductSuccess(response.data))
      })
      .catch(() => {
        dispatch(syncProductFailed({
          message: 'No se ha podido sincronizar el producto. Esto puede deberse a varios motivos:',
          details: API_POSSIBLE_ERRORS
        }))
      })
  }
}

// Start searching products.
export const searchProductsStart = () => {
  return {
    type: actions.SEARCH_PRODUCTS_START
  }
}

// Successfully search products.
export const searchProductsSuccess = products => {
  return {
    type: actions.SEARCH_PRODUCTS_SUCCESS,
    products
  }
}

// Search products, with error.
export const searchProductsFailed = error => {
  return {
    type: actions.SEARCH_PRODUCTS_FAILED,
    error
  }
}

// Search products.
export const searchProducts = (token, params = {}) => {
  return dispatch => {
    dispatch(searchProductsStart())
    // Perform request.
    axios(token).get(buildUrl('/products', params))
      .then(response => {
        dispatch(searchProductsSuccess(response.data))
      })
      .catch(error => {
        dispatch(searchProductsFailed(getErrorMessage(error)))
      })
  }
}

// Update the query string.
export const updateProductQueryString = queryString => {
  return {
    type: actions.UPDATE_PRODUCT_QUERY_STRING,
    queryString
  }
}

// Start saving a product.
export const saveProductStart = posTerminal => {
  return {
    type: actions.SAVE_PRODUCT_START,
    posTerminal
  }
}

// Save product success.
export const saveProductSuccess = product => {
  return {
    type: actions.SAVE_PRODUCT_SUCCESS,
    product
  }
}

// Save product fail.
export const saveProductFailed = error => {
  return {
    type: actions.SAVE_PRODUCT_FAILED,
    error
  }
}

// Save a product.
export const saveProduct = (id, token, body = {}) => {
  return dispatch => {
    dispatch(saveProductStart())
    // Perform request.
    body = body || {}
    const postData = {
      stock_synced_to: body.stock_synced_to ? +body.stock_synced_to : null
    }
    axios(token).put(`/product/${id}`, postData)
      .then(response => {
        dispatch(saveProductSuccess(response.data))
      })
      .catch(error => {
        dispatch(saveProductFailed(getErrorMessage(error)))
      })
  }
}
