// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import { React } from 'react'
import { CssBaseline } from '@material-ui/core'

// Components.
import TopMenu from './Menus/TopMenu'
import SideMenu from './Menus/SideMenu/SideMenu'
import Content from './Content/Content'

// Style.
import { useStyles } from '../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const Dashboard = props => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <CssBaseline />
      <TopMenu />
      <SideMenu
        section={props.section}
        subsection={props.subsection}
      />
      <Content
        loggedArtist={props.loggedArtist}
        section={props.section}
        subsection={props.subsection}
        id={props.id}
        extraId1={props.extraId1}
        extraId2={props.extraId2}
        form={props.form}
        list={props.list}
      />
    </div>
  )
}

// Export.
export default Dashboard
