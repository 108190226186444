// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  Grid,
  Paper
} from '@material-ui/core'
import { connect } from 'react-redux'
import Pagination from '@material-ui/lab/Pagination'

// Components.
import SitesTable from './SitesTable'
import Title from '../../../../UI/Brand/Title'
import AlertSuccess from '../../../../UI/Alerts/AlertSuccess'
import AlertError from '../../../../UI/Alerts/AlertError'
import TableFilters from '../../../../UI/Table/TableFilters'
import CreateButton from '../../../../UI/Buttons/CreateButton'
import RefreshButton from '../../../../UI/Buttons/RefreshButton'

// Shared.
import { applyFilter, calculateNumPages } from '../../../../../shared/functions'

// Redux actions.
import * as actions from '../../../../../store/actions/index'

// Styles.
import { useStyles } from '../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class SiteList extends Component {
  state = {
    page: 1,
    orderBy: 'id',
    orderDirection: 'asc',
    filter: {
      url: {
        touched: false,
        operand: 'match',
        value: null,
        key: 'url',
        label: 'URL',
        type: 'text'
      }
    }
  }

  handlePageChange = (e, value) => {
    this.setState({ page: +value })
  }

  onFilterChange = (elemKey, value, forceUntouched = false) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.filter[elemKey]
    }
    // Modify the filter value.
    newElement.value = value
    // Set as touched.
    newElement.touched = !forceUntouched
    // Update the state.
    this.setState({
      page: 1,
      filter: {
        ...this.state.filter,
        [elemKey]: newElement
      }
    })
  }

  handleSort = (fieldName) => event => {
    // Create a copy of the state object.
    const newState = { ...this.state }
    // Set the order field.
    newState.orderBy = fieldName
    // Toggle the order direction.
    newState.orderDirection = this.state.orderBy === fieldName ? (newState.orderDirection === 'asc' ? 'desc' : 'asc') : 'asc'
    // Update the state.
    this.setState(newState)
  }

  componentDidMount () {
    this.props.loadSites(this.props.token)
  }

  render () {
    // Load content dynamically.
    let content = null

    if (this.props.error) {
      // Handle error message.
      content = <AlertError error={this.props.error} />
    } else {
      // Filter results.
      const sites = applyFilter(this.props.sites, this.state.filter, this.state.orderBy, this.state.orderDirection)

      content = (
        <>
          <AlertSuccess message={this.props.success} />
          <TableFilters
            filters={this.state.filter}
            handleChange={this.onFilterChange}
          />
          <SitesTable
            loading={this.props.loading}
            sites={sites}
            page={this.state.page}
            deleteSite={id => this.props.deleteSite(id, this.props.token)}
            orderBy={this.state.orderBy}
            orderDirection={this.state.orderDirection}
            onSort={this.handleSort}
          />
          <Grid container spacing={3} className={this.props.classes.tableTools}>
            <Grid item xs={4}>
              <Pagination
                count={calculateNumPages(sites)}
                page={this.state.page}
                onChange={this.handlePageChange}
              />
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'center' }}>
              {sites.length} resultados
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <RefreshButton
                model='Site'
                onClick={() => this.props.loadSites(this.props.token)}
              />
              <CreateButton
                model='Site'
                link='/site/create'
              />
            </Grid>
          </Grid>
        </>
      )
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={this.props.classes.paper}>
            <div className='SiteList'>
              <Title>Sitios web</Title>
              {content}
            </div>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    loading: state.sites.loading,
    error: state.sites.error,
    sites: state.sites.sites,
    success: state.sites.success,
    selectedSite: state.sites.selectedSite
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadSites: token => dispatch(actions.loadSites(token)),
    deleteSite: (id, token) => dispatch(actions.deleteSite(id, token))
  }
}

// Style component.
const SiteListStyled = props => {
  const classes = useStyles()
  return (
    <SiteList classes={classes} {...props} />
  )
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(SiteListStyled)
