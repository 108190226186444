// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  Grid,
  Paper
} from '@material-ui/core'
import { connect } from 'react-redux'
import { Redirect, withRouter } from 'react-router-dom'
import Pagination from '@material-ui/lab/Pagination'

// Components.
import StockWithdrawalsTable from '../StockWithdrawals/StockWithdrawalsList/StockWithdrawalsTable'
import StockMovementDetailsDialog from './StockMovementDialogs/StockMovementDetailsDialog'
import StockMovementLogsDialog from './StockMovementDialogs/StockMovementLogsDialog'
import StockEntriesTable from '../StockEntries/StockEntriesList/StockEntriesTable'
import Title from '../../../../../UI/Brand/Title'
import AlertSuccess from '../../../../../UI/Alerts/AlertSuccess'
import AlertError from '../../../../../UI/Alerts/AlertError'
import TableFilters from '../../../../../UI/Table/TableFilters'
import CreateButton from '../../../../../UI/Buttons/CreateButton'
import RefreshButton from '../../../../../UI/Buttons/RefreshButton'

// Shared.
import {
  applyFilter,
  calculateNumPages,
  getOptionsArray
} from '../../../../../../shared/functions'
import { ASYNC_ACTION_STATUSES } from '../../../../../../shared/constants'

// Redux actions.
import * as actions from '../../../../../../store/actions'

// Styles.
import { useStyles } from '../../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class StockMovementList extends Component {
  state = {
    page: 1,
    orderBy: 'id',
    orderDirection: 'asc',
    filter: {
      posTerminalId: {
        touched: false,
        operand: 'equals',
        value: '',
        key: 'pos_terminal_id',
        label: 'TPV',
        type: 'select',
        options: []
      },
      status: {
        touched: false,
        operand: 'equals',
        value: '',
        key: 'status',
        label: 'Estado',
        type: 'select',
        options: getOptionsArray(ASYNC_ACTION_STATUSES)
      }
    },
    stockMovementDetailsId: null
  }

  handlePageChange = (e, value) => {
    this.setState({ page: +value })
  }

  onFilterChange = (elemKey, value, forceUntouched = false) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.filter[elemKey]
    }
    // Modify the filter value.
    newElement.value = value
    // Set as touched.
    newElement.touched = !forceUntouched
    // Update the state.
    this.setState({
      page: 1,
      filter: {
        ...this.state.filter,
        [elemKey]: newElement
      }
    })
  }

  handleSort = (fieldName) => () => {
    // Create a copy of the state object.
    const newState = { ...this.state }
    // Set the order field.
    newState.orderBy = fieldName
    // Toggle the order direction.
    newState.orderDirection = this.state.orderBy === fieldName ? (newState.orderDirection === 'asc' ? 'desc' : 'asc') : 'asc'
    // Update the state.
    this.setState(newState)
  }

  componentDidMount () {
    switch (this.props.type) {
      case 'out':
        this.props.loadStockWithdrawals(this.props.token)
        break
      case 'in':
        this.props.loadStockEntries(this.props.token)
        break
      default:
    }
  }

  showStockMovementDetails = id => {
    this.setState({ stockMovementDetailsId: id })
  }

  handleCloseStockMovementDetails = () => {
    this.setState({ stockMovementDetailsId: null })
  }

  showStockMovementLogs = id => {
    this.setState({ stockMovementLogsId: id })
  }

  handleCloseStockMovementLogs = () => {
    this.setState({ stockMovementLogsId: null })
  }

  render () {
    if (this.props.redirectTo) {
      return <Redirect to={this.props.redirectTo} />
    }

    // Load content dynamically.
    let content = null
    let detailsDialog = null
    let logsDialog = null

    if (this.props.stockMovements) {
      // Filter results.
      const stockMovements = applyFilter(this.props.stockMovements, this.state.filter, this.state.orderBy, this.state.orderDirection)

      const posTerminalFilterOptions = [
        { label: 'Todos', value: '' },
        ...this.props.stockMovements.filter(m => !!m.pos_terminal).map(d => {
          return { value: d.pos_terminal.id, label: `Nº ${d.pos_terminal.name}` }
        })
      ]

      let table = null
      const tableProps = {
        token: this.props.token,
        stockMovements: stockMovements,
        page: this.state.page,
        loading: this.props.loading,
        deleteStockMovement: id => this.props.deleteStockMovement(id, this.props.token),
        createStockMovement: (id, body) => this.props.createStockMovement(id, this.props.token, body),
        handleOpenStockMovementDetails: this.showStockMovementDetails,
        handleOpenStockMovementLogs: this.showStockMovementLogs,
        orderBy: this.state.orderBy,
        orderDirection: this.state.orderDirection,
        onSort: this.handleSort
      }
      switch (this.props.type) {
        case 'in':
          table = <StockEntriesTable {...tableProps} />
          break
        case 'out':
          table = <StockWithdrawalsTable {...tableProps} handleCreateStockEntryFromWithdrawal={id => this.props.createStockEntryFromWithdrawal(id, this.props.token)} />
          break
        default:
      }

      content = (
        <>
          <AlertSuccess message={this.props.success} />
          <AlertError error={this.props.error} />
          <TableFilters
            filters={this.state.filter}
            deferredData={{ posTerminalId: posTerminalFilterOptions }}
            handleChange={this.onFilterChange}
          />
          {table}
          <Grid container spacing={3} className={this.props.classes.tableTools}>
            <Grid item xs={4}>
              <Pagination
                count={calculateNumPages(stockMovements)}
                page={this.state.page}
                onChange={this.handlePageChange}
              />
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'center' }}>
              {stockMovements.length} resultados
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <RefreshButton
                model='StockMovement'
                onClick={() => this.props.type === 'in' ? this.props.loadStockEntries(this.props.token) : this.props.loadStockWithdrawals(this.props.token)}
              />
              <CreateButton
                model='StockMovement'
                link={`/warehouse/stock_${this.props.type === 'in' ? 'entries' : 'withdrawals'}/create`}
              />
            </Grid>
          </Grid>
        </>
      )

      if (this.state.stockMovementDetailsId) {
        detailsDialog = <StockMovementDetailsDialog id={this.state.stockMovementDetailsId} onDialogClose={this.handleCloseStockMovementDetails} />
      }

      if (this.state.stockMovementLogsId) {
        logsDialog = <StockMovementLogsDialog stockMovement={stockMovements.find(m => +m.id === +this.state.stockMovementLogsId)} onDialogClose={this.handleCloseStockMovementLogs} />
      }
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={this.props.classes.paper}>
            <div className='StockMovementList'>
              <Title>{this.props.type === 'out' ? 'Salidas' : 'Entradas'} de stock</Title>
              {content}
              {detailsDialog}
              {logsDialog}
            </div>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    loading: state.stockMovements.loading,
    error: state.stockMovements.error,
    stockMovements: state.stockMovements.stockMovements,
    success: state.stockMovements.success,
    selectedStockMovement: state.stockMovements.selectedStockMovement,
    redirectTo: state.stockMovements.redirectTo
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadStockEntries: token => dispatch(actions.loadStockMovements(token, 'in')),
    loadStockWithdrawals: token => dispatch(actions.loadStockMovements(token, 'out')),
    deleteStockMovement: (id, token) => dispatch(actions.deleteStockMovement(id, token)),
    createStockMovement: (id, token, body) => dispatch(actions.createStockMovement(id, token, body)),
    createStockEntryFromWithdrawal: (id, token) => dispatch(actions.createStockEntryFromWithdrawal(id, token))
  }
}

// Style component.
const StockMovementListStyled = props => {
  const classes = useStyles()
  return (
    <StockMovementList classes={classes} {...props} />
  )
}

// Export.
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StockMovementListStyled))
