// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import * as _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  Grid,
  LinearProgress,
  Typography,
  Paper
} from '@material-ui/core'

// Components.
import Title from '../../../../UI/Brand/Title'
import FormField from '../../../../UI/Form/FormField'
import AlertSuccess from '../../../../UI/Alerts/AlertSuccess'
import AlertError from '../../../../UI/Alerts/AlertError'
import SaveButton from '../../../../UI/Buttons/SaveButton'
import GoBackButton from '../../../../UI/Buttons/GoBackButton'
import UserImage from '../../../../UI/Image/UserImage'
import ArtistLink from '../../../../UI/Links/ArtistLink'

// Redux actions.
import * as actions from '../../../../../store/actions'

// Shared.
import { validateEmail } from '../../../../../shared/functions'

// Styles.
import { useStyles } from '../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class MyAccountForm extends Component {
  componentDidMount () {
    // Load the user.
    this.props.loadLoggedUser(this.props.token)
  }

  state = {
    fields: {
      email: {
        fullWidth: true,
        label: 'Email del usuario',
        error: null,
        type: 'text',
        required: true,
        touched: false,
        validation: validateEmail,
        errMsg: 'Por favor, escribe un email válido.',
        autoFocus: true
      },
      firstname: {
        fullWidth: true,
        label: 'Nombre del usuario',
        error: null,
        type: 'text',
        required: true,
        touched: false,
        validation: val => val.length >= 2,
        errMsg: 'Por favor, escribe un nombre de al menos dos caracteres.',
        autoFocus: false
      },
      image: {
        fullWidth: true,
        label: 'Selecciona un archivo',
        error: null,
        type: 'file',
        value: '',
        accept: ['image/png', 'image/gif', 'image/jpeg'],
        required: false,
        touched: false,
        errMsg: 'Por favor, selecciona una imagen de tipo PNG, GIF o JPEG.',
        autoFocus: false
      },
      password: {
        label: 'Contraseña',
        error: null,
        type: 'password',
        required: false,
        touched: false,
        validation: val => val.length >= 2,
        errMsg: 'Por favor, escribe una contraseña de al menos dos caracteres.',
        autoFocus: false,
        helperText: 'Tu nueva contraseña, si deseas restablecerla.'
      },
      repassword: {
        label: 'Confirma tu contraseña',
        error: null,
        type: 'password',
        required: false,
        touched: false,
        validation: (val, fields) => val === fields.password,
        errMsg: 'Las contarseñas no coinciden.',
        autoFocus: false
      }
    }
  }

  handleChange = (elemKey, newValue) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields[elemKey]
    }
    // Take care of the value change, if the field is a checkbox.
    if (this.state.fields[elemKey].type === 'checkbox') newValue = this.props.selectedUser ? !this.props.selectedUser[elemKey] : true
    // Modify the element value.
    this.props.updateSelectedUser(elemKey, newValue)
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.required && !(newValue + '').length && newElement.touched) {
      newElement.error = 'Campo obligatorio'
    } else if (newElement.validation && newValue.length && !newElement.validation(newValue, this.props.selectedUser)) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Update the state.
    this.setState({
      fields: {
        ...this.state.fields,
        [elemKey]: newElement
      }
    })
  }

  handleImageUpload = file => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields.image
    }
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.accept && newElement.accept.length && newElement.accept.indexOf(file.type) === -1) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Modify the element value.
    if (!newElement.error) {
      this.props.uploadLoggedUserImage(this.props.token, file)
    }
    // Update the state.
    this.setState({
      fields: {
        ...this.state.fields,
        image: newElement
      }
    })
  }

  shouldSaveButtonBeDisabled = () => {
    if (this.props.formLoading) return true
    if (!this.props.selectedUser) return true
    for (const fieldName in this.state.fields) {
      if (Object.prototype.hasOwnProperty.call(this.state.fields, fieldName)) {
        const field = this.state.fields[fieldName]
        const value = this.props.selectedUser[fieldName] || ''
        if (field.required && !(value + '').length && field.touched) {
          return true
        } else if (field.validation && (value + '').length && !field.validation(value, this.props.selectedUser)) {
          return true
        } else if (fieldName === 'repassword' && !value.length && this.props.selectedUser.password && this.props.selectedUser.password.length) {
          return true
        }
      }
    }
    return false
  }

  handleSaveLoggedUser = () => {
    // Save the user.
    this.props.saveLoggedUser(this.props.token, this.props.selectedUser)
    // Update the logged user object.
    const newUserFields = {}
    for (const fieldName in this.state.fields) {
      if (Object.prototype.hasOwnProperty.call(this.state.fields, fieldName)) {
        const field = this.state.fields[fieldName]
        if (field.type === 'file') continue
        newUserFields[fieldName] = this.props.selectedUser[fieldName]
      }
    }
    this.props.updateLoggedUserFields(newUserFields)
  }

  render () {
    let content = null

    // Handle loading state.
    if (this.props.loading || this.props.formLoading) {
      // Show loader.
      content = <LinearProgress />
    } else {
      content = (
        <Grid container spacing={3} style={{ marginBottom: 20 }}>
          <Grid item xs={12} md={3} lg={2}>
            <UserImage
              user={this.props.selectedUser}
              token={this.props.token}
              loading={this.props.imageLoading}
            />
            <div style={{ marginTop: 15, textAlign: 'center' }}>
              <FormField
                fullWidth={this.state.fields.image.fullWidth}
                width={this.state.fields.image.width}
                key='image'
                name='image'
                type={this.state.fields.image.type}
                autoFocus={this.state.fields.image.autoFocus}
                required={this.state.fields.image.required}
                error={this.state.fields.image.error}
                label={this.state.fields.image.label}
                value={this.props.selectedUser && this.props.selectedUser.image !== null && typeof this.props.selectedUser.image !== 'undefined' ? this.props.selectedUser.image : ''}
                handleChange={e => this.handleImageUpload(e.target.files[0])}
                options={this.state.fields.image.options}
                startAdornment={this.state.fields.image.startAdornment}
                helperText={this.state.fields.image.helperText}
                accept={this.state.fields.image.accept}
                disabled={this.props.imageLoading}
              />
            </div>
          </Grid>
          <Grid item xs={12} md={9} lg={10}>
            {
              this.props.user && this.props.user.artist_id
                ? (
                  <Typography variant='h6' component='h2' className={this.props.classes.mb2}>
                    Artista: <ArtistLink artist={this.props.user.artist} />
                  </Typography>
                  )
                : null
            }
            <form className={this.props.classes.rootWrap} noValidate autoComplete='off' style={{ marginBottom: 30 }}>
              {
                _.keys(this.state.fields).filter(k => k !== 'image').map(fieldName => {
                  const field = this.state.fields[fieldName]

                  // Get value.
                  const value = this.props.selectedUser && this.props.selectedUser[fieldName] !== null && typeof this.props.selectedUser[fieldName] !== 'undefined' ? this.props.selectedUser[fieldName] : ''
                  const disabled = field.disabled

                  return (
                    <FormField
                      fullWidth={field.fullWidth}
                      width={field.width}
                      key={fieldName}
                      name={fieldName}
                      type={field.type}
                      autoFocus={field.autoFocus}
                      required={field.required}
                      error={field.error}
                      label={field.label}
                      disabled={disabled}
                      value={value}
                      handleChange={e => this.handleChange(fieldName, e.target.value)}
                      options={field.options}
                      startAdornment={field.startAdornment}
                      helperText={field.helperText}
                      accept={field.accept}
                    />
                  )
                })
              }
            </form>
          </Grid>
        </Grid>
      )
    }

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={this.props.classes.paper}>
            <div className='MyAccountForm'>
              <Title>Mi cuenta</Title>
              <AlertError error={this.props.error} />
              <AlertSuccess message={this.props.success} />
              {content}
              <div className={this.props.classes.mt2}>
                <GoBackButton link='/users' />
                <SaveButton
                  disabled={this.shouldSaveButtonBeDisabled()}
                  onClick={this.handleSaveLoggedUser}
                />
              </div>
            </div>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    user: state.auth.user,
    token: state.auth.token,
    loading: state.users.loading,
    formLoading: state.users.formLoading,
    error: state.users.error,
    users: state.users.users,
    profiles: state.users.profiles,
    success: state.users.success,
    selectedUser: state.users.selectedUser,
    imageLoading: state.users.imageLoading
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadLoggedUser: (token) => dispatch(actions.loadLoggedUser(token)),
    saveLoggedUser: (token, body) => dispatch(actions.saveLoggedUser(token, body)),
    updateSelectedUser: (key, value) => dispatch(actions.updateSelectedUser(key, value)),
    updateLoggedUserFields: (user) => dispatch(actions.updateLoggedUserFields(user)),
    uploadLoggedUserImage: (token, file) => dispatch(actions.uploadLoggedUserImage(token, file))
  }
}

// Style component.
const MyAccountFormStyled = props => {
  const classes = useStyles()
  return (
    <MyAccountForm classes={classes} {...props} />
  )
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(MyAccountFormStyled)
