// ============================================================================
// Dependencies.
// ============================================================================

// Actions.
import * as actions from '../actions/types'

// Shared.
import { updateObject } from '../../shared/functions'

// ============================================================================
// Initial state.
// ============================================================================

// Initial state.
const initialState = {
  loading: false,
  error: null,
  success: null,
  arrangementDocuments: [],
  selectedArrangementDocument: null
}

// ============================================================================
// Functionality.
// ============================================================================

// Start loading list of expense types.
const loadArrangementDocumentsStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    success: null,
    arrangementDocuments: []
  })
}

// Finish loading list of expense types.
const loadArrangementDocumentsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    arrangementDocuments: action.arrangementDocuments,
    error: null,
    success: null
  })
}

// Finish loading list of expense types, with error.
const loadArrangementDocumentsFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    arrangementDocuments: [],
    error: action.error,
    success: null
  })
}

// Start deleting expense type.
const deleteArrangementDocumentStart = state => {
  return updateObject(state, {
    loading: true,
    success: null,
    error: null
  })
}

// Delete an expense type successfully.
const deleteArrangementDocumentSuccess = (state, action) => {
  const oldArrangementDocumentIdx = state.arrangementDocuments.findIndex(e => +e.id === +action.id)
  const newArrangementDocuments = [...state.arrangementDocuments]
  newArrangementDocuments.splice(oldArrangementDocumentIdx, 1)
  return updateObject(state, {
    loading: false,
    arrangementDocuments: newArrangementDocuments,
    error: null,
    success: `Se ha eliminado el documento #${action.id} correctamente.`
  })
}

// Delete expense type, with error.
const deleteArrangementDocumentFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    success: null
  })
}

// Start saving an expense type.
const saveArrangementDocumentStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    success: null
  })
}

// Save an expense type successfully.
const saveArrangementDocumentSuccess = (state, action) => {
  const newArrangementDocuments = [
    ...state.arrangementDocuments
  ]
  if (action.id) {
    const updatedArrangementDocumentIdx = state.arrangementDocuments.findIndex(s => +s.id === +action.id)
    newArrangementDocuments[updatedArrangementDocumentIdx] = { ...action.arrangementDocument }
  } else {
    newArrangementDocuments.push(action.arrangementDocument)
  }
  return updateObject(state, {
    loading: false,
    error: null,
    success: action.id ? 'Documento actualizado correctamente.' : 'Documento creado correctamente.',
    arrangementDocuments: newArrangementDocuments,
    selectedArrangementDocument: action.arrangementDocument
  })
}

// Save an expense type, with error.
const saveArrangementDocumentFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    success: null
  })
}

// ============================================================================
// Reducer definition.
// ============================================================================

// Reducer definition.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_ARRANGEMENT_DOCUMENTS_START:
      return loadArrangementDocumentsStart(state, action)
    case actions.LOAD_ARRANGEMENT_DOCUMENTS_SUCCESS:
      return loadArrangementDocumentsSuccess(state, action)
    case actions.LOAD_ARRANGEMENT_DOCUMENTS_FAILED:
      return loadArrangementDocumentsFailed(state, action)
    case actions.DELETE_ARRANGEMENT_DOCUMENT_START:
      return deleteArrangementDocumentStart(state, action)
    case actions.DELETE_ARRANGEMENT_DOCUMENT_SUCCESS:
      return deleteArrangementDocumentSuccess(state, action)
    case actions.DELETE_ARRANGEMENT_DOCUMENT_FAILED:
      return deleteArrangementDocumentFailed(state, action)
    case actions.SAVE_ARRANGEMENT_DOCUMENT_START:
      return saveArrangementDocumentStart(state, action)
    case actions.SAVE_ARRANGEMENT_DOCUMENT_SUCCESS:
      return saveArrangementDocumentSuccess(state, action)
    case actions.SAVE_ARRANGEMENT_DOCUMENT_FAILED:
      return saveArrangementDocumentFailed(state, action)
    default:
      return state
  }
}

export default reducer
