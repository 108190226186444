// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import { Alert, AlertTitle } from '@material-ui/lab'

// Style.
import { useStyles } from '../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const AlertSuccess = props => {
  const classes = useStyles()
  if (!props.message) {
    return null
  }
  const title = props.title ? props.title : 'Acción realizada con éxito'
  return (
    <Alert severity='success' className={classes.alert}>
      <AlertTitle>{title}</AlertTitle>
      {props.message}
      {props.children}
    </Alert>
  )
}

// Export.
export default AlertSuccess
