// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import { Redirect, withRouter } from 'react-router-dom'
import { connect } from 'react-redux'

// Components.
import Dashboard from './Dashboard/Dashboard'

// Redux actions.
import * as actions from '../../store/actions/index'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class TumerchanCrm extends Component {
  // Class method, inherited from Component.
  render () {
    // Check authentication state.
    if (!this.props.isAuth) {
      // Change the redirection path.
      if (!this.props.resetRedirectPath) {
        this.props.setAuthRedirectPath(window.location.pathname)
      }
      // Redirect to /login.
      return <Redirect to='/login' />
    }

    return (
      <div className='TumerchanCrm'>
        <Dashboard
          loggedArtist={this.props.user && this.props.user.artist_id}
          section={this.props.section}
          form={this.props.form}
          list={this.props.list}
          subsection={this.props.subsection}
          id={this.props.match && this.props.match.params && this.props.match.params.id ? this.props.match.params.id : null}
          extraId1={this.props.extraParam1 && this.props.match && this.props.match.params && this.props.match.params[this.props.extraParam1] ? this.props.match.params[this.props.extraParam1] : null}
          extraId2={this.props.extraParam2 && this.props.match && this.props.match.params && this.props.match.params[this.props.extraParam2] ? this.props.match.params[this.props.extraParam2] : null}
        />
      </div>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    loading: state.auth.loading,
    isAuth: state.auth.user !== null,
    user: state.auth.user,
    resetRedirectPath: state.auth.resetRedirectPath
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    setAuthRedirectPath: path => dispatch(actions.setAuthRedirectPath(path))
  }
}

// Export.
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(TumerchanCrm))
