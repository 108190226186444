// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  LinearProgress
} from '@material-ui/core'

// Components.
import TableColumnTitle from '../Table/TableColumnTitle'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const TableLoader = props => {
  return (
    <div className='TableLoader'>
      <Table size={props.size || 'small'}>
        <TableHead>
          <TableRow>
            {
              props.cols.map((col, key) => <TableColumnTitle key={key} {...col} />)
            }
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {
              props.cols.map((col, key) => <TableCell key={key}>&nbsp;</TableCell>)
            }
          </TableRow>
        </TableBody>
      </Table>
      <LinearProgress />
    </div>
  )
}

// Export.
export default TableLoader
