// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  DialogTitle,
  DialogContent,
  DialogActions,
  Dialog
} from '@material-ui/core'
import { connect } from 'react-redux'

// Components.
import NumberOfStuff from '../../../../../../UI/Custom/NumberOfStuff'
import AlertInfo from '../../../../../../UI/Alerts/AlertInfo'
import AlertError from '../../../../../../UI/Alerts/AlertError'
import SiteLink from '../../../../../../UI/Links/SiteLink'
import ProductLink from '../../../../../../UI/Links/ProductLink'
import AsyncActionStatus from '../../../../../../UI/Custom/AsyncActionStatus'

// Redux actions.
import * as actions from '../../../../../../../store/actions'

// Shared.
import { prettyPrintDate, printPrice } from '../../../../../../../shared/functions'

// Styles.
import { useStyles } from '../../../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class StockMovementDetailsDialog extends Component {
  componentDidMount () {
    this.props.loadStockMovement(this.props.id, this.props.token)
  }

  render () {
    return (
      <Dialog
        maxWidth='lg'
        open={!!(this.props.loading || this.props.selectedStockMovement)}
        onClose={this.props.onDialogClose}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
      >
        <DialogTitle id='alert-dialog-title'>
          {
            this.props.selectedStockMovement
              ? (
                <>
                  <span style={{ marginRight: 10 }}>ID #{this.props.selectedStockMovement.id} - {this.props.selectedStockMovement.location}, {prettyPrintDate(this.props.selectedStockMovement.created_at)}</span>
                </>
                )
              : null
          }
        </DialogTitle>
        <DialogContent>
          {
            !this.props.loading && !this.props.selectedStockMovement
              ? null
              : this.props.loading
                ? <LinearProgress />
                : this.props.error
                  ? <AlertError error={this.props.error} />
                  : this.props.selectedStockMovement.details && this.props.selectedStockMovement.details.length
                    ? (
                      <Table size='small'>
                        <TableHead>
                          <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Sitio web</TableCell>
                            <TableCell>Producto</TableCell>
                            <TableCell>Variación de producto</TableCell>
                            <TableCell>Estado</TableCell>
                            <TableCell>Cantidad</TableCell>
                            {this.props.type === 'in' ? null : <TableCell>Precio en web</TableCell>}
                            {this.props.type === 'in' ? null : <TableCell>Precio en el evento</TableCell>}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                        this.props.selectedStockMovement.details.map((d, key) => {
                          return (
                            <TableRow key={key}>
                              <TableCell>{d.id}</TableCell>
                              <TableCell><SiteLink site={d.product.category.site} /></TableCell>
                              <TableCell><ProductLink product={d.product} site_id={d.product.category.site_id} /></TableCell>
                              <TableCell>{d.product_variation ? d.product_variation.name : '-'}</TableCell>
                              <TableCell><AsyncActionStatus>{d.finished ? 'finished' : 'scheduled'}</AsyncActionStatus></TableCell>
                              <TableCell><NumberOfStuff>{d.stock_quantity}</NumberOfStuff></TableCell>
                              {this.props.type === 'in' ? null : <TableCell>{printPrice(d.price_original)}</TableCell>}
                              {this.props.type === 'in' ? null : <TableCell>{printPrice(d.price_new)}</TableCell>}
                            </TableRow>
                          )
                        })
                      }
                        </TableBody>
                      </Table>
                      )
                    : <AlertInfo>No se han encontrado resultados.</AlertInfo>
          }
        </DialogContent>
        <DialogActions>
          <Button color='default' variant='outlined' onClick={this.props.onDialogClose}>
            Cerrar
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    loading: state.stockMovements.detailsLoading,
    error: state.stockMovements.error,
    success: state.stockMovements.success,
    sites: state.sites.sites,
    selectedSite: state.sites.selectedSite,
    selectedCategory: state.categories.selectedCategory,
    selectedStockMovement: state.stockMovements.selectedStockMovement
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadStockMovement: (id, token) => dispatch(actions.loadStockMovement(id, token))
  }
}

// Style component.
const StockMovementDetailsDialogStyled = props => {
  const classes = useStyles()
  return (
    <StockMovementDetailsDialog classes={classes} {...props} />
  )
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(StockMovementDetailsDialogStyled)
