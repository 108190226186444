// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import axios from 'axios'

// =============================================================================
// Instance.
// =============================================================================

// Build instance with token.
const getInstance = token => {
  // Init options.
  const options = {
    baseURL: process.env.REACT_APP_API_URL
  }
  // If token, also set header.
  if (token) {
    options.headers = {
      'x-access-token': token
    }
  }
  // Return axios instance.
  return axios.create(options)
}

// Export.
export default getInstance
