// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
  Grid,
  Button
} from '@material-ui/core'
import AddIcon from '@material-ui/icons/Add'

// Components.
import StockMovementsFormDataSource from './StockMovementsFormDataSource'

// Style.
import { useStyles } from '../../../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const StockMovementsFormDataSources = props => {
  const classes = useStyles()
  return (
    <Grid container>
      <Grid item xs={12}>
        <h3 className={classes.formSection}>Fuentes de datos</h3>
        {
          props.dataSources.map((dataSource, key) => (
            <StockMovementsFormDataSource
              key={key}
              idx={key}
              categoryId={dataSource.category_id}
              artistId={dataSource.artist_id}
            />
          ))
        }
      </Grid>
      <Grid item xs={12} align='center'>
        <Button
          color='primary'
          disabled={!props.dataSources[props.dataSources.length - 1].category_id}
          onClick={() => props.addStockMovementDataSource(null, null)}
          startIcon={<AddIcon />}
        >
          Añadir otra fuente de datos
        </Button>
      </Grid>
    </Grid>
  )
}

// Export.
export default StockMovementsFormDataSources
