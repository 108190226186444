// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  IconButton
} from '@material-ui/core'
import ClearIcon from '@material-ui/icons/Clear'
import { connect } from 'react-redux'

// Redux actions.
import * as actions from '../../../../../../store/actions'

// Components.
import CategoryLink from '../../../../../UI/Links/CategoryLink'
import AlertInfo from '../../../../../UI/Alerts/AlertInfo'
import SiteLink from '../../../../../UI/Links/SiteLink'
import RowActions from '../../../../../UI/Table/RowActions'
import FormField from '../../../../../UI/Form/FormField'
import SaveButton from '../../../../../UI/Buttons/SaveButton'
import TableColumnTitle from '../../../../../UI/Table/TableColumnTitle'
import TableLoader from '../../../../../UI/Loaders/TableLoader'

// Shared.
import { RESULTS_PER_PAGE } from '../../../../../../shared/constants'
import { prettyPrintDateTime } from '../../../../../../shared/functions'

// Style.
import { useStyles } from '../../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Table columns.
const columns = [
  { name: 'id', label: 'ID' },
  { name: 'site_id', label: 'Sitio web' },
  { name: 'category_id', label: 'Categorías' },
  { name: 'created_at', label: 'Fecha de creación (UTC)' },
  { name: 'updated_at', label: 'Fecha de modificación (UTC)' },
  { name: 'actions', label: '' }
]

// Stateful component declaration.
class DataSourcesTable extends Component {
  componentDidMount () {
    this.props.loadSites(this.props.token)
  }

  state = {
    fields: {
      site_id: {
        label: 'Sitio web',
        error: null,
        value: '',
        type: 'select',
        required: true,
        touched: false,
        errMsg: 'Por favor, seleciona un sitio web de la lista.',
        autoFocus: true,
        options: []
      },
      category_id: {
        label: 'Categoría',
        error: null,
        value: '',
        type: 'select',
        required: true,
        touched: false,
        errMsg: 'Por favor, seleciona una categoría de la lista.',
        autoFocus: false,
        options: []
      }
    }
  }

  isCategoryAlreadySelected = categoryId => {
    for (let i = 0; i < this.props.dataSources.length; i++) {
      const dataSource = this.props.dataSources[i]
      if (+dataSource.category.id === +categoryId) return true
    }
    return false
  }

  handleChange = (elemKey, newValue) => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields[elemKey]
    }
    // Modify the element value.
    newElement.value = +newValue
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.required && !newValue.length && newElement.touched) {
      newElement.error = 'Campo obligatorio'
    } else if (newElement.validation && newValue.length && !newElement.validation(newValue)) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Build new state.
    const newState = {
      fields: {
        ...this.state.fields,
        [elemKey]: newElement
      }
    }
    // Load the site.
    if (elemKey === 'site_id') {
      newState.fields.category_id.value = ''
      this.props.loadSite(newElement.value, this.props.token)
    }
    // Update the state.
    this.setState(newState)
  }

  handleSaveDataSource = () => {
    // Save the data source.
    this.props.saveDataSource(this.props.token, this.props.artistId, this.state.fields.category_id.value)
    // Reset the form.
    const newStateFields = {
      ...this.state.fields
    }
    newStateFields.site_id.value = ''
    newStateFields.category_id.value = ''
    this.setState({
      fields: newStateFields
    })
    // Close the form.
    this.props.handleFormClose()
  }

  render () {
    if (this.props.loading) {
      return <TableLoader cols={columns} />
    } else if ((!this.props.dataSources || !this.props.dataSources.length) && !this.props.creating) {
      return (
        <AlertInfo>
          Aún no se han configurado fuentes de datos para este artista.
        </AlertInfo>
      )
    }

    const artistDataSources = []
    const minLimit = (this.props.page - 1) * RESULTS_PER_PAGE
    const maxLimit = Math.min(this.props.dataSources.length, this.props.page * RESULTS_PER_PAGE)

    for (let i = minLimit; i < maxLimit; i++) {
      const dataSource = this.props.dataSources[i]

      artistDataSources.push(
        <TableRow key={dataSource.id}>
          <TableCell>
            {dataSource.id}
          </TableCell>
          <TableCell>
            <SiteLink site={dataSource.category.site} />
          </TableCell>
          <TableCell>
            <CategoryLink category={dataSource.category} />
          </TableCell>
          <TableCell>
            {prettyPrintDateTime(dataSource.created_at)}
          </TableCell>
          <TableCell>
            {prettyPrintDateTime(dataSource.updated_at)}
          </TableCell>
          <TableCell>
            <RowActions
              model='DataSource'
              id={dataSource.id}
              handleDelete={() => this.props.deleteDataSource(dataSource.id)}
            />
          </TableCell>
        </TableRow>
      )
    }

    if (this.props.creating) {
      const siteOptions = [
        { label: '-', value: '' },
        ...this.props.sites.map(s => {
          return { value: s.id, label: s.url }
        })
      ]

      const siteCategories = this.props.selectedSite && this.props.selectedSite.categories
        ? [
            { label: '-', value: '' },
            ...this.props.selectedSite.categories.map(c => {
              return { value: c.id, label: c.name, disabled: this.isCategoryAlreadySelected(c.id) }
            })
          ]
        : null

      artistDataSources.push(
        <TableRow key='new'>
          <TableCell className={this.props.classes.tableCellForm}>
            <IconButton color='default' aria-label='undo' size='small' onClick={this.props.handleFormClose}>
              <ClearIcon />
            </IconButton>
          </TableCell>
          <TableCell className={this.props.classes.tableCellForm} style={{ width: 240 }}>
            <FormField
              noMargin
              fullWidth
              name='site_id'
              type={this.state.fields.site_id.type}
              autoFocus={this.state.fields.site_id.autofocus}
              required={this.state.fields.site_id.required}
              label={this.state.fields.site_id.label}
              value={this.state.fields.site_id.value}
              handleChange={e => this.handleChange('site_id', e.target.value)}
              disabled={this.props.sitesLoading}
              options={siteOptions}
            />
          </TableCell>
          <TableCell className={this.props.classes.tableCellForm} style={{ width: 240 }}>
            <FormField
              noMargin
              fullWidth
              name='category_id'
              type={this.state.fields.category_id.type}
              autoFocus={this.state.fields.category_id.autofocus}
              required={this.state.fields.category_id.required}
              label={this.state.fields.category_id.label}
              value={this.state.fields.category_id.value}
              handleChange={e => this.handleChange('category_id', e.target.value)}
              disabled={this.props.sitesLoading || !this.props.selectedSite || !this.props.selectedSite.categories || !this.state.fields.site_id.value || this.state.fields.site_id.value === ''}
              options={this.props.selectedSite && this.props.selectedSite.categories && this.state.fields.site_id.value && this.state.fields.site_id.value !== '' ? siteCategories : []}
            />
          </TableCell>
          <TableCell className={this.props.classes.tableCellForm} />
          <TableCell className={this.props.classes.tableCellForm} />
          <TableCell className={this.props.classes.tableCellForm}>
            <SaveButton
              model='DataSource'
              action='create'
              disabled={this.props.sitesLoading || !this.props.selectedSite || !this.props.selectedSite.categories || !this.state.fields.site_id.value || !this.state.fields.category_id.value || this.state.fields.site_id.value === '' || this.state.fields.category_id.value === ''}
              onClick={this.handleSaveDataSource}
            />
          </TableCell>
        </TableRow>
      )
    }

    return (
      <Table size='small'>
        <TableHead>
          <TableRow>
            {
              columns.map((col, key) => <TableColumnTitle key={key} {...col} />)
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {artistDataSources}
        </TableBody>
      </Table>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    sites: state.sites.sites,
    sitesLoading: state.sites.loading,
    selectedSite: state.sites.selectedSite
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    loadSites: (token) => dispatch(actions.loadSites(token)),
    loadSite: (id, token) => dispatch(actions.loadSite(id, token)),
    saveDataSource: (token, artistId, categoryId) => dispatch(actions.saveDataSource(token, artistId, categoryId))
  }
}

// Style component.
const ArtistDataSourcesStyled = props => {
  const classes = useStyles()
  return <DataSourcesTable classes={classes} {...props} />
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(ArtistDataSourcesStyled)
