// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import {
  Grid
} from '@material-ui/core'
import React from 'react'

// Components.
import ArrangementsList from './ArrangementsList'
import ArrangementDocumentsList from './ArrangementDocuments/ArrangementDocumentsList'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const Arrangements = props => {
  return (
    <Grid container spacing={2} className='Arrangements'>
      <ArrangementsList artistId={props.artistId} />
      <ArrangementDocumentsList artistId={props.artistId} />
    </Grid>
  )
}

// Export.
export default Arrangements
