// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Tooltip
} from '@material-ui/core'
import SyncIcon from '@material-ui/icons/Sync'
import clsx from 'clsx'

// Style.
import { useStyles } from '../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const SiteApiKeyButton = props => {
  const classes = useStyles()
  return (
    <FormControl fullWidth className={clsx(classes.mt3, classes.mb)} variant='outlined'>
      <InputLabel htmlFor='site-form-api_key'>Clave de API</InputLabel>
      <OutlinedInput
        id='site-form-api_key'
        type='text'
        style={!props.apiKey ? { color: '#aaa' } : null}
        value={props.apiKey || 'Aún no se ha generado una clave de API para este sitio'}
        readOnly
        autoFocus={props.apiKey && /^TM-/.test(props.apiKey) && !/\*/.test(props.apiKey)}
        onFocus={e => { e.target.select() }}
        endAdornment={
          <InputAdornment position='end'>
            <Tooltip title={`${props.apiKey ? 'Regenerar' : 'Generar'} clave de API`} arrow placement='top'>
              <IconButton
                aria-label='generate API key'
                onClick={props.handleGenerateSiteApiKey}
                edge='end'
              >
                <SyncIcon />
              </IconButton>
            </Tooltip>
          </InputAdornment>
        }
        labelWidth={94}
      />
    </FormControl>
  )
}

// Export.
export default SiteApiKeyButton
