// ============================================================================
// Dependencies.
// ============================================================================

// Axios instance.
import axios from '../../axios'

// Action types.
import * as actions from './types'

// Functions.
import { getErrorMessage } from '../../shared/functions'

// ============================================================================
// Action creators.
// ============================================================================

// Load settings start.
export const loadSettingsStart = () => {
  return {
    type: actions.LOAD_SETTINGS_START
  }
}

// Load settings success.
export const loadSettingsSuccess = settings => {
  return {
    type: actions.LOAD_SETTINGS_SUCCESS,
    settings
  }
}

// Load settings fail.
export const loadSettingsFailed = error => {
  return {
    type: actions.LOAD_SETTINGS_FAILED,
    error
  }
}

// Load settings.
export const loadSettings = token => {
  return dispatch => {
    dispatch(loadSettingsStart())
    // Perform request.
    axios(token).get('/settings')
      .then(response => {
        dispatch(loadSettingsSuccess(response.data.settings))
      })
      .catch(error => {
        dispatch(loadSettingsFailed(getErrorMessage(error)))
      })
  }
}

// Update a setting from the list.
export const updateSetting = (idx, value, isBoolean) => {
  return {
    type: actions.UPDATE_SELECTED_SETTING,
    idx,
    value,
    isBoolean
  }
}

// Start saving a setting.
export const saveSettingStart = setting => {
  return {
    type: actions.SAVE_SETTING_START,
    setting
  }
}

// Save setting success.
export const saveSettingSuccess = (id, setting) => {
  return {
    type: actions.SAVE_SETTING_SUCCESS,
    id,
    setting
  }
}

// Save setting fail.
export const saveSettingFailed = (id, error) => {
  return {
    type: actions.SAVE_SETTING_FAILED,
    id,
    error
  }
}

// Save a setting.
export const saveSetting = (id, token, key, value) => {
  return dispatch => {
    dispatch(saveSettingStart())
    if (!id) {
      return
    }
    // Convert value to string.
    if (typeof value === 'boolean') {
      value = `${+value}`
    } else if (typeof value === 'number') {
      value = `${value}`
    }
    // Perform request.
    const postData = {
      value
    }
    axios(token).put(`/setting/${key}`, postData)
      .then(response => {
        dispatch(saveSettingSuccess(id, response.data))
      })
      .catch(error => {
        dispatch(saveSettingFailed(id, getErrorMessage(error)))
      })
  }
}
