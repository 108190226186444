// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import { Link } from '@material-ui/core'
import { connect } from 'react-redux'

// Shared.
import { buildUrl, canSeeArtistContent, userCan } from '../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class AttachmentDownloadLink extends Component {
  render () {
    // Return placeholder if wrong props.
    if (!this.props.label && !this.props.downloadLink) return <span>-</span>
    // Return raw content if no download link.
    if (!this.props.downloadLink) {
      return <div className='AttachmentLabel'>{this.props.label}</div>
    }
    // Return raw content if the user doesn't have permissions.
    if (!userCan(this.props.user, 'ArrangementDocument', 'read') || !canSeeArtistContent(this.props.user, this.props.artistId)) {
      return <div className='AttachmentLabel'>{this.props.label}</div>
    }
    return (
      <Link component='a' href={buildUrl(this.props.downloadLink, { _t: this.props.token })}>
        <div className='AttachmentLabel'>{this.props.label}</div>
      </Link>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    user: state.auth.user
  }
}

// Export.
export default connect(mapStateToProps)(AttachmentDownloadLink)
