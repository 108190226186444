// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import { Link } from '@material-ui/core'
import { Link as RouterLink } from 'react-router-dom'
import { connect } from 'react-redux'

// Shared.
import { userCan } from '../../../shared/functions'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateful component declaration.
class SaleLink extends Component {
  render () {
    // Return null if wrong props.
    if (!this.props.sale || !this.props.sale.name) return null
    // Return raw text if the user doesn't have permissions.
    if (!userCan(this.props.user, 'Sale', 'list')) {
      return this.props.sale.name
    }

    return (
      <Link component={RouterLink} to='/settings/sales'>{this.props.sale.name}</Link>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    user: state.auth.user
  }
}

// Export.
export default connect(mapStateToProps)(SaleLink)
