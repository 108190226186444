// ============================================================================
// Dependencies.
// ============================================================================

// Axios instance.
import axios from '../../axios'

// Action types.
import * as actions from './types'

// Functions.
import { getErrorMessage } from '../../shared/functions'

// ============================================================================
// Constants.
// ============================================================================

// Web storage variables.
const webStorageVars = {
  token: 'token',
  expiresIn: 'expiresIn',
  cookiePolicyAccepted: 'cookiePolicyAccepted'
}

// ============================================================================
// Action creators.
// ============================================================================

// Auth start.
export const loginStart = () => {
  return {
    type: actions.LOGIN_START
  }
}

// Auth success.
export const loginSuccess = (token, user) => {
  return {
    type: actions.LOGIN_SUCCESS,
    token,
    user
  }
}

// Auth fail.
export const loginFail = (error, email) => {
  return {
    type: actions.LOGIN_FAILED,
    error,
    email
  }
}

// Log out.
export const logout = (resetRedirectPath = false) => {
  // Remove items from web storage.
  localStorage.removeItem(webStorageVars.token)
  localStorage.removeItem(webStorageVars.expiresIn)
  // Return action.
  return {
    type: actions.LOGOUT,
    resetRedirectPath
  }
}

// Check token expiration.
export const checkAuthTimeout = (expirationTime) => {
  return dispatch => {
    setTimeout(() => {
      dispatch(logout())
    }, expirationTime)
  }
}

// Authenticate in the API.
export const signIn = body => {
  return dispatch => {
    dispatch(loginStart())
    // Perform request.
    axios().post('/auth/login', body)
      .then(response => {
        // Get the expiration date.
        const tokenExpirationDate = new Date(new Date().getTime() + response.data.expiresIn * 1000)
        // Save token in web storage.
        localStorage.setItem(webStorageVars.token, response.data.token)
        localStorage.setItem(webStorageVars.expiresIn, tokenExpirationDate)
        // Dispatch actions.
        dispatch(loginSuccess(response.data.token, response.data.user))
        dispatch(checkAuthTimeout(+response.data.expiresIn * 1000))
      })
      .catch(error => {
        dispatch(loginFail(getErrorMessage(error), body.email))
      })
  }
}

// Change the auth redirect path.
export const setAuthRedirectPath = (path) => {
  return {
    type: actions.SET_LOGIN_REDIRECT_PATH,
    path: path
  }
}

// Set page in maintenance mode.
export const setMaintenanceMode = () => {
  return {
    type: actions.SET_MAINTENANCE_MODE
  }
}

// Set page in offline mode.
export const setOfflineMode = () => {
  return {
    type: actions.SET_OFFLINE_MODE
  }
}

// Unset maintenance and offline states.
export const unsetUnavailableStates = () => {
  return {
    type: actions.UNSET_UNAVAILABLE_STATES
  }
}

// Start loading the page.
const startLoading = () => {
  return {
    type: actions.START_LOADING_GLOBAL
  }
}

// Check the web storage session info.
export const checkInitialState = () => {
  return dispatch => {
    dispatch(startLoading())
    // Get project information.
    axios().get('/')
      .then(response => {
        if (response.data.maintenance) {
          return dispatch(setMaintenanceMode())
        }
        dispatch(unsetUnavailableStates)
        // Get auth items from web storage.
        const token = localStorage.getItem(webStorageVars.token)
        // If no token found, log out.
        if (!token) {
          dispatch(logout())
        } else {
          // Get expiration date.
          const expirationDate = new Date(localStorage.getItem(webStorageVars.expiresIn))
          const currentDate = new Date()
          // If not expired, same as log in.
          if (expirationDate > currentDate) {
            // Get information about current user.
            axios(token).get('/me')
              .then(response => {
                dispatch(loginSuccess(token, response.data))
                dispatch(checkAuthTimeout(expirationDate.getTime() - currentDate.getTime()))
              })
              .catch(() => {
                dispatch(logout())
              })
          } else {
            // If expired,logout.
            dispatch(logout())
          }
        }
      })
      .catch(() => {
        dispatch(setOfflineMode())
      })
  }
}

// Reset the login error.
export const resetLoginError = () => {
  return {
    type: actions.RESET_LOGIN_ERROR
  }
}

// Set password recovery start.
export const setPasswordRecoveryStart = () => {
  return {
    type: actions.SET_PASSWORD_RECOVERY_START
  }
}

// Set password recovery success.
export const setPasswordRecoverySuccess = email => {
  return {
    type: actions.SET_PASSWORD_RECOVERY_SUCCESS,
    email
  }
}

// Set password recovery fail.
export const setPasswordRecoveryFail = error => {
  return {
    type: actions.SET_PASSWORD_RECOVERY_FAILED,
    error
  }
}

// Set password recovery.
export const setPasswordRecovery = email => {
  return dispatch => {
    dispatch(setPasswordRecoveryStart())
    // Perform request.
    axios().post('/auth/password_recovery', { email })
      .then(response => {
        dispatch(setPasswordRecoverySuccess(email))
      })
      .catch(error => {
        dispatch(setPasswordRecoveryFail(getErrorMessage(error)))
      })
  }
}

// Recover password start.
export const recoverPasswordStart = () => {
  return {
    type: actions.RECOVER_PASSWORD_START
  }
}

// Recover password success.
export const recoverPasswordSuccess = () => {
  return {
    type: actions.RECOVER_PASSWORD_SUCCESS
  }
}

// Recover password fail.
export const recoverPasswordFail = error => {
  return {
    type: actions.RECOVER_PASSWORD_FAILED,
    error
  }
}

// Recover password.
export const recoverPassword = (password, token) => {
  return dispatch => {
    dispatch(recoverPasswordStart())
    // Perform request.
    axios().post('/auth/recover_password', { password, token })
      .then(() => {
        dispatch(recoverPasswordSuccess())
      })
      .catch(error => {
        dispatch(recoverPasswordFail(getErrorMessage(error)))
      })
  }
}

// Reset the password recovery page.
export const resetPasswordRecovery = () => {
  return {
    type: actions.RESET_PASSWORD_RECOVERY
  }
}

// Set the password recovery token.
export const setRecoveryToken = token => {
  return {
    type: actions.SET_RECOVERY_TOKEN,
    token
  }
}

// Toggle state of the side menu.
export const toggleSideMenuState = () => {
  return {
    type: actions.TOGGLE_SIDE_MENU_STATE
  }
}

// Expand section of the side menu.
export const expandSideMenuSection = section => {
  return {
    type: actions.EXPAND_SIDE_MENU_SECTION,
    section
  }
}

// Collapse section of the side menu.
export const collapseAllSideMenuSections = () => {
  return {
    type: actions.COLLAPSE_ALL_SIDE_MENU_SECTIONS
  }
}

// Update the logged user object.
export const updateLoggedUserFields = user => {
  return {
    type: actions.UPDATE_LOGGED_USER_FIELDS,
    user
  }
}
