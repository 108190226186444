// ============================================================================
// Dependencies.
// ============================================================================

// Axios instance.
import axios from '../../axios'

// Action types.
import * as actions from './types'

// Functions.
import { getErrorMessage } from '../../shared/functions'

// ============================================================================
// Action creators.
// ============================================================================

// Auth start.
export const loadDashboardStart = () => {
  return {
    type: actions.LOAD_DASHBOARD_STATS_START
  }
}

// Auth success.
export const loadDashboardSuccess = stats => {
  return {
    type: actions.LOAD_DASHBOARD_STATS_SUCCESS,
    stats
  }
}

// Auth fail.
export const loadDashboardFail = error => {
  return {
    type: actions.LOAD_DASHBOARD_STATS_FAILED,
    error: error
  }
}

// Load dashboard stats.
export const loadDashboardStats = token => {
  return dispatch => {
    dispatch(loadDashboardStart())
    // Perform request.
    axios(token).get('/stats/dashboard')
      .then(response => {
        dispatch(loadDashboardSuccess(response.data))
      })
      .catch(error => {
        dispatch(loadDashboardFail(getErrorMessage(error)))
      })
  }
}
