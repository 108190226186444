// ============================================================================
// Dependencies.
// ============================================================================

// Actions.
import * as actions from '../actions/types'

// Shared.
import { updateObject } from '../../shared/functions'

// ============================================================================
// Initial state.
// ============================================================================

// Initial state.
const initialState = {
  loading: true,
  error: null,
  success: null,
  settings: [],
  selectedSetting: null
}

// ============================================================================
// Functionality.
// ============================================================================

// Start loading list of settings.
const loadSettingsStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    success: null,
    settings: []
  })
}

// Finish loading list of settings.
const loadSettingsSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    settings: action.settings,
    error: null,
    success: null
  })
}

// Finish loading list of settings, with error.
const loadSettingsFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    settings: [],
    error: action.error,
    success: null
  })
}

// Update the selected setting.
const updateSetting = (state, action) => {
  const newSetting = { ...state.settings[+action.idx] }
  newSetting.value = action.isBoolean ? !newSetting.value : action.value
  const newSettings = [
    ...state.settings
  ]
  newSettings[+action.idx] = newSetting
  return updateObject(state, {
    selectedSetting: newSetting,
    settings: newSettings
  })
}

// Start saving a setting.
const saveSettingStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    success: null
  })
}

// Save a setting successfully.
const saveSettingSuccess = (state, action) => {
  const newSettings = [
    ...state.settings
  ]
  const updatedSettingIdx = state.settings.findIndex(s => +s.id === +action.id)
  const newSetting = { ...action.setting }
  newSettings[updatedSettingIdx] = newSetting
  return updateObject(state, {
    loading: false,
    error: null,
    success: 'Configuración actualizada con éxito.',
    settings: newSettings,
    selectedSetting: newSetting
  })
}

// Save a setting, with error.
const saveSettingFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    success: null
  })
}

// ============================================================================
// Reducer definition.
// ============================================================================

// Reducer definition.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_SETTINGS_START:
      return loadSettingsStart(state, action)
    case actions.LOAD_SETTINGS_SUCCESS:
      return loadSettingsSuccess(state, action)
    case actions.LOAD_SETTINGS_FAILED:
      return loadSettingsFailed(state, action)
    case actions.UPDATE_SELECTED_SETTING:
      return updateSetting(state, action)
    case actions.SAVE_SETTING_START:
      return saveSettingStart(state, action)
    case actions.SAVE_SETTING_SUCCESS:
      return saveSettingSuccess(state, action)
    case actions.SAVE_SETTING_FAILED:
      return saveSettingFailed(state, action)
    default:
      return state
  }
}

export default reducer
