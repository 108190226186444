// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'

// Components.
import TabSection from '../../../../UI/Custom/TabSection'
import StockWithdrawalsList from './StockWithdrawals/StockWithdrawalsList/StockWithdrawalsList'
import StockEntriesList from './StockEntries/StockEntriesList/StockEntriesList'
import ProductList from './Inventory/ProductList'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const Settings = props => {
  const tabs = {
    stock_withdrawals: { label: 'Salidas de stock', component: <StockWithdrawalsList />, link: '/warehouse/stock_withdrawals' },
    stock_entries: { label: 'Entradas de stock', component: <StockEntriesList />, link: '/warehouse/stock_entries' },
    inventory: { label: 'Inventario', component: <ProductList />, link: '/warehouse/inventory' }
  }

  return <TabSection tabs={tabs} {...props} />
}

// Export.
export default Settings
