// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import {
  CardContent,
  CardActionArea,
  CardMedia,
  Typography,
  Card
} from '@material-ui/core'

// Shared.
import { printNumber, printPrice } from '../../../../../../shared/functions'

// Style.
import { useStyles } from '../../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Stateless component declaration.
const ArtistStatsCardsCard = props => {
  const classes = useStyles()

  if (props.loading) {
    return (
      <Card className={classes.root} style={{ height: '100%' }}>
        <CardActionArea>
          <CardMedia className={classes.artistStatsCardPlaceholder} component='img' image='/placeholder.gif' title='Loading...' />
        </CardActionArea>
      </Card>
    )
  }

  return (
    <Card className={classes.root} style={{ height: '100%' }}>
      <CardContent>
        <Typography gutterBottom variant='h5' component='h2'>
          <b>{props.price ? printPrice(props.total) : printNumber(props.total)}</b> {props.title}
        </Typography>
        <Typography variant='body1' component='p'>
          Año anterior: <b>{props.price ? printPrice(props.totalLastYear) : printNumber(props.totalLastYear)}</b>
        </Typography>
        <Typography variant='caption' color='textSecondary' component='p' style={{ marginTop: 10 }}>
          {props.footer}
        </Typography>
      </CardContent>
    </Card>
  )
}

// Export.
export default ArtistStatsCardsCard
