// ============================================================================
// Dependencies.
// ============================================================================

// Actions.
import * as actions from '../actions/types'

// Shared.
import { buildUrl, updateObject } from '../../shared/functions'

// ============================================================================
// Initial state.
// ============================================================================

// Initial state.
const initialState = {
  loading: true,
  formLoading: false,
  error: null,
  success: null,
  users: [],
  profiles: [],
  selectedUser: null
}

// ============================================================================
// Functionality.
// ============================================================================

// Start loading list of users.
const loadUsersStart = state => {
  return updateObject(state, {
    loading: true,
    error: null,
    success: null,
    users: []
  })
}

// Finish loading list of users.
const loadUsersSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    users: action.users,
    error: null,
    success: null
  })
}

// Finish loading list of users, with error.
const loadUsersFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    users: [],
    error: action.error,
    success: null
  })
}

// Start deleting user.
const deleteUserStart = state => {
  return updateObject(state, {
    loading: true,
    success: null,
    error: null
  })
}

// Delete a user successfully.
const deleteUserSuccess = (state, action) => {
  const oldUserIdx = state.users.findIndex(e => +e.id === +action.id)
  const newUsers = [...state.users]
  newUsers.splice(oldUserIdx, 1)
  return updateObject(state, {
    loading: false,
    users: newUsers,
    error: null,
    success: `Se ha eliminado el usuario #${action.id} correctamente.`
  })
}

// Delete user, with error.
const deleteUserFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    success: null
  })
}

// Start loading user.
const loadUserStart = state => {
  return updateObject(state, {
    loading: true,
    success: null,
    error: null,
    selectedUser: null
  })
}

// Delete a user successfully.
const loadUserSuccess = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: null,
    success: null,
    selectedUser: action.user,
    userCategories: null
  })
}

// Delete user, with error.
const loadUserFailed = (state, action) => {
  return updateObject(state, {
    loading: false,
    error: action.error,
    selectedUser: null
  })
}

// Update the selected user.
const updateSelectedUser = (state, action) => {
  const newSelectedUser = {
    ...state.selectedUser
  }
  newSelectedUser[action.key] = action.value
  return updateObject(state, {
    selectedUser: newSelectedUser
  })
}

// Start saving a user.
const saveUserStart = state => {
  return updateObject(state, {
    formLoading: true,
    error: null,
    success: null
  })
}

// Save a user successfully.
const saveUserSuccess = (state, action) => {
  const newUsers = [
    ...state.users
  ]
  if (action.id) {
    const updatedUserIdx = state.users.findIndex(s => +s.id === +action.id)
    newUsers[updatedUserIdx] = { ...action.user }
  } else {
    newUsers.push(action.user)
  }
  return updateObject(state, {
    formLoading: false,
    error: null,
    success: action.isLoggedUser ? 'Tus datos han sido actualizados correctamente.' : action.id ? 'Usuario actualizado correctamente.' : 'Usuario creado correctamente.',
    users: newUsers,
    selectedUser: action.user
  })
}

// Save a user, with error.
const saveUserFailed = (state, action) => {
  return updateObject(state, {
    formLoading: false,
    error: action.error,
    success: null
  })
}

// Start loading list of profiles.
const loadProfilesStart = state => {
  return updateObject(state, {
    formLoading: true,
    error: null,
    success: null,
    profiles: []
  })
}

// Finish loading list of profiles.
const loadProfilesSuccess = (state, action) => {
  return updateObject(state, {
    formLoading: false,
    profiles: action.profiles,
    error: null,
    success: null
  })
}

// Finish loading list of profiles, with error.
const loadProfilesFailed = (state, action) => {
  return updateObject(state, {
    formLoading: false,
    profiles: [],
    error: action.error,
    success: null
  })
}

// Start uploading user image.
const uploadUserImageStart = state => {
  return updateObject(state, {
    imageLoading: true,
    error: null,
    success: null
  })
}

// Finish uploading user image.
const uploadUserImageSuccess = (state, action) => {
  const newSelectedUser = {
    ...state.selectedUser
  }
  const newUsers = [
    ...state.users
  ]
  newSelectedUser.image = buildUrl(action.image, { ck: Date.now() })
  const updatedUserIdx = newUsers.findIndex(s => +s.id === +state.selectedUser.id)
  if (updatedUserIdx > -1) {
    newUsers[updatedUserIdx].image = newSelectedUser.image
  }
  return updateObject(state, {
    imageLoading: false,
    error: null,
    selectedUser: newSelectedUser,
    users: newUsers,
    success: 'Imagen de perfil actualizada con éxito.'
  })
}

// Finish uploading user image, with error.
const uploadUserImageFailed = (state, action) => {
  return updateObject(state, {
    imageLoading: false,
    error: action.error,
    success: null
  })
}

// ============================================================================
// Reducer definition.
// ============================================================================

// Reducer definition.
const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.LOAD_USERS_START:
      return loadUsersStart(state, action)
    case actions.LOAD_USERS_SUCCESS:
      return loadUsersSuccess(state, action)
    case actions.LOAD_USERS_FAILED:
      return loadUsersFailed(state, action)
    case actions.DELETE_USER_START:
      return deleteUserStart(state, action)
    case actions.DELETE_USER_SUCCESS:
      return deleteUserSuccess(state, action)
    case actions.DELETE_USER_FAILED:
      return deleteUserFailed(state, action)
    case actions.LOAD_USER_START:
      return loadUserStart(state, action)
    case actions.LOAD_USER_SUCCESS:
      return loadUserSuccess(state, action)
    case actions.LOAD_USER_FAILED:
      return loadUserFailed(state, action)
    case actions.UPDATE_SELECTED_USER:
      return updateSelectedUser(state, action)
    case actions.SAVE_USER_START:
      return saveUserStart(state, action)
    case actions.SAVE_USER_SUCCESS:
      return saveUserSuccess(state, action)
    case actions.SAVE_USER_FAILED:
      return saveUserFailed(state, action)
    case actions.LOAD_PROFILES_START:
      return loadProfilesStart(state, action)
    case actions.LOAD_PROFILES_SUCCESS:
      return loadProfilesSuccess(state, action)
    case actions.LOAD_PROFILES_FAILED:
      return loadProfilesFailed(state, action)
    case actions.UPLOAD_USER_IMAGE_START:
      return uploadUserImageStart(state, action)
    case actions.UPLOAD_USER_IMAGE_SUCCESS:
      return uploadUserImageSuccess(state, action)
    case actions.UPLOAD_USER_IMAGE_FAILED:
      return uploadUserImageFailed(state, action)
    default:
      return state
  }
}

export default reducer
