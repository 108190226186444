// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React from 'react'
import ReactDOM from 'react-dom'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware, combineReducers, compose } from 'redux'

// Styles.
import './index.css'

// Components.
import App from './app/App'

// Service worker.
import * as serviceWorker from './serviceWorker'

// Reducers.
import authReducer from './store/reducers/auth'
import usersReducer from './store/reducers/users'
import sitesReducer from './store/reducers/sites'
import salesReducer from './store/reducers/sales'
import artistsReducer from './store/reducers/artists'
import productsReducer from './store/reducers/products'
import settingsReducer from './store/reducers/settings'
import dashboardReducer from './store/reducers/dashboard'
import categoriesReducer from './store/reducers/categories'
import settlementsReducer from './store/reducers/settlements'
import salesReportsReducer from './store/reducers/salesReports'
import earningTypesReducer from './store/reducers/earningTypes'
import expenseTypesReducer from './store/reducers/expenseTypes'
import posTerminalsReducer from './store/reducers/posTerminals'
import stockMovementsReducer from './store/reducers/stockMovements'
import arrangementDocumentsReducer from './store/reducers/arrangementDocuments'
import productScheduledDiscountsReducer from './store/reducers/productScheduledDiscounts'

// =============================================================================
// Main.
// =============================================================================

// Compose Redux enhancers.
const middlewareEnhancer = applyMiddleware(thunk)
const composedEnhancers = compose(middlewareEnhancer)

// Combine reducers.
const rootReducer = combineReducers({
  auth: authReducer,
  users: usersReducer,
  sites: sitesReducer,
  sales: salesReducer,
  artists: artistsReducer,
  products: productsReducer,
  settings: settingsReducer,
  dashboard: dashboardReducer,
  categories: categoriesReducer,
  settlements: settlementsReducer,
  salesReports: salesReportsReducer,
  earningTypes: earningTypesReducer,
  expenseTypes: expenseTypesReducer,
  posTerminals: posTerminalsReducer,
  stockMovements: stockMovementsReducer,
  arrangementDocuments: arrangementDocumentsReducer,
  productScheduledDiscounts: productScheduledDiscountsReducer
})

// Create store.
const store = createStore(rootReducer, composedEnhancers)

// Init the app component.
const app = (
  <Provider store={store}>
    <App />
  </Provider>
)

// Create compose enhancers.
ReactDOM.render(app, document.getElementById('root'))

// Register service worker.
serviceWorker.register()
