// =============================================================================
// Dependencies.
// =============================================================================

// Vendor.
import React, { Component } from 'react'
import {
  Grid,
  Paper
} from '@material-ui/core'
import { connect } from 'react-redux'
import Pagination from '@material-ui/lab/Pagination'

// Components.
import ProductsTable from './ProductsTable'
import Title from '../../../../../UI/Brand/Title'
import FormField from '../../../../../UI/Form/FormField'
import AlertError from '../../../../../UI/Alerts/AlertError'
import AlertInfo from '../../../../../UI/Alerts/AlertInfo'
import AlertWarning from '../../../../../UI/Alerts/AlertWarning'
import CreateButton from '../../../../../UI/Buttons/CreateButton'
import RefreshButton from '../../../../../UI/Buttons/RefreshButton'

// Shared.
import { applyFilter, calculateNumPages } from '../../../../../../shared/functions'
import { QUERY_STRING_MIN_LENGTH, TEXT_SEARCH_DELAY } from '../../../../../../shared/constants'

// Redux actions.
import * as actions from '../../../../../../store/actions'

// Styles.
import { useStyles } from '../../../../../styles'

// =============================================================================
// Component declaration.
// =============================================================================

// Init timeout.
let searchTimeout

// Stateful component declaration.
class ProductList extends Component {
  state = {
    page: 1,
    orderBy: 'id',
    orderDirection: 'asc',
    fields: {
      queryString: {
        label: 'Búsqueda por nombre',
        error: null,
        type: 'text',
        required: true,
        touched: false,
        validation: val => val.length >= 3,
        errMsg: 'Por favor, escribe al menos 3 caracteres.',
        autoFocus: true
      }
    }
  }

  componentDidMount () {
    document.addEventListener('keydown', this.escFunction, false)
  }

  escFunction = e => {
    if (e.keyCode === 27) {
      this.handleChange('')
    }
  }

  handleChange = newValue => {
    // Create a copy of the state object.
    const newElement = {
      ...this.state.fields.queryString
    }
    // Modify the element value.
    this.props.updateProductQueryString(newValue)
    // Also, set touched to true.
    newElement.touched = true
    // Check element validity.
    if (newElement.required && !newValue.length && newElement.touched) {
      newElement.error = 'Campo obligatorio'
    } else if (newElement.validation && newValue.length && !newElement.validation(newValue)) {
      newElement.error = newElement.errMsg
    } else {
      newElement.error = null
    }
    // Start searching products if the length of the query string is higher then the limit.
    if (newValue.length && newValue.length >= QUERY_STRING_MIN_LENGTH) {
      this.searchProductsWithDelay(newValue)
    }
    // Update the state.
    this.setState({
      ...this.state,
      fields: {
        queryString: newElement
      }
    })
  }

  handlePageChange = (e, value) => {
    this.setState({ page: +value })
  }

  searchProductsWithDelay = queryString => {
    // First, reset the timeout.
    clearTimeout(searchTimeout)
    // Set new timeout.
    searchTimeout = setTimeout(() => {
      this.props.searchProducts(this.props.token, { q: queryString })
    }, TEXT_SEARCH_DELAY)
  }

  handleSort = (fieldName) => event => {
    // Create a copy of the state object.
    const newState = { ...this.state }
    // Set the order field.
    newState.orderBy = fieldName
    // Toggle the order direction.
    newState.orderDirection = this.state.orderBy === fieldName ? (newState.orderDirection === 'asc' ? 'desc' : 'asc') : 'asc'
    // Update the state.
    this.setState(newState)
  }

  render () {
    // Load content dynamically.
    let content = null

    if (this.props.error) {
      content = <AlertError error={this.props.error} />
    } else if (!this.props.queryString.length || this.props.queryString.length < QUERY_STRING_MIN_LENGTH) {
      content = <AlertInfo>Por favor, escribe al menos 3 caracteres para comenzar la búsqueda.</AlertInfo>
    } else if (!this.props.loading && !this.props.products.length) {
      content = <AlertWarning>No se han encontrado resultados para "{this.props.queryString}".</AlertWarning>
    } else {
      // Filter results.
      const products = applyFilter(this.props.products, {}, this.state.orderBy, this.state.orderDirection)

      content = (
        <>
          <ProductsTable
            loading={this.props.loading}
            products={products}
            page={this.state.page}
            orderBy={this.state.orderBy}
            orderDirection={this.state.orderDirection}
            onSort={this.handleSort}
            simplified
          />
          <Grid container spacing={3} className={this.props.classes.tableTools}>
            <Grid item xs={4}>
              <Pagination
                count={calculateNumPages(products)}
                page={this.state.page}
                onChange={this.handlePageChange}
              />
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'center' }}>
              {products.length} resultados
            </Grid>
            <Grid item xs={4} style={{ textAlign: 'right' }}>
              <RefreshButton
                model='Product'
                onClick={() => this.searchProductsWithDelay(this.props.queryString)}
              />
              <CreateButton
                model='Product'
                link='/product/create'
              />
            </Grid>
          </Grid>
        </>
      )
    }

    const form = (
      <form className={this.props.classes.rootWrap} noValidate autoComplete='off'>
        <FormField
          fullWidth
          key='queryString'
          name='queryString'
          type={this.state.fields.queryString.type}
          autoFocus={this.state.fields.queryString.autoFocus}
          required={this.state.fields.queryString.required}
          error={this.state.fields.queryString.error}
          label={this.state.fields.queryString.label}
          value={this.props.queryString}
          handleChange={e => this.handleChange(e.target.value)}
          options={this.state.fields.queryString.options}
          helperText={this.state.fields.queryString.helperText}
          noMargin={this.state.fields.queryString.noMargin}
        />
      </form>
    )

    return (
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <Paper className={this.props.classes.paper}>
            <div className='ProductList'>
              <Title>Inventario</Title>
              {form}
              {content}
            </div>
          </Paper>
        </Grid>
      </Grid>
    )
  }
}

// ============================================================================
// Connect with Redux and export.
// ============================================================================

// State mapping.
const mapStateToProps = state => {
  return {
    token: state.auth.token,
    loading: state.products.loading,
    error: state.products.error,
    products: state.products.products,
    queryString: state.products.queryString
  }
}

// Action mapping.
const mapDispatchToProps = dispatch => {
  return {
    searchProducts: (token, params) => dispatch(actions.searchProducts(token, params)),
    updateProductQueryString: queryString => dispatch(actions.updateProductQueryString(queryString))
  }
}

// Style component.
const ProductListStyled = props => {
  const classes = useStyles()
  return (
    <ProductList classes={classes} {...props} />
  )
}

// Export.
export default connect(mapStateToProps, mapDispatchToProps)(ProductListStyled)
